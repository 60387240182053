import "react-toastify/dist/ReactToastify.css";
import Loader from "../../../assets/images/LoaderNew.gif";
import Logosm from "../../../assets/images/logo.png";
import MainLayout from "../../../layouts";
import NoRecordFound from "../../../assets/images/noRecordFound.png";
import React, { useEffect, useRef, useState } from "react";
import ReactPaginate from "react-paginate";
import Swal from "sweetalert2";
import moment from "moment";
import { Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { ToastContainer, toast } from "react-toastify";
import { PATH } from "../../../constants/paths";

import {
  Email,
  File,
  Edit,
  WorkConfirmation,
  Search,
  Void,
  Approve,
  Print,
  Delete,
  Delete2,
} from "../../../Icons/Icons";
import {
  adminApiCall,
  capatalizeLetters,
  userAccessibility,
  getDate,
  getDateOnly,
} from "../../../helpers/ajax_request";
import { formatDollarToUS } from "../../../helpers/converter";

export default function Index() {
  const buildingName = localStorage.getItem("building_namee");
  const [modalShow, setModalShow] = useState(false);
  const [purchaseOrderListing, setPurchaseOrderListing] = useState([]);
  const [loader, setLoader] = useState(true);
  const [noRecord, setNoRecord] = useState(false);
  const [pageCount, setPageCount] = React.useState(10);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [totalRecord, setTotalRecord] = React.useState(10);
  const [perPage, setPerPage] = React.useState(50);
  const [deleteItem, setDeleteItem] = useState([]);
  const [searchBar, setSearchBar] = useState("");
  const [voidDescription, setVoidDescription] = useState("");
  const [listID, setListID] = useState("");
  const [orderID, setOrderID] = useState("");
  const [approveSendEmailCheckBox, setApproveSendEmailCheckBox] =
    useState(false);
  const [printPO, setPrintPO] = useState(false);
  const [printMS, setPrintMS] = useState(false);
  const [purchaseOrderID, setpurchaseOrderID] = useState(false);
  const [description, setdescription] = useState(false);
  const [orderType, setorderType] = useState(false);
  const [assignedTrader, setAssignedTrader] = useState(false);
  const [orderRequestedAt, setorderRequestedAt] = useState(false);
  const [orderState, setorderState] = useState(false);
  const [orderStatus, setorderStatus] = useState(false);
  const [sortValue, setSortValue] = useState("0");
  const [addComments, setAddComments] = useState("");

  const printTablePurchase = useRef(null);
  const printWCPurchase = useRef(null);
  const printTableMaintenance = useRef(null);
  const printWCMaintenance = useRef(null);
  // purchase
  const pOPrintPurchase = useReactToPrint({
    content: () => printTablePurchase.current,
    pageStyle:
      "@media print { body { -webkit-print-color-adjust: exact; } @page { size: A4; margin-top:200mm }}",

    copyStyles: true,
  });
  const wCPrintPurchase = useReactToPrint({
    pageStyle:
      "@media print { body { -webkit-print-color-adjust: exact; } @page { size: A4; margin-top:200mm }}",
    content: () => printWCPurchase.current,
    copyStyles: true,
  });
  // maintenance
  const pOPrintMaintenance = useReactToPrint({
    pageStyle:
      "@media print { body { -webkit-print-color-adjust: exact; } @page { size: A4; margin-top:200mm }}",
    content: () => printTableMaintenance.current,
  });
  const wCPrintMaintenance = useReactToPrint({
    pageStyle:
      "@media print { body { -webkit-print-color-adjust: exact; } @page { size: A4; margin-top:200mm }}",
    content: () => printWCMaintenance.current,
  });

  const searchHandler = (e) => {
    setSearchBar(e.target.value);
    // console.log(e.target.value);
  };

  const purchaseOrderIDSorting = () => {
    setpurchaseOrderID(!purchaseOrderID);
    setSortValue("1");
  };
  const descriptionSorting = () => {
    setdescription(!description);
  };

  const orderTypeSorting = () => {
    setorderType(!orderType);
    setSortValue("2");
  };

  const assignTraderSorting = () => {
    setAssignedTrader(!assignedTrader);
    setSortValue("3");
  };

  const orderRequestedAtSorting = () => {
    setorderRequestedAt(!orderRequestedAt);
    setSortValue("4");
  };

  const orderStateSorting = () => {
    setorderState(!orderState);
    setSortValue("5");
  };

  const orderStatusSorting = () => {
    setorderStatus(!orderStatus);
    setSortValue("6");
  };

  useEffect(() => {
    listPurchaseOrder();
  }, [
    currentPage,
    purchaseOrderID,
    description,
    orderType,
    assignedTrader,
    orderRequestedAt,
    orderState,
    orderStatus,
    searchBar,
  ]);

  toast.configure();

  const notifySuccess = (msg) =>
    toast.success(msg, {
      autoClose: 3000,
    });

  const notifyWarning = (msg) =>
    toast.warning(msg, {
      autoClose: 3000,
    });
  const notifyError = (msg) =>
    toast.error(msg, {
      autoClose: 3000,
    });

  const listPurchaseOrder = async () => {
    let data = {
      current_page: currentPage,
      building_id: localStorage.getItem("building_id"),
      itemsPerPage: perPage,
      search_value: searchBar,
      ...(sortValue === "1"
        ? {
            sort_by_purchase_order_id: purchaseOrderID,
          }
        : sortValue === "2"
        ? {
            sort_by_order_type: orderType,
          }
        : sortValue === "3"
        ? {
            sort_by_assigned_trader: assignedTrader,
          }
        : sortValue === "4"
        ? {
            sort_by_order_requested_at: orderRequestedAt,
          }
        : sortValue === "5"
        ? {
            sort_by_order_state: orderState,
          }
        : sortValue === "6"
        ? {
            sort_by_order_status: orderStatus,
          }
        : ""),
      // sort_by_description: description,
      // sort_by_created_at: false,
    };

    let res = await adminApiCall(
      "/api/admin/purchase_order/list",
      "POST",
      JSON.stringify(data)
    );
    if (!res) {
      notifyError("Something went wrong.. Please Try Again");
      return false;
    }
    if (res.hasOwnProperty("status")) {
      if (localStorage.getItem("building_id") === null) {
        notifyError("Please Select a Building First");
        setLoader(false);
        setNoRecord(true);
      } else if (res.status === 200) {
        setPurchaseOrderListing(res.data);
        // setMaintenanceDetails(res.data?.)
        setPageCount(res.total_pages);
        setTotalRecord(res.total_records);
        setLoader(false);
        setNoRecord(false);
        // console.log("res.data: ", res.data);
      } else if (res.status === 400) {
        notifyError(res.message);
        setLoader(false);
        setNoRecord(true);
      } else if (res.status === 300) {
        setLoader(false);
        setNoRecord(true);
      }
    }
  };

  const handleChange = (e) => {
    const itemPerPage = parseInt(e.target.value);
    setPerPage(itemPerPage);
  };

  const handlePageClick = (e) => {
    const selectedPage = e.selected + 1;
    setCurrentPage(selectedPage);
  };

  function MyVerticallyCenteredModal(props) {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="justify-content-center">
          <Modal.Title id="contained-modal-title-vcenter">
            <h2 className="modalTitle text-left"> Choose a Category</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="text text-center">
            To create the purchase order, Please select the category first
          </p>
          <div className="formDesign text-center">
            <Link
              className="myBtn shadow "
              style={{ minWidth: "176px" }}
              to={PATH.MAINTENANCE_SERVICES}
            >
              Maintenance Service
            </Link>
            <br />
            <br />
            <Link
              className="myBtn shadow "
              style={{ minWidth: "176px" }}
              to={PATH.PURCHASE_PARTS}
            >
              Purchase Parts
            </Link>
          </div>
        </Modal.Body>
      </Modal>
    );
  }

  const voidListing = async () => {
    let data = {
      purchase_order_id: listID,
      reason_for_void: voidDescription,
    };

    let res = await adminApiCall(
      "/api/admin/purchase_order/void_order",
      "POST",
      JSON.stringify(data)
    );
    if (!res) {
      notifyError("Something went wrong.. Please Try Again");
      return false;
    }
    if (res.hasOwnProperty("status")) {
      if (res.status === 200) {
        notifySuccess("Order Void Successfully!");
        setListID("");
        setOrderID("");
        setVoidDescription("");
        listPurchaseOrder();
      } else if (res.status === 400) {
        notifyError(res.message);
      }
    }
  };

  const approveListing = async () => {
    let data = {
      purchase_order_id: listID,
      send_to_trader: approveSendEmailCheckBox,
    };

    // console.log(data);
    // return;

    let res = await adminApiCall(
      "/api/admin/purchase_order/approve_order",
      "POST",
      JSON.stringify(data)
    );
    if (!res) {
      notifyError("Something went wrong.. Please Try Again");
      return false;
    }
    if (res.hasOwnProperty("status")) {
      if (res.status === 200) {
        notifySuccess("Order has been Approved!");
        setListID("");
        setOrderID("");
        listPurchaseOrder();
      } else if (res.status === 400) {
        notifyError(res.message);
      }
    }
  };

  const emailToContractor = async (id) => {
    let data = {
      purchase_order_id: id,
    };

    // console.log("data: ===> ", data);
    // return;

    let res = await adminApiCall(
      "/api/admin/purchase_order/email_contractor",
      "POST",
      JSON.stringify(data)
    );
    if (!res) {
      notifyError("Something went wrong.. Please Try Again");
      return false;
    }
    if (res.hasOwnProperty("status")) {
      if (res.status === 200) {
        console.log("res =======> email =======>", res);
        notifySuccess("Email Sent to Contractor!");
      } else if (res.status === 400) {
        notifyError(res.message);
      }
    }
  };

  const handleChangeCheckBox = (e) => {
    let checked = e.target.checked;
    let value = e.target.value;
    let tempArray = [...deleteItem];
    if (checked) {
      tempArray.push(value);
      // console.log("addeditem: ", tempArray);
    } else {
      tempArray.splice(tempArray.indexOf(value), 1);
      // console.log("deleteitem: ", tempArray);
    }
    setDeleteItem(tempArray);
    // }
  };

  const deleteUser = (deleteArray) => {
    Swal.fire({
      title: "Are you sure!",
      text: "You want to delete?",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "No, cancel please!",
      confirmButtonText: "Yes! Delete This",
      confirmButtonColor: "#d97228",
      closeOnConfirm: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        let data;
        if (typeof deleteArray === "number") {
          // let dataArray = [];
          // dataArray.push(deleteArray);
          data = {
            purchase_order_id: deleteArray,
          };
        } else {
          data = {
            purchase_order_id: deleteArray,
          };
        }
        let res = await adminApiCall(
          "/api/admin/purchase_order/delete",
          "POST",
          JSON.stringify(data)
        );
        if (!res) {
          notifyError("Something went wrong.. Please Try Again");
          return false;
        }
        if (res.hasOwnProperty("status")) {
          if (res.status === 200) {
            listPurchaseOrder();
            setDeleteItem([]);
            notifySuccess("Building Deleted Successfully!");
          } else if (res.status === 400) {
            notifyError(res.message);
          }
        }
      }
    });
  };
  ///////////////////////////////////////Purchase View ///////////////////////////////////////

  const [purchaseOrderListingg, setPurchaseOrderListingg] = useState({});
  // console.log(purchaseOrderListingg);
  const [purchaseItemsList, setpurchaseItemsList] = useState([]);

  const getPurchaseOrder = async (ID, type) => {
    let data = {
      purchase_order_id: ID,
      order_type: "purchase_parts",
    };
    console.log("========", data, "===========");
    let res = await adminApiCall(
      "/api/admin/purchase_order/get",
      "POST",
      JSON.stringify(data)
    );
    if (!res) {
      notifyError("Something went wrong.. Please Try Again");
      return false;
    }
    if (res.hasOwnProperty("status")) {
      if (res.status === 200) {
        console.log("res", res);
        setPurchaseOrderListingg(res.data);
        setpurchaseItemsList(res.data?.purchase_items);
        console.log("purchase type: ", type);

        if (type === "ptable") {
          wCPrintPurchase();
        } else if (type === "pwc") {
          pOPrintPurchase();
        }
      } else if (res.status === 400) {
        notifyError(res.message);
      }
    }
  };

  ///////////////////////////////////////Maintenance View ///////////////////////////////////////

  const [maintenanceOrderListing, setMaintenanceOrderListing] = useState({});
  const [maintenanceServiceItems, setMaintenanceServiceItems] = useState([]);

  const getMaintenanceService = async (ID, type) => {
    let data = {
      purchase_order_id: ID,
      order_type: "maintenance_service",
    };
    let res = await adminApiCall(
      "/api/admin/purchase_order/get",
      "POST",
      JSON.stringify(data)
    );
    if (!res) {
      notifyError("Something went wrong.. Please Try Again");
      return false;
    }
    if (res.hasOwnProperty("status")) {
      if (res.status === 200) {
        // console.log("res", res);
        setMaintenanceOrderListing(res.data);
        setMaintenanceServiceItems(res.data?.maintenance_service_items);
        // console.log("maintenance type: ", type);

        if (type === "mtable") {
          wCPrintMaintenance();
        } else if (type === "mwc") {
          pOPrintMaintenance();
        }
      } else if (res.status === 400) {
        notifyError(res.message);
      }
    }
  };

  const addComment = async () => {
    let data = {
      purchase_order_id: purchaseOrderID,
      comment: { comment: addComments },
    };
    let res = await adminApiCall(
      "/api/admin/purchase_order/add_comment",
      "POST",
      JSON.stringify(data)
    );
    if (!res) {
      notifyError("Something went wrong.. Please Try Again");
      return false;
    }
    if (res.hasOwnProperty("status")) {
      if (res.status === 200) {
        setAddComments("");
        getPurchaseOrder();
        notifySuccess("Comment Has Been Added!");
      } else if (res.status === 400) {
        notifyError(res.message);
      }
    }
  };

  const deleteComment = (deleteArray) => {
    // console.log("deleteArray", deleteArray);
    Swal.fire({
      title: "Are you sure!",
      text: "You want to delete?",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "No, cancel please!",
      confirmButtonText: "Yes! Delete This",
      confirmButtonColor: "#d97228",
      closeOnConfirm: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        let data;
        if (typeof deleteArray === "number") {
          data = {
            purchase_order_id: purchaseOrderID,
            comment_ids: deleteArray,
          };
        } else {
          data = {
            purchase_order_id: purchaseOrderID,
            comment_ids: deleteArray,
          };
        }
        let res = await adminApiCall(
          "/api/admin/purchase_order/delete_comment",
          "POST",
          JSON.stringify(data)
        );
        if (!res) {
          notifyError("Something went wrong.. Please Try Again");
          return false;
        }
        if (res.hasOwnProperty("status")) {
          if (res.status === 200) {
            getPurchaseOrder();
            notifySuccess("Comment Deleted Successfully!");
          } else if (res.status === 400) {
            notifyError(res.message);
          }
        }
      }
    });
  };
  console.log("maintenanceServiceItems", maintenanceServiceItems);

  return (
    <MainLayout>
      <>
        {/* <style>
          {`@page { margin: 20px !important; }`}
        </style> */}
        <section className="pageWrapper">
          <div className="contentCard">
            <div className="titleWrapper">
              <span>Purchase Orders</span>

              <div className="d-flex justify-content-center align-items-center">
                <div className="search mr-2">
                  <div className="searchField">
                    <input
                      className="form-control searchBar"
                      type="text"
                      placeholder="Search"
                      value={searchBar}
                      onChange={searchHandler}
                    />
                    <div className="icon">
                      <Search />
                    </div>
                  </div>
                </div>
                {localStorage.getItem("role") === "viewer" ? (
                  userAccessibility("purchase_order_management", "create") ===
                  false ? (
                    ""
                  ) : (
                    <div>
                      <Button
                        className="myBtn reactBoostrapModalBtn shadow ml-auto"
                        onClick={() => setModalShow(true)}
                      >
                        <i className="reactBoostrapIcon fa fa-plus-circle"></i>
                        Generate Purchase Order
                      </Button>
                    </div>
                  )
                ) : localStorage.getItem("role") === "building_manager" ? (
                  userAccessibility("purchase_order_management", "create") ===
                  false ? (
                    ""
                  ) : (
                    <div>
                      <Button
                        className="myBtn reactBoostrapModalBtn shadow ml-auto"
                        onClick={() => setModalShow(true)}
                      >
                        <i className="reactBoostrapIcon fa fa-plus-circle"></i>
                        Generate Purchase Order
                      </Button>
                    </div>
                  )
                ) : localStorage.getItem("role") === "property_manager" ? (
                  userAccessibility("purchase_order_management", "create") ===
                  false ? (
                    ""
                  ) : (
                    <div>
                      <Button
                        className="myBtn reactBoostrapModalBtn shadow ml-auto"
                        onClick={() => setModalShow(true)}
                      >
                        <i className="reactBoostrapIcon fa fa-plus-circle"></i>
                        Generate Purchase Order
                      </Button>
                    </div>
                  )
                ) : (
                  <div>
                    <Button
                      className="myBtn reactBoostrapModalBtn shadow ml-auto"
                      onClick={() => setModalShow(true)}
                    >
                      <i className="reactBoostrapIcon fa fa-plus-circle"></i>
                      Generate Purchase Order
                    </Button>
                  </div>
                )}
              </div>

              <MyVerticallyCenteredModal
                show={modalShow}
                onHide={() => setModalShow(false)}
              />
            </div>

            <div className="filters">
              <div className="row"></div>
            </div>

            {loader ? (
              <div className="text-center">
                <img src={Loader} alt="" />
              </div>
            ) : noRecord ? (
              <div className="text-center">
                <img src={NoRecordFound} alt="" />
              </div>
            ) : (
              <div className="mainTable ax table-responsive dataTable scrollable abb">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      {localStorage.getItem("role") === "viewer" ? (
                        userAccessibility(
                          "purchase_order_management",
                          "delete"
                        ) === false ? (
                          ""
                        ) : (
                          <th></th>
                        )
                      ) : localStorage.getItem("role") ===
                        "building_manager" ? (
                        userAccessibility(
                          "purchase_order_management",
                          "delete"
                        ) === false ? (
                          ""
                        ) : (
                          <th></th>
                        )
                      ) : localStorage.getItem("role") ===
                        "property_manager" ? (
                        userAccessibility(
                          "purchase_order_management",
                          "delete"
                        ) === false ? (
                          ""
                        ) : (
                          <th></th>
                        )
                      ) : (
                        <th></th>
                      )}

                      <th onClick={purchaseOrderIDSorting} className="sort">
                        Order ID
                      </th>
                      <th onClick={orderTypeSorting} className="sort">
                        Type
                      </th>
                      <th onClick={assignTraderSorting} className="sort">
                        Assigned Trade
                      </th>
                      <th onClick={orderRequestedAtSorting} className="sort">
                        Requested Date
                      </th>
                      <th onClick={orderStateSorting} className="sort">
                        State
                      </th>
                      <th onClick={orderStatusSorting} className="sort">
                        Status
                      </th>
                      <th className="text-right">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {purchaseOrderListing &&
                      purchaseOrderListing.map((v, i) => {
                        return (
                          <tr key={i}>
                            {localStorage.getItem("role") === "viewer" ? (
                              userAccessibility(
                                "purchase_order_management",
                                "delete"
                              ) === false ? (
                                ""
                              ) : (
                                <td>
                                  <input
                                    type="checkbox"
                                    className="customCheck"
                                    id={"purchaseOrder" + i}
                                    value={v._id}
                                    checked={
                                      deleteItem && deleteItem.includes(v._id)
                                        ? true
                                        : false
                                    }
                                    onChange={(e) => handleChangeCheckBox(e)}
                                  />
                                </td>
                              )
                            ) : localStorage.getItem("role") ===
                              "building_manager" ? (
                              userAccessibility(
                                "purchase_order_management",
                                "delete"
                              ) === false ? (
                                ""
                              ) : (
                                <td>
                                  <input
                                    type="checkbox"
                                    className="customCheck"
                                    id={"purchaseOrder" + i}
                                    value={v._id}
                                    checked={
                                      deleteItem && deleteItem.includes(v._id)
                                        ? true
                                        : false
                                    }
                                    onChange={(e) => handleChangeCheckBox(e)}
                                  />
                                </td>
                              )
                            ) : localStorage.getItem("role") ===
                              "property_manager" ? (
                              userAccessibility(
                                "purchase_order_management",
                                "delete"
                              ) === false ? (
                                ""
                              ) : (
                                <td>
                                  <input
                                    type="checkbox"
                                    className="customCheck"
                                    id={"purchaseOrder" + i}
                                    value={v._id}
                                    checked={
                                      deleteItem && deleteItem.includes(v._id)
                                        ? true
                                        : false
                                    }
                                    onChange={(e) => handleChangeCheckBox(e)}
                                  />
                                </td>
                              )
                            ) : (
                              <td>
                                <input
                                  type="checkbox"
                                  className="customCheck"
                                  id={"purchaseOrder" + i}
                                  value={v._id}
                                  checked={
                                    deleteItem && deleteItem.includes(v._id)
                                      ? true
                                      : false
                                  }
                                  onChange={(e) => handleChangeCheckBox(e)}
                                />
                              </td>
                            )}

                            <td className="orderItems">
                              <Link
                                className="textOrderItem"
                                to={
                                  v.order_type === "purchase_parts"
                                    ? `${PATH.PURCHASE_PART_DETAIL}/${v._id}`
                                    : `${PATH.MAINTENANCE_SERVICES_DETAIL}/${v._id}`
                                  // "#"
                                }
                              >
                                <b>{v.order_id} </b>
                                <span className="magnifyIcon">
                                  <Search />
                                  <div className="popUpHover mainTable table-responsive">
                                    {v.order_type === "purchase_parts" ? (
                                      <>
                                        <table className="table table-striped">
                                          <thead>
                                            <tr
                                              style={{
                                                background: "#606071",
                                              }}
                                            >
                                              <th>Category</th>
                                              <th>Model Number</th>
                                              <th>Description</th>
                                              <th>Apt. No</th>
                                              <th>Price</th>
                                              <th>Qty.</th>
                                              <th>Qty. Received</th>
                                              {/* <th className="text-right"></th> */}
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {v?.purchase_items &&
                                              v?.purchase_items.map(
                                                (val, index) => {
                                                  return (
                                                    <tr key={index}>
                                                      <td>
                                                        <b>
                                                          {
                                                            val.inventory_category
                                                          }
                                                        </b>
                                                      </td>
                                                      <td>
                                                        {val.model_number}
                                                      </td>
                                                      <td>{val.description}</td>
                                                      <td>{val.suite_no}</td>
                                                      <td>
                                                        <b>
                                                          $
                                                          {(
                                                            (val.price * 100) /
                                                            100
                                                          ).toLocaleString(
                                                            undefined,
                                                            {
                                                              minimumFractionDigits: 2,
                                                            }
                                                          )}
                                                        </b>
                                                      </td>
                                                      <td>{val.quantity}</td>
                                                      <td>
                                                        <span
                                                          className={`text ${
                                                            val.quantity_received ===
                                                            val.quantity
                                                              ? "success"
                                                              : "danger"
                                                          }`}
                                                          style={{
                                                            minWidth: "110px",
                                                          }}
                                                        >
                                                          <span className=""></span>
                                                          <small
                                                            style={{
                                                              fontSize: "18px",
                                                              paddingLeft:
                                                                "3px",
                                                            }}
                                                          >
                                                            {
                                                              val.quantity_received
                                                            }
                                                          </small>
                                                          {val?.received_date ===
                                                          null ? (
                                                            ""
                                                          ) : (
                                                            <small
                                                              style={{
                                                                color:
                                                                  "#606071",
                                                              }}
                                                            >
                                                              (
                                                              {getDateOnly(
                                                                val?.received_date
                                                              )}
                                                              ){" "}
                                                            </small>
                                                          )}
                                                        </span>
                                                      </td>
                                                    </tr>
                                                  );
                                                }
                                              )}
                                          </tbody>
                                        </table>
                                      </>
                                    ) : (
                                      <>
                                        <table className="table table-striped">
                                          <thead>
                                            <tr
                                              style={{
                                                background: "#606071",
                                              }}
                                            >
                                              <th>Description</th>
                                              <th>Apt. No</th>
                                              <th>Price</th>
                                              <th className="text-center">
                                                Completed
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {v?.maintenance_service_items &&
                                              v?.maintenance_service_items.map(
                                                (value, ind) => {
                                                  return (
                                                    <tr key={ind}>
                                                      <td>
                                                        <b>
                                                          {value.description}
                                                        </b>
                                                      </td>
                                                      <td>{value.suite_no}</td>
                                                      <td>
                                                        <b>
                                                          {value.price
                                                            ? "$" +
                                                              (
                                                                (value.price *
                                                                  100) /
                                                                100
                                                              ).toLocaleString(
                                                                undefined,
                                                                {
                                                                  minimumFractionDigits: 2,
                                                                }
                                                              )
                                                            : ""}
                                                        </b>
                                                      </td>
                                                      <td className="text-center">
                                                        <input
                                                          type="checkbox"
                                                          className="customRadio"
                                                          checked={
                                                            value.is_complete
                                                              ? true
                                                              : false
                                                          }
                                                          disabled
                                                        />
                                                      </td>
                                                    </tr>
                                                  );
                                                }
                                              )}
                                          </tbody>
                                        </table>
                                      </>
                                    )}
                                  </div>
                                </span>
                              </Link>
                            </td>
                            <td>
                              {capatalizeLetters(v.order_type.split("_")[0]) +
                                " " +
                                capatalizeLetters(v.order_type.split("_")[1])}
                            </td>
                            <td>{v.trader}</td>
                            <td>
                              {/* {moment(v.order_requested_at).format(
                                "DD MMMM YYYY"
                              )} */}
                              {moment
                                .utc(new Date(v.order_requested_at))
                                .format("DD MMMM YYYY")}
                            </td>
                            <td>
                              <span
                                className={`badge ${
                                  v.order_state === "PENDING"
                                    ? "grey"
                                    : v.order_state === "APPROVED"
                                    ? "pending"
                                    : v.order_state === "CONFIRM"
                                    ? "success"
                                    : "danger"
                                }`}
                              >
                                {v.order_state}
                              </span>
                            </td>
                            <td>
                              {" "}
                              <span
                                className={`text ${
                                  v.order_status === "COMPLETED"
                                    ? "success"
                                    : v.order_status === "PARTIALLY_COMPLETE"
                                    ? "pending"
                                    : "danger"
                                }`}
                              >
                                <span></span>{" "}
                                {v.order_status === "COMPLETED"
                                  ? "INVOICE RECEIVED"
                                  : v.order_status === "PARTIALLY_COMPLETE"
                                  ? "RECEIVED"
                                  : "INCOMPLETE"}
                              </span>
                            </td>

                            <td className="text-right">
                              <div className="dropleft">
                                <button
                                  type="button"
                                  className="actionEclips dropdown-toggle"
                                  data-toggle="dropdown"
                                >
                                  <span></span>
                                  <span></span>
                                  <span></span>
                                </button>
                                <div className="dropdown-menu dropDesign ">
                                  {localStorage.getItem("role") === "viewer" ? (
                                    userAccessibility(
                                      "purchase_order_management",
                                      "void"
                                    ) === false ? (
                                      ""
                                    ) : v.order_state === "APPROVED" ||
                                      v.order_state === "CONFIRM" ? (
                                      ""
                                    ) : (
                                      <a
                                        href="#"
                                        data-toggle="modal"
                                        data-target="#voidOrder"
                                        onClick={() => setListID(v._id)}
                                      >
                                        Void
                                        <span>
                                          <Void />
                                        </span>
                                      </a>
                                    )
                                  ) : localStorage.getItem("role") ===
                                    "building_manager" ? "" : localStorage.getItem("role") ===
                                    "property_manager" ? (
                                    userAccessibility(
                                      "purchase_order_management",
                                      "void"
                                    ) === false ? (
                                      ""
                                    ) : v.order_state === "CONFIRM" ? (
                                      ""
                                    ) : (
                                      <a
                                        href="#"
                                        data-toggle="modal"
                                        data-target="#voidOrder"
                                        onClick={() => setListID(v._id)}
                                      >
                                        Void
                                        <span>
                                          <Void />
                                        </span>
                                      </a>
                                    )
                                  ) : v.order_state === "APPROVED" ||
                                    v.order_state === "CONFIRM" ? (
                                    ""
                                  ) : (
                                    <a
                                      href="#"
                                      data-toggle="modal"
                                      data-target="#voidOrder"
                                      onClick={() => setListID(v._id)}
                                    >
                                      Void
                                      <span>
                                        <Void />
                                      </span>
                                    </a>
                                  )}
                                  {/* /////////////////////////approve btn/////////////////////////// */}

                                  {localStorage.getItem("role") === "viewer" ? (
                                    userAccessibility(
                                      "purchase_order_management",
                                      "approve"
                                    ) === false ? (
                                      ""
                                    ) : (
                                      <a
                                        href="#"
                                        data-toggle="modal"
                                        data-target="#approveOrder"
                                        onClick={() => {
                                          setListID(v._id);
                                          setOrderID(v.order_id);
                                        }}
                                      >
                                        Approve
                                        <span>
                                          <Approve />
                                        </span>
                                      </a>
                                    )
                                  ) : localStorage.getItem("role") ===
                                    "building_manager" ? (
                                    userAccessibility(
                                      "purchase_order_management",
                                      "approve"
                                    ) === false ? (
                                      ""
                                    ) : (
                                      <a
                                        href="#"
                                        data-toggle="modal"
                                        data-target="#approveOrder"
                                        onClick={() => {
                                          setListID(v._id);
                                          setOrderID(v.order_id);
                                        }}
                                      >
                                        Approve
                                        <span>
                                          <Approve />
                                        </span>
                                      </a>
                                    )
                                  ) : localStorage.getItem("role") ===
                                    "property_manager" ? (
                                    userAccessibility(
                                      "purchase_order_management",
                                      "approve"
                                    ) === false ? (
                                      ""
                                    ) : (
                                      <a
                                        href="#"
                                        data-toggle="modal"
                                        data-target="#approveOrder"
                                        onClick={() => {
                                          setListID(v._id);
                                          setOrderID(v.order_id);
                                        }}
                                      >
                                        Approve
                                        <span>
                                          <Approve />
                                        </span>
                                      </a>
                                    )
                                  ) : (
                                    <a
                                      href="#"
                                      data-toggle="modal"
                                      data-target="#approveOrder"
                                      onClick={() => {
                                        setListID(v._id);
                                        setOrderID(v.order_id);
                                      }}
                                    >
                                      Approve
                                      <span>
                                        <Approve />
                                      </span>
                                    </a>
                                  )}

                                  {/* ////////////////////////////////////work confirmation///////////////////////////////////// */}
                                  {localStorage.getItem("role") === "viewer" ? (
                                    userAccessibility(
                                      "purchase_order_management",
                                      "update_item"
                                    ) === false ? (
                                      ""
                                    ) : v.order_state === "PENDING" ||
                                      v.order_state === "VOID" ? (
                                      ""
                                    ) : (
                                      <Link
                                        to={
                                          v.order_type === "purchase_parts"
                                            ? `${PATH.WORK_CONFIRMATION_PURCHASE_ORDER}/${v._id}`
                                            : `${PATH.WORK_CONFIRMATION_MAINTENANCE_ORDER}/${v._id}`
                                        }
                                      >
                                        Work Confirmation
                                        <span>
                                          <WorkConfirmation />
                                        </span>
                                      </Link>
                                    )
                                  ) : localStorage.getItem("role") ===
                                    "building_manager" ? (
                                    userAccessibility(
                                      "purchase_order_management",
                                      "update_item"
                                    ) === false ? (
                                      ""
                                    ) : v.order_state === "PENDING" ||
                                      v.order_state === "VOID" ? (
                                      ""
                                    ) : (
                                      <Link
                                        to={
                                          v.order_type === "purchase_parts"
                                            ? `${PATH.WORK_CONFIRMATION_PURCHASE_ORDER}/${v._id}`
                                            : `${PATH.WORK_CONFIRMATION_MAINTENANCE_ORDER}/${v._id}`
                                        }
                                      >
                                        Work Confirmation
                                        <span>
                                          <WorkConfirmation />
                                        </span>
                                      </Link>
                                    )
                                  ) : localStorage.getItem("role") ===
                                    "property_manager" ? (
                                    userAccessibility(
                                      "purchase_order_management",
                                      "update_item"
                                    ) === false ? (
                                      ""
                                    ) : v.order_state === "PENDING" ||
                                      v.order_state === "VOID" ? (
                                      ""
                                    ) : (
                                      <Link
                                        to={
                                          v.order_type === "purchase_parts"
                                            ? `${PATH.WORK_CONFIRMATION_PURCHASE_ORDER}/${v._id}`
                                            : `${PATH.WORK_CONFIRMATION_MAINTENANCE_ORDER}/${v._id}`
                                        }
                                      >
                                        Work Confirmation
                                        <span>
                                          <WorkConfirmation />
                                        </span>
                                      </Link>
                                    )
                                  ) : v.order_state === "PENDING" ||
                                    v.order_state === "VOID" ? (
                                    ""
                                  ) : (
                                    <Link
                                      to={
                                        v.order_type === "purchase_parts"
                                          ? `${PATH.WORK_CONFIRMATION_PURCHASE_ORDER}/${v._id}`
                                          : `${PATH.WORK_CONFIRMATION_MAINTENANCE_ORDER}/${v._id}`
                                      }
                                    >
                                      Work Confirmation
                                      <span>
                                        <WorkConfirmation />
                                      </span>
                                    </Link>
                                  )}

                                  {/* ////////////////////////////////////email to contractor///////////////////////////////////// */}
                                  {v.order_state === "VOID" ||
                                  v.order_state === "PENDING" ? (
                                    ""
                                  ) : (
                                    <a
                                      href="#"
                                      onClick={() => emailToContractor(v._id)}
                                    >
                                      Email To Contractor
                                      <span>
                                        <Email />
                                      </span>
                                    </a>
                                  )}

                                  {/* ////////////////////////////////////edit///////////////////////////////////// */}
                                  {localStorage.getItem("role") === "viewer" ? (
                                    userAccessibility(
                                      "purchase_order_management",
                                      "update"
                                    ) === false ? (
                                      ""
                                    ) : (
                                      <Link
                                        to={
                                          v.order_type === "purchase_parts"
                                            ? `${PATH.EDIT_PURCHASE_PART_DETAIL}/${v._id}`
                                            : `${PATH.EDIT_MAINTENANCE_SERVICES_DETAIL}/${v._id}`
                                        }
                                      >
                                        Edit
                                        <span>
                                          <Edit />
                                        </span>
                                      </Link>
                                    )
                                  ) : localStorage.getItem("role") ===
                                    "building_manager" ? (
                                    userAccessibility(
                                      "purchase_order_management",
                                      "update"
                                    ) === false ? (
                                      ""
                                    ) : (
                                      <>
                                        {v.order_state === "APPROVED" ? (
                                          ""
                                        ) : (
                                          <Link
                                            to={
                                              v.order_type === "purchase_parts"
                                                ? `${PATH.EDIT_PURCHASE_PART_DETAIL}/${v._id}`
                                                : `${PATH.EDIT_MAINTENANCE_SERVICES_DETAIL}/${v._id}`
                                            }
                                          >
                                            Edit
                                            <span>
                                              <Edit />
                                            </span>
                                          </Link>
                                        )}
                                      </>
                                    )
                                  ) : localStorage.getItem("role") ===
                                    "property_manager" ? (
                                    userAccessibility(
                                      "purchase_order_management",
                                      "update"
                                    ) === false ? (
                                      ""
                                    ) : (
                                      <Link
                                        to={
                                          v.order_type === "purchase_parts"
                                            ? `${PATH.EDIT_PURCHASE_PART_DETAIL}/${v._id}`
                                            : `${PATH.EDIT_MAINTENANCE_SERVICES_DETAIL}/${v._id}`
                                        }
                                      >
                                        Edit
                                        <span>
                                          <Edit />
                                        </span>
                                      </Link>
                                    )
                                  ) : (
                                    <Link
                                      to={
                                        v.order_type === "purchase_parts"
                                          ? `${PATH.EDIT_PURCHASE_PART_DETAIL}/${v._id}`
                                          : `${PATH.EDIT_MAINTENANCE_SERVICES_DETAIL}/${v._id}`
                                      }
                                    >
                                      Edit
                                      <span>
                                        <Edit />
                                      </span>
                                    </Link>
                                  )}
                                  {/* ////////////////////////////////////view///////////////////////////////////// */}
                                  {localStorage.getItem("role") === "viewer" ? (
                                    userAccessibility(
                                      "purchase_order_management",
                                      "read"
                                    ) === false ? (
                                      ""
                                    ) : (
                                      <Link
                                        to={
                                          v.order_type === "purchase_parts"
                                            ? `${PATH.PURCHASE_PART_DETAIL}/${v._id}`
                                            : `${PATH.MAINTENANCE_SERVICES_DETAIL}/${v._id}`
                                        }
                                      >
                                        View
                                        <span>
                                          <File />
                                        </span>
                                      </Link>
                                    )
                                  ) : localStorage.getItem("role") ===
                                    "building_manager" ? (
                                    userAccessibility(
                                      "purchase_order_management",
                                      "read"
                                    ) === false ? (
                                      ""
                                    ) : (
                                      <Link
                                        to={
                                          v.order_type === "purchase_parts"
                                            ? `${PATH.PURCHASE_PART_DETAIL}/${v._id}`
                                            : `${PATH.MAINTENANCE_SERVICES_DETAIL}/${v._id}`
                                        }
                                      >
                                        View
                                        <span>
                                          <File />
                                        </span>
                                      </Link>
                                    )
                                  ) : localStorage.getItem("role") ===
                                    "property_manager" ? (
                                    userAccessibility(
                                      "purchase_order_management",
                                      "read"
                                    ) === false ? (
                                      ""
                                    ) : (
                                      <Link
                                        to={
                                          v.order_type === "purchase_parts"
                                            ? `${PATH.PURCHASE_PART_DETAIL}/${v._id}`
                                            : `${PATH.MAINTENANCE_SERVICES_DETAIL}/${v._id}`
                                        }
                                      >
                                        View
                                        <span>
                                          <File />
                                        </span>
                                      </Link>
                                    )
                                  ) : (
                                    <Link
                                      to={
                                        v.order_type === "purchase_parts"
                                          ? `${PATH.PURCHASE_PART_DETAIL}/${v._id}`
                                          : `${PATH.MAINTENANCE_SERVICES_DETAIL}/${v._id}`
                                      }
                                    >
                                      View
                                      <span>
                                        <File />
                                      </span>
                                    </Link>
                                  )}
                                  {/* ////////////////////////////////////print///////////////////////////////////// */}
                                  <Link
                                    to="#"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setPrintPO(false);
                                      setPrintMS(false);
                                      v.order_type === "purchase_parts"
                                        ? getPurchaseOrder(v._id, "ptable")
                                        : getMaintenanceService(
                                            v._id,
                                            "mtable"
                                          );
                                    }}
                                  >
                                    Print Purchase Order
                                    <span>
                                      <Print />
                                    </span>
                                  </Link>
                                  {/* <Link
                                    to="#"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setPrintPO(true);
                                      setPrintMS(true);
                                      v.order_type === "purchase_parts"
                                        ? getPurchaseOrder(v._id, "pwc")
                                        : getMaintenanceService(v._id, "mwc");
                                    }}
                                  >
                                    Print Work Confirmation
                                    <span>
                                      <Print />
                                    </span>
                                  </Link> */}
                                </div>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            )}

            {purchaseOrderListing && purchaseOrderListing.length > 0 ? (
              <div className="row align-items-center">
                <div className="col-sm-12 col-md-5">
                  Showing {currentPage * perPage - perPage + 1} to{" "}
                  {totalRecord > currentPage * perPage
                    ? currentPage * perPage
                    : totalRecord}{" "}
                  of {totalRecord} entries
                </div>
                <div className="col-sm-12 col-md-7">
                  <div
                    className="dataTables_paginate paging_simple_numbers text-right"
                    id="datatable_paginate"
                  >
                    <ReactPaginate
                      previousLabel={"Prev"}
                      nextLabel={"Next"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={pageCount}
                      onPageChange={(e) => handlePageClick(e)}
                      containerClassName={"pagination"}
                      subContainerClassName={"pages pagination"}
                      pageClassName={"paginate_button page-item"}
                      activeClassName={"active"}
                    />
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}

            <div className="cardFooter">
              {localStorage.getItem("role") === "viewer" ? (
                userAccessibility("purchase_order_management", "delete") ===
                false ? (
                  ""
                ) : (
                  <button
                    className="myBtn danger squre deleteThis"
                    onClick={() => deleteUser(deleteItem)}
                    disabled={deleteItem.length === 0 ? true : false}
                  >
                    <Delete />
                  </button>
                )
              ) : localStorage.getItem("role") === "building_manager" ? (
                userAccessibility("purchase_order_management", "delete") ===
                false ? (
                  ""
                ) : (
                  <button
                    className="myBtn danger squre deleteThis"
                    onClick={() => deleteUser(deleteItem)}
                    disabled={deleteItem.length === 0 ? true : false}
                  >
                    <Delete />
                  </button>
                )
              ) : localStorage.getItem("role") === "property_manager" ? (
                userAccessibility("purchase_order_management", "delete") ===
                false ? (
                  ""
                ) : (
                  <button
                    className="myBtn danger squre deleteThis"
                    onClick={() => deleteUser(deleteItem)}
                    disabled={deleteItem.length === 0 ? true : false}
                  >
                    <Delete />
                  </button>
                )
              ) : (
                <button
                  className="myBtn danger squre deleteThis"
                  onClick={() => deleteUser(deleteItem)}
                  disabled={deleteItem.length === 0 ? true : false}
                >
                  <Delete />
                </button>
              )}
            </div>
          </div>
        </section>
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* Purchase View */}
        <section style={{ display: "none" }} className="pageWrapper pt-0">
          <div ref={printWCPurchase} className="row">
            <div className="col-12 col-md-4 col-lg-12 my-0 py-1">
              <div className="row justify-content-between align-items-center">
                <div className="col-4 col-md-4 col-lg-3">
                  <div
                    className="logoWrapper"
                    style={{ backgroundColor: "white" }}
                  >
                    <Link to={PATH.BUILDING_LIST}>
                      <img className="logo" src={Logosm} alt="" />
                    </Link>
                  </div>
                </div>
                <div
                  className="col-6 col-md-6 col-lg-6 text-center"
                  style={{ margin: "10px 0px" }}
                >
                  <table style={{ width: "100%" }}>
                    <tbody>
                      <tr
                        style={{
                          color: "black",
                          fontSize: "18px",
                        }}
                      >
                        <th className="tableBorderPrint">PO ID</th>
                        <td
                          className="tableBorderPrint"
                          style={{ minWidth: "155px" }}
                        >
                          {purchaseOrderListingg?.order_id}
                        </td>
                      </tr>
                      <tr style={{ color: "black", fontSize: "18px" }}>
                        <th
                          className="tableBorderPrint"
                          style={{ width: "max-content", minWidth: "200px" }}
                        >
                          Building Name
                        </th>
                        <td className="tableBorderPrint">{buildingName}</td>
                      </tr>
                      <tr style={{ color: "black", fontSize: "18px" }}>
                        <th className="tableBorderPrint">Status</th>
                        <td
                          className={`tableBorderPrint text ${
                            purchaseOrderListingg?.order_state === "PENDING"
                              ? "pending"
                              : purchaseOrderListingg?.order_state ===
                                "APPROVED"
                              ? "success"
                              : purchaseOrderListingg?.order_state === "CONFIRM"
                              ? "success"
                              : "danger"
                          }`}
                          style={{ fontWeight: "900", fontSize: "18px" }}
                        >
                          {purchaseOrderListingg?.order_state === "PENDING"
                            ? "PENDING"
                            : purchaseOrderListingg?.order_state === "APPROVED"
                            ? "APPROVED"
                            : purchaseOrderListingg?.order_state === "CONFIRM"
                            ? "INVOICED"
                            : ""}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              {/* <hr className="splitCard" /> */}
            </div>
            <div className="contentCard my-0 py-1 row justify-content-between w-100">
              <div className="col-6 col-md-4 col-lg-4">
                <table className="mx-auto">
                  <tbody>
                    <tr
                      style={{
                        color: "black",
                        fontSize: "16px",
                      }}
                    >
                      <th
                        colSpan={2}
                        style={{
                          backgroundColor: "#d97228",
                          border: "1px solid black",
                          boxSizing: "border-box",
                          color: "white",
                          fontWeight: "600",
                          textAlign: "center",
                          fontSize: "16px",
                          lineHeight: 2,
                        }}
                      >
                        Purchase Order Details
                      </th>
                    </tr>
                    <tr style={{ color: "black", fontSize: "16px" }}>
                      <th className="tableBorderPrint">Building Address</th>
                      <td className="tableBorderPrint">
                        {purchaseOrderListingg?.building_info?.street +
                          ", " +
                          purchaseOrderListingg?.building_info?.city +
                          ", " +
                          purchaseOrderListingg?.building_info?.country}
                      </td>
                    </tr>
                    <tr style={{ color: "black", fontSize: "16px" }}>
                      <th className="tableBorderPrint">Postal Code</th>
                      <td className="tableBorderPrint">
                        {purchaseOrderListingg?.building_info?.postal_code}
                      </td>
                    </tr>
                    <tr style={{ color: "black", fontSize: "16px" }}>
                      <th className="tableBorderPrint">Phone</th>
                      <td className="tableBorderPrint">
                        {" ("}
                        {purchaseOrderListingg?.building_info?.phone_no?.split(
                          ""
                        )[0] !== undefined
                          ? purchaseOrderListingg?.building_info?.phone_no?.split(
                              ""
                            )[0]
                          : ""}
                        {purchaseOrderListingg?.building_info?.phone_no?.split(
                          ""
                        )[1] !== undefined
                          ? purchaseOrderListingg?.building_info?.phone_no?.split(
                              ""
                            )[1]
                          : ""}
                        {purchaseOrderListingg?.building_info?.phone_no?.split(
                          ""
                        )[2] !== undefined
                          ? purchaseOrderListingg?.building_info?.phone_no?.split(
                              ""
                            )[2]
                          : ""}
                        {") "}
                        {purchaseOrderListingg?.building_info?.phone_no?.split(
                          ""
                        )[3] !== undefined
                          ? purchaseOrderListingg?.building_info?.phone_no?.split(
                              ""
                            )[3]
                          : ""}
                        {purchaseOrderListingg?.building_info?.phone_no?.split(
                          ""
                        )[4] !== undefined
                          ? purchaseOrderListingg?.building_info?.phone_no?.split(
                              ""
                            )[4]
                          : ""}
                        {purchaseOrderListingg?.building_info?.phone_no?.split(
                          ""
                        )[5] !== undefined
                          ? purchaseOrderListingg?.building_info?.phone_no?.split(
                              ""
                            )[5]
                          : ""}
                        {" - "}
                        {purchaseOrderListingg?.building_info?.phone_no?.split(
                          ""
                        )[6] !== undefined
                          ? purchaseOrderListingg?.building_info?.phone_no?.split(
                              ""
                            )[6]
                          : ""}
                        {purchaseOrderListingg?.building_info?.phone_no?.split(
                          ""
                        )[7] !== undefined
                          ? purchaseOrderListingg?.building_info?.phone_no?.split(
                              ""
                            )[7]
                          : ""}
                        {purchaseOrderListingg?.building_info?.phone_no?.split(
                          ""
                        )[8] !== undefined
                          ? purchaseOrderListingg?.building_info?.phone_no?.split(
                              ""
                            )[8]
                          : ""}
                        {purchaseOrderListingg?.building_info?.phone_no?.split(
                          ""
                        )[9] !== undefined
                          ? purchaseOrderListingg?.building_info?.phone_no?.split(
                              ""
                            )[9]
                          : ""}
                      </td>
                    </tr>
                    {/* <tr style={{ color: "black", fontSize: "16px" }}>
                      <th className="tableBorderPrint">Created Date</th>
                      <td className="tableBorderPrint">
                        {getDateOnly(purchaseOrderListingg?.created_at)}
                      </td>
                    </tr> */}
                    <tr style={{ color: "black", fontSize: "16px" }}>
                      <th className="tableBorderPrint">Type</th>
                      <td className="tableBorderPrint">
                        {capatalizeLetters(
                          purchaseOrderListingg?.order_type +
                            " " +
                            purchaseOrderListingg?.order_type
                        )}
                      </td>
                    </tr>
                    <tr style={{ color: "black", fontSize: "16px" }}>
                      <th className="tableBorderPrint">Requested By</th>
                      <td className="tableBorderPrint">
                        {capatalizeLetters(
                          purchaseOrderListingg?.order_requested_by_user_info
                            ?.first_name +
                            "_" +
                            purchaseOrderListingg?.order_requested_by_user_info
                              ?.last_name
                        )}
                      </td>
                    </tr>
                    <tr style={{ color: "black", fontSize: "16px" }}>
                      <th className="tableBorderPrint">Requested On</th>
                      <td className="tableBorderPrint">
                        {getDateOnly(purchaseOrderListingg?.order_requested_at)}
                      </td>
                    </tr>
                    {purchaseOrderListingg?.order_approved_by === null ? (
                      ""
                    ) : (
                      <>
                        <tr style={{ color: "black", fontSize: "16px" }}>
                          <th className="tableBorderPrint">Approved By</th>
                          <td className="tableBorderPrint">
                            {capatalizeLetters(
                              purchaseOrderListingg?.order_approved_by_user_info
                                ?.first_name +
                                "_" +
                                purchaseOrderListingg
                                  ?.order_approved_by_user_info?.last_name
                            )}
                          </td>
                        </tr>
                        <tr style={{ color: "black", fontSize: "16px" }}>
                          <th className="tableBorderPrint">Approved On</th>
                          <td className="tableBorderPrint">
                            {getDateOnly(
                              purchaseOrderListingg?.order_approved_at
                            )}
                          </td>
                        </tr>
                      </>
                    )}
                    {purchaseOrderListingg?.email_to_contractor === null ? (
                      ""
                    ) : (
                      <tr style={{ color: "black", fontSize: "16px" }}>
                        <th className="tableBorderPrint">
                          Emailed To Contractor On
                        </th>
                        <td className="tableBorderPrint">
                          {/* {purchaseOrderListingg?.email_to_contractor} */}
                          {moment(
                            new Date(
                              purchaseOrderListingg?.email_to_contractor
                            ).toUTCString()
                          ).format("DD-MMMM-YYYY hh:mm a")}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <div className="col-6 col-md-4 col-lg-4">
                <table className="w-100">
                  <tbody>
                    <tr
                      style={{
                        color: "black",
                        fontSize: "16px",
                      }}
                    >
                      <th
                        colSpan={2}
                        style={{
                          backgroundColor: "#d97228",
                          border: "1px solid black",
                          boxSizing: "border-box",
                          color: "white",
                          fontWeight: "600",
                          textAlign: "center",
                          fontSize: "22px",
                          lineHeight: 2,
                        }}
                      >
                        Trade Details
                      </th>
                    </tr>
                    <tr style={{ color: "black", fontSize: "16px" }}>
                      <th className="tableBorderPrint">Name</th>
                      <td className="tableBorderPrint">
                        {purchaseOrderListingg?.trade_info_updated?.company}
                      </td>
                    </tr>
                    <tr style={{ color: "black", fontSize: "16px" }}>
                      <th className="tableBorderPrint">Phone</th>
                      <td className="tableBorderPrint">
                        {" ("}
                        {purchaseOrderListingg?.trade_info_updated?.phone_no?.split(
                          ""
                        )[0] !== undefined
                          ? purchaseOrderListingg?.trade_info_updated?.phone_no?.split(
                              ""
                            )[0]
                          : ""}
                        {purchaseOrderListingg?.trade_info_updated?.phone_no?.split(
                          ""
                        )[1] !== undefined
                          ? purchaseOrderListingg?.trade_info_updated?.phone_no?.split(
                              ""
                            )[1]
                          : ""}
                        {purchaseOrderListingg?.trade_info_updated?.phone_no?.split(
                          ""
                        )[2] !== undefined
                          ? purchaseOrderListingg?.trade_info_updated?.phone_no?.split(
                              ""
                            )[2]
                          : ""}
                        {") "}
                        {purchaseOrderListingg?.trade_info_updated?.phone_no?.split(
                          ""
                        )[3] !== undefined
                          ? purchaseOrderListingg?.trade_info_updated?.phone_no?.split(
                              ""
                            )[3]
                          : ""}
                        {purchaseOrderListingg?.trade_info_updated?.phone_no?.split(
                          ""
                        )[4] !== undefined
                          ? purchaseOrderListingg?.trade_info_updated?.phone_no?.split(
                              ""
                            )[4]
                          : ""}
                        {purchaseOrderListingg?.trade_info_updated?.phone_no?.split(
                          ""
                        )[5] !== undefined
                          ? purchaseOrderListingg?.trade_info_updated?.phone_no?.split(
                              ""
                            )[5]
                          : ""}
                        {" - "}
                        {purchaseOrderListingg?.trade_info_updated?.phone_no?.split(
                          ""
                        )[6] !== undefined
                          ? purchaseOrderListingg?.trade_info_updated?.phone_no?.split(
                              ""
                            )[6]
                          : ""}
                        {purchaseOrderListingg?.trade_info_updated?.phone_no?.split(
                          ""
                        )[7] !== undefined
                          ? purchaseOrderListingg?.trade_info_updated?.phone_no?.split(
                              ""
                            )[7]
                          : ""}
                        {purchaseOrderListingg?.trade_info_updated?.phone_no?.split(
                          ""
                        )[8] !== undefined
                          ? purchaseOrderListingg?.trade_info_updated?.phone_no?.split(
                              ""
                            )[8]
                          : ""}
                        {purchaseOrderListingg?.trade_info_updated?.phone_no?.split(
                          ""
                        )[9] !== undefined
                          ? purchaseOrderListingg?.trade_info_updated?.phone_no?.split(
                              ""
                            )[9]
                          : ""}
                      </td>
                    </tr>
                    <tr style={{ color: "black", fontSize: "16px" }}>
                      <th className="tableBorderPrint">Fax</th>
                      <td className="tableBorderPrint">
                        {" ("}
                        {purchaseOrderListingg?.trade_info_updated?.fax?.split(
                          ""
                        )[0] !== undefined
                          ? purchaseOrderListingg?.trade_info_updated?.fax?.split(
                              ""
                            )[0]
                          : ""}
                        {purchaseOrderListingg?.trade_info_updated?.fax?.split(
                          ""
                        )[1] !== undefined
                          ? purchaseOrderListingg?.trade_info_updated?.fax?.split(
                              ""
                            )[1]
                          : ""}
                        {purchaseOrderListingg?.trade_info_updated?.fax?.split(
                          ""
                        )[2] !== undefined
                          ? purchaseOrderListingg?.trade_info_updated?.fax?.split(
                              ""
                            )[2]
                          : ""}
                        {") "}
                        {purchaseOrderListingg?.trade_info_updated?.fax?.split(
                          ""
                        )[3] !== undefined
                          ? purchaseOrderListingg?.trade_info_updated?.fax?.split(
                              ""
                            )[3]
                          : ""}
                        {purchaseOrderListingg?.trade_info_updated?.fax?.split(
                          ""
                        )[4] !== undefined
                          ? purchaseOrderListingg?.trade_info_updated?.fax?.split(
                              ""
                            )[4]
                          : ""}
                        {purchaseOrderListingg?.trade_info_updated?.fax?.split(
                          ""
                        )[5] !== undefined
                          ? purchaseOrderListingg?.trade_info_updated?.fax?.split(
                              ""
                            )[5]
                          : ""}
                        {" - "}
                        {purchaseOrderListingg?.trade_info_updated?.fax?.split(
                          ""
                        )[6] !== undefined
                          ? purchaseOrderListingg?.trade_info_updated?.fax?.split(
                              ""
                            )[6]
                          : ""}
                        {purchaseOrderListingg?.trade_info_updated?.fax?.split(
                          ""
                        )[7] !== undefined
                          ? purchaseOrderListingg?.trade_info_updated?.fax?.split(
                              ""
                            )[7]
                          : ""}
                        {purchaseOrderListingg?.trade_info_updated?.fax?.split(
                          ""
                        )[8] !== undefined
                          ? purchaseOrderListingg?.trade_info_updated?.fax?.split(
                              ""
                            )[8]
                          : ""}
                        {purchaseOrderListingg?.trade_info_updated?.fax?.split(
                          ""
                        )[9] !== undefined
                          ? purchaseOrderListingg?.trade_info_updated?.fax?.split(
                              ""
                            )[9]
                          : ""}
                      </td>
                    </tr>
                    <tr style={{ color: "black", fontSize: "16px" }}>
                      <th className="tableBorderPrint">Email</th>
                      <td className="tableBorderPrint">
                        {purchaseOrderListingg?.trade_info_updated?.email}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <hr className="splitCard" />
            </div>
            <div ref={printTablePurchase} className="col-12 col-md-8 col-lg-9">
              {printPO === true ? (
                <div className="col-12 col-md-4 col-lg-12">
                  <div
                    className="row justify-content-between align-items-center"
                    style={{ backgroundColor: "#38393c" }}
                  >
                    <div className="col-4 col-md-4 col-lg-3">
                      <div
                        className="logoWrapper"
                        style={{ backgroundColor: "#38393c" }}
                      >
                        <Link to={PATH.BUILDING_LIST}>
                          <img className="logo" src={Logosm} alt="" />
                        </Link>
                      </div>
                    </div>

                    <div className="col-4 col-md-4 col-lg-3 text-center">
                      <div className="details mt-2 mb-2">
                        <div className="detailContent">
                          <h4 style={{ color: "white", fontSize: "16px" }}>
                            Purchase Order ID
                          </h4>
                          <h2 style={{ color: "white", fontSize: "16px" }}>
                            {purchaseOrderListingg?.order_id}
                          </h2>
                        </div>
                        <div className="detailContent">
                          <h4 style={{ color: "white", fontSize: "16px" }}>
                            Building Name
                          </h4>
                          <h2 style={{ color: "white", fontSize: "16px" }}>
                            {buildingName}
                          </h2>
                        </div>
                        <div className="detailContent">
                          <h4 style={{ color: "white", fontSize: "16px" }}>
                            Status
                          </h4>
                          <h2
                            className={`badge ${
                              purchaseOrderListingg?.order_state === "PENDING"
                                ? "pending"
                                : purchaseOrderListingg?.order_state ===
                                  "APPROVED"
                                ? "success"
                                : purchaseOrderListingg?.order_state ===
                                  "CONFIRM"
                                ? "success"
                                : "danger"
                            }`}
                          >
                            {purchaseOrderListingg?.order_state}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
              <div className="contentCard my-0 py-1 mb-0">
                <div className="titleWrapperPrint my-1">
                  <span style={{ fontSize: "16px" }}>
                    Purchase Parts Work Confirmation
                  </span>
                </div>
                <div className=" table-responsive">
                  <table className="p-orderTable table table-striped">
                    <thead>
                      <tr>
                        <th
                          style={{
                            fontSize: "16px",
                            width: "75.590551181px",
                            wordBreak: "break-all",
                          }}
                        >
                          Category
                        </th>
                        <th
                          style={{
                            fontSize: "16px",
                            width: "94.488188976px",
                            wordBreak: "break-all",
                          }}
                        >
                          Model Number
                        </th>
                        <th
                          style={{
                            fontSize: "16px",
                            width: "226.77165354px",
                            wordBreak: "break-all",
                          }}
                        >
                          Description
                        </th>
                        <th
                          style={{
                            fontSize: "16px",
                            width: "56.692913386px",
                            wordBreak: "break-all",
                          }}
                        >
                          Apt
                        </th>
                        <th
                          style={{
                            fontSize: "16px",
                            width: "56.692913386px",
                            wordBreak: "break-all",
                          }}
                        >
                          Price
                        </th>
                        <th
                          style={{
                            fontSize: "16px",
                            width: "56.692913386px",
                            wordBreak: "break-all",
                          }}
                        >
                          Qty
                        </th>
                        <th
                          style={{
                            fontSize: "16px",
                            width: "113.38582677px",
                            wordBreak: "break-all",
                          }}
                        >
                          Qty Rcvd.
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {purchaseItemsList &&
                        purchaseItemsList.map((v, i) => {
                          return (
                            <tr key={i}>
                              <td
                                style={{
                                  fontSize: "16px",
                                  border: "1px solid black",
                                  borderLeft: "none",
                                }}
                              >
                                <b>{v.inventory_category}</b>
                              </td>
                              <td
                                style={{
                                  fontSize: "16px",
                                  border: "1px solid black",
                                }}
                              >
                                {v.model_number}
                              </td>
                              <td
                                style={{
                                  fontSize: "16px",
                                  // minWidth: "330px",
                                  border: "1px solid black",
                                }}
                              >
                                {v.description}
                              </td>
                              <td
                                style={{
                                  fontSize: "16px",
                                  border: "1px solid black",
                                }}
                              >
                                {v.suite_no}
                              </td>
                              <td
                                style={{
                                  fontSize: "16px",
                                  border: "1px solid black",
                                }}
                              >
                                <b>${v.price}</b>
                              </td>
                              <td
                                style={{
                                  fontSize: "16px",
                                  border: "1px solid black",
                                }}
                              >
                                {v.quantity}
                              </td>
                              <td
                                style={{
                                  fontSize: "16px",
                                  // minWidth: "175px",
                                  border: "1px solid black",
                                  borderRight: "none",
                                }}
                              >
                                {v.quantity_received}
                                <span style={{ marginLeft: "5px" }}>
                                  {v.received_date === null ? (
                                    ""
                                  ) : (
                                    <>
                                      {"("}
                                      {getDateOnly(v.received_date)}
                                      {")"}
                                    </>
                                  )}
                                </span>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>

                {/* <hr className="splitCard" /> */}
              </div>
              {purchaseOrderListingg?.comments?.length < 1 ? (
                ""
              ) : (
                <div className="contentCard my-0 py-1 commentWrapper mt-3">
                  <div className="formDesign">
                    <div className="form-group">
                      <label style={{ fontSize: "20px" }}>
                        Comments
                        {/* <small>(optional)</small> */}
                      </label>
                      {/* <textarea
                      className="form-control"
                      rows="3"
                      value={addComments}
                      onChange={(e) => setAddComments(e.target.value)}
                    ></textarea> */}
                      <div className="commentLogs">
                        {purchaseOrderListingg?.comments?.map((v, i) => {
                          return (
                            <div key={i} className="comment">
                              <h2 style={{ fontSize: "20px" }}>
                                {capatalizeLetters(
                                  v?.created_by_user_info?.first_name +
                                    "_" +
                                    v?.created_by_user_info?.last_name
                                )}{" "}
                                <span style={{ fontSize: "16px" }}>
                                  {getDate(v.created_at)}{" "}
                                  {localStorage.getItem("role") === "viewer" ? (
                                    userAccessibility(
                                      "purchase_order_management",
                                      "delete"
                                    ) === false ? (
                                      ""
                                    ) : (
                                      <a
                                        href="#"
                                        className="circleBtn danger deleteThis ml-2"
                                        onClick={() => {
                                          deleteComment([v._id]);
                                        }}
                                      >
                                        <Delete2 />
                                      </a>
                                    )
                                  ) : localStorage.getItem("role") ===
                                    "building_manager" ? (
                                    userAccessibility(
                                      "purchase_order_management",
                                      "delete"
                                    ) === false ? (
                                      ""
                                    ) : (
                                      <a
                                        href="#"
                                        className="circleBtn danger deleteThis ml-2"
                                        onClick={() => {
                                          deleteComment([v._id]);
                                        }}
                                      >
                                        <Delete2 />
                                      </a>
                                    )
                                  ) : localStorage.getItem("role") ===
                                    "property_manager" ? (
                                    userAccessibility(
                                      "purchase_order_management",
                                      "delete"
                                    ) === false ? (
                                      ""
                                    ) : (
                                      <a
                                        href="#"
                                        className="circleBtn danger deleteThis ml-2"
                                        onClick={() => {
                                          deleteComment([v._id]);
                                        }}
                                      >
                                        <Delete2 />
                                      </a>
                                    )
                                  ) : (
                                    <a
                                      href="#"
                                      className="circleBtn danger deleteThis ml-2"
                                      onClick={() => {
                                        deleteComment([v._id]);
                                      }}
                                    >
                                      <Delete2 />
                                    </a>
                                  )}
                                </span>
                              </h2>
                              <p style={{ fontSize: "16px" }}>{v.comment} </p>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </section>
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* ############################################################################# */}
        {/* Maintenance View */}

        <section style={{ display: "none" }} className="pageWrapper">
          <div ref={printWCMaintenance} className="row">
            <div className="col-12 col-md-4 col-lg-12">
              <div
                className="row justify-content-between align-items-center"
                style={{ backgroundColor: "white" }}
              >
                <div className="col-4 col-md-4 col-lg-3">
                  <div
                    className="logoWrapper"
                    style={{ backgroundColor: "white" }}
                  >
                    <Link to={PATH.BUILDING_LIST}>
                      <img className="logo" src={Logosm} alt="" />
                    </Link>
                  </div>
                </div>
                <div
                  className="col-4 col-md-4 col-lg-3 text-center"
                  style={{ margin: "10px 0px" }}
                >
                  <table>
                    <tbody>
                      <tr
                        style={{
                          color: "black",
                          fontSize: "18px",
                        }}
                      >
                        <th className="tableBorderPrint">PO ID</th>
                        <td
                          className="tableBorderPrint"
                          style={{ minWidth: "140px" }}
                        >
                          {maintenanceOrderListing?.order_id}
                        </td>
                      </tr>
                      <tr style={{ color: "black", fontSize: "18px" }}>
                        <th className="tableBorderPrint">Building Name</th>
                        <td className="tableBorderPrint">{buildingName}</td>
                      </tr>
                      <tr style={{ color: "black", fontSize: "18px" }}>
                        <th className="tableBorderPrint">Status</th>
                        <td
                          className={`tableBorderPrint text ${
                            maintenanceOrderListing?.order_state === "PENDING"
                              ? "pending"
                              : maintenanceOrderListing?.order_state ===
                                "APPROVED"
                              ? "success"
                              : maintenanceOrderListing?.order_state ===
                                "CONFIRM"
                              ? "success"
                              : "danger"
                          }`}
                          style={{ fontWeight: "900", fontSize: "18px" }}
                        >
                          {maintenanceOrderListing?.order_state === "PENDING"
                            ? "PENDING"
                            : maintenanceOrderListing?.order_state ===
                              "APPROVED"
                            ? "APPROVED"
                            : maintenanceOrderListing?.order_state === "CONFIRM"
                            ? "INVOICED"
                            : ""}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <hr className="splitCard" />
            </div>
            <div className="contentCard row justify-content-between w-100">
              <div className="col-6 col-md-4 col-lg-4">
                <table className="mx-auto">
                  <tbody>
                    <tr
                      style={{
                        color: "black",
                        fontSize: "18px",
                      }}
                    >
                      <th
                        colSpan={2}
                        style={{
                          backgroundColor: "#d97228",
                          border: "1px solid black",
                          boxSizing: "border-box",
                          color: "white",
                          fontWeight: "600",
                          textAlign: "center",
                          fontSize: 22,
                          lineHeight: 2,
                        }}
                      >
                        Maintenance Service Details
                      </th>
                    </tr>
                    {/* <tr
                      style={{
                        color: "black",
                        fontSize: "20px",
                      }}
                    >
                      <th className="tableBorderPrint">Purchase Order ID</th>
                      <td className="tableBorderPrint">
                        {purchaseOrderListingg?.order_id}
                      </td>
                    </tr> */}
                    <tr style={{ color: "black", fontSize: "18px" }}>
                      <th className="tableBorderPrint">Building Address</th>
                      <td className="tableBorderPrint">
                        {maintenanceOrderListing?.building_info?.street +
                          ", " +
                          maintenanceOrderListing?.building_info?.city +
                          ", " +
                          maintenanceOrderListing?.building_info?.country}
                      </td>
                    </tr>
                    <tr style={{ color: "black", fontSize: "18px" }}>
                      <th className="tableBorderPrint">Postal Code</th>
                      <td className="tableBorderPrint">
                        {maintenanceOrderListing?.building_info?.postal_code}
                      </td>
                    </tr>
                    <tr style={{ color: "black", fontSize: "18px" }}>
                      <th className="tableBorderPrint">Phone</th>
                      <td className="tableBorderPrint">
                        {" ("}
                        {maintenanceOrderListing?.building_info?.phone_no?.split(
                          ""
                        )[0] !== undefined
                          ? maintenanceOrderListing?.building_info?.phone_no?.split(
                              ""
                            )[0]
                          : ""}
                        {maintenanceOrderListing?.building_info?.phone_no?.split(
                          ""
                        )[1] !== undefined
                          ? maintenanceOrderListing?.building_info?.phone_no?.split(
                              ""
                            )[1]
                          : ""}
                        {maintenanceOrderListing?.building_info?.phone_no?.split(
                          ""
                        )[2] !== undefined
                          ? maintenanceOrderListing?.building_info?.phone_no?.split(
                              ""
                            )[2]
                          : ""}
                        {") "}
                        {maintenanceOrderListing?.building_info?.phone_no?.split(
                          ""
                        )[3] !== undefined
                          ? maintenanceOrderListing?.building_info?.phone_no?.split(
                              ""
                            )[3]
                          : ""}
                        {maintenanceOrderListing?.building_info?.phone_no?.split(
                          ""
                        )[4] !== undefined
                          ? maintenanceOrderListing?.building_info?.phone_no?.split(
                              ""
                            )[4]
                          : ""}
                        {maintenanceOrderListing?.building_info?.phone_no?.split(
                          ""
                        )[5] !== undefined
                          ? maintenanceOrderListing?.building_info?.phone_no?.split(
                              ""
                            )[5]
                          : ""}
                        {" - "}
                        {maintenanceOrderListing?.building_info?.phone_no?.split(
                          ""
                        )[6] !== undefined
                          ? maintenanceOrderListing?.building_info?.phone_no?.split(
                              ""
                            )[6]
                          : ""}
                        {maintenanceOrderListing?.building_info?.phone_no?.split(
                          ""
                        )[7] !== undefined
                          ? maintenanceOrderListing?.building_info?.phone_no?.split(
                              ""
                            )[7]
                          : ""}
                        {maintenanceOrderListing?.building_info?.phone_no?.split(
                          ""
                        )[8] !== undefined
                          ? maintenanceOrderListing?.building_info?.phone_no?.split(
                              ""
                            )[8]
                          : ""}
                        {maintenanceOrderListing?.building_info?.phone_no?.split(
                          ""
                        )[9] !== undefined
                          ? maintenanceOrderListing?.building_info?.phone_no?.split(
                              ""
                            )[9]
                          : ""}
                      </td>
                    </tr>
                    <tr style={{ color: "black", fontSize: "18px" }}>
                      <th className="tableBorderPrint">Created Date</th>
                      <td className="tableBorderPrint">
                        {getDateOnly(maintenanceOrderListing?.created_at)}
                      </td>
                    </tr>
                    <tr style={{ color: "black", fontSize: "18px" }}>
                      <th className="tableBorderPrint">Type</th>
                      <td className="tableBorderPrint">
                        {capatalizeLetters(
                          maintenanceOrderListing?.order_type +
                            " " +
                            maintenanceOrderListing?.order_type
                        )}
                      </td>
                    </tr>
                    <tr style={{ color: "black", fontSize: "18px" }}>
                      <th className="tableBorderPrint">Requested By</th>
                      <td className="tableBorderPrint">
                        {capatalizeLetters(
                          maintenanceOrderListing?.order_requested_by_user_info
                            ?.first_name +
                            "_" +
                            maintenanceOrderListing
                              ?.order_requested_by_user_info?.last_name
                        )}
                      </td>
                    </tr>
                    <tr style={{ color: "black", fontSize: "18px" }}>
                      <th className="tableBorderPrint">Requested On</th>
                      <td className="tableBorderPrint">
                        {getDateOnly(
                          maintenanceOrderListing?.order_requested_at
                        )}
                      </td>
                    </tr>
                    {maintenanceOrderListing?.order_approved_by === null ? (
                      ""
                    ) : (
                      <>
                        <tr style={{ color: "black", fontSize: "18px" }}>
                          <th className="tableBorderPrint">Approved By</th>
                          <td className="tableBorderPrint">
                            {capatalizeLetters(
                              maintenanceOrderListing
                                ?.order_approved_by_user_info?.first_name +
                                "_" +
                                maintenanceOrderListing
                                  ?.order_approved_by_user_info?.last_name
                            )}
                          </td>
                        </tr>
                        <tr style={{ color: "black", fontSize: "18px" }}>
                          <th className="tableBorderPrint">Approved On</th>
                          <td className="tableBorderPrint">
                            {getDateOnly(
                              maintenanceOrderListing?.order_approved_at
                            )}
                          </td>
                        </tr>
                      </>
                    )}
                    {maintenanceOrderListing?.email_to_contractor === null ? (
                      ""
                    ) : (
                      <tr style={{ color: "black", fontSize: "18px" }}>
                        <th className="tableBorderPrint">
                          Emailed To Contractor On
                        </th>
                        <td className="tableBorderPrint">
                          {/* {maintenanceOrderListing?.email_to_contractor} */}
                          {moment(
                            new Date(
                              maintenanceOrderListing?.email_to_contractor
                            ).toUTCString()
                          ).format("DD-MMMM-YYYY hh:mm a")}
                        </td>
                      </tr>
                    )}
                    {/* <tr style={{ color: "black", fontSize: "18px" }}>
                      <th className="tableBorderPrint">
                        Emailed To Contractor On
                      </th>
                      <td className="tableBorderPrint">
                        {maintenanceOrderListing?.trade_info?.email}
                      </td>
                    </tr> */}
                  </tbody>
                </table>
              </div>
              <div className="col-6 col-md-4 col-lg-4">
                <table className="w-100">
                  <tbody>
                    <tr
                      style={{
                        color: "black",
                        fontSize: "18px",
                      }}
                    >
                      <th
                        colSpan={2}
                        style={{
                          backgroundColor: "#d97228",
                          border: "1px solid black",
                          boxSizing: "border-box",
                          color: "white",
                          fontWeight: "600",
                          textAlign: "center",
                          fontSize: "22px",
                          lineHeight: 2,
                        }}
                      >
                        Trade Details
                      </th>
                    </tr>
                    <tr style={{ color: "black", fontSize: "18px" }}>
                      <th className="tableBorderPrint">Name</th>
                      <td className="tableBorderPrint">
                        {maintenanceOrderListing?.trade_info_updated?.company}
                      </td>
                    </tr>
                    <tr style={{ color: "black", fontSize: "18px" }}>
                      <th className="tableBorderPrint">Phone</th>
                      <td className="tableBorderPrint">
                        {" ("}
                        {maintenanceOrderListing?.trade_info_updated?.phone_no?.split(
                          ""
                        )[0] !== undefined
                          ? maintenanceOrderListing?.trade_info_updated?.phone_no?.split(
                              ""
                            )[0]
                          : ""}
                        {maintenanceOrderListing?.trade_info_updated?.phone_no?.split(
                          ""
                        )[1] !== undefined
                          ? maintenanceOrderListing?.trade_info_updated?.phone_no?.split(
                              ""
                            )[1]
                          : ""}
                        {maintenanceOrderListing?.trade_info_updated?.phone_no?.split(
                          ""
                        )[2] !== undefined
                          ? maintenanceOrderListing?.trade_info_updated?.phone_no?.split(
                              ""
                            )[2]
                          : ""}
                        {") "}
                        {maintenanceOrderListing?.trade_info_updated?.phone_no?.split(
                          ""
                        )[3] !== undefined
                          ? maintenanceOrderListing?.trade_info_updated?.phone_no?.split(
                              ""
                            )[3]
                          : ""}
                        {maintenanceOrderListing?.trade_info_updated?.phone_no?.split(
                          ""
                        )[4] !== undefined
                          ? maintenanceOrderListing?.trade_info_updated?.phone_no?.split(
                              ""
                            )[4]
                          : ""}
                        {maintenanceOrderListing?.trade_info_updated?.phone_no?.split(
                          ""
                        )[5] !== undefined
                          ? maintenanceOrderListing?.trade_info_updated?.phone_no?.split(
                              ""
                            )[5]
                          : ""}
                        {" - "}
                        {maintenanceOrderListing?.trade_info_updated?.phone_no?.split(
                          ""
                        )[6] !== undefined
                          ? maintenanceOrderListing?.trade_info_updated?.phone_no?.split(
                              ""
                            )[6]
                          : ""}
                        {maintenanceOrderListing?.trade_info_updated?.phone_no?.split(
                          ""
                        )[7] !== undefined
                          ? maintenanceOrderListing?.trade_info_updated?.phone_no?.split(
                              ""
                            )[7]
                          : ""}
                        {maintenanceOrderListing?.trade_info_updated?.phone_no?.split(
                          ""
                        )[8] !== undefined
                          ? maintenanceOrderListing?.trade_info_updated?.phone_no?.split(
                              ""
                            )[8]
                          : ""}
                        {maintenanceOrderListing?.trade_info_updated?.phone_no?.split(
                          ""
                        )[9] !== undefined
                          ? maintenanceOrderListing?.trade_info_updated?.phone_no?.split(
                              ""
                            )[9]
                          : ""}
                      </td>
                    </tr>
                    <tr style={{ color: "black", fontSize: "18px" }}>
                      <th className="tableBorderPrint">Fax</th>
                      <td className="tableBorderPrint">
                        {" ("}
                        {maintenanceOrderListing?.trade_info_updated?.fax?.split(
                          ""
                        )[0] !== undefined
                          ? maintenanceOrderListing?.trade_info_updated?.fax?.split(
                              ""
                            )[0]
                          : ""}
                        {maintenanceOrderListing?.trade_info_updated?.fax?.split(
                          ""
                        )[1] !== undefined
                          ? maintenanceOrderListing?.trade_info_updated?.fax?.split(
                              ""
                            )[1]
                          : ""}
                        {maintenanceOrderListing?.trade_info_updated?.fax?.split(
                          ""
                        )[2] !== undefined
                          ? maintenanceOrderListing?.trade_info_updated?.fax?.split(
                              ""
                            )[2]
                          : ""}
                        {") "}
                        {maintenanceOrderListing?.trade_info_updated?.fax?.split(
                          ""
                        )[3] !== undefined
                          ? maintenanceOrderListing?.trade_info_updated?.fax?.split(
                              ""
                            )[3]
                          : ""}
                        {maintenanceOrderListing?.trade_info_updated?.fax?.split(
                          ""
                        )[4] !== undefined
                          ? maintenanceOrderListing?.trade_info_updated?.fax?.split(
                              ""
                            )[4]
                          : ""}
                        {maintenanceOrderListing?.trade_info_updated?.fax?.split(
                          ""
                        )[5] !== undefined
                          ? maintenanceOrderListing?.trade_info_updated?.fax?.split(
                              ""
                            )[5]
                          : ""}
                        {" - "}
                        {maintenanceOrderListing?.trade_info_updated?.fax?.split(
                          ""
                        )[6] !== undefined
                          ? maintenanceOrderListing?.trade_info_updated?.fax?.split(
                              ""
                            )[6]
                          : ""}
                        {maintenanceOrderListing?.trade_info_updated?.fax?.split(
                          ""
                        )[7] !== undefined
                          ? maintenanceOrderListing?.trade_info_updated?.fax?.split(
                              ""
                            )[7]
                          : ""}
                        {maintenanceOrderListing?.trade_info_updated?.fax?.split(
                          ""
                        )[8] !== undefined
                          ? maintenanceOrderListing?.trade_info_updated?.fax?.split(
                              ""
                            )[8]
                          : ""}
                        {maintenanceOrderListing?.trade_info_updated?.fax?.split(
                          ""
                        )[9] !== undefined
                          ? maintenanceOrderListing?.trade_info_updated?.fax?.split(
                              ""
                            )[9]
                          : ""}
                      </td>
                    </tr>
                    <tr style={{ color: "black", fontSize: "18px" }}>
                      <th className="tableBorderPrint">Email</th>
                      <td className="tableBorderPrint">
                        {maintenanceOrderListing?.trade_info_updated?.email}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div
              ref={printTableMaintenance}
              className="col-12 col-md-8 col-lg-9"
            >
              {printMS === true ? (
                <div className="col-12 col-md-4 col-lg-12">
                  <div
                    className="row justify-content-between align-items-center"
                    style={{ backgroundColor: "#38393c" }}
                  >
                    <div className="col-4 col-md-4 col-lg-3">
                      <div
                        className="logoWrapper"
                        style={{ backgroundColor: "#38393c" }}
                      >
                        <Link to={PATH.BUILDING_LIST}>
                          <img className="logo" src={Logosm} alt="" />
                        </Link>
                      </div>
                    </div>
                    {/* <div className="col-4 col-md-4 col-lg-3">abc</div> */}
                    <div className="col-4 col-md-4 col-lg-3 text-center">
                      <div className="details mt-2 mb-2">
                        <div className="detailContent">
                          <h4 style={{ color: "white", fontSize: "18px" }}>
                            Maintenance Service ID
                          </h4>
                          <h2 style={{ color: "white", fontSize: "18px" }}>
                            {maintenanceOrderListing?.order_id}
                          </h2>
                        </div>
                        <div className="detailContent">
                          <h4 style={{ color: "white", fontSize: "18px" }}>
                            Building Name
                          </h4>
                          <h2 style={{ color: "white", fontSize: "18px" }}>
                            {buildingName}
                          </h2>
                        </div>
                        <div className="detailContent">
                          <h4 style={{ color: "white", fontSize: "18px" }}>
                            Status
                          </h4>
                          <h2
                            className={`badge ${
                              maintenanceOrderListing?.order_state === "PENDING"
                                ? "pending"
                                : maintenanceOrderListing?.order_state ===
                                  "APPROVED"
                                ? "success"
                                : maintenanceOrderListing?.order_state ===
                                  "CONFIRM"
                                ? "success"
                                : "danger"
                            }`}
                          >
                            {maintenanceOrderListing?.order_state}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
              <div className="contentCard mb-0">
                <div className="titleWrapperPrint">
                  <span style={{ fontSize: "22px" }}>
                    Maintenance Service Work Confirmation
                  </span>
                </div>
                <div className=" table-responsive">
                  <table className="p-orderTable table table-striped">
                    <thead>
                      <tr>
                        <th
                          style={{
                            fontSize: "18px",
                            width: "283px",
                            wordBreak: "break-all",
                          }}
                        >
                          Description
                        </th>
                        <th
                          style={{
                            fontSize: "18px",
                            width: "160.07874016px",
                            wordBreak: "break-all",
                          }}
                        >
                          Log Hrs.
                        </th>
                        <th
                          style={{
                            fontSize: "18px",
                            width: "75.590551181px",
                            wordBreak: "break-all",
                          }}
                        >
                          Apt
                        </th>
                        <th
                          style={{
                            fontSize: "18px",
                            width: "68.590551181px",
                            wordBreak: "break-all",
                          }}
                        >
                          Price
                        </th>
                        <th
                          className="text-center"
                          style={{
                            fontSize: "17px",
                            width: "100.590551181px",
                            wordBreak: "break-all",
                          }}
                        >
                          Completed
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {maintenanceServiceItems &&
                        maintenanceServiceItems.map((v, i) => {
                          return (
                            <tr key={i}>
                              <td
                                style={{
                                  fontSize: "18px",
                                  border: "1px solid black",
                                  borderLeft: "none",
                                }}
                              >
                                {v.description}
                              </td>

                              <td
                                style={{
                                  fontSize: "18px",
                                  border: "1px solid black",
                                }}
                              >
                                {v.received_date ? (
                                  <>
                                    {" "}
                                    {!v.time_logs.length ? (
                                      <span>
                                        <b>{getDateOnly(v.received_date)}</b>
                                      </span>
                                    ) : (
                                      v?.time_logs?.map((val, ind) => {
                                        return (
                                          <span key={ind}>
                                            {!!v.received_date && (
                                              <b>
                                                {ind === 0
                                                  ? getDateOnly(
                                                      v.received_date
                                                    ) + ""
                                                  : ""}
                                              </b>
                                            )}
                                            <br />(
                                            {val.time_in === "Invalid date"
                                              ? "No Time In Added"
                                              : val.time_in}{" "}
                                            -{" "}
                                            {val.time_out === "Invalid date"
                                              ? "No Time Out Added"
                                              : val.time_out}
                                            )
                                          </span>
                                        );
                                      })
                                    )}
                                  </>
                                ) : (
                                  "-"
                                )}
                              </td>
                              <td
                                style={{
                                  fontSize: "18px",
                                  border: "1px solid black",
                                }}
                              >
                                {v.suite_no}
                              </td>
                              <td
                                style={{
                                  fontSize: "18px",
                                  border: "1px solid black",
                                }}
                              >
                                <b>
                                  {v.price
                                    ? formatDollarToUS(
                                        v.price,
                                        "00",
                                        undefined,
                                        {
                                          maxDigits: 0,
                                        }
                                      )
                                    : ""}
                                </b>
                              </td>
                              <td
                                className="text-center"
                                style={{
                                  fontSize: "18px",
                                  border: "1px solid black",
                                  borderRight: "none",
                                }}
                              >
                                <input
                                  type="checkbox"
                                  className="customRadio"
                                  checked={v.is_complete ? true : false}
                                  disabled
                                />
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>

                <hr className="splitCard" />
              </div>
              {maintenanceOrderListing?.comments?.length < 1 ? (
                ""
              ) : (
                <div className="contentCard commentWrapper mt-3">
                  {/* {bug here } */}
                  <div className="formDesign">
                    <div className="form-group">
                      <label style={{ fontSize: "20px" }}>Comments </label>
                      <div className="commentLogs">
                        {maintenanceOrderListing?.comments?.map((v, i, arr) => {
                          return (
                            <div key={v._id} className="comment">
                              <h2 style={{ fontSize: "20px" }}>
                                {capatalizeLetters(
                                  v?.created_by_user_info?.first_name +
                                    "_" +
                                    v?.created_by_user_info?.last_name
                                )}{" "}
                                <span style={{ fontSize: "18px" }}>
                                  {getDate(v.created_at)}{" "}
                                  {localStorage.getItem("role") === "viewer" ? (
                                    userAccessibility(
                                      "purchase_order_management",
                                      "delete"
                                    ) === false ? (
                                      ""
                                    ) : (
                                      <a
                                        href="#"
                                        className="circleBtn danger deleteThis ml-2"
                                        onClick={() => {
                                          deleteComment([v._id]);
                                        }}
                                      >
                                        <Delete2 />
                                      </a>
                                    )
                                  ) : localStorage.getItem("role") ===
                                    "building_manager" ? (
                                    userAccessibility(
                                      "purchase_order_management",
                                      "delete"
                                    ) === false ? (
                                      ""
                                    ) : (
                                      <a
                                        href="#"
                                        className="circleBtn danger deleteThis ml-2"
                                        onClick={() => {
                                          deleteComment([v._id]);
                                        }}
                                      >
                                        <Delete2 />
                                      </a>
                                    )
                                  ) : localStorage.getItem("role") ===
                                    "property_manager" ? (
                                    userAccessibility(
                                      "purchase_order_management",
                                      "delete"
                                    ) === false ? (
                                      ""
                                    ) : (
                                      <a
                                        href="#"
                                        className="circleBtn danger deleteThis ml-2"
                                        onClick={() => {
                                          deleteComment([v._id]);
                                        }}
                                      >
                                        <Delete2 />
                                      </a>
                                    )
                                  ) : (
                                    <a
                                      href="#"
                                      className="circleBtn danger deleteThis ml-2"
                                      onClick={() => {
                                        deleteComment([v._id]);
                                      }}
                                    >
                                      <Delete2 />
                                    </a>
                                  )}
                                </span>
                              </h2>
                              <p style={{ fontSize: "20px" }}>{v.comment}</p>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </section>

        {/* <!-- voide order --> */}
        <div className="modal fade" id="voidOrder">
          <div className="modal-dialog modal-sm modal-dialog-centered">
            <div className="modal-content customModal">
              <div className="modal-body">
                <h2 className="modalTitle"> Void!</h2>
                <div className="formDesign">
                  <div className="form-group">
                    <label>Please Enter a Reason For Void.</label>
                    <textarea
                      className="form-control"
                      rows="5"
                      value={voidDescription}
                      onChange={(e) => setVoidDescription(e.target.value)}
                    ></textarea>
                  </div>
                  <button
                    className="myBtn ml-auto mr-1"
                    data-dismiss="modal"
                    onClick={() => voidListing()}
                  >
                    Void
                  </button>
                  <button className="myBtn simple ml-auto" data-dismiss="modal">
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- approve Order --> */}
        <div className="modal fade" id="approveOrder">
          <div className="modal-dialog modal-sm modal-dialog-centered">
            <div className="modal-content customModal">
              <div className="modal-body">
                <h2 className="modalTitle"> Approve!</h2>
                <div className="formDesign">
                  <div className="form-group">
                    <label>
                      You Are About To Approve Purchase Order [{orderID}]
                    </label>
                    <input
                      type="checkbox"
                      className="customCheck mx-3"
                      // id={"purchaseOrder" + i}
                      //               value={v._id}
                      //               checked={
                      //                 deleteItem && deleteItem.includes(v._id)
                      //                   ? true
                      //                   : false
                      //               }

                      value={approveSendEmailCheckBox}
                      onChange={(e) =>
                        setApproveSendEmailCheckBox(e.target.checked)
                      }
                    />
                    <label>Send Email to Contractor</label>
                  </div>
                  <div className="text-center">
                    <button
                      className="myBtn mr-1"
                      data-dismiss="modal"
                      onClick={() => approveListing()}
                    >
                      Approve
                    </button>
                    <button className="myBtn simple " data-dismiss="modal">
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </MainLayout>
  );
}
