import React, { useEffect, useState } from "react";
import MainLayout from "../../layouts";
import FormTables from "../../components/Table/FormTable/FormTables";
import { Button } from "react-bootstrap";
import AddForms from "./AddForms/AddForms";
import { adminApiCall, deleteFormApiCall } from "../../helpers/ajax_request";
import Loading from "../../components/Loading";
function Forms() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [data, setData] = useState([]);
  const [selectId, setSelectedId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const fechList = async () => {
    setIsLoading(true);
    try {
      let res = await adminApiCall("/api/admin/get-forms", "get");
      if (res?.status === 200) {
        setData(res?.data);
        setIsLoading(false);
      }
      console.log(res, "fladjkshfalkdjhflakdjf");
    } catch (e) {
      setIsLoading(false);
      console.log(e + "error");
    }
  };
  useEffect(() => {
    fechList();
  }, []);
  const handleUpdate = (data) => {
    setIsModalOpen(true);
    setSelectedId(data);
  };
  const handleDelete = async (id) => {
    const res = await deleteFormApiCall(`/api/admin/delete-form/${id}`);
    console.log(res, "fadl;fahldkfhalsdkfhalskdj");
    if (res.status === 200) {
      fechList();
    }
  };
  console.log(data, "afdsfadsfalfjalhdskjfahdjsf");
  return (
    <MainLayout>
      <section className="pageWrapper cardControl">
        <div className="contentCard" style={{ minHeight: "850px" }}>
          <div className="titleWrapper">
            <span>Forms!</span>
          </div>
          <div className="tabsSection" style={{ marginBottom: "20px" }}>
            <ul className="nav customTabs">
              <li>
                <a
                  className="nav-link active"
                  data-toggle="tab"
                  href="#view"
                ></a>
              </li>
            </ul>
            <Button
              onClick={() => setIsModalOpen(true)}
              className="myBtn shadow reactBoostrapModalBtn"
            >
              Add Form
            </Button>
          </div>
          {isLoading ? (
            <Loading />
          ) : (
            <FormTables
              handleDelete={handleDelete}
              data={data}
              handleUpdate={handleUpdate}
            />
          )}
          <AddForms
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            fetchList={fechList}
            selectedId={selectId}
            setSelectedId={setSelectedId}
          />
        </div>
      </section>
    </MainLayout>
  );
}

export default Forms;
