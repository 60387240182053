import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import Map from "../../../assets/images/map.png";
import { Delete2, Edit } from "../../../Icons/Icons";
import MainLayout from "../../../layouts";
import { PATH } from "../../../constants/paths";
import { adminApiCall, userAccessibility } from "../../../helpers/ajax_request";
import {  toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";

export default function Index() {
  const params = useParams();
  const buildingID = params.id;
  // console.log(buildingID);

  const navigate = useNavigate();

  const [buildingDetail, setBuildingDetail] = useState({});
  const [buildingDetailArray, setBuildingDetailArray] = useState([]);
  const [toggleVisibility, setToggleVisibility] = useState();

  useEffect(() => {
    getBuildingDetails();
  }, [buildingID]);

  toast.configure();

  const notifySuccess = (msg) =>
    toast.success(msg, {
      autoClose: 3000,
    });

  const notifyWarning = (msg) =>
    toast.warning(msg, {
      autoClose: 3000,
    });
  const notifyError = (msg) =>
    toast.error(msg, {
      autoClose: 3000,
    });

  const getBuildingDetails = async () => {
    let data = {
      building_id: buildingID,
    };

    let res = await adminApiCall(
      "/api/admin/building/get",
      "POST",
      JSON.stringify(data)
    );
    if (!res) {
      notifyError("Something went wrong.. Please Try Again");
      return false;
    }
    if (res.hasOwnProperty("status")) {
      if (res.status === 200) {
        setBuildingDetail(res.data);
        setBuildingDetailArray([res.data]);
        setToggleVisibility(res.data?.status);
      } else if (res.status === 400) {
        notifyError(res.message);
      }
    }
  };

  const deleteUser = (deleteArray) => {
    Swal.fire({
      title: "Are you sure!",
      text: "You want to delete?",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "No, cancel please!",
      confirmButtonText: "Yes! Delete This",
      confirmButtonColor: "#d97228",
      closeOnConfirm: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        let data;
        if (typeof deleteArray === "number") {
          data = {
            building_id: [deleteArray],
          };
        } else {
          data = {
            building_id: [deleteArray],
          };
        }
        let res = await adminApiCall(
          "/api/admin/building/delete",
          "POST",
          JSON.stringify(data)
        );
        if (!res) {
          notifyError("Something went wrong.. Please Try Again");
          return false;
        }
        if (res.hasOwnProperty("status")) {
          if (res.status === 200) {
            notifySuccess("Building Deleted Successfully!");
            navigate(PATH.BUILDING_LIST);
            localStorage.removeItem("building_name");
            localStorage.removeItem("building_id");
          } else if (res.status === 400) {
            notifyError(res.message);
          }
        }
      }
    });
  };

  const handleVisibility = async (e) => {
    let value = e.target.checked;
    setToggleVisibility(value);

    let data = {
      building_id: buildingID,
      status: e.target.checked,
    };

    let res = await adminApiCall(
      "/api/admin/building/change_status",
      "POST",
      JSON.stringify(data)
    );

    if (!res) {
      notifyError("Something went wrong.. Please Try Again");
      return false;
    }
    if (res.hasOwnProperty("status")) {
      if (res.status === 200) {
        notifySuccess("Building Status Changed Successfully!");
        window.location.reload();
      } else if (res.status === 400) {
        notifyError(res.message);
      }
    }
  };

  return (
    <MainLayout>
      <>
        <section className="pageWrapper">
          <div className="row">
            <div className="col-12 col-md-4 col-lg-3">
              <div className="contentCard dark mb-0">
                <div className="titleWrapper">
                  <span>Building Details</span>
                </div>
                <div className="details">
                  <div className="detailContent">
                    <h4 className="textColor">Building ID</h4>
                    <h2 className="textColor">{buildingDetail?.building_id}</h2>
                  </div>
                  <div className="detailContent">
                    <h4 className="textColor">Building Name</h4>
                    <h2 className="textColor">
                      {buildingDetail?.building_name}
                    </h2>
                  </div>
                  <div className="detailContent">
                    <h4 className="textColor">Street</h4>
                    <h2 className="textColor">{buildingDetail?.street}</h2>
                  </div>
                  <div className="detailContent">
                    <h4 className="textColor">City</h4>
                    <h2 className="textColor">{buildingDetail?.city}</h2>
                  </div>
                  <div className="detailContent">
                    <h4 className="textColor">Province</h4>
                    <h2 className="textColor">{buildingDetail?.province}</h2>
                  </div>
                  <div className="detailContent">
                    <h4 className="textColor">Country</h4>
                    <h2 className="textColor">{buildingDetail?.country}</h2>
                  </div>
                  <div className="detailContent">
                    <h4 className="textColor">Available Apts.</h4>
                    <h2 className="textColor">
                      {buildingDetail?.available_suite}
                    </h2>
                  </div>

                  <div className="detailContent">
                    <h4 className="textColor">Region</h4>
                    <h2 className="textColor">{buildingDetail?.region}</h2>
                  </div>

                  {localStorage.getItem("role") === "viewer" ? (
                    userAccessibility("building_management", "create") ===
                    false ? (
                      ""
                    ) : (
                      <>
                        <div className="detailContent">
                          <div className="d-flex align-items-center justify-content-between">
                            <h2 className="textColor">Visibility</h2>
                            <div className="toggleBtn withLable">
                              <input
                                type="checkbox"
                                id="togglePrice"
                                package="package4"
                                value={toggleVisibility}
                                checked={toggleVisibility ? "checked" : false}
                                onChange={(e) => {
                                  handleVisibility(e);
                                }}
                              />
                              <label htmlFor="togglePrice">
                                <span className="on">ON</span>
                                <span className="off">OFF</span>
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="detailContent">
                          <div className="d-flex align-items-center justify-content-between">
                            <h2 className="textColor">Action</h2>
                            <div className="actionBtns">
                              <Link
                                className="circleBtn"
                                to={`${PATH.EDIT_BUILDING}/${buildingID}`}
                              >
                                <Edit />
                              </Link>
                              <a
                                className="circleBtn"
                                href="#"
                                onClick={() => deleteUser(buildingID)}
                              >
                                <Delete2 />
                              </a>
                            </div>
                          </div>
                        </div>
                      </>
                    )
                  ) : localStorage.getItem("role") === "building_manager" ? (
                    userAccessibility("building_management", "create") ===
                    false ? (
                      ""
                    ) : (
                      <>
                        <div className="detailContent">
                          <div className="d-flex align-items-center justify-content-between">
                            <h2 className="textColor">Visibility</h2>
                            <div className="toggleBtn withLable">
                              <input
                                type="checkbox"
                                id="togglePrice"
                                package="package4"
                                value={toggleVisibility}
                                checked={toggleVisibility ? "checked" : false}
                                onChange={(e) => {
                                  handleVisibility(e);
                                }}
                              />
                              <label htmlFor="togglePrice">
                                <span className="on">ON</span>
                                <span className="off">OFF</span>
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="detailContent">
                          <div className="d-flex align-items-center justify-content-between">
                            <h2 className="textColor">Action</h2>
                            <div className="actionBtns">
                              <Link
                                className="circleBtn"
                                to={`${PATH.EDIT_BUILDING}/${buildingID}`}
                              >
                                <Edit />
                              </Link>
                              <a
                                className="circleBtn"
                                href="#"
                                onClick={() => deleteUser(buildingID)}
                              >
                                <Delete2 />
                              </a>
                            </div>
                          </div>
                        </div>
                      </>
                    )
                  ) : localStorage.getItem("role") === "property_manager" ? (
                    userAccessibility("building_management", "create") ===
                    false ? (
                      ""
                    ) : (
                      <>
                        <div className="detailContent">
                          <div className="d-flex align-items-center justify-content-between">
                            <h2 className="textColor">Visibility</h2>
                            <div className="toggleBtn withLable">
                              <input
                                type="checkbox"
                                id="togglePrice"
                                package="package4"
                                value={toggleVisibility}
                                checked={toggleVisibility ? "checked" : false}
                                onChange={(e) => {
                                  handleVisibility(e);
                                }}
                              />
                              <label htmlFor="togglePrice">
                                <span className="on">ON</span>
                                <span className="off">OFF</span>
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="detailContent">
                          <div className="d-flex align-items-center justify-content-between">
                            <h2 className="textColor">Action</h2>
                            <div className="actionBtns">
                              <Link
                                className="circleBtn"
                                to={`${PATH.EDIT_BUILDING}/${buildingID}`}
                              >
                                <Edit />
                              </Link>
                              <a
                                className="circleBtn"
                                href="#"
                                onClick={() => deleteUser(buildingID)}
                              >
                                <Delete2 />
                              </a>
                            </div>
                          </div>
                        </div>
                      </>
                    )
                  ) : (
                    <>
                      <div className="detailContent">
                        <div className="d-flex align-items-center justify-content-between">
                          <h2 className="textColor">Visibility</h2>
                          <div className="toggleBtn withLable">
                            <input
                              type="checkbox"
                              id="togglePrice"
                              package="package4"
                              value={toggleVisibility}
                              checked={toggleVisibility ? "checked" : false}
                              onChange={(e) => {
                                handleVisibility(e);
                              }}
                            />
                            <label htmlFor="togglePrice">
                              <span className="on">ON</span>
                              <span className="off">OFF</span>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="detailContent">
                        <div className="d-flex align-items-center justify-content-between">
                          <h2 className="textColor">Action</h2>
                          <div className="actionBtns">
                            <Link
                              className="circleBtn"
                              to={`${PATH.EDIT_BUILDING}/${buildingID}`}
                            >
                              <Edit />
                            </Link>
                            <a
                              className="circleBtn"
                              href="#"
                              onClick={() => deleteUser(buildingID)}
                            >
                              <Delete2 />
                            </a>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="col-12 col-md-8 col-lg-9">
              <div className="mapImg">
                <img src={Map} />
              </div>
            </div>
          </div>
        </section>
      </>
    </MainLayout>
  );
}
