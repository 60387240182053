import "react-toastify/dist/ReactToastify.css";
import Loader from "../../../assets/images/LoaderNew.gif";
import Logosm from "../../../assets/images/logo.png";
import MainLayout from "../../../layouts";
import Multiselect from "multiselect-react-dropdown";
import MySelect from "../../../components/SelectDropdown/Index";
import NoRecordFound from "../../../assets/images/noRecordFound.png";
import React, { useEffect, useRef, useState } from "react";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import ReactPaginate from "react-paginate";
import Select, { components } from "react-select";
import makeAnimated from "react-select/animated";
import moment from "moment";
import { Link } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { ToastContainer, toast } from "react-toastify";
import { Export, Print } from "../../../Icons/Icons";
import { PATH } from "../../../constants/paths";
import { adminApiCall } from "../../../helpers/ajax_request";

// import { MultiSelect } from "react-multi-select-component";
// import "react-select/dist/react-select.css";

const animatedComponents = makeAnimated();
export default function Index() {
  const [vacancyListing, setVacancyListing] = useState([]);
  const [loader, setLoader] = useState(true);
  const [noRecord, setNoRecord] = useState(false);
  const [pageCount, setPageCount] = React.useState(10);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [totalRecord, setTotalRecord] = React.useState(10);
  const [perPage, setPerPage] = React.useState(10);
  const [options, setOptions] = useState([]);
  const [buildingIDs, setBuildingIDs] = useState([]);
  const [showLogo, setShowLogo] = useState(false);
  const [vacateDateSortOrder, setVacateDateSortOrder] = useState(-1)
  
  const componentRef = useRef();

  const [optionSelected, setOptionSelected] = useState([]);

  const handleChange = (selected) => {
    // console.log(selected);
    // this.setState({
    //   optionSelected: selected
    // });
    setOptionSelected(selected);
    if (selected.length === 0) {
      setBuildingIDs([]);
    } else {
      let tempArray = [];
      selected.map((v, i) => {
        tempArray.push(v.value);
      });
      setBuildingIDs(tempArray);
    }
  };

  useEffect(() => {
    getBuildingNames();
  }, []);

  useEffect(() => {
    getVacancyListing();
  }, [buildingIDs.length, currentPage, vacateDateSortOrder]);

  toast.configure();

  const notifySuccess = (msg) =>
    toast.success(msg, {
      autoClose: 3000,
    });

  const notifyWarning = (msg) =>
    toast.warning(msg, {
      autoClose: 3000,
    });
  const notifyError = (msg) =>
    toast.error(msg, {
      autoClose: 3000,
    });

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: () => {
      setShowLogo(false);
    },
    copyStyles: true,
    pageStyle:
      "@media print { body { -webkit-print-color-adjust: exact;  font-size:16px !important;} @page { size: A4;  margin: 0  }}",
    documentTitle: "Mopo Vacancy Report",
  });

  const getBuildingNames = async () => {
    let res = await adminApiCall("/api/admin/user/assign_buildings", "GET");
    if (!res) {
      notifyError("Something went wrong.. Please Try Again");
      return false;
    }
    if (res.hasOwnProperty("status")) {
      if (res.status === 200) {
        // console.log(res);
        // setOptions(res.data);
        // console.log("res select building", res);

        let tempArray = [];
        for (let i = 0; i < res.data.length; i++) {
          tempArray.push({
            // name: res.data[i].property_id,
            // _id: res.data[i]._id,
            // building_id: res.data[i].building_id,
            // address: res.data[i].street,
            // noOfSuite: res.data[i].no_of_suite,
            // availableSuite: res.data[i].available_suite,
            // nameOfBuilding: res.data[i].building_name,
            label: res.data[i].building_name,
            value: res.data[i]._id,
          });
        }
        // console.log(tempArray);
        setOptions(tempArray);
      } else if (res.status === 400) {
        notifyError(res.message);
      }
    }
  };

  const getVacancyListing = async () => {
    let data = {
      current_page: currentPage,
      itemsPerPage: 10000,
      building_id: buildingIDs,
      vacate_date_sort_order: vacateDateSortOrder
    };
    let res = await adminApiCall(
      "/api/admin/reports/vacancy_report/list",
      "POST",
      JSON.stringify(data)
    );
    if (!res) {
      notifyError("Something went wrong.. Please Try Again");
      setLoader(false);
      setNoRecord(true);
      return false;
    }
    if (res.hasOwnProperty("status")) {
      // console.log('===========',res.data,'==========')
      if (res.status === 200) {
        setLoader(false);
        setNoRecord(false);
        setPageCount(res.total_pages);
        setTotalRecord(res.total_records);
        setVacancyListing(res.data);
        // console.log('===========',res.data,'==========')
      } else if (res.status === 400) {
        setLoader(false);
        setNoRecord(true);
        notifyError(res.message);
      } else if (res.status === 300) {
        setLoader(false);
        setNoRecord(true);
      }
    }
  };

  const onSelect = (selectedList, selectedItem) => {
    // console.log("selectedList: ", selectedList);
    // console.log("selectedItem: ", selectedItem);
    // if (selectedItem.nameOfBuilding === "Select All") {
    //   console.log("select all");
    // } else {
    //   console.log("single select");
    // }
    let selectOptionsArray = [];
    for (const selectedListElement of selectedList) {
      selectOptionsArray.push(selectedListElement._id);
    }
    let tempArray = [...selectedList];
    tempArray.push(selectedItem);
    // console.log("selectOptionsArray: ", selectOptionsArray);

    setBuildingIDs(selectOptionsArray);
  };

  const onRemove = (selectedList, removedItem) => {
    // console.log("selectedList: ", selectedList);
    // console.log("removedItem: ", removedItem);
    let tempArray = [...buildingIDs];
    tempArray.splice(tempArray.indexOf(removedItem), 1);
    // console.log("tempArrayremove: ", tempArray);

    setBuildingIDs(tempArray);
  };

  const handlePageClick = (e) => {
    const selectedPage = e.selected + 1;
    setCurrentPage(selectedPage);
  };

  const handleSortVacateDate = () => {
    setVacateDateSortOrder((prev) => (prev === 1 ? -1 : 1))
  }

console.log("vacancyListing",vacancyListing)
  return (
    <MainLayout>
      <>
        <style type="text/css" media="print">
          {
            "\
  @page {size: landscape;margin: 40px }\
  html, body { width: 210mm;height: 297mm;}\
  .mainTable td, .mainTable td b, .mainTable td span, .tableBorderPrint td,  .tableBorderPrint td b,.mainTable th, .shadowTable td, .shadowTable td span, .shadowTable td b, .shadowTable th,.table_vacancy_report span{font-size: 11px;}\
  .mainTable th{margin-top:10px}\
"
          }
        </style>
        <section className="pageWrapper ">
          <div className="contentCard">
            <div className="titleWrapper">
              <span>Vacancy Report</span>
            </div>
            <div className="filters">
              <div className="row">
                <div className="col-4 col-md-4">
                  <b>
                    <label>Search Building</label>
                  </b>
                  <MySelect
                    options={options}
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    components={animatedComponents}
                    onChange={handleChange}
                    allowSelectAll={true}
                    value={optionSelected}
                  />
                  {/* <Multiselect
                    options={options} // Options to display in the dropdown
                    // selectedValues={selectedValue} // Preselected value to persist in dropdown
                    onSelect={(list, item) => onSelect(list, item)} // Function will trigger on select event
                    onRemove={(list, item) => onRemove(list, item)}
                    showCheckbox={true} // Function will trigger on remove event
                    displayValue="label" // Property name to display in the dropdown options
                    placeholder="Select Building.."
                    className="multiselectWrapper"
                    style={{
                      chips: {
                        background: "var(--primary)",
                      },
                    }}
                  /> */}
                </div>
              </div>
            </div>
            {loader ? (
              <div className="text-center">
                <img src={Loader} alt="" />
              </div>
            ) : noRecord ? (
              <div className="text-center">
                <img src={NoRecordFound} alt="" />
              </div>
            ) : (
              // here is the compoennet ref for printing
              // here is the compoennet ref for printing
              // here is the compoennet ref for printing
              // here is the compoennet ref for printing
              // here is the compoennet ref for printing
              // here is the compoennet ref for printing
              // here is the compoennet ref for printing
              // here is the compoennet ref for printing
              // here is the compoennet ref for printing
              <div className="tab-content">
                <div
                  ref={componentRef}
                  className="tab-pane fade show active scalePage"
                  id="purchase "
                >
                  {showLogo ? (
                    <div
                      className="logoWrapper"
                      style={{
                        backgroundColor: "white",
                        marginBottom: "15px",
                      }}
                    >
                      <Link className="logo" to={PATH.BUILDING_LIST}>
                        <img
                          className="logo"
                          src={Logosm}
                          alt=""
                          style={{ width: 140 }}
                        />
                      </Link>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="mainTable table-responsive vacancyTD tableReports">
                    <table
                      id="table-to-xls"
                      className="table table-striped landscape vacancyTablePrint"
                      //
                    >
                      <thead className="">
                        <tr>
                          <th style={{ width: "200px" }} className="aptPrint">
                            Apt. No.
                          </th>
                          <th style={{ width: "5%" }}>Bed</th>
                          <th style={{ width: "5%" }}>Bath</th>
                          <th>Square Footage</th>
                          <th style={{ whiteSpace: "nowrap", width: "200px" }}>
                            Rent Without{" "}
                            <br className={showLogo ? "" : "d-none"} />
                            Discount
                          </th>
                          <th style={{ whiteSpace: "nowrap", width: "200px" }}>
                            Rent With{" "}
                            <br className={showLogo ? "" : "d-none"} /> 2%
                            Discount
                          </th>
                          <th>
                            <span className="sort d-flex" onClick={handleSortVacateDate}>Vacate Date</span>
                          </th>
                          <th>Comment</th>
                        </tr>
                      </thead>
                      <tbody>
                        {vacancyListing &&
                          vacancyListing.map((v, i) => {
                            return (
                              <>
                                <tr
                                  key={i}
                                  className="contentCard px-0 mx-0 tabel_vacancy_report_tr"
                                >
                                  <td
                                    className="table_vacancy_report text-left"
                                    colSpan={"8"}
                                  >
                                    <span className="reportBuildingName">
                                      {v._id}
                                    </span>
                                  </td>
                                </tr>
                                {v?.unit_info?.map((val, ind) => {
                                  return (
                                    <tr className="tableBorderPrint" key={ind}>
                                      <td>
                                        <b
                                          className="text-body "
                                          style={{ whiteSpace: "nowrap" }}
                                        >
                                          {val?.unit_no}
                                        </b>
                                      </td>
                                      <td style={{ width: "5%" }}>
                                        {val?.type === "0"
                                          ? "B"
                                          : val?.type.split("BEDROOM")[0]}
                                      </td>
                                      { console.log(val?.bathroom) }
                                      <td style={{ width: "5%",fontSize:"12px" }}>
                                        {(val?.bathroom ===  "1" || val?.bathroom === "1 BATHROOM")
                                          ? "1"
                                          : (val?.bathroom === "1.5" || val?.bathroom === "1.5 BATHROOM")
                                          ? "1.5"
                                          : "2"}
                                      </td>
                                      <td>{val?.square_footage}</td>
                                      <td>
                                        {" "}
                                        $
                                        {(
                                          (val?.standard_rent * 100) /
                                          100
                                        ).toLocaleString(undefined, {
                                          minimumFractionDigits: 2,
                                        })}
                                      </td>
                                      <td>
                                        <b>
                                          $
                                          {(
                                            (val?.rent_with_discount * 100) /
                                            100
                                          ).toLocaleString(undefined, {
                                            minimumFractionDigits: 2,
                                          })}
                                        </b>
                                      </td>
                                      <td
                                        style={{
                                          fontSize: 12,
                                          whiteSpace: "nowrap",
                                        }}
                                      >
                                        {/* {moment(val?.vacate_date).format(
                                          "DD MMMM YYYY"
                                        )} */}
                                        {moment
                                          .utc(new Date(val?.vacate_date))
                                          .format("DD MMM YYYY")}
                                      </td>
                                      <td>
                                        {val?.comments?.length === 0
                                          ? ""
                                          : val?.comments.map(
                                              (value, index) => {
                                                return (
                                                  <span key={index}>
                                                    {value.comment}
                                                    {val?.comments.lastIndexOf(
                                                      val?.comments[
                                                        val?.comments?.length -
                                                          1
                                                      ]
                                                    ) === index
                                                      ? ""
                                                      : ""}
                                                  </span>
                                                );
                                              }
                                            )}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            )}

            <div className="cardFooter">
              <button
                className="myBtn bdr mr-1"
                href="#"
                type="button"
                onClick={() => {
                  setShowLogo(true);
                  setTimeout(() => {
                    handlePrint();
                  }, 500);
                }}
                disabled={noRecord ? true : false}
              >
                <Print />
                <span className="ml-2">Print</span>
              </button>
              <ReactHTMLTableToExcel
                id="test-table-xls-button"
                className="download-table-xls-button myBtn bdr"
                table="table-to-xls"
                filename="vacancyReport"
                sheet="tablexls"
                buttonText={`Export`}
              />
            </div>
          </div>
        </section>
      </>
    </MainLayout>
  );
}
