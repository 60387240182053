import React, { forwardRef, useImperativeHandle } from "react";
import { useState, useRef, useEffect } from "react";
import MainLayout from "../../layouts";
import { DatePicker, Space } from "antd";
import { useFormik } from "formik";
import moment from "moment";
import { useReactToPrint } from "react-to-print";
import { flushSync } from "react-dom";

import {
  createApplcation,
  updateApplication,
} from "../../helpers/ajax_request";
import * as Yup from "yup";
import download from "../../assets/images/download.png";

import SignatureCanvas from "react-signature-canvas";
import $ from "jquery";
import { Modal, Button, Input } from "antd";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Print } from "../../Icons/Icons";
import { getApplication } from "../../helpers/ajax_request";
import { adminApiCall } from "../../helpers/ajax_request";
import { useParams } from "react-router-dom";
import { baseURL } from "../../config";
import Logosm from "../../assets/images/logosm.png";
import Logo from "../../assets/images/logo.png";

import { useNavigate } from "react-router-dom";
import momentTz from "moment-timezone";
import { Tabs } from "antd";
import { useLocation } from "react-router-dom";
import { setUpdateSubmitFunction } from "../Application-listing";
import ReactInputMask from "react-input-mask";
import statics from "../../assets/images/statics.bmp";

const { TabPane } = Tabs;

const Application = ({ printData }, ref) => {
  console.log({ printData });
  const [fields, setFields] = useState([
    {
      firstName: "",
      middleName: "",
      lastName: "",
      sin: "",
      email: "",
    },
  ]);
  const [occupantFields, setOccupantFields] = useState([
    {
      firstNameOccupant: "",
      middleNameOccupant: "",
      lastNameOccupant: "",
      sinOccupant: "",
      emailOccupant: "",
    },
  ]);
  const [type, setType] = useState([
    { label: "Bachelor", value: "BACHELOR" },
    { label: "1 Bedroom", value: "1 Bedroom" },
    { label: "2 Bedroom", value: "2 Bedroom" },
    { label: "3 Bedroom", value: "3 Bedroom" },
    { label: "4 Bedroom", value: "4 Bedroom" },
  ]);
  const [typeSelected, setTypeSelected] = useState("Bachelor");

  const [requestDate, setRequestDate] = useState(moment.utc());
  const [creditDate, setcreditDate] = useState(moment.utc());
  const [selectedBuilding, setSelectedBuilding] = useState(
    localStorage.getItem("building_namee")
  );
  const [sum, setSum] = useState(0);
  const [filesDataGov, setFilesDataGov] = useState();
  const [filesDataProof, setFilesDataProof] = useState();
  const [approvedDate, setApprovedDate] = useState();
  const [approvedBy, setApprovedBy] = useState();

  const [limmiterDate, setLimmitertDate] = useState(moment.utc());
  const [limmiterDate2, setLimmitertDate2] = useState(moment.utc());

  const [smokeDate, setSmokeDate] = useState(moment.utc());
  const [visible, setVisible] = useState(false);
  const [agreementDate, setAgreementDate] = useState(moment.utc());
  const [acceptanceDate, setAcceptanceDate] = useState(moment.utc());
  const [dobApplicantOnes, setDobApplicantOnes] = useState(moment.utc());
  const [dobApplicantTwos, setDobApplicantTows] = useState(moment.utc());
  const [dobApplicantThrees, setDobApplicantThrees] = useState(moment.utc());
  const [tenentStartDate, setTenentStartDate] = useState("");

  // const [tenentStartDate, setTenentStartDate] = useState(moment.utc());
  // const [tenentEndDate, setTenentEndDate] = useState(moment.utc());
  const [tenentEndDate, setTenentEndDate] = useState("");

  const [proStartDate, setProStartDate] = useState("");
  const [proEndDate, setProEndDate] = useState("");
  const [divWidth, setDivWidth] = useState("");
  const [divWidthCreditBm, setDivWidthCreditBm] = useState("");
  const [notes, setNotes] = useState([]);
  const [emailStatus, setEmailStatus] = useState("");
  const [applicationStatus, setApplicationStatus] = useState("");

  const [divWidthSmoke, setDivWidthSmoke] = useState("");
  const [divWidthLimmiter, setDivWidthLimmiter] = useState("");

  const [divWidhCreditTenent1, setDivWidthCreditTenent1] = useState("");
  const [divWidhCreditTenent2, setDivWidthCreditTenent2] = useState("");
  const [divWidhCreditTenent3, setDivWidthCreditTenent3] = useState("");
  const [print, setPrint] = useState(false);
  const [divWidth1, setDivWidth1] = useState("");
  const [divHeight1, setDivHeight1] = useState("");
  const [divWidth2, setDivWidth2] = useState("");
  const [divHeight2, setDivHeight2] = useState("");
  const [divHeightSmoke, setDivHeightSmoke] = useState("");
  const [divHeightLimmiter, setDivHeightLimmiter] = useState("");
  const [divHeightCreditBm, setDivHeightCreditBm] = useState("");

  const [divHeightCreditTenent1, setDivHeightCreditTenent1] = useState("");
  const [divHeightCreditTenent2, setDivHeightCreditTenent2] = useState("");
  const [divHeightCreditTenent3, setDivHeightCreditTenent3] = useState("");

  const [signPadTenent, setSignPadTenent] = useState("");
  const [signPadTenent2, setSignPadTenent2] = useState("");
  const [signPadTenent3, setSignPadTenent3] = useState("");

  const [signPadTenentSmoke, setSignPadTenentSmoke] = useState("");
  const [signPadTenentSmoke2, setSignPadTenentSmoke2] = useState("");
  const [signPadTenentSmoke3, setSignPadTenentSmoke3] = useState("");

  const [signPadBuildingManager, setSignPadBuldingManager] = useState("");
  const [signPadPropertyManager, setSignPadPropertyManager] = useState("");
  const [signPadCreditBm, setSignPadCreditBm] = useState("");
  const [signPadCreditTenent1, setSignPadCreditTenent1] = useState("");
  const [signPadCreditTenent2, setSignPadCreditTenent2] = useState("");
  const [signPadCreditTenent3, setSignPadCreditTenent3] = useState("");

  const [signPadTenentLimmiter, setSignPadTenentLimmiter] = useState("");
  const [signPadTenentLimmiter2, setSignPadTenentLimmiter2] = useState("");
  const [signPadTenentLimmiter3, setSignPadTenentLimmiter3] = useState("");

  // const [signaturingPad, setSignaturingPad] = useState("");
  const [signaturingPadTenent, setSignaturingPadTenent] = useState("");
  const [signaturingPadBuldingManager, setSignaturingPadBuldingManager] =
    useState("");
  const [signaturingPadPropertyManager, setSignaturingPadPropertyManager] =
    useState("");
  const [divHeight, setDivHeight] = useState("");
  // images
  const [selectedImage, setSelectedImage] = useState("");
  const [uploadProgress, setUploadProgress] = useState(0);
  // file
  const [selectedFile, setSelectedFile] = useState("");
  const [filePreview, setFilePreview] = useState(null);
  const [uploadProgressFile, setUploadProgressFile] = useState(0);
  const [modalVisible, setModalVisible] = useState(false); // State to control the visibility of the modal
  const [showLogo, setShowLogo] = useState(false);
  // check boxes
  const [newApplicants, setNewApplicants] = useState(false);
  const [assignments, setAssignments] = useState(false);
  const [sublets, setSublets] = useState(false);
  const [transfers, setTransfers] = useState(false);
  const [nameChanges, setNameChanges] = useState(false);
  const [activeTab, setActiveTab] = useState("1: Application");

  const [tenantView, setTenantView] = useState("");
  const [tenantView2, setTenantView2] = useState("");
  const [tenantView3, setTenantView3] = useState("");

  const [buildingManagerView, setBuildingManagerView] = useState("");
  const [propertyManagerView, setPropertyManagerView] = useState("");

  const [smokeTenantView, setSmokeTenantView] = useState("");
  const [smokeTenantView2, setSmokeTenantView2] = useState("");
  const [smokeTenantView3, setSmokeTenantView3] = useState("");

  const [creditTenantView, setCreditTenantView] = useState("");
  const [creditTenantView2, setCreditTenantView2] = useState("");

  const [creditTenantView3, setCreditTenantView3] = useState("");

  const [limmiterTenantView, setLimmiterTenantView] = useState("");
  const [limmiterTenantView2, setLimmiterTenantView2] = useState("");
  const [limmiterTenantView3, setLimmiterTenantView3] = useState("");

  const [options, setOptions] = useState([]);
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [buildingList, setBuildingList] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [signatureExists, setSignatureExists] = useState(false);
  const [checkDownload, setCheckDownload] = useState(false);
  const [checkDownloadImage, setCheckDownloadImage] = useState(false);


  const location = useLocation();
  const currentRoute = location.pathname;
  const navigate = useNavigate();

  // All Response
  const [responseData, setResponseData] = useState([]);
  const [inputFields, setInputFields] = useState([
    { value: "", noteId: "", isNew: true },
  ]);

  const params = useParams();

  const applicationID = params.id;
  // console.log("params", params);
  const isApplicationRoute = location.pathname === "/application";
  const handleType = (e) => {
    // console.log(e.target.value);
    setTypeSelected(e.target.value);
    // selectedTypeField: typeSelected ?? "",

    formik?.setFieldValue("selectedTypeField", e.target.value);
  };

  const showModal = () => {
    setIsModalVisible(true);
    // setModalId(id)
  };
  const handleModalApp = (event) => {
    event.preventDefault(); // Prevent default form submission behavior

    setVisible(!modalVisible);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  // checkboxes
  const newApplicant = () => {
    setNewApplicants(!newApplicants);
  };
  const assignment = () => {
    setAssignments(!assignments);
  };
  const sublet = () => {
    setSublets(!sublets);
  };
  const transfer = () => {
    setTransfers(!transfers);
  };
  const nameChange = () => {
    setNameChanges(!nameChanges);
  };
  // checkboxes

  // file
  const renderPreview = () => {
    if (filePreview) {
      if (selectedFile.type.startsWith("image/")) {
        return (
          <img src={filePreview} alt="Preview" style={{ maxWidth: "200px" }} />
        );
      } else if (selectedFile.type === "application/pdf") {
        return (
          <embed
            src={filePreview}
            type="application/pdf"
            maxWidth="200px"
            maxHeight="16rem"
          />
        );
      } else {
        return <p>{selectedFile.name}</p>;
      }
    } else {
      return <p></p>;
    }
  };

  toast.configure();

  const notifySuccess = (msg) =>
    toast.success(msg, {
      autoClose: 3000,
    });

  const notifyWarning = (msg) =>
    toast.warning(msg, {
      autoClose: 3000,
    });
  const notifyError = (msg) =>
    toast.error(msg, {
      autoClose: 3000,
    });
  useEffect(() => {
    return () => {
      if (filePreview) {
        URL.revokeObjectURL(filePreview);
      }
    };
  }, [filePreview]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    console.log("file preview", file);
    console.log("file preview url", URL.createObjectURL(file));

    setSelectedFile(file);
    setFilePreview(URL.createObjectURL(file));
    setUploadProgress(0);
  };

  const handleRemoveFile = () => {
    URL.revokeObjectURL(filePreview);
    setSelectedFile(null);
    setFilePreview(null);
    setUploadProgress(0);
  };

  useImperativeHandle(ref, () => ({
    refsss,
  }));
  // get buffer from blob

  // file
  // images
  useEffect(() => {
    return () => {
      // Clean up the object URL when component unmounts
      if (selectedImage) {
        URL.revokeObjectURL(selectedImage);
      }
    };
  }, [selectedImage]);

  const handleImageChange = async (event) => {
    const file = event.target.files[0];
    // setSelectedImage(URL.createObjectURL(file));
    setUploadProgress(0); // Reset upload progress when a new image is selected
    try {
      // Convert the Blob URL to buffer data using the Fetch API
      const response = await fetch(URL.createObjectURL(file));
      const buffer = await response.arrayBuffer();
      // 'buffer' now contains the image data in the form of ArrayBuffer

      // Do something with the 'buffer' (e.g., upload to the  )
      // For demonstration purposes, let's log the byte length of the buffer
      console.log("Image buffer size:", buffer);
    } catch (error) {
      console.error("Error fetching image data:", error);
    }
  };

  const handleRemoveImage = () => {
    URL.revokeObjectURL(selectedImage);
    setSelectedImage(null);
    setUploadProgress(0);
  };
  // images
  const listingRef1 = useRef();
  const listingRef2 = useRef();
  const listingRef3 = useRef();
  const listingRef4 = useRef();
  const listingRef5 = useRef();
  const listingRef6 = useRef();

  let sigCanvas = useRef();
  // Dates in Application
  function dobApplicantOne(date, dateString) {
    date = moment.utc(date);
    setDobApplicantOnes(dateString === "" ? "" : moment.utc(date));
  }
  function dobApplicantTwo(date, dateString) {
    // setVacateDate(date === null ? "" : date);
    // console.log("pla", date, dateString);
    date = moment.utc(date);
    setDobApplicantTows(dateString === "" ? "" : moment.utc(date));
  }
  function dobApplicantThree(date, dateString) {
    // setVacateDate(date === null ? "" : date);
    // console.log("pla", date, dateString);
    date = moment.utc(date);
    setDobApplicantThrees(dateString === "" ? "" : moment.utc(date));
  }
  // const handleRequestDate = (selectedDate) => {
  //   const dateString = selectedDate; // Convert date to string

  //   setRequestDate(dateString);
  // };

  function handleRequestDate(date, dateString) {
    // setVacateDate(date === null ? "" : date);
    // console.log("pla", date, dateString);
    date = moment.utc(date);
    setRequestDate(dateString === "" ? "" : moment.utc(date));
  }
  function handleCreditDate(date, dateString) {
    // setVacateDate(date === null ? "" : date);
    // console.log("pla", date, dateString);
    date = moment.utc(date);
    setcreditDate(dateString === "" ? "" : moment.utc(date));
  }

  function handleLimmiterDate(date, dateString) {
    // setVacateDate(date === null ? "" : date);
    // console.log("pla", date, dateString);
    date = moment.utc(date);
    setLimmitertDate(dateString === "" ? "" : moment.utc(date));
  }

  function handleLimmiterDate2(date, dateString) {
    // setVacateDate(date === null ? "" : date);
    // console.log("pla", date, dateString);
    date = moment.utc(date);
    setLimmitertDate2(dateString === "" ? "" : moment.utc(date));
  }
  function handleSmokeDate(date, dateString) {
    // setVacateDate(date === null ? "" : date);
    // console.log("pla", date, dateString);
    date = moment.utc(date);
    setSmokeDate(dateString === "" ? "" : moment.utc(date));
  }
  function handleTenencyStartDate(date, dateString) {
    // setVacateDate(date === null ? "" : date);
    // console.log("pla", date, dateString);
    date = moment.utc(date);
    setTenentStartDate(dateString === "" ? "" : moment.utc(date));
  }
  function handleTenencyEndDate(date, dateString) {
    // setVacateDate(date === null ? "" : date);
    // console.log("pla", date, dateString);
    date = moment.utc(date);
    setTenentEndDate(dateString === "" ? "" : moment.utc(date));
  }
  function ProRatesStart(date, dateString) {
    // console.log("start date:", date, dateString);
    if (!dateString) {
      // Handle the case where the start date is cleared or empty
      setProStartDate(""); // Reset proStartDate
      formik.setFieldValue("noDays", 0);
      formik.setFieldValue("proAmount", ""); // Reset proAmount
      formik.setFieldValue("proAmountRent", ""); // Reset proAmount
      formik.setFieldValue("lmr", ""); // Reset proAmount
      formik.setFieldValue("perDiem", "");
      setProEndDate(""); // Reset proEndDate
    } else {
      const startDate = moment.utc(dateString, "MMMM DD YYYY");
      const endDate = proEndDate
        ? moment.utc(proEndDate, "MMMM DD YYYY")
        : null;

      formik.setFieldValue("proAmountRent", formik.values?.legalRent); // Reset proAmount
      formik.setFieldValue("lmr", formik.values?.legalRent); // Reset proAmount

      if (endDate && startDate.isAfter(endDate)) {
        setProEndDate(""); // Reset end date or handle the error as needed
      } else {
        // Calculate the difference in days
        let daysDifference = endDate ? endDate.diff(startDate, "days") : 0;

        // Add 1 to the calculated difference if start date is less than end date
        if (startDate.isBefore(endDate)) {
          daysDifference += 1;
        }

        // Update the formik field "noDays" with the calculated days
        formik.setFieldValue("noDays", daysDifference);
      }

      // Set the proStartDate state with the selected date
      setProStartDate(startDate);
    }
  }

  function ProRatesEnd(date, dateString) {
    // console.log("end date:", date, dateString);
    if (dateString) {
      const endDate = moment.utc(dateString, "MMMM DD YYYY");
      setProEndDate(endDate); // Set the proEndDate state with the selected date

      formik.setFieldValue("noDays", calculateNoDays(proStartDate, endDate));
      // formik.setFieldValue("noDays", formik.values?.legalRent);
      // formik.setFieldValue("proAmount", formik.values?.legalRent); // Reset proAmount
      formik.setFieldValue("proAmountRent", formik.values?.legalRent); // Reset proAmount
      formik.setFieldValue("lmr", formik.values?.legalRent); // Reset proAmount
      // formik.setFieldValue("perDiem", "");
    } else {
      // Handle the case where the end date is empty
      // You can set the noDays field to 0 or any other default value
      setProEndDate(""); // Reset proEndDate
      formik.setFieldValue("noDays", 0);
      formik.setFieldValue("proAmount", ""); // Reset proAmount
      formik.setFieldValue("proAmountRent", ""); // Reset proAmount
      formik.setFieldValue("lmr", ""); // Reset proAmount
      formik.setFieldValue("perDiem", "");
    }
  }

  function calculateNoDays(startDate, endDate) {
    if (!startDate || !endDate) {
      return 0;
    }

    // Calculate the difference in days
    let daysDifference = endDate.diff(startDate, "days");

    // Add 1 to the calculated difference if start date is less than end date
    if (startDate.isBefore(endDate)) {
      daysDifference += 1;
    }

    return daysDifference;
  }

  // Custom function to disable dates before the selected start date
  const disabledEndDate = (current) => {
    if (!proStartDate) {
      return false; // No start date selected, allow all end dates
    }
    return current && current < moment(proStartDate).endOf("day");
  };

  function handleAgreementtDate(date, dateString) {
    // setVacateDate(date === null ? "" : date);
    // console.log("pla", date, dateString);
    date = moment.utc(date);
    setAgreementDate(dateString === "" ? "" : moment.utc(date));
  }

  function handleAcceptancetDate(date, dateString) {
    // setVacateDate(date === null ? "" : date);
    // console.log("pla", date, dateString);
    date = moment.utc(date);
    setAcceptanceDate(dateString === "" ? "" : moment.utc(date));
  }

  // Dates in ApplicationdobApplicantOne

  const handleChangeTenent = (index, event) => {
    let { name, value } = event.target;
    let updatedFields = [...fields];
    updatedFields[index] = { ...updatedFields[index], [name]: value };
    setFields(updatedFields);
  };
  const handleChangeOccupant = (index, event) => {
    let { name, value } = event.target;
    let updatedFields = [...occupantFields];
    updatedFields[index] = { ...updatedFields[index], [name]: value };
    setOccupantFields(updatedFields);
  };
  const handleAddField = () => {
    setFields([
      ...fields,
      {
        firstName: "",
        middleName: "",
        lastName: "",
        sin: "",
        email: "",
      },
    ]);
  };
  const handleAddFieldOccupant = () => {
    setOccupantFields([
      ...occupantFields,
      {
        firstNameOccupant: "",
        middleNameOccupant: "",
        lastNameOccupant: "",
        sinOccupant: "",
        emailOccupant: "",
      },
    ]);
  };
  const handleRemoveFieldOcuupant = (index) => {
    let updatedFields = [...occupantFields];
    updatedFields.splice(index, 1);
    setOccupantFields(updatedFields);
  };
  const handleRemoveField = (index) => {
    let updatedFields = [...fields];
    updatedFields.splice(index, 1);
    setFields(updatedFields);
  };

  // validation
  let validationSchema = Yup.object({
    suite: Yup.string().required("This Field is required."),
    // type: Yup.string().required("This Field is required."),
    // building: Yup.string().required("This Field is required."),
    // inside: Yup.string().required("This Field is required."),
    // outside: Yup.string().required("This Field is required."),
    lawRent: Yup.string().required("This Field is required."),
    // payAbleRent: Yup.string().required("This Field is required."),
    // lastMonthRent: Yup.string().required("This Field is required."),
    parkingLawRent: Yup.string().required("This Field is required."),

    // parkingLastRent: Yup.string().required("This Field is required."),
    belowRent: Yup.string().required("This Field is required."),
    // belowPayAble: Yup.string().required("This Field is required."),

    // belowLast: Yup.string().required("This Field is required."),
    // legalRent: Yup.string().required("This Field is required."),
    // legalPayAble: Yup.string().required("This Field is required."),
    // legalLast: Yup.string().required("This Field is required."),

    // discountRent: Yup.string().required("This Field is required."),
    // discountLast: Yup.string().required("This Field is required."),
    // discountPayable: Yup.string().required("This Field is required."),
    // netLast: Yup.string().required("This Field is required."),

    // netPayable: Yup.string().required("This Field is required."),
    // lastMonthDeposit: Yup.string().required("This Field is required."),
    // noDays: Yup.string().required("This Field is required."),
    // perDiem: Yup.string().required("This Field is required."),
    // proAmountRent: Yup.string().required("This Field is required."),

    // proAmount: Yup.string().required("This Field is required."),
    // Prior: Yup.string().required("This Field is required."),

    tenentContactName: Yup.string().required("This Field is required."),
    tenentEmailAddress: Yup.string().required("This Field is required."),
    tenentPhoneNumber: Yup.string().required("This Field is required."),
    selectedTypeField: Yup.string().required("This Field is required."),

    // lmr: Yup.string().required("This Field is required."),
  });

  // onSubmit
  const onSubmit = async (values) => {
    setSignaturingPadTenent(
      signPadTenent?.getTrimmedCanvas()?.toDataURL("image/png")
    );
    setSignaturingPadBuldingManager(
      signPadBuildingManager?.getTrimmedCanvas()?.toDataURL("image/png")
    );
    setSignaturingPadPropertyManager(
      signPadPropertyManager?.getTrimmedCanvas()?.toDataURL("image/png")
    );

    // console.log("all okay", values);
    let data = {
      suite_no: formik?.values?.suite,
      application_type: formik?.values?.type,
      building: formik?.values?.building,
      // building_id: buildingList?.find(b => b?.building_name === formik.values.building)?._id,
      building_id: localStorage.getItem("building_id"),

      inside: formik?.values?.inside,
      outside: formik?.values?.outside,
      lastMonthRent: formik?.values?.lastMonthRent,
      belowLast: formik?.values?.belowLast,
      discountRent: formik?.values?.discountRent,
      discountLast: formik?.values?.discountLast,
      discountPayable: formik?.values?.discountPayable,
      netLast: formik?.values?.netLast,
      netPayable: formik?.values?.netPayable,
      lastMonthDeposit: formik?.values?.lastMonthDeposit,
      noDays: formik?.values?.noDays,
      perDiem: formik?.values?.perDiem,
      proAmountRent: formik?.values?.proAmountRent,
      proAmount: formik?.values?.proAmount,
      Prior: formik?.values?.Prior,

      tenant_name: formik?.values?.tenentContactName,
      tenant_email: formik?.values?.tenentEmailAddress,
      tenant_phone_no: formik?.values?.tenentPhoneNumber,

      bona_fide_tenants: fields,
      occupants: occupantFields,

      tenancy_start_date: tenentStartDate,
      tenancy_end_date: tenentEndDate,

      parking_below_ground: {
        law_rent: formik?.values?.belowRent,
        rent_payable: formik?.values?.belowPayAble,
      },
      rent: {
        law_rent: formik?.values?.lawRent,
        rent_payable: formik?.values?.payAbleRent,
      },
      parking_above_ground: {
        law_rent: formik?.values?.parkingLawRent,
        rent_payable: formik?.values?.parkingPayAbleRent,
      },
      total_legal_rent: {
        law_rent: formik?.values?.legalRent,
        rent_payable: formik?.values?.legalPayAble,
        last_month_rent: formik?.values?.legalLast,
      },
      prompt_payment_discount: formik?.values?.discountPayable,
      net_rent_payable: formik?.values?.netPayable,

      pro_rate: {
        pro_start_date: proStartDate,
        pro_End_date: proEndDate,
        no_of_days: formik?.values?.noDays,
        per_diem_rate: formik?.values?.perDiem,
        pro_rent: formik?.values?.proAmountRent,
        pro_rate_amount: formik?.values?.proAmount,
        lmr: formik?.values?.lmr,
        total_amount_prior_move_in: formik?.values?.Prior,
      },

      application_type: {
        new_application: newApplicants,
        assignment: assignments,
        sublet: sublets,
        transfer: transfers,
        name_change: nameChanges,
      },

      government_identification: selectedImage,
      proof_of_employment: selectedFile,
      aggrement_date: agreementDate,
      tenant_signature:
        signPadTenent?.getTrimmedCanvas()?.toDataURL("image/png") ?? null, // building_manager_signature: agreementDate,

      building_manager_signature:
        signPadBuildingManager?.getTrimmedCanvas()?.toDataURL("image/png") ??
        null,
      property_manager_signature:
        signPadPropertyManager?.getTrimmedCanvas()?.toDataURL("image/png") ??
        null,
      building_manager_signature_credit:
        signPadCreditBm?.getTrimmedCanvas()?.toDataURL("image/png") ?? null,
      action: "send",
      // acceptance_date :acceptanceDate,
      today_date: requestDate,
      unit_type: typeSelected,

      initial_one: formik?.values?.initial_one,
      initial_two: formik?.values?.initial_two,
      initial_three: formik?.values?.initial_three,

      // new data
      // new form

      applicant_name: {
        name_applicant_one: formik?.values?.name_applicant_one,
        name_applicant_two: formik?.values?.name_applicant_two,
        name_applicant_three: formik?.values?.name_applicant_three,
      },
      date_of_birth: {
        date_of_birth_one: formik?.values?.date_of_birth_one,
        date_of_birth_two: formik?.values?.date_of_birth_two,
        date_of_birth_three: formik?.values?.date_of_birth_three,
      },
      current_address: {
        current_address_one: formik?.values?.current_address_one,
        current_address_two: formik?.values?.current_address_two,
        current_address_three: formik?.values?.current_address_three,
      },
      apartment_no: {
        appartment_no_one: formik?.values?.appartment_no_one,
        appartment_no_two: formik?.values?.appartment_no_two,
        appartment_no_three: formik?.values?.appartment_no_three,
      },
      present_monthly_rent: {
        present_monthly_rent_one: formik?.values?.present_monthly_rent_one,
        present_monthly_rent_two: formik?.values?.present_monthly_rent_two,
        present_monthly_rent_three: formik?.values?.present_monthly_rent_three,
      },
      postal_code: {
        postal_code_one: formik?.values?.postal_code_one,
        postal_code_two: formik?.values?.postal_code_two,
        postal_code_three: formik?.values?.postal_code_three,
      },
      length_of_residence: {
        length_of_residence_one: formik?.values?.length_of_residence_one,
        length_of_residence_two: formik?.values?.length_of_residence_two,
        length_of_residence_three: formik?.values?.length_of_residence_three,
      },
      home_phone_no: {
        home_phone_no_one: formik?.values?.home_phone_no_one,
        home_phone_no_two: formik?.values?.home_phone_no_two,
        home_phone_no_three: formik?.values?.home_phone_no_three,
      },
      cell_phone_no: {
        cell_phone_no_one: formik?.values?.cell_phone_no_one,
        cell_phone_no_two: formik?.values?.cell_phone_no_two,
        cell_phone_no_three: formik?.values?.cell_phone_no_three,
      },

      landlords_name: {
        landlords_name_one: formik?.values?.landlords_name_one,
        landlords_name_two: formik?.values?.landlords_name_two,
        landlords_name_three: formik?.values?.landlords_name_three,
      },
      landlords_phone_no: {
        landlords_phone_one: formik?.values?.landlords_phone_one,
        landlords_phone_two: formik?.values?.landlords_phone_two,
        landlords_phone_three: formik?.values?.landlords_name_three,
      },

      previous_address: {
        previous_address_one: formik?.values?.previous_address_one,
        previous_address_two: formik?.values?.previous_address_two,
        previous_address_three: formik?.values?.previous_address_three,
      },

      previous_apartment_no: {
        previous_appartment_no_one: formik?.values?.previous_appartment_no_one,
        previous_appartment_no_two: formik?.values?.previous_appartment_no_two,
        previous_appartment_no_three:
          formik?.values?.previous_appartment_no_three,
      },
      previous_monthly_rent: {
        previous_monthly_rent_one: formik?.values?.previous_monthly_rent_one,
        previous_monthly_rent_two: formik?.values?.previous_monthly_rent_two,
        previous_monthly_rent_three:
          formik?.values?.previous_monthly_rent_three,
      },
      previous_postal_code: {
        previous_postal_code_one: formik?.values?.previous_postal_code_one,
        previous_postal_code_two: formik?.values?.previous_postal_code_two,
        previous_postal_code_three: formik?.values?.previous_position_three,
      },
      previous_length_of_residence: {
        previous_length_of_residence_one:
          formik?.values?.previous_length_of_residence_one,
        previous_length_of_residence_two:
          formik?.values?.previous_length_of_residence_two,
        previous_length_of_residence_three:
          formik?.values?.previous_length_of_residence_three,
      },
      previous_landlords_name: {
        previous_landlords_name_one:
          formik?.values?.previous_landlords_name_one,
        previous_landlords_name_two:
          formik?.values?.previous_landlords_name_two,
        previous_landlords_name_three:
          formik?.values?.previous_landlords_name_three,
      },
      previous_landlords_phone_no: {
        previous_landlords_phone_one:
          formik?.values?.previous_landlords_phone_one,
        previous_landlords_phone_two:
          formik?.values?.previous_landlords_name_two,
        previous_landlords_phone_three:
          formik?.values?.previous_landlords_name_three,
      },
      current_employer: {
        current_employer_one: formik?.values?.current_employer_one,
        current_employer_two: formik?.values?.current_employer_two,
        current_employer_three: formik?.values?.current_employer_three,
      },
      work_phone_no: {
        work_phone_no_one: formik?.values?.work_phone_no_one,
        work_phone_no_two: formik?.values?.work_phone_no_two,
        work_phone_no_three: formik?.values?.work_phone_no_three,
      },
      position: {
        position_one: formik?.values?.position_one,
        position_two: formik?.values?.position_two,
        position_three: formik?.values?.position_three,
      },
      length_of_employment: {
        length_of_employment_one: formik?.values?.length_of_employment_one,
        length_of_employment_two: formik?.values?.length_of_employment_two,
        length_of_employment_three: formik?.values?.length_of_employment_three,
      },
      contact_name_and_no: {
        contact_name_no_one: formik?.values?.contact_name_no_one,
        contact_name_no_two: formik?.values?.contact_name_no_two,
        contact_name_no_three: formik?.values?.contact_name_no_three,
      },
      phone_no: {
        phone_no_one: formik?.values?.phone_no_one,
        phone_no_two: formik?.values?.phone_no_two,
        phone_no_three: formik?.values?.phone_no_three,
      },
      annual_income: {
        annual_income_one: formik?.values?.annual_income_one,
        annual_income_two: formik?.values?.annual_income_two,
        annual_income_three: formik?.values?.annual_income_three,
      },
      previous_employer: {
        previous_employer_one: formik?.values?.previous_employer_one,
        previous_employer_two: formik?.values?.previous_employer_two,
        previous_employer_three: formik?.values?.previous_employer_three,
      },
      previous_position: {
        previous_position_one: formik?.values?.previous_position_one,
        previous_position_two: formik?.values?.previous_position_two,
        previous_position_three: formik?.values?.previous_position_three,
      },
      previous_length_of_employment: {
        Previous_length_of_employment_one:
          formik?.values?.Previous_length_of_employment_one,
        Previous_length_of_employment_two:
          formik?.values?.Previous_length_of_employment_two,
        Previous_length_of_employment_three:
          formik?.values?.previous_length_of_residence_three,
      },
      previous_contact_name_and_no: {
        previous_contact_name_no_one:
          formik?.values?.previous_contact_name_no_one,
        previous_contact_name_no_two:
          formik?.values?.previous_contact_name_no_two,
        previous_contact_name_no_three:
          formik?.values?.previous_annual_income_three,
      },
      previous_annual_income: {
        previous_annual_income_one: formik?.values?.previous_annual_income_one,
        previous_annual_income_two: formik?.values?.previous_annual_income_two,
        previous_annual_income_three:
          formik?.values?.previous_annual_income_three,
      },
      bank: {
        bank_one: formik?.values?.bank_one,
        bank_two: formik?.values?.bank_two,
        bank_three: formik?.values?.bank_three,
      },
      branch: {
        branch_one: formik?.values?.branch_one,
        branch_two: formik?.values?.branch_two,
        branch_three: formik?.values?.branch_three,
      },
      account_no: {
        account_no_one: formik?.values?.account_no_one,
        account_no_two: formik?.values?.account_no_two,
        account_no_three: formik?.values?.account_no_three,
      },
      make_of_vehicle: {
        make_of_vehicle_one: formik?.values?.make_of_vehicle_one,
        make_of_vehicle_two: formik?.values?.make_of_vehicle_two,
        make_of_vehicle_three: formik?.values?.make_of_vehicle_three,
      },
      year_model_of_vehicle: {
        year_model_of_vehicle_one: formik?.values?.year_model_of_vehicle_one,
        year_model_of_vehicle_two: formik?.values?.year_model_of_vehicle_two,
        year_model_of_vehicle_three:
          formik?.values?.year_model_of_vehicle_threer,
      },
      colour_of_vehicle: {
        colour_of_vehicle_one: formik?.values?.colour_of_vehicle_one,
        colour_of_vehicle_two: formik?.values?.colour_of_vehicle_two,
        colour_of_vehicle_three: formik?.values?.colour_of_vehicle_three,
      },
      licence_plate_no: {
        licence_plate_no_one: formik?.values?.licence_plate_no_one,
        licence_plate_no_two: formik?.values?.licence_plate_no_two,
        licence_plate_no_three: formik?.values?.licence_plate_no_three,
      },
      drivers_license_no: {
        drivers_license_no_one: formik?.values?.drivers_license_no_one,
        drivers_license_no_two: formik?.values?.drivers_license_no_two,
        drivers_license_no_three: formik?.values?.drivers_license_no_three,
      },
      reference_name: {
        reference_name_one: formik?.values?.reference_name_one,
        reference_name_two: formik?.values?.reference_name_two,
        reference_name_three: formik?.values?.reference_name_three,
      },
      reference_address: {
        reference_address_one: formik?.values?.reference_address_one,
        reference_address_two: formik?.values?.reference_address_two,
        reference_address_three: formik?.values?.reference_address_three,
      },
      reference_phone_no: {
        reference_phone_no_one: formik?.values?.reference_phone_no_one,
        reference_phone_no_two: formik?.values?.reference_phone_no_two,
        reference_phone_no_three: formik?.values?.reference_phone_no_three,
      },
      reference_relationship: {
        reference_relationship_one: formik?.values?.reference_relationship_one,
        reference_relationship_two: formik?.values?.reference_relationship_two,
        reference_relationship_three:
          formik?.values?.reference_relationship_three,
      },
      next_of_kin_name: {
        next_name_one: formik?.values?.next_name_one,
        next_name_two: formik?.values?.next_name_two,
        next_name_three: formik?.values?.next_name_three,
      },
      next_of_kin_address: {
        next_address_one: formik?.values?.next_address_one,
        next_address_two: formik?.values?.next_address_two,
        next_address_three: formik?.values?.next_address_three,
      },
      next_of_kin_phone_no: {
        next_phone_no_one: formik?.values?.next_phone_no_one,
        next_phone_no_two: formik?.values?.next_phone_no_two,
        next_phone_no_three:
          formik?.values?.formik?.values?.next_phone_no_three,
      },
      next_of_kin_relationship: {
        next_relationship_one: formik?.values?.next_relationship_one,
        next_relationship_two: formik?.values?.next_relationship_two,
        next_relationship_three: formik?.values?.next_relationship_three,
      },
      credit_unit: formik?.values?.suite,
      credit_building: formik?.values?.building,
      credit_date: creditDate,
    };

    try {
      let res = await createApplcation(
        "/api/admin/application/create",
        JSON.stringify(data)
      );
      // console.log("Response", res);
      toast.success(res?.message); // Display the success toaster notification
      navigate("/application-listing");
    } catch (error) {
      // console.log("err", error);
      if (error.status == 400) {
        toast.error("Something Went Wrong"); // Display the error toaster notification
      } else {
        toast.error("Server Side Error"); // Display the error toaster notification
      }
    }
  };

  // draft
  // onSubmit
  const DraftHandling = async (values) => {
    // setSignaturingPad(signPad.getTrimmedCanvas().toDataURL("image/png"));
    setSignaturingPadTenent(
      signPadTenent?.getTrimmedCanvas().toDataURL("image/png")
    );
    setSignaturingPadBuldingManager(
      signPadBuildingManager?.getTrimmedCanvas().toDataURL("image/png")
    );
    setSignaturingPadPropertyManager(
      signPadPropertyManager?.getTrimmedCanvas().toDataURL("image/png")
    );

    // console.log("all okay", values);
    let data = {
      suite_no: formik?.values?.suite,
      application_type: formik?.values?.type,
      building: formik?.values?.building,
      initial_one: formik?.values?.initial_one,
      initial_two: formik?.values?.initial_two,
      initial_three: formik?.values?.initial_three,

      // building_id: buildingList?.find(b => b?.building_name === formik.values.building)?._id,
      building_id: localStorage.getItem("building_id"),

      inside: formik?.values?.inside,
      outside: formik?.values?.outside,
      lastMonthRent: formik?.values?.lastMonthRent,
      belowLast: formik?.values?.belowLast,
      discountRent: formik?.values?.discountRent,
      discountLast: formik?.values?.discountLast,
      discountPayable: formik?.values?.discountPayable,
      netLast: formik?.values?.netLast,
      netPayable: formik?.values?.netPayable,
      lastMonthDeposit: formik?.values?.lastMonthDeposit,
      noDays: formik?.values?.noDays,
      perDiem: formik?.values?.perDiem,
      // proAmountRent: formik?.values?.proAmountRent,
      proAmount: formik?.values?.proAmount,
      Prior: formik?.values?.Prior,

      parking_below_ground: {
        law_rent: formik?.values?.belowRent,
        rent_payable: formik?.values?.belowPayAble,
      },
      tenant_name: formik?.values?.tenentContactName,
      tenant_email: formik?.values?.tenentEmailAddress,
      tenant_phone_no: formik?.values?.tenentPhoneNumber,

      bona_fide_tenants: fields,
      occupants: occupantFields,

      tenancy_start_date: tenentStartDate,
      tenancy_end_date: tenentEndDate,
      rent: {
        law_rent: formik?.values?.lawRent,
        rent_payable: formik?.values?.payAbleRent,
      },
      parking_above_ground: {
        law_rent: formik?.values?.parkingLawRent,
        rent_payable: formik?.values?.parkingPayAbleRent,
      },
      total_legal_rent: {
        law_rent: formik?.values?.legalRent,
        rent_payable: formik?.values?.legalPayAble,
        last_month_rent: formik?.values?.legalLast,
      },
      prompt_payment_discount: formik?.values?.discountPayable,
      net_rent_payable: formik?.values?.netPayable,

      pro_rate: {
        pro_start_date: proStartDate,
        pro_End_date: proEndDate,
        no_of_days: formik?.values?.noDays,
        per_diem_rate: formik?.values?.perDiem,
        pro_rent: formik?.values?.proAmountRent,
        pro_rate_amount: formik?.values?.proAmount,
        lmr: formik?.values?.lmr,
        total_amount_prior_move_in: formik?.values?.Prior,
      },

      application_type: {
        new_application: newApplicants,
        assignment: assignments,
        sublet: sublets,
        transfer: transfers,
        name_change: nameChanges,
      },

      government_identification: selectedImage,
      proof_of_employment: selectedFile,
      aggrement_date: agreementDate,
      tenant_signature:
        signPadTenent?.getTrimmedCanvas()?.toDataURL("image/png") ?? null,
      building_manager_signature:
        signPadBuildingManager?.getTrimmedCanvas()?.toDataURL("image/png") ??
        null,
      property_manager_signature:
        signPadPropertyManager?.getTrimmedCanvas()?.toDataURL("image/png") ??
        null,
      building_manager_signature_credit:
        signPadCreditBm?.getTrimmedCanvas()?.toDataURL("image/png") ?? null,

      action: "draft",
      // acceptance_date :acceptanceDate,
      today_date: requestDate,
      unit_type: typeSelected,

      // new form

      applicant_name: {
        name_applicant_one: formik?.values?.name_applicant_one,
        name_applicant_two: formik?.values?.name_applicant_two,
        name_applicant_three: formik?.values?.name_applicant_three,
      },
      date_of_birth: {
        date_of_birth_one: formik?.values?.date_of_birth_one,
        date_of_birth_two: formik?.values?.date_of_birth_two,
        date_of_birth_three: formik?.values?.date_of_birth_three,
      },
      current_address: {
        current_address_one: formik?.values?.current_address_one,
        current_address_two: formik?.values?.current_address_two,
        current_address_three: formik?.values?.current_address_three,
      },
      apartment_no: {
        appartment_no_one: formik?.values?.appartment_no_one,
        appartment_no_two: formik?.values?.appartment_no_two,
        appartment_no_three: formik?.values?.appartment_no_three,
      },
      present_monthly_rent: {
        present_monthly_rent_one: formik?.values?.present_monthly_rent_one,
        present_monthly_rent_two: formik?.values?.present_monthly_rent_two,
        present_monthly_rent_three: formik?.values?.present_monthly_rent_three,
      },
      postal_code: {
        postal_code_one: formik?.values?.postal_code_one,
        postal_code_two: formik?.values?.postal_code_two,
        postal_code_three: formik?.values?.postal_code_three,
      },
      length_of_residence: {
        length_of_residence_one: formik?.values?.length_of_residence_one,
        length_of_residence_two: formik?.values?.length_of_residence_two,
        length_of_residence_three: formik?.values?.length_of_residence_three,
      },
      home_phone_no: {
        home_phone_no_one: formik?.values?.home_phone_no_one,
        home_phone_no_two: formik?.values?.home_phone_no_two,
        home_phone_no_three: formik?.values?.home_phone_no_three,
      },
      cell_phone_no: {
        cell_phone_no_one: formik?.values?.cell_phone_no_one,
        cell_phone_no_two: formik?.values?.cell_phone_no_two,
        cell_phone_no_three: formik?.values?.cell_phone_no_three,
      },

      landlords_name: {
        landlords_name_one: formik?.values?.landlords_name_one,
        landlords_name_two: formik?.values?.landlords_name_two,
        landlords_name_three: formik?.values?.landlords_name_three,
      },
      landlords_phone_no: {
        landlords_phone_one: formik?.values?.landlords_phone_one,
        landlords_phone_two: formik?.values?.landlords_phone_two,
        landlords_phone_three: formik?.values?.landlords_name_three,
      },

      previous_address: {
        previous_address_one: formik?.values?.previous_address_one,
        previous_address_two: formik?.values?.previous_address_two,
        previous_address_three: formik?.values?.previous_address_three,
      },
      previous_apartment_no: {
        previous_appartment_no_one: formik?.values?.previous_appartment_no_one,
        previous_appartment_no_two: formik?.values?.previous_appartment_no_two,
        previous_appartment_no_three:
          formik?.values?.previous_appartment_no_three,
      },
      previous_monthly_rent: {
        previous_monthly_rent_one: formik?.values?.previous_monthly_rent_one,
        previous_monthly_rent_two: formik?.values?.previous_monthly_rent_two,
        previous_monthly_rent_three:
          formik?.values?.previous_monthly_rent_three,
      },
      previous_postal_code: {
        previous_postal_code_one: formik?.values?.previous_postal_code_one,
        previous_postal_code_two: formik?.values?.previous_postal_code_two,
        previous_postal_code_three: formik?.values?.previous_position_three,
      },
      previous_length_of_residence: {
        previous_length_of_residence_one:
          formik?.values?.previous_length_of_residence_one,
        previous_length_of_residence_two:
          formik?.values?.previous_length_of_residence_two,
        previous_length_of_residence_three:
          formik?.values?.previous_length_of_residence_three,
      },
      previous_landlords_name: {
        previous_landlords_name_one:
          formik?.values?.previous_landlords_name_one,
        previous_landlords_name_two:
          formik?.values?.previous_landlords_name_two,
        previous_landlords_name_three:
          formik?.values?.previous_landlords_name_three,
      },
      previous_landlords_phone_no: {
        previous_landlords_phone_one:
          formik?.values?.previous_landlords_phone_one,
        previous_landlords_phone_two:
          formik?.values?.previous_landlords_name_two,
        previous_landlords_phone_three:
          formik?.values?.previous_landlords_name_three,
      },
      current_employer: {
        current_employer_one: formik?.values?.current_employer_one,
        current_employer_two: formik?.values?.current_employer_two,
        current_employer_three: formik?.values?.current_employer_three,
      },
      work_phone_no: {
        work_phone_no_one: formik?.values?.work_phone_no_one,
        work_phone_no_two: formik?.values?.work_phone_no_two,
        work_phone_no_three: formik?.values?.work_phone_no_three,
      },
      position: {
        position_one: formik?.values?.position_one,
        position_two: formik?.values?.position_two,
        position_three: formik?.values?.position_three,
      },
      length_of_employment: {
        length_of_employment_one: formik?.values?.length_of_employment_one,
        length_of_employment_two: formik?.values?.length_of_employment_two,
        length_of_employment_three: formik?.values?.length_of_employment_three,
      },
      contact_name_and_no: {
        contact_name_no_one: formik?.values?.contact_name_no_one,
        contact_name_no_two: formik?.values?.contact_name_no_two,
        contact_name_no_three: formik?.values?.contact_name_no_three,
      },
      phone_no: {
        phone_no_one: formik?.values?.phone_no_one,
        phone_no_two: formik?.values?.phone_no_two,
        phone_no_three: formik?.values?.phone_no_three,
      },
      annual_income: {
        annual_income_one: formik?.values?.annual_income_one,
        annual_income_two: formik?.values?.annual_income_two,
        annual_income_three: formik?.values?.annual_income_three,
      },
      previous_employer: {
        previous_employer_one: formik?.values?.previous_employer_one,
        previous_employer_two: formik?.values?.previous_employer_two,
        previous_employer_three: formik?.values?.previous_employer_three,
      },
      previous_position: {
        previous_position_one: formik?.values?.previous_position_one,
        previous_position_two: formik?.values?.previous_position_two,
        previous_position_three: formik?.values?.previous_position_three,
      },
      previous_length_of_employment: {
        Previous_length_of_employment_one:
          formik?.values?.Previous_length_of_employment_one,
        Previous_length_of_employment_two:
          formik?.values?.Previous_length_of_employment_two,
        Previous_length_of_employment_three:
          formik?.values?.previous_length_of_residence_three,
      },
      previous_contact_name_and_no: {
        previous_contact_name_no_one:
          formik?.values?.previous_contact_name_no_one,
        previous_contact_name_no_two:
          formik?.values?.previous_contact_name_no_two,
        previous_contact_name_no_three:
          formik?.values?.previous_annual_income_three,
      },
      previous_annual_income: {
        previous_annual_income_one: formik?.values?.previous_annual_income_one,
        previous_annual_income_two: formik?.values?.previous_annual_income_two,
        previous_annual_income_three:
          formik?.values?.previous_annual_income_three,
      },
      bank: {
        bank_one: formik?.values?.bank_one,
        bank_two: formik?.values?.bank_two,
        bank_three: formik?.values?.bank_three,
      },
      branch: {
        branch_one: formik?.values?.branch_one,
        branch_two: formik?.values?.branch_two,
        branch_three: formik?.values?.branch_three,
      },
      account_no: {
        account_no_one: formik?.values?.account_no_one,
        account_no_two: formik?.values?.account_no_two,
        account_no_three: formik?.values?.account_no_three,
      },
      make_of_vehicle: {
        make_of_vehicle_one: formik?.values?.make_of_vehicle_one,
        make_of_vehicle_two: formik?.values?.make_of_vehicle_two,
        make_of_vehicle_three: formik?.values?.make_of_vehicle_three,
      },
      year_model_of_vehicle: {
        year_model_of_vehicle_one: formik?.values?.year_model_of_vehicle_one,
        year_model_of_vehicle_two: formik?.values?.year_model_of_vehicle_two,
        year_model_of_vehicle_three:
          formik?.values?.year_model_of_vehicle_threer,
      },
      colour_of_vehicle: {
        colour_of_vehicle_one: formik?.values?.colour_of_vehicle_one,
        colour_of_vehicle_two: formik?.values?.colour_of_vehicle_two,
        colour_of_vehicle_three: formik?.values?.colour_of_vehicle_three,
      },
      licence_plate_no: {
        licence_plate_no_one: formik?.values?.licence_plate_no_one,
        licence_plate_no_two: formik?.values?.licence_plate_no_two,
        licence_plate_no_three: formik?.values?.licence_plate_no_three,
      },
      drivers_license_no: {
        drivers_license_no_one: formik?.values?.drivers_license_no_one,
        drivers_license_no_two: formik?.values?.drivers_license_no_two,
        drivers_license_no_three: formik?.values?.drivers_license_no_three,
      },
      reference_name: {
        reference_name_one: formik?.values?.reference_name_one,
        reference_name_two: formik?.values?.reference_name_two,
        reference_name_three: formik?.values?.reference_name_three,
      },
      reference_address: {
        reference_address_one: formik?.values?.reference_address_one,
        reference_address_two: formik?.values?.reference_address_two,
        reference_address_three: formik?.values?.reference_address_three,
      },
      reference_phone_no: {
        reference_phone_no_one: formik?.values?.reference_phone_no_one,
        reference_phone_no_two: formik?.values?.reference_phone_no_two,
        reference_phone_no_three: formik?.values?.reference_phone_no_three,
      },
      reference_relationship: {
        reference_relationship_one: formik?.values?.reference_relationship_one,
        reference_relationship_two: formik?.values?.reference_relationship_two,
        reference_relationship_three:
          formik?.values?.reference_relationship_three,
      },
      next_of_kin_name: {
        next_name_one: formik?.values?.next_name_one,
        next_name_two: formik?.values?.next_name_two,
        next_name_three: formik?.values?.next_name_three,
      },
      next_of_kin_address: {
        next_address_one: formik?.values?.next_address_one,
        next_address_two: formik?.values?.next_address_two,
        next_address_three: formik?.values?.next_address_three,
      },
      next_of_kin_phone_no: {
        next_phone_no_one: formik?.values?.next_phone_no_one,
        next_phone_no_two: formik?.values?.next_phone_no_two,
        next_phone_no_three:
          formik?.values?.formik?.values?.next_phone_no_three,
      },
      next_of_kin_relationship: {
        next_relationship_one: formik?.values?.next_relationship_one,
        next_relationship_two: formik?.values?.next_relationship_two,
        next_relationship_three: formik?.values?.next_relationship_three,
      },
      credit_unit: formik?.values?.suite,
      credit_building: formik?.values?.building,
      credit_date: creditDate,
      application_id: applicationID,
    };
    try {
      let res = await createApplcation(
        "/api/admin/application/update",
        JSON.stringify(data)
      );
      // console.log("Response", res);
      // setResponseData(res?.data);
      toast.success(res?.message); // Display the success toaster notification
      navigate("/application-listing");
    } catch (error) {
      // console.log("err", error);
      toast.error("Server Side Error"); // Display the error toaster notification
    }
  };

  // onSubmit
  const DraftHandlingCreate = async (values) => {
    // setSignaturingPad(signPad.getTrimmedCanvas().toDataURL("image/png"));
    setSignaturingPadTenent(
      signPadTenent?.getTrimmedCanvas().toDataURL("image/png")
    );
    setSignaturingPadBuldingManager(
      signPadBuildingManager?.getTrimmedCanvas().toDataURL("image/png")
    );
    setSignaturingPadPropertyManager(
      signPadPropertyManager?.getTrimmedCanvas().toDataURL("image/png")
    );

    // console.log("all okay", values);
    let data = {
      suite_no: formik?.values?.suite,
      application_type: formik?.values?.type,
      building: formik?.values?.building,
      initial_one: formik?.values?.initial_one,
      initial_two: formik?.values?.initial_two,
      initial_three: formik?.values?.initial_three,

      // building_id: buildingList?.find(b => b?.building_name === formik.values.building)?._id,
      building_id: localStorage.getItem("building_id"),

      inside: formik?.values?.inside,
      outside: formik?.values?.outside,
      lastMonthRent: formik?.values?.lastMonthRent,
      belowLast: formik?.values?.belowLast,
      discountRent: formik?.values?.discountRent,
      discountLast: formik?.values?.discountLast,
      discountPayable: formik?.values?.discountPayable,
      netLast: formik?.values?.netLast,
      netPayable: formik?.values?.netPayable,
      lastMonthDeposit: formik?.values?.lastMonthDeposit,
      noDays: formik?.values?.noDays,
      perDiem: formik?.values?.perDiem,
      // proAmountRent: formik?.values?.proAmountRent,
      proAmount: formik?.values?.proAmount,
      Prior: formik?.values?.Prior,

      parking_below_ground: {
        law_rent: formik?.values?.belowRent,
        rent_payable: formik?.values?.belowPayAble,
      },
      tenant_name: formik?.values?.tenentContactName,
      tenant_email: formik?.values?.tenentEmailAddress,
      tenant_phone_no: formik?.values?.tenentPhoneNumber,

      bona_fide_tenants: fields,
      occupants: occupantFields,

      tenancy_start_date: tenentStartDate,
      tenancy_end_date: tenentEndDate,
      rent: {
        law_rent: formik?.values?.lawRent,
        rent_payable: formik?.values?.payAbleRent,
      },
      parking_above_ground: {
        law_rent: formik?.values?.parkingLawRent,
        rent_payable: formik?.values?.parkingPayAbleRent,
      },
      total_legal_rent: {
        law_rent: formik?.values?.legalRent,
        rent_payable: formik?.values?.legalPayAble,
        last_month_rent: formik?.values?.legalLast,
      },
      prompt_payment_discount: formik?.values?.discountPayable,
      net_rent_payable: formik?.values?.netPayable,

      pro_rate: {
        pro_start_date: proStartDate,
        pro_End_date: proEndDate,
        no_of_days: formik?.values?.noDays,
        per_diem_rate: formik?.values?.perDiem,
        pro_rent: formik?.values?.proAmountRent,
        pro_rate_amount: formik?.values?.proAmount,
        lmr: formik?.values?.lmr,
        total_amount_prior_move_in: formik?.values?.Prior,
      },

      application_type: {
        new_application: newApplicants,
        assignment: assignments,
        sublet: sublets,
        transfer: transfers,
        name_change: nameChanges,
      },

      government_identification: selectedImage,
      proof_of_employment: selectedFile,
      aggrement_date: agreementDate,
      tenant_signature:
        signPadTenent?.getTrimmedCanvas()?.toDataURL("image/png") ?? null,
      building_manager_signature:
        signPadBuildingManager?.getTrimmedCanvas()?.toDataURL("image/png") ??
        null,
      property_manager_signature:
        signPadPropertyManager?.getTrimmedCanvas()?.toDataURL("image/png") ??
        null,
      building_manager_signature_credit:
        signPadCreditBm?.getTrimmedCanvas()?.toDataURL("image/png") ?? null,

      action: "draft",
      // acceptance_date :acceptanceDate,
      today_date: requestDate,
      unit_type: typeSelected,

      // new form

      applicant_name: {
        name_applicant_one: formik?.values?.name_applicant_one,
        name_applicant_two: formik?.values?.name_applicant_two,
        name_applicant_three: formik?.values?.name_applicant_three,
      },
      date_of_birth: {
        date_of_birth_one: formik?.values?.date_of_birth_one,
        date_of_birth_two: formik?.values?.date_of_birth_two,
        date_of_birth_three: formik?.values?.date_of_birth_three,
      },
      current_address: {
        current_address_one: formik?.values?.current_address_one,
        current_address_two: formik?.values?.current_address_two,
        current_address_three: formik?.values?.current_address_three,
      },
      apartment_no: {
        appartment_no_one: formik?.values?.appartment_no_one,
        appartment_no_two: formik?.values?.appartment_no_two,
        appartment_no_three: formik?.values?.appartment_no_three,
      },
      present_monthly_rent: {
        present_monthly_rent_one: formik?.values?.present_monthly_rent_one,
        present_monthly_rent_two: formik?.values?.present_monthly_rent_two,
        present_monthly_rent_three: formik?.values?.present_monthly_rent_three,
      },
      postal_code: {
        postal_code_one: formik?.values?.postal_code_one,
        postal_code_two: formik?.values?.postal_code_two,
        postal_code_three: formik?.values?.postal_code_three,
      },
      length_of_residence: {
        length_of_residence_one: formik?.values?.length_of_residence_one,
        length_of_residence_two: formik?.values?.length_of_residence_two,
        length_of_residence_three: formik?.values?.length_of_residence_three,
      },
      home_phone_no: {
        home_phone_no_one: formik?.values?.home_phone_no_one,
        home_phone_no_two: formik?.values?.home_phone_no_two,
        home_phone_no_three: formik?.values?.home_phone_no_three,
      },
      cell_phone_no: {
        cell_phone_no_one: formik?.values?.cell_phone_no_one,
        cell_phone_no_two: formik?.values?.cell_phone_no_two,
        cell_phone_no_three: formik?.values?.cell_phone_no_three,
      },

      landlords_name: {
        landlords_name_one: formik?.values?.landlords_name_one,
        landlords_name_two: formik?.values?.landlords_name_two,
        landlords_name_three: formik?.values?.landlords_name_three,
      },
      landlords_phone_no: {
        landlords_phone_one: formik?.values?.landlords_phone_one,
        landlords_phone_two: formik?.values?.landlords_phone_two,
        landlords_phone_three: formik?.values?.landlords_name_three,
      },

      previous_address: {
        previous_address_one: formik?.values?.previous_address_one,
        previous_address_two: formik?.values?.previous_address_two,
        previous_address_three: formik?.values?.previous_address_three,
      },
      previous_apartment_no: {
        previous_appartment_no_one: formik?.values?.previous_appartment_no_one,
        previous_appartment_no_two: formik?.values?.previous_appartment_no_two,
        previous_appartment_no_three:
          formik?.values?.previous_appartment_no_three,
      },
      previous_monthly_rent: {
        previous_monthly_rent_one: formik?.values?.previous_monthly_rent_one,
        previous_monthly_rent_two: formik?.values?.previous_monthly_rent_two,
        previous_monthly_rent_three:
          formik?.values?.previous_monthly_rent_three,
      },
      previous_postal_code: {
        previous_postal_code_one: formik?.values?.previous_postal_code_one,
        previous_postal_code_two: formik?.values?.previous_postal_code_two,
        previous_postal_code_three: formik?.values?.previous_position_three,
      },
      previous_length_of_residence: {
        previous_length_of_residence_one:
          formik?.values?.previous_length_of_residence_one,
        previous_length_of_residence_two:
          formik?.values?.previous_length_of_residence_two,
        previous_length_of_residence_three:
          formik?.values?.previous_length_of_residence_three,
      },
      previous_landlords_name: {
        previous_landlords_name_one:
          formik?.values?.previous_landlords_name_one,
        previous_landlords_name_two:
          formik?.values?.previous_landlords_name_two,
        previous_landlords_name_three:
          formik?.values?.previous_landlords_name_three,
      },
      previous_landlords_phone_no: {
        previous_landlords_phone_one:
          formik?.values?.previous_landlords_phone_one,
        previous_landlords_phone_two:
          formik?.values?.previous_landlords_name_two,
        previous_landlords_phone_three:
          formik?.values?.previous_landlords_name_three,
      },
      current_employer: {
        current_employer_one: formik?.values?.current_employer_one,
        current_employer_two: formik?.values?.current_employer_two,
        current_employer_three: formik?.values?.current_employer_three,
      },
      work_phone_no: {
        work_phone_no_one: formik?.values?.work_phone_no_one,
        work_phone_no_two: formik?.values?.work_phone_no_two,
        work_phone_no_three: formik?.values?.work_phone_no_three,
      },
      position: {
        position_one: formik?.values?.position_one,
        position_two: formik?.values?.position_two,
        position_three: formik?.values?.position_three,
      },
      length_of_employment: {
        length_of_employment_one: formik?.values?.length_of_employment_one,
        length_of_employment_two: formik?.values?.length_of_employment_two,
        length_of_employment_three: formik?.values?.length_of_employment_three,
      },
      contact_name_and_no: {
        contact_name_no_one: formik?.values?.contact_name_no_one,
        contact_name_no_two: formik?.values?.contact_name_no_two,
        contact_name_no_three: formik?.values?.contact_name_no_three,
      },
      phone_no: {
        phone_no_one: formik?.values?.phone_no_one,
        phone_no_two: formik?.values?.phone_no_two,
        phone_no_three: formik?.values?.phone_no_three,
      },
      annual_income: {
        annual_income_one: formik?.values?.annual_income_one,
        annual_income_two: formik?.values?.annual_income_two,
        annual_income_three: formik?.values?.annual_income_three,
      },
      previous_employer: {
        previous_employer_one: formik?.values?.previous_employer_one,
        previous_employer_two: formik?.values?.previous_employer_two,
        previous_employer_three: formik?.values?.previous_employer_three,
      },
      previous_position: {
        previous_position_one: formik?.values?.previous_position_one,
        previous_position_two: formik?.values?.previous_position_two,
        previous_position_three: formik?.values?.previous_position_three,
      },
      previous_length_of_employment: {
        Previous_length_of_employment_one:
          formik?.values?.Previous_length_of_employment_one,
        Previous_length_of_employment_two:
          formik?.values?.Previous_length_of_employment_two,
        Previous_length_of_employment_three:
          formik?.values?.previous_length_of_residence_three,
      },
      previous_contact_name_and_no: {
        previous_contact_name_no_one:
          formik?.values?.previous_contact_name_no_one,
        previous_contact_name_no_two:
          formik?.values?.previous_contact_name_no_two,
        previous_contact_name_no_three:
          formik?.values?.previous_annual_income_three,
      },
      previous_annual_income: {
        previous_annual_income_one: formik?.values?.previous_annual_income_one,
        previous_annual_income_two: formik?.values?.previous_annual_income_two,
        previous_annual_income_three:
          formik?.values?.previous_annual_income_three,
      },
      bank: {
        bank_one: formik?.values?.bank_one,
        bank_two: formik?.values?.bank_two,
        bank_three: formik?.values?.bank_three,
      },
      branch: {
        branch_one: formik?.values?.branch_one,
        branch_two: formik?.values?.branch_two,
        branch_three: formik?.values?.branch_three,
      },
      account_no: {
        account_no_one: formik?.values?.account_no_one,
        account_no_two: formik?.values?.account_no_two,
        account_no_three: formik?.values?.account_no_three,
      },
      make_of_vehicle: {
        make_of_vehicle_one: formik?.values?.make_of_vehicle_one,
        make_of_vehicle_two: formik?.values?.make_of_vehicle_two,
        make_of_vehicle_three: formik?.values?.make_of_vehicle_three,
      },
      year_model_of_vehicle: {
        year_model_of_vehicle_one: formik?.values?.year_model_of_vehicle_one,
        year_model_of_vehicle_two: formik?.values?.year_model_of_vehicle_two,
        year_model_of_vehicle_three:
          formik?.values?.year_model_of_vehicle_threer,
      },
      colour_of_vehicle: {
        colour_of_vehicle_one: formik?.values?.colour_of_vehicle_one,
        colour_of_vehicle_two: formik?.values?.colour_of_vehicle_two,
        colour_of_vehicle_three: formik?.values?.colour_of_vehicle_three,
      },
      licence_plate_no: {
        licence_plate_no_one: formik?.values?.licence_plate_no_one,
        licence_plate_no_two: formik?.values?.licence_plate_no_two,
        licence_plate_no_three: formik?.values?.licence_plate_no_three,
      },
      drivers_license_no: {
        drivers_license_no_one: formik?.values?.drivers_license_no_one,
        drivers_license_no_two: formik?.values?.drivers_license_no_two,
        drivers_license_no_three: formik?.values?.drivers_license_no_three,
      },
      reference_name: {
        reference_name_one: formik?.values?.reference_name_one,
        reference_name_two: formik?.values?.reference_name_two,
        reference_name_three: formik?.values?.reference_name_three,
      },
      reference_address: {
        reference_address_one: formik?.values?.reference_address_one,
        reference_address_two: formik?.values?.reference_address_two,
        reference_address_three: formik?.values?.reference_address_three,
      },
      reference_phone_no: {
        reference_phone_no_one: formik?.values?.reference_phone_no_one,
        reference_phone_no_two: formik?.values?.reference_phone_no_two,
        reference_phone_no_three: formik?.values?.reference_phone_no_three,
      },
      reference_relationship: {
        reference_relationship_one: formik?.values?.reference_relationship_one,
        reference_relationship_two: formik?.values?.reference_relationship_two,
        reference_relationship_three:
          formik?.values?.reference_relationship_three,
      },
      next_of_kin_name: {
        next_name_one: formik?.values?.next_name_one,
        next_name_two: formik?.values?.next_name_two,
        next_name_three: formik?.values?.next_name_three,
      },
      next_of_kin_address: {
        next_address_one: formik?.values?.next_address_one,
        next_address_two: formik?.values?.next_address_two,
        next_address_three: formik?.values?.next_address_three,
      },
      next_of_kin_phone_no: {
        next_phone_no_one: formik?.values?.next_phone_no_one,
        next_phone_no_two: formik?.values?.next_phone_no_two,
        next_phone_no_three:
          formik?.values?.formik?.values?.next_phone_no_three,
      },
      next_of_kin_relationship: {
        next_relationship_one: formik?.values?.next_relationship_one,
        next_relationship_two: formik?.values?.next_relationship_two,
        next_relationship_three: formik?.values?.next_relationship_three,
      },
      credit_unit: formik?.values?.suite,
      credit_building: formik?.values?.building,
      credit_date: creditDate,
      // application_id: applicationID,
    };
    try {
      let res = await createApplcation(
        "/api/admin/application/create",
        JSON.stringify(data)
      );
      // console.log("Response", res);
      // setResponseData(res?.data);
      toast.success(res?.message); // Display the success toaster notification
      navigate("/application-listing");
    } catch (error) {
      // console.log("err", error);
      toast.error("Server Side Error"); // Display the error toaster notification
    }
  };

  // checking initial values
  const initialValues = {
    suite: "",
    type: "",
    building: selectedBuilding ? selectedBuilding : "",
    selectedTypeField: typeSelected ?? "",
    inside: "",
    outside: "",
    lawRent: "",
    payAbleRent: "",
    lastMonthRent: "",
    parkingLawRent: "",

    parkingLastRent: "",
    belowRent: "",
    belowPayAble: "",
    initial_one: "",
    initial_two: "",
    initial_three: "",

    belowLast: "",
    legalRent: "",
    legalPayAble: "",
    legalLast: "",

    discountRent: "",
    discountLast: "",
    discountPayable: "",
    netLast: "",

    netPayable: "",
    lastMonthDeposit: "",
    noDays: "",
    perDiem: "",
    proAmountRent: "",
    proAmount: "",
    Prior: "",

    tenentContactName: "",
    tenentEmailAddress: "",
    tenentPhoneNumber: "",
    lmr: "",

    // new Application

    name_applicant_one: "",
    name_applicant_two: "",
    name_applicant_three: "",

    date_of_birth_one: "",
    date_of_birth_two: "",
    date_of_birth_three: "",

    current_address_one: "",
    current_address_two: "",
    current_address_three: "",

    appartment_no_one: "",
    appartment_no_two: "",
    appartment_no_three: "",

    present_monthly_rent_one: "",
    present_monthly_rent_two: "",
    present_monthly_rent_three: "",

    postal_code_one: "",
    postal_code_two: "",
    postal_code_three: "",

    length_of_residence_one: "",
    length_of_residence_two: "",
    length_of_residence_three: "",

    home_phone_no_one: "",
    home_phone_no_two: "",
    home_phone_no_three: "",

    cell_phone_no_one: "",
    cell_phone_no_two: "",
    cell_phone_no_three: "",

    landlords_name_one: "",
    landlords_name_two: "",
    landlords_name_three: "",

    landlords_phone_one: "",
    landlords_phone_two: "",
    landlords_phone_three: "",

    previous_address_one: "",
    previous_address_two: "",
    previous_address_three: "",

    previous_appartment_no_one: "",
    previous_appartment_no_two: "",
    previous_appartment_no_three: "",

    previous_monthly_rent_one: "",
    previous_monthly_rent_two: "",
    previous_monthly_rent_three: "",

    previous_postal_code_one: "",
    previous_postal_code_two: "",
    previous_postal_code_three: "",

    previous_length_of_residence_one: "",
    previous_length_of_residence_two: "",
    previous_length_of_residence_three: "",

    previous_landlords_name_one: "",
    previous_landlords_name_two: "",
    previous_landlords_name_three: "",

    previous_landlords_phone_one: "",
    previous_landlords_phone_two: "",
    previous_landlords_phone_three: "",

    current_employer_one: "",
    current_employer_two: "",
    current_employer_three: "",

    work_phone_no_one: "",
    work_phone_no_two: "",
    work_phone_no_three: "",

    position_one: "",
    position_two: "",
    position_three: "",

    length_of_employment_one: "",
    length_of_employment_two: "",
    length_of_employment_three: "",

    contact_name_no_one: "",
    contact_name_no_two: "",
    contact_name_no_three: "",

    phone_no_one: "",
    phone_no_two: "",
    phone_no_three: "",

    annual_income_one: "",
    annual_income_two: "",
    annual_income_three: "",

    previous_employer_one: "",
    previous_employer_two: "",
    previous_employer_three: "",

    previous_position_one: "",
    previous_position_two: "",
    previous_position_three: "",

    Previous_length_of_employment_one: "",
    Previous_length_of_employment_two: "",
    Previous_length_of_employment_three: "",

    previous_contact_name_no_one: "",
    previous_contact_name_no_two: "",
    previous_contact_name_no_three: "",

    previous_annual_income_one: "",
    previous_annual_income_two: "",
    previous_annual_income_three: "",

    bank_one: "",
    bank_two: "",
    bank_three: "",

    branch_one: "",
    branch_two: "",
    branch_three: "",

    account_no_one: "",
    account_no_two: "",
    account_no_three: "",

    make_of_vehicle_one: "",
    make_of_vehicle_two: "",
    make_of_vehicle_three: "",

    year_model_of_vehicle_one: "",
    year_model_of_vehicle_two: "",
    year_model_of_vehicle_three: "",

    colour_of_vehicle_one: "",
    colour_of_vehicle_two: "",
    colour_of_vehicle_three: "",

    licence_plate_no_one: "",
    licence_plate_no_two: "",
    licence_plate_no_three: "",

    drivers_license_no_one: "",
    drivers_license_no_two: "",
    drivers_license_no_three: "",

    reference_name_one: "",
    reference_name_two: "",
    reference_name_three: "",

    reference_address_one: "",
    reference_address_two: "",
    reference_address_three: "",

    reference_phone_no_one: "",
    reference_phone_no_two: "",
    reference_phone_no_three: "",

    reference_relationship_one: "",
    reference_relationship_two: "",
    reference_relationship_three: "",

    next_name_one: "",
    next_name_two: "",
    next_name_three: "",

    next_address_one: "",
    next_address_two: "",
    next_address_three: "",

    next_phone_no_one: "",
    next_phone_no_two: "",
    next_phone_no_three: "",

    next_relationship_one: "",
    next_relationship_two: "",
    next_relationship_three: "",
    credit_unit: "",
    credit_building: "",
  };
  // checking in formiks
  const formik = useFormik({
    initialValues,

    // initialValues: responseData,
    validationSchema,
    onSubmit,
  });
  // signature
  const signatureHandlerTenent = (ref) => {
    sigCanvas = ref;
    setSignPadTenent(sigCanvas); //elem ~ dataurl
    // console.log(
    //   sigCanvas.getTrimmedCanvas().toDataURL("image/png")
    // );
  };

  const signatureHandlerTenent2 = (ref) => {
    sigCanvas = ref;
    setSignPadTenent2(sigCanvas); //elem ~ dataurl
    // console.log(
    //   sigCanvas.getTrimmedCanvas().toDataURL("image/png")
    // );
  };
  const signatureHandlerTenent3 = (ref) => {
    sigCanvas = ref;
    setSignPadTenent3(sigCanvas); //elem ~ dataurl
    // console.log(
    //   sigCanvas.getTrimmedCanvas().toDataURL("image/png")
    // );
  };
  const signatureHandlerTenentSmoke = (ref) => {
    sigCanvas = ref;
    setSignPadTenentSmoke(sigCanvas);
  };
  const signatureHandlerTenentSmoke2 = (ref) => {
    sigCanvas = ref;
    setSignPadTenentSmoke2(sigCanvas);
  };
  const signatureHandlerTenentSmoke3 = (ref) => {
    sigCanvas = ref;
    setSignPadTenentSmoke3(sigCanvas);
  };
  // signature
  const signatureHandlerBuldingManager = (ref) => {
    if (ref) {
      sigCanvas = ref;
      setSignPadBuldingManager(sigCanvas);
    }
    // console.log(
    //   sigCanvas.getTrimmedCanvas().toDataURL("image/png")
    // );
  };

  // const clearSignatureBM = () => {
  //   if (signPadBuildingManager) {
  //     signPadBuildingManager.clear();
  //     signPadBuildingManager.fromDataURL(""); // Try resetting the canvas data
  // signPadBuildingManager._isEmpty = true; // Try setting the internal _isEmpty flag      console.log("Signature Cleared");
  //     setSignatureExists(false);

  //   } else {
  // console.log("sigCanvas is null");
  //   }
  // };

  // const handleSignatureChange = () => {
  //   if (signPadBuildingManager.current && !signPadBuildingManager.current.isEmpty()) {
  //     setSignatureExists(true);
  //   } else {
  //     setSignatureExists(false);
  //   }
  // };

  // signature
  const signatureHandlerPropertyManager = (ref) => {
    sigCanvas = ref;
    // console.log({ ref });
    setSignPadPropertyManager(sigCanvas);
    // console.log(
    //   sigCanvas.getTrimmedCanvas().toDataURL("image/png")
    // );
  };

  // const clearSignaturePM = () => {
  //   if (signPadPropertyManager) {
  //     signPadPropertyManager.clear();
  //     signPadPropertyManager.fromDataURL(""); // Try resetting the canvas data
  //     signPadPropertyManager._isEmpty = true; // Try setting the internal _isEmpty flag      console.log("Signature Cleared");
  //   } else {
  //     console.log("sigCanvas is null");
  //   }
  // };
  const signatureHandlerCreditBm = (ref) => {
    sigCanvas = ref;
    setSignPadCreditBm(sigCanvas);
    // console.log(
    //   sigCanvas.getTrimmedCanvas().toDataURL("image/png")
    // );
  };

  const signatureHandlerCreditTenent1 = (ref) => {
    sigCanvas = ref;
    setSignPadCreditTenent1(sigCanvas);
    // console.log(
    //   sigCanvas.getTrimmedCanvas().toDataURL("image/png")
    // );
  };
  const signatureHandlerCreditTenent2 = (ref) => {
    sigCanvas = ref;
    setSignPadCreditTenent2(sigCanvas);
  };
  const signatureHandlerCreditTenent3 = (ref) => {
    sigCanvas = ref;
    setSignPadCreditTenent3(sigCanvas);
    // console.log(
    //   sigCanvas.getTrimmedCanvas().toDataURL("image/png")
    // );
  };

  const signatureHandlerTenentLimmiter = (ref) => {
    sigCanvas = ref;
    setSignPadTenentLimmiter(sigCanvas);
  };
  const signatureHandlerTenentLimmiter2 = (ref) => {
    sigCanvas = ref;
    setSignPadTenentLimmiter2(sigCanvas);
  };
  const signatureHandlerTenentLimmiter3 = (ref) => {
    sigCanvas = ref;
    setSignPadTenentLimmiter3(sigCanvas);
  };

  const getDimension = () => {
    let divWidths = $("#canvas_Wraper").width();
    let divHeights = $("#canvas_Wraper").height();
    setDivWidth(divWidths);
    setDivHeight(divHeights);
    // console.log("Width: " + divWidth + ", " + "Height: " + divHeight);
  };

  const getDimensionCreditTenent1 = () => {
    let divWidths = $("#canvas_Wraper_creditTenent1").width();
    let divHeights = $("#canvas_Wraper_creditTenent1").height();
    setDivWidthCreditTenent1(divWidths);
    setDivHeightCreditTenent1(divHeights);
    // console.log("Width: " + divWidth + ", " + "Height: " + divHeight);
  };
  const getDimensionCreditTenent2 = () => {
    let divWidths = $("#canvas_Wraper_creditTenent2").width();
    let divHeights = $("#canvas_Wraper_creditTenent2").height();
    setDivWidthCreditTenent2(divWidths);
    setDivHeightCreditTenent2(divHeights);
    // console.log("Width: " + divWidth + ", " + "Height: " + divHeight);
  };

  const getDimensionCreditTenent3 = () => {
    let divWidths = $("#canvas_Wraper_creditTenent3").width();
    let divHeights = $("#canvas_Wraper_creditTenent3").height();
    setDivWidthCreditTenent3(divWidths);
    setDivHeightCreditTenent3(divHeights);
    // console.log("Width: " + divWidth + ", " + "Height: " + divHeight);
  };

  const getDimensionCreditBm = () => {
    let divWidths = $("#canvas_Wraper_credit_bm").width();
    let divHeights = $("#canvas_Wraper_credit_bm").height();
    setDivWidthCreditBm(divWidths);
    setDivHeightCreditBm(divHeights);
    // console.log("Width: " + divWidth + ", " + "Height: " + divHeight);
  };

  const getDimensionSmoke = () => {
    let divWidths = $("#canvas_Wraper_smoke").width();
    let divHeights = $("#canvas_Wraper_smoke").height();
    setDivWidthSmoke(divWidths);
    setDivHeightSmoke(divHeights);
    // console.log("Width: " + divWidth + ", " + "Height: " + divHeight);
  };

  const getDimensionLimmiter = () => {
    let divWidths = $("#canvas_Wraper_limmiter").width();
    let divHeights = $("#canvas_Wraper_limmiter").height();
    setDivWidthLimmiter(divWidths);
    setDivHeightLimmiter(divHeights);
    // console.log("Width: " + divWidth + ", " + "Height: " + divHeight);
  };

  const getDimension1 = () => {
    let divWidths = $("#canvas_Wraper1").width();
    let divHeights = $("#canvas_Wraper1").height();
    setDivWidth1(divWidths);
    setDivHeight1(divHeights);
    // console.log("Width: " + divWidth + ", " + "Height: " + divHeight);
  };
  const getDimension2 = () => {
    let divWidths = $("#canvas_Wraper2").width();
    let divHeights = $("#canvas_Wraper2").height();
    // console.log("divWidths", divWidths);
    // console.log("divHeights", divHeights);

    setDivWidth2(divWidths);
    setDivHeight2(divHeights);
    // console.log("Width: " + divWidth + ", " + "Height: " + divHeight);
  };
  // console.log("formik in application", formik);
  // console.log("selectedFile", selectedFile);
  // console.log("selectedFile img", selectedImage);

  const handleKeyPress = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    const regex = /^[0-9]+$/;

    if (!regex.test(keyValue)) {
      event.preventDefault();
    }
  };
  // console.log("responseData", responseData);

  // get Api call
  // let re={
  //   data:listingData
  // }

  useEffect(() => {
    if (location.pathname !== "/application") {
      if (!signPadPropertyManager) return;
      (async () => {
        try {
          let res;
          if (!printData) {
            let data = {
              application_id: applicationID,
            };

            res =
              applicationID &&
              (await getApplication(
                "/api/admin/application/get",
                "POST",
                JSON.stringify(data)
              ));
          } else {
            res = {
              data: printData,
            };
          }
          setFilesDataGov(res?.data?.government_identification);
          setFilesDataProof(res?.data?.proof_of_employment);
          setApprovedDate(res?.data?.approved_at);
          setApprovedBy(res?.data?.approved_by_info?.role);

          // console.log("Response of get", res);
          // setFields([{ firstName: res?.data?. }]);
          formik.setValues({
            tenentContactName: res?.data?.tenant_name,
            tenentEmailAddress: res?.data?.tenant_email,
            tenentPhoneNumber: res?.data?.tenant_phone_no,
            suite: res?.data?.suite_no,
            // tenentContactName:res?.data?.building,
            legalRent: res?.data?.total_legal_rent?.law_rent,
            legalLast: res?.data?.total_legal_rent?.last_month_rent,
            // legalPayAble : res?.data?.rent?.rent_payable,
            legalPayAble: res?.data?.rent?.rent_payable_legal,
            lawRent: res?.data?.rent?.law_rent,
            payAbleRent: res?.data?.rent?.rent_payable,

            discountPayable: res?.data?.prompt_payment_discount,
            lawRent: res?.data?.rent?.law_rent,
            payAbleRent: res?.data?.rent?.rent_payable,
            parkingLawRent: res?.data?.parking_above_ground?.law_rent,
            parkingPayAbleRent: res?.data?.parking_above_ground?.rent_payable,
            belowRent: res?.data?.parking_below_ground?.law_rent,
            belowPayAble: res?.data?.parking_below_ground?.rent_payable,
            legalRent: res?.data?.total_legal_rent?.law_rent,
            legalPayAble: res?.data?.total_legal_rent?.rent_payable_legal,
            legalLast: res?.data?.total_legal_rent?.last_month_rent,
            netPayable: res?.data?.net_rent_payable,

            proStartDate: res?.data?.pro_rate?.pro_start_date,
            proEndDate: res?.data?.pro_rate?.pro_End_date,
            noDays: res?.data?.pro_rate?.no_of_days,
            perDiem: res?.data?.pro_rate?.per_diem_rate,
            proAmountRent: res?.data?.pro_rate?.pro_rent,
            proAmount: res?.data?.pro_rate?.pro_rate_amount,
            lmr: res?.data?.pro_rate?.lmr,
            Prior: res?.data?.pro_rate?.total_amount_prior_move_in,
            tenentStartDate: res?.data?.tenancy_start_date,
            tenentEndDate: res?.data?.tenancy_end_date,
            initial_one: res?.data?.initial_one,
            initial_two: res?.data?.initial_two,
            initial_three: res?.data?.initial_three,

            newApplicants: setNewApplicants(
              res?.data?.application_type?.new_application
            ),
            assignments: setAssignments(
              res?.data?.application_type?.assignment
            ),
            nameChanges: setNameChanges(
              res?.data?.application_type?.name_change
            ),
            sublets: setSublets(res?.data?.application_type?.sublet),
            transfers: setTransfers(res?.data?.application_type?.transfer),
            // date = moment.utc(date);
            // setRequestDate(dateString === "" ? "" : moment.utc(date));
            // Use formattedDate as the value for the DatePicker component

            // new form
            name_applicant_one: res?.data?.applicant_name?.name_applicant_one,
            name_applicant_two: res?.data?.applicant_name?.name_applicant_two,
            name_applicant_three:
              res?.data?.applicant_name?.name_applicant_three,

            current_address_one:
              res?.data?.current_address?.current_address_one,
            current_address_two:
              res?.data?.current_address?.current_address_two,
            current_address_three:
              res?.data?.current_address?.current_address_three,

            appartment_no_one: res?.data?.apartment_no?.appartment_no_one,
            appartment_no_two: res?.data?.apartment_no?.appartment_no_two,
            appartment_no_three: res?.data?.apartment_no?.appartment_no_three,

            present_monthly_rent_one:
              res?.data?.present_monthly_rent?.present_monthly_rent_one,
            present_monthly_rent_two:
              res?.data?.present_monthly_rent?.present_monthly_rent_two,
            present_monthly_rent_three:
              res?.data?.present_monthly_rent?.present_monthly_rent_three,

            postal_code_one: res?.data?.postal_code?.postal_code_one,
            postal_code_two: res?.data?.postal_code?.postal_code_two,
            postal_code_three: res?.data?.postal_code?.postal_code_three,

            length_of_residence_one:
              res?.data?.length_of_residence?.length_of_residence_one,
            length_of_residence_two:
              res?.data?.length_of_residence?.length_of_residence_two,
            length_of_residence_three:
              res?.data?.length_of_residence?.length_of_residence_three,

            home_phone_no_one: res?.data?.home_phone_no?.home_phone_no_one,
            home_phone_no_two: res?.data?.home_phone_no?.home_phone_no_two,
            home_phone_no_three: res?.data?.home_phone_no?.home_phone_no_three,

            cell_phone_no_one: res?.data?.cell_phone_no?.cell_phone_no_one,
            cell_phone_no_two: res?.data?.cell_phone_no?.cell_phone_no_two,
            cell_phone_no_three: res?.data?.cell_phone_no?.cell_phone_no_three,

            landlords_name_one: res?.data?.landlords_name?.landlords_name_one,
            landlords_name_two: res?.data?.landlords_name?.landlords_name_two,
            landlords_name_three:
              res?.data?.landlords_name?.landlords_name_three,

            landlords_phone_one:
              res?.data?.landlords_phone_no?.landlords_phone_one,
            landlords_phone_two:
              res?.data?.landlords_phone_no?.landlords_phone_two,
            landlords_phone_three:
              res?.data?.landlords_phone_no?.landlords_phone_three,

            previous_address_one:
              res?.data?.previous_address?.previous_address_one,
            previous_address_two:
              res?.data?.previous_address?.previous_address_two,
            previous_address_three:
              res?.data?.previous_address?.previous_address_three,

            previous_appartment_no_one:
              res?.data?.previous_apartment_no?.previous_appartment_no_one,

            previous_appartment_no_two:
              res?.data?.previous_apartment_no?.previous_appartment_no_two,
            previous_appartment_no_three:
              res?.data?.previous_apartment_no?.previous_appartment_no_three,

            previous_monthly_rent_one:
              res?.data?.previous_monthly_rent?.previous_monthly_rent_one,
            previous_monthly_rent_two:
              res?.data?.previous_monthly_rent?.previous_monthly_rent_two,
            previous_monthly_rent_three:
              res?.data?.previous_monthly_rent?.previous_monthly_rent_three,

            previous_postal_code_one:
              res?.data?.previous_postal_code?.previous_postal_code_one,
            previous_postal_code_two:
              res?.data?.previous_postal_code?.previous_postal_code_two,
            previous_postal_code_three:
              res?.data?.previous_postal_code?.previous_postal_code_three,

            previous_length_of_residence_one:
              res?.data?.previous_length_of_residence
                ?.previous_length_of_residence_one,
            previous_length_of_residence_two:
              res?.data?.previous_length_of_residence
                ?.previous_length_of_residence_two,
            previous_length_of_residence_three:
              res?.data?.previous_length_of_residence
                ?.previous_length_of_residence_three,

            previous_landlords_name_one:
              res?.data?.previous_landlords_name?.previous_landlords_name_one,
            previous_landlords_name_two:
              res?.data?.previous_landlords_name?.previous_landlords_name_two,
            previous_landlords_name_three:
              res?.data?.previous_landlords_name?.previous_landlords_name_three,

            previous_landlords_phone_one:
              res?.data?.previous_landlords_phone_no
                ?.previous_landlords_phone_one,
            previous_landlords_phone_two:
              res?.data?.previous_landlords_phone_no
                ?.previous_landlords_phone_two,
            previous_landlords_phone_three:
              res?.data?.previous_landlords_phone_no
                ?.previous_landlords_phone_three,

            current_employer_one:
              res?.data?.current_employer?.current_employer_one,
            current_employer_two:
              res?.data?.current_employer?.current_employer_two,
            current_employer_three:
              res?.data?.current_employer?.current_employer_three,

            work_phone_no_one: res?.data?.work_phone_no?.work_phone_no_one,
            work_phone_no_two: res?.data?.work_phone_no?.work_phone_no_two,
            work_phone_no_three: res?.data?.work_phone_no?.work_phone_no_three,

            position_one: res?.data?.position?.position_one,
            position_two: res?.data?.position?.position_two,
            position_three: res?.data?.position?.position_three,

            length_of_employment_one:
              res?.data?.length_of_employment?.length_of_employment_one,
            length_of_employment_two:
              res?.data?.length_of_employment?.length_of_employment_two,
            length_of_employment_three:
              res?.data?.length_of_employment?.length_of_employment_three,

            contact_name_no_one:
              res?.data?.contact_name_and_no?.contact_name_no_one,
            contact_name_no_two:
              res?.data?.contact_name_and_no?.contact_name_no_two,
            contact_name_no_three:
              res?.data?.contact_name_and_no?.contact_name_no_three,

            phone_no_one: res?.data?.phone_no?.phone_no_one,
            phone_no_two: res?.data?.phone_no?.phone_no_two,
            phone_no_three: res?.data?.phone_no?.phone_no_three,

            annual_income_one: res?.data?.annual_income?.annual_income_one,
            annual_income_two: res?.data?.annual_income?.annual_income_two,
            annual_income_three: res?.data?.annual_income?.annual_income_three,

            previous_employer_one:
              res?.data?.previous_employer?.previous_employer_one,
            previous_employer_two:
              res?.data?.previous_employer?.previous_employer_one,
            previous_employer_two:
              res?.data?.previous_employer?.previous_employer_two,

            previous_position_one:
              res?.data?.previous_position?.previous_position_one,
            previous_position_two:
              res?.data?.previous_position?.previous_position_two,
            previous_position_three:
              res?.data?.previous_position?.previous_position_three,

            Previous_length_of_employment_one:
              res?.data?.previous_length_of_employment
                ?.Previous_length_of_employment_one,
            Previous_length_of_employment_two:
              res?.data?.previous_length_of_employment
                ?.Previous_length_of_employment_two,
            Previous_length_of_employment_three:
              res?.data?.previous_length_of_employment
                ?.Previous_length_of_employment_three,

            previous_contact_name_no_one:
              res?.data?.previous_contact_name_and_no
                ?.previous_contact_name_no_one,
            previous_contact_name_no_two:
              res?.data?.previous_contact_name_and_no
                ?.previous_contact_name_no_two,
            previous_contact_name_no_three:
              res?.data?.previous_contact_name_and_no
                ?.previous_contact_name_no_three,

            previous_annual_income_one:
              res?.data?.previous_annual_income?.previous_annual_income_one,
            previous_annual_income_two:
              res?.data?.previous_annual_income?.previous_annual_income_two,
            previous_annual_income_three:
              res?.data?.previous_annual_income?.previous_annual_income_three,

            bank_one: res?.data?.bank?.bank_one,
            bank_two: res?.data?.bank?.bank_two,
            bank_three: res?.data?.bank?.bank_three,

            branch_one: res?.data?.branch?.branch_one,
            branch_two: res?.data?.branch?.branch_two,
            bank_three: res?.data?.branch?.bank_three,

            account_no_one: res?.data?.account_no?.account_no_one,
            account_no_two: res?.data?.account_no?.account_no_two,
            account_no_three: res?.data?.account_no?.account_no_three,

            make_of_vehicle_one:
              res?.data?.make_of_vehicle?.make_of_vehicle_one,
            make_of_vehicle_two:
              res?.data?.make_of_vehicle?.make_of_vehicle_two,
            make_of_vehicle_three:
              res?.data?.make_of_vehicle?.make_of_vehicle_three,

            year_model_of_vehicle_one:
              res?.data?.year_model_of_vehicle?.year_model_of_vehicle_one,
            year_model_of_vehicle_two:
              res?.data?.year_model_of_vehicle?.year_model_of_vehicle_two,
            year_model_of_vehicle_three:
              res?.data?.year_model_of_vehicle?.year_model_of_vehicle_three,

            colour_of_vehicle_one:
              res?.data?.colour_of_vehicle?.colour_of_vehicle_one,
            colour_of_vehicle_two:
              res?.data?.colour_of_vehicle?.colour_of_vehicle_two,
            colour_of_vehicle_three:
              res?.data?.colour_of_vehicle?.colour_of_vehicle_three,

            licence_plate_no_one:
              res?.data?.licence_plate_no?.licence_plate_no_one,
            licence_plate_no_two:
              res?.data?.licence_plate_no?.licence_plate_no_two,
            licence_plate_no_three:
              res?.data?.licence_plate_no?.licence_plate_no_three,

            drivers_license_no_one:
              res?.data?.drivers_license_no?.drivers_license_no_one,
            drivers_license_no_two:
              res?.data?.drivers_license_no?.drivers_license_no_two,
            drivers_license_no_three:
              res?.data?.drivers_license_no?.drivers_license_no_three,

            reference_name_one: res?.data?.reference_name?.reference_name_one,
            reference_name_two: res?.data?.reference_name?.reference_name_two,
            reference_name_three:
              res?.data?.reference_name?.reference_name_three,

            reference_address_one:
              res?.data?.reference_address?.reference_address_one,
            reference_address_two:
              res?.data?.reference_address?.reference_address_two,
            reference_address_three:
              res?.data?.reference_address?.reference_address_three,

            reference_phone_no_one:
              res?.data?.reference_phone_no?.reference_phone_no_one,
            reference_phone_no_two:
              res?.data?.reference_phone_no?.reference_phone_no_two,
            reference_phone_no_three:
              res?.data?.reference_phone_no?.reference_phone_no_three,

            reference_relationship_one:
              res?.data?.reference_relationship?.reference_relationship_one,
            reference_relationship_two:
              res?.data?.reference_relationship?.reference_relationship_two,
            reference_relationship_three:
              res?.data?.reference_relationship?.reference_relationship_three,

            next_name_one: res?.data?.next_of_kin_name?.next_name_one,
            next_name_two: res?.data?.next_of_kin_name?.next_name_two,
            next_name_three: res?.data?.next_of_kin_name?.next_name_three,

            next_address_one: res?.data?.next_of_kin_address?.next_address_one,
            next_address_two: res?.data?.next_of_kin_address?.next_address_two,
            next_address_three:
              res?.data?.next_of_kin_address?.next_address_three,

            next_phone_no_one:
              res?.data?.next_of_kin_phone_no?.next_phone_no_one,
            next_phone_no_two:
              res?.data?.next_of_kin_phone_no?.next_phone_no_two,
            next_phone_no_three:
              res?.data?.next_of_kin_phone_no?.next_phone_no_three,

            next_relationship_one:
              res?.data?.next_of_kin_relationship?.next_relationship_one,
            next_relationship_two:
              res?.data?.next_of_kin_relationship?.next_relationship_two,
            next_relationship_three:
              res?.data?.next_of_kin_relationship?.next_relationship_three,

            credit_building: res?.data?.credit_building,
            credit_unit: res?.data?.credit_unit,
            unit_type: res?.data?.unit_type,
          });

          setDobApplicantOnes(
            res?.data?.date_of_birth?.date_of_birth_one === "" ||
              res?.data?.date_of_birth?.date_of_birth_one === null
              ? ""
              : momentTz(res?.data?.date_of_birth?.date_of_birth_one).tz("utc")
          );
          setDobApplicantTows(
            res?.data?.date_of_birth?.date_of_birth_two === "" ||
              res?.data?.date_of_birth?.date_of_birth_two === null
              ? ""
              : momentTz(res?.data?.date_of_birth?.date_of_birth_two).tz("utc")
          );
          setDobApplicantThrees(
            res?.data?.date_of_birth?.date_of_birth_three === "" ||
              res?.data?.date_of_birth?.date_of_birth_three === null
              ? ""
              : momentTz(res?.data?.date_of_birth?.date_of_birth_three).tz(
                  "utc"
                )
          );

          // setFields(data?.data?.bona_fide_tenants)
          if (res?.data?.bona_fide_tenants?.length > 0) {
            setFields(res.data.bona_fide_tenants);
          }
          setTypeSelected(res?.data?.unit_type);

          // setOccupantFields(data?.data?.occupants)
          if (res?.data?.occupants?.length > 0) {
            setOccupantFields(res.data.occupants);
          }

          setRequestDate(momentTz(res?.data?.today_date).tz("utc"));
          setTenentStartDate(
            res?.data?.tenancy_start_date === "" ||
              res?.data?.tenancy_start_date === null
              ? ""
              : momentTz(res?.data?.tenancy_start_date).tz("utc")
          );

          setTenentEndDate(
            res?.data?.tenancy_end_date === "" ||
              res?.data?.tenancy_end_date === null
              ? ""
              : momentTz(res?.data?.tenancy_end_date).tz("utc")
          );
          setLimmitertDate(
            momentTz(res?.data?.cosmetic_limmiter_date).tz("utc")
          );
          setLimmitertDate2(
            momentTz(res?.data?.cosmetic_limmiter_date_2).tz("utc")
          );

          setSmokeDate(momentTz(res?.data?.cosmetic_smoke_date).tz("utc"));
          setEmailStatus(res?.data?.email_status);
          setApplicationStatus(res?.data?.status);
          // setProStartDate(
          //   momentTz(res?.data?.pro_rate?.pro_start_date).tz("utc")
          // );
          // setProEndDate(momentTz(res?.data?.pro_rate?.pro_End_date).tz("utc"));

          setProStartDate(
            res?.data?.pro_rate?.pro_start_date === "" ||
              res?.data?.pro_rate?.pro_start_date === null
              ? ""
              : momentTz(res?.data?.pro_rate?.pro_start_date).tz("utc")
          );
          setProEndDate(
            res?.data?.pro_rate?.pro_End_date === "" ||
              res?.data?.pro_rate?.pro_End_date === null
              ? ""
              : momentTz(res?.data?.pro_rate?.pro_End_date).tz("utc")
          );
          setTenantView(res?.data?.tenant_signature);
          setTenantView2(res?.data?.tenant_signature_2);
          setTenantView3(res?.data?.tenant_signature_3);

          setBuildingManagerView(res?.data?.building_manager_signature);
          setCreditTenantView(res?.data?.credit_tenant1_signature);
          setCreditTenantView2(res?.data?.credit_tenant2_signature);

          setCreditTenantView3(res?.data?.credit_tenant3_signature);

          setSmokeTenantView(res?.data?.cosmetic_smoke_tenant_signature);
          setSmokeTenantView2(res?.data?.cosmetic_smoke_tenant_signature_2);
          setSmokeTenantView3(res?.data?.cosmetic_smoke_tenant_signature_3);

          setLimmiterTenantView(res?.data?.cosmetic_limmiter_tenant_signature);
          setLimmiterTenantView2(
            res?.data?.cosmetic_limmiter_tenant_signature_2
          );
          setLimmiterTenantView3(
            res?.data?.cosmetic_limmiter_tenant_signature_3
          );

          // ==========================================================
          // console.log("====I AM HERE===== FIRST TIME ");
          // console.log("====>", signPadPropertyManager);
          console.log("get Response", res?.data);
          signPadPropertyManager?.fromDataURL(
            res?.data?.property_manager_signature
          );
          // signPadCreditBm?.fromDataURL(
          //   res?.data?.building_manager_signature_credit
          // );
          signPadBuildingManager?.fromDataURL(
            res?.data?.building_manager_signature
          );
          // signPadCreditTenent1?.fromDataURL(
          //   res?.data?.credit_tenant1_signature
          // );
          // signPadTenentSmoke?.fromDataURL(
          //   res?.data?.cosmetic_smoke_tenant_signature
          // );

          // signPadTenentLimmiter?.fromDataURL(
          //   res?.data?.cosmetic_limmiter_tenant_signature
          // );

          // signPadCreditTenent2?.fromDataURL(
          //   res?.data?.credit_tenant2_signature
          // );

          // signPadTenent?.fromDataURL(res?.data?.tenant_signature);

          // signPadTenent2?.fromDataURL(res?.data?.tenant_signature_2);
          // signPadTenent3?.fromDataURL(res?.data?.tenant_signature_3
          //   );

          // signPadTenent?.fromDataURL(
          //   res?.data?.Tan
          // );

          // signPadCreditBm?.fromDataURL(
          //   r
          // );
          // signPadCreditTenent1?.fromDataURL(
          //   res?.data?.sig
          // );
          // signPadTenent?.fromDataURL(
          //   res?.data?.property_manager_signature
          // );

          setPropertyManagerView(res?.data?.property_manager_signature);
          setSelectedImage(res?.data?.government_identification);
          setSelectedFile(res?.data?.proof_of_employment);

          // selectedFile(res?.data?.proof_of_employment)
          // setAgreementDate(momentTz(res?.data?.aggrement_date).tz("utc"));

          toast.success(res?.message); // Display the success toaster notification
        } catch (error) {
          console.log("err in get", error);
          // toast.error("Server Side Error"); // Display the error toaster notification
        }
      })();
    }
  }, [signPadPropertyManager]);

  useEffect(() => {
    formik.setFieldValue("building", selectedBuilding);
  }, [selectedBuilding]);
  // class visibility hidden
  // update Api call on Draft

  const Update = async () => {
    try {
      let data = {
        suite_no: formik?.values?.suite,
        application_type: formik?.values?.type,
        building: formik?.values?.building,
        // building_id: buildingList?.find(b => b?.building_name === formik.values.building)?._id,
        building_id: localStorage.getItem("building_id"),

        inside: formik?.values?.inside,
        outside: formik?.values?.outside,
        lastMonthRent: formik?.values?.lastMonthRent,
        belowLast: formik?.values?.belowLast,
        discountRent: formik?.values?.discountRent,
        discountLast: formik?.values?.discountLast,
        discountPayable: formik?.values?.discountPayable,
        netLast: formik?.values?.netLast,
        netPayable: formik?.values?.netPayable,
        lastMonthDeposit: formik?.values?.lastMonthDeposit,
        noDays: formik?.values?.noDays,
        perDiem: formik?.values?.perDiem,
        proAmountRent: formik?.values?.proAmountRent,
        proAmount: formik?.values?.proAmount,
        Prior: formik?.values?.Prior,
        initial_one: formik?.values?.initial_one,
        initial_two: formik?.values?.initial_two,
        initial_three: formik?.values?.initial_three,

        tenant_name: formik?.values?.tenentContactName,
        tenant_email: formik?.values?.tenentEmailAddress,
        tenant_phone_no: formik?.values?.tenentPhoneNumber,
        parking_below_ground: {
          law_rent: formik?.values?.belowRent,
          rent_payable: formik?.values?.belowPayAble,
        },
        bona_fide_tenants: fields,
        occupants: occupantFields,

        tenancy_start_date: tenentStartDate,
        tenancy_end_date: tenentEndDate,
        rent: {
          law_rent: formik?.values?.lawRent,
          rent_payable: formik?.values?.payAbleRent,
        },
        parking_above_ground: {
          law_rent: formik?.values?.parkingLawRent,
          rent_payable: formik?.values?.parkingPayAbleRent,
        },
        total_legal_rent: {
          law_rent: formik?.values?.legalRent,
          rent_payable_legal: formik?.values?.legalPayAble,
          last_month_rent: formik?.values?.legalLast,
        },
        prompt_payment_discount: formik?.values?.discountPayable,
        net_rent_payable: formik?.values?.netPayable,

        pro_rate: {
          pro_start_date: proStartDate,
          pro_End_date: proEndDate,
          no_of_days: formik?.values?.noDays,
          per_diem_rate: formik?.values?.perDiem,
          pro_rent: formik?.values?.proAmountRent,
          pro_rate_amount: formik?.values?.proAmount,
          lmr: formik?.values?.lmr,
          total_amount_prior_move_in: formik?.values?.Prior,
        },

        application_type: {
          new_application: newApplicants,
          assignment: assignments,
          sublet: sublets,
          transfer: transfers,
          name_change: nameChanges,
        },
        action: "draft",
        application_id: applicationID,

        government_identification: selectedImage,
        proof_of_employment: selectedFile,
        aggrement_date: agreementDate,
        credit_unit: formik?.values?.suite,
        credit_building: formik?.values?.building,
        credit_date: creditDate,
        // tenant_signature:
        //   signPadTenent?.getTrimmedCanvas()?.toDataURL("image/png") ?? null,
        // building_manager_signature: signPadBuildingManager
        //   ?.getTrimmedCanvas()
        //   ?.toDataURL("image/png"),
        building_manager_signature:
          signPadBuildingManager?.getTrimmedCanvas()?.toDataURL("image/png") ??
          null,

        // property_manager_signature: signPadPropertyManager
        //   .getTrimmedCanvas()
        //   .toDataURL("image/png"),
        property_manager_signature:
          signPadPropertyManager?.getTrimmedCanvas()?.toDataURL("image/png") ??
          null,
        building_manager_signature_credit:
          signPadCreditBm?.getTrimmedCanvas()?.toDataURL("image/png") ?? null,

        // acceptance_date :acceptanceDate,
        today_date: requestDate,
        unit_type: typeSelected,

        // action: "draft",

        // new form

        applicant_name: {
          name_applicant_one: formik?.values?.name_applicant_one,
          name_applicant_two: formik?.values?.name_applicant_two,
          name_applicant_three: formik?.values?.name_applicant_three,
        },
        date_of_birth: {
          date_of_birth_one: formik?.values?.date_of_birth_one,
          date_of_birth_two: formik?.values?.date_of_birth_two,
          date_of_birth_three: formik?.values?.date_of_birth_three,
        },
        current_address: {
          current_address_one: formik?.values?.current_address_one,
          current_address_two: formik?.values?.current_address_two,
          current_address_three: formik?.values?.current_address_three,
        },
        apartment_no: {
          appartment_no_one: formik?.values?.appartment_no_one,
          appartment_no_two: formik?.values?.appartment_no_two,
          appartment_no_three: formik?.values?.appartment_no_three,
        },
        present_monthly_rent: {
          present_monthly_rent_one: formik?.values?.present_monthly_rent_one,
          present_monthly_rent_two: formik?.values?.present_monthly_rent_two,
          present_monthly_rent_three:
            formik?.values?.present_monthly_rent_three,
        },
        postal_code: {
          postal_code_one: formik?.values?.postal_code_one,
          postal_code_two: formik?.values?.postal_code_two,
          postal_code_three: formik?.values?.postal_code_three,
        },
        length_of_residence: {
          length_of_residence_one: formik?.values?.length_of_residence_one,
          length_of_residence_two: formik?.values?.length_of_residence_two,
          length_of_residence_three: formik?.values?.length_of_residence_three,
        },
        home_phone_no: {
          home_phone_no_one: formik?.values?.home_phone_no_one,
          home_phone_no_two: formik?.values?.home_phone_no_two,
          home_phone_no_three: formik?.values?.home_phone_no_three,
        },
        cell_phone_no: {
          cell_phone_no_one: formik?.values?.cell_phone_no_one,
          cell_phone_no_two: formik?.values?.cell_phone_no_two,
          cell_phone_no_three: formik?.values?.cell_phone_no_three,
        },

        landlords_name: {
          landlords_name_one: formik?.values?.landlords_name_one,
          landlords_name_two: formik?.values?.landlords_name_two,
          landlords_name_three: formik?.values?.landlords_name_three,
        },
        landlords_phone_no: {
          landlords_phone_one: formik?.values?.landlords_phone_one,
          landlords_phone_two: formik?.values?.landlords_phone_two,
          landlords_phone_three: formik?.values?.landlords_name_three,
        },
        previous_address: {
          previous_address_one: formik?.values?.previous_address_one,
          previous_address_two: formik?.values?.previous_address_two,
          previous_address_three: formik?.values?.previous_address_three,
        },
        previous_apartment_no: {
          previous_appartment_no_one:
            formik?.values?.previous_appartment_no_one,
          previous_appartment_no_two:
            formik?.values?.previous_appartment_no_two,
          previous_appartment_no_three:
            formik?.values?.previous_appartment_no_three,
        },
        previous_monthly_rent: {
          previous_monthly_rent_one: formik?.values?.previous_monthly_rent_one,
          previous_monthly_rent_two: formik?.values?.previous_monthly_rent_two,
          previous_monthly_rent_three:
            formik?.values?.previous_monthly_rent_three,
        },
        previous_postal_code: {
          previous_postal_code_one: formik?.values?.previous_postal_code_one,
          previous_postal_code_two: formik?.values?.previous_postal_code_two,
          previous_postal_code_three: formik?.values?.previous_position_three,
        },
        previous_length_of_residence: {
          previous_length_of_residence_one:
            formik?.values?.previous_length_of_residence_one,
          previous_length_of_residence_two:
            formik?.values?.previous_length_of_residence_two,
          previous_length_of_residence_three:
            formik?.values?.previous_length_of_residence_three,
        },
        previous_landlords_name: {
          previous_landlords_name_one:
            formik?.values?.previous_landlords_name_one,
          previous_landlords_name_two:
            formik?.values?.previous_landlords_name_two,
          previous_landlords_name_three:
            formik?.values?.previous_landlords_name_three,
        },
        previous_landlords_phone_no: {
          previous_landlords_phone_one:
            formik?.values?.previous_landlords_phone_one,
          previous_landlords_phone_two:
            formik?.values?.previous_landlords_name_two,
          previous_landlords_phone_three:
            formik?.values?.previous_landlords_name_three,
        },
        current_employer: {
          current_employer_one: formik?.values?.current_employer_one,
          current_employer_two: formik?.values?.current_employer_two,
          current_employer_three: formik?.values?.current_employer_three,
        },
        work_phone_no: {
          work_phone_no_one: formik?.values?.work_phone_no_one,
          work_phone_no_two: formik?.values?.work_phone_no_two,
          work_phone_no_three: formik?.values?.work_phone_no_three,
        },
        position: {
          position_one: formik?.values?.position_one,
          position_two: formik?.values?.position_two,
          position_three: formik?.values?.position_three,
        },
        length_of_employment: {
          length_of_employment_one: formik?.values?.length_of_employment_one,
          length_of_employment_two: formik?.values?.length_of_employment_two,
          length_of_employment_three:
            formik?.values?.length_of_employment_three,
        },
        contact_name_and_no: {
          contact_name_no_one: formik?.values?.contact_name_no_one,
          contact_name_no_two: formik?.values?.contact_name_no_two,
          contact_name_no_three: formik?.values?.contact_name_no_three,
        },
        phone_no: {
          phone_no_one: formik?.values?.phone_no_one,
          phone_no_two: formik?.values?.phone_no_two,
          phone_no_three: formik?.values?.phone_no_three,
        },
        annual_income: {
          annual_income_one: formik?.values?.annual_income_one,
          annual_income_two: formik?.values?.annual_income_two,
          annual_income_three: formik?.values?.annual_income_three,
        },
        previous_employer: {
          previous_employer_one: formik?.values?.previous_employer_one,
          previous_employer_two: formik?.values?.previous_employer_two,
          previous_employer_three: formik?.values?.previous_employer_three,
        },
        previous_position: {
          previous_position_one: formik?.values?.previous_position_one,
          previous_position_two: formik?.values?.previous_position_two,
          previous_position_three: formik?.values?.previous_position_three,
        },
        previous_length_of_employment: {
          Previous_length_of_employment_one:
            formik?.values?.Previous_length_of_employment_one,
          Previous_length_of_employment_two:
            formik?.values?.Previous_length_of_employment_two,
          Previous_length_of_employment_three:
            formik?.values?.previous_length_of_residence_three,
        },
        previous_contact_name_and_no: {
          previous_contact_name_no_one:
            formik?.values?.previous_contact_name_no_one,
          previous_contact_name_no_two:
            formik?.values?.previous_contact_name_no_two,
          previous_contact_name_no_three:
            formik?.values?.previous_annual_income_three,
        },
        previous_annual_income: {
          previous_annual_income_one:
            formik?.values?.previous_annual_income_one,
          previous_annual_income_two:
            formik?.values?.previous_annual_income_two,
          previous_annual_income_three:
            formik?.values?.previous_annual_income_three,
        },
        bank: {
          bank_one: formik?.values?.bank_one,
          bank_two: formik?.values?.bank_two,
          bank_three: formik?.values?.bank_three,
        },
        branch: {
          branch_one: formik?.values?.branch_one,
          branch_two: formik?.values?.branch_two,
          branch_three: formik?.values?.branch_three,
        },
        account_no: {
          account_no_one: formik?.values?.account_no_one,
          account_no_two: formik?.values?.account_no_two,
          account_no_three: formik?.values?.account_no_three,
        },
        make_of_vehicle: {
          make_of_vehicle_one: formik?.values?.make_of_vehicle_one,
          make_of_vehicle_two: formik?.values?.make_of_vehicle_two,
          make_of_vehicle_three: formik?.values?.make_of_vehicle_three,
        },
        year_model_of_vehicle: {
          year_model_of_vehicle_one: formik?.values?.year_model_of_vehicle_one,
          year_model_of_vehicle_two: formik?.values?.year_model_of_vehicle_two,
          year_model_of_vehicle_three:
            formik?.values?.year_model_of_vehicle_threer,
        },
        colour_of_vehicle: {
          colour_of_vehicle_one: formik?.values?.colour_of_vehicle_one,
          colour_of_vehicle_two: formik?.values?.colour_of_vehicle_two,
          colour_of_vehicle_three: formik?.values?.colour_of_vehicle_three,
        },
        licence_plate_no: {
          licence_plate_no_one: formik?.values?.licence_plate_no_one,
          licence_plate_no_two: formik?.values?.licence_plate_no_two,
          licence_plate_no_three: formik?.values?.licence_plate_no_three,
        },
        drivers_license_no: {
          drivers_license_no_one: formik?.values?.drivers_license_no_one,
          drivers_license_no_two: formik?.values?.drivers_license_no_two,
          drivers_license_no_three: formik?.values?.drivers_license_no_three,
        },
        reference_name: {
          reference_name_one: formik?.values?.reference_name_one,
          reference_name_two: formik?.values?.reference_name_two,
          reference_name_three: formik?.values?.reference_name_three,
        },
        reference_address: {
          reference_address_one: formik?.values?.reference_address_one,
          reference_address_two: formik?.values?.reference_address_two,
          reference_address_three: formik?.values?.reference_address_three,
        },
        reference_phone_no: {
          reference_phone_no_one: formik?.values?.reference_phone_no_one,
          reference_phone_no_two: formik?.values?.reference_phone_no_two,
          reference_phone_no_three: formik?.values?.reference_phone_no_three,
        },
        reference_relationship: {
          reference_relationship_one:
            formik?.values?.reference_relationship_one,
          reference_relationship_two:
            formik?.values?.reference_relationship_two,
          reference_relationship_three:
            formik?.values?.reference_relationship_three,
        },
        next_of_kin_name: {
          next_name_one: formik?.values?.next_name_one,
          next_name_two: formik?.values?.next_name_two,
          next_name_three: formik?.values?.next_name_three,
        },
        next_of_kin_address: {
          next_address_one: formik?.values?.next_address_one,
          next_address_two: formik?.values?.next_address_two,
          next_address_three: formik?.values?.next_address_three,
        },
        next_of_kin_phone_no: {
          next_phone_no_one: formik?.values?.next_phone_no_one,
          next_phone_no_two: formik?.values?.next_phone_no_two,
          next_phone_no_three:
            formik?.values?.formik?.values?.next_phone_no_three,
        },
        next_of_kin_relationship: {
          next_relationship_one: formik?.values?.next_relationship_one,
          next_relationship_two: formik?.values?.next_relationship_two,
          next_relationship_three: formik?.values?.next_relationship_three,
        },
        credit_unit: formik?.values?.suite,
        credit_building: formik?.values?.building,
        credit_date: creditDate,
      };
      // console.log(data);
      let res = await updateApplication(
        "/api/admin/application/update",
        "POST",
        JSON.stringify(data)
      );
      toast.success(res?.message); // Display the success toaster notification
      navigate("/application-listing");
    } catch (error) {
      // console.log("error update", error);
      notifyError(error?.responseJSON?.message);
      return false;
    }
  };

  // update Api call on submit
  const UpdateSubmit = async () => {
    // console.log("with id Submit");
    try {
      let data = {
        suite_no: formik?.values?.suite,
        application_type: formik?.values?.type,
        building: formik?.values?.building,
        initial_one: formik?.values?.initial_one,
        initial_two: formik?.values?.initial_two,
        initial_three: formik?.values?.initial_three,

        // building_id: buildingList?.find(b => b?.building_name === formik.values.building)?._id,
        building_id: localStorage.getItem("building_id"),

        inside: formik?.values?.inside,
        outside: formik?.values?.outside,
        lastMonthRent: formik?.values?.lastMonthRent,
        belowLast: formik?.values?.belowLast,
        discountRent: formik?.values?.discountRent,
        discountLast: formik?.values?.discountLast,
        discountPayable: formik?.values?.discountPayable,
        netLast: formik?.values?.netLast,
        netPayable: formik?.values?.netPayable,
        lastMonthDeposit: formik?.values?.lastMonthDeposit,
        noDays: formik?.values?.noDays,
        perDiem: formik?.values?.perDiem,
        proAmountRent: formik?.values?.proAmountRent,
        proAmount: formik?.values?.proAmount,
        Prior: formik?.values?.Prior,

        tenant_name: formik?.values?.tenentContactName,
        tenant_email: formik?.values?.tenentEmailAddress,
        tenant_phone_no: formik?.values?.tenentPhoneNumber,
        parking_below_ground: {
          law_rent: formik?.values?.belowRent,
          rent_payable: formik?.values?.belowPayAble,
        },
        bona_fide_tenants: fields,
        occupants: occupantFields,

        tenancy_start_date: tenentStartDate,
        tenancy_end_date: tenentEndDate,
        rent: {
          law_rent: formik?.values?.lawRent,
          rent_payable: formik?.values?.payAbleRent,
        },
        parking_above_ground: {
          law_rent: formik?.values?.parkingLawRent,
          rent_payable: formik?.values?.parkingPayAbleRent,
        },
        total_legal_rent: {
          law_rent: formik?.values?.legalRent,
          rent_payable_legal: formik?.values?.legalPayAble,
          last_month_rent: formik?.values?.legalLast,
        },
        prompt_payment_discount: formik?.values?.discountPayable,
        net_rent_payable: formik?.values?.netPayable,

        pro_rate: {
          pro_start_date: proStartDate,
          pro_End_date: proEndDate,
          no_of_days: formik?.values?.noDays,
          per_diem_rate: formik?.values?.perDiem,
          pro_rent: formik?.values?.proAmountRent,
          pro_rate_amount: formik?.values?.proAmount,
          lmr: formik?.values?.lmr,
          total_amount_prior_move_in: formik?.values?.Prior,
        },

        application_type: {
          new_application: newApplicants,
          assignment: assignments,
          sublet: sublets,
          transfer: transfers,
          name_change: nameChanges,
        },
        action: "send",
        application_id: applicationID,

        government_identification: selectedImage,
        proof_of_employment: selectedFile,
        aggrement_date: agreementDate,
        tenant_signature:
          signPadTenent?.getTrimmedCanvas()?.toDataURL("image/png") ?? null,
        building_manager_signature:
          signPadBuildingManager?.getTrimmedCanvas()?.toDataURL("image/png") ??
          null,
        building_manager_signature_credit:
          signPadCreditBm?.getTrimmedCanvas()?.toDataURL("image/png") ?? null,
        property_manager_signature:
          signPadPropertyManager?.getTrimmedCanvas()?.toDataURL("image/png") ??
          null,
        // acceptance_date :acceptanceDate,
        today_date: requestDate,
        unit_type: typeSelected,

        applicant_name: {
          name_applicant_one: formik?.values?.name_applicant_one,
          name_applicant_two: formik?.values?.name_applicant_two,
          name_applicant_three: formik?.values?.name_applicant_three,
        },
        date_of_birth: {
          date_of_birth_one: formik?.values?.date_of_birth_one,
          date_of_birth_two: formik?.values?.date_of_birth_two,
          date_of_birth_three: formik?.values?.date_of_birth_three,
        },
        current_address: {
          current_address_one: formik?.values?.current_address_one,
          current_address_two: formik?.values?.current_address_two,
          current_address_three: formik?.values?.current_address_three,
        },
        apartment_no: {
          appartment_no_one: formik?.values?.appartment_no_one,
          appartment_no_two: formik?.values?.appartment_no_two,
          appartment_no_three: formik?.values?.appartment_no_three,
        },
        present_monthly_rent: {
          present_monthly_rent_one: formik?.values?.present_monthly_rent_one,
          present_monthly_rent_two: formik?.values?.present_monthly_rent_two,
          present_monthly_rent_three:
            formik?.values?.present_monthly_rent_three,
        },
        postal_code: {
          postal_code_one: formik?.values?.postal_code_one,
          postal_code_two: formik?.values?.postal_code_two,
          postal_code_three: formik?.values?.postal_code_three,
        },
        length_of_residence: {
          length_of_residence_one: formik?.values?.length_of_residence_one,
          length_of_residence_two: formik?.values?.length_of_residence_two,
          length_of_residence_three: formik?.values?.length_of_residence_three,
        },
        home_phone_no: {
          home_phone_no_one: formik?.values?.home_phone_no_one,
          home_phone_no_two: formik?.values?.home_phone_no_two,
          home_phone_no_three: formik?.values?.home_phone_no_three,
        },
        cell_phone_no: {
          cell_phone_no_one: formik?.values?.cell_phone_no_one,
          cell_phone_no_two: formik?.values?.cell_phone_no_two,
          cell_phone_no_three: formik?.values?.cell_phone_no_three,
        },

        landlords_name: {
          landlords_name_one: formik?.values?.landlords_name_one,
          landlords_name_two: formik?.values?.landlords_name_two,
          landlords_name_three: formik?.values?.landlords_name_three,
        },
        landlords_phone_no: {
          landlords_phone_one: formik?.values?.landlords_phone_one,
          landlords_phone_two: formik?.values?.landlords_phone_two,
          landlords_phone_three: formik?.values?.landlords_name_three,
        },
        previous_address: {
          previous_address_one: formik?.values?.previous_address_one,
          previous_address_two: formik?.values?.previous_address_two,
          previous_address_three: formik?.values?.previous_address_three,
        },
        previous_apartment_no: {
          previous_appartment_no_one:
            formik?.values?.previous_appartment_no_one,
          previous_appartment_no_two:
            formik?.values?.previous_appartment_no_two,
          previous_appartment_no_three:
            formik?.values?.previous_appartment_no_three,
        },
        previous_monthly_rent: {
          previous_monthly_rent_one: formik?.values?.previous_monthly_rent_one,
          previous_monthly_rent_two: formik?.values?.previous_monthly_rent_two,
          previous_monthly_rent_three:
            formik?.values?.previous_monthly_rent_three,
        },
        previous_postal_code: {
          previous_postal_code_one: formik?.values?.previous_postal_code_one,
          previous_postal_code_two: formik?.values?.previous_postal_code_two,
          previous_postal_code_three: formik?.values?.previous_position_three,
        },
        previous_length_of_residence: {
          previous_length_of_residence_one:
            formik?.values?.previous_length_of_residence_one,
          previous_length_of_residence_two:
            formik?.values?.previous_length_of_residence_two,
          previous_length_of_residence_three:
            formik?.values?.previous_length_of_residence_three,
        },
        previous_landlords_name: {
          previous_landlords_name_one:
            formik?.values?.previous_landlords_name_one,
          previous_landlords_name_two:
            formik?.values?.previous_landlords_name_two,
          previous_landlords_name_three:
            formik?.values?.previous_landlords_name_three,
        },
        previous_landlords_phone_no: {
          previous_landlords_phone_one:
            formik?.values?.previous_landlords_phone_one,
          previous_landlords_phone_two:
            formik?.values?.previous_landlords_name_two,
          previous_landlords_phone_three:
            formik?.values?.previous_landlords_name_three,
        },
        current_employer: {
          current_employer_one: formik?.values?.current_employer_one,
          current_employer_two: formik?.values?.current_employer_two,
          current_employer_three: formik?.values?.current_employer_three,
        },
        work_phone_no: {
          work_phone_no_one: formik?.values?.work_phone_no_one,
          work_phone_no_two: formik?.values?.work_phone_no_two,
          work_phone_no_three: formik?.values?.work_phone_no_three,
        },
        position: {
          position_one: formik?.values?.position_one,
          position_two: formik?.values?.position_two,
          position_three: formik?.values?.position_three,
        },
        length_of_employment: {
          length_of_employment_one: formik?.values?.length_of_employment_one,
          length_of_employment_two: formik?.values?.length_of_employment_two,
          length_of_employment_three:
            formik?.values?.length_of_employment_three,
        },
        contact_name_and_no: {
          contact_name_no_one: formik?.values?.contact_name_no_one,
          contact_name_no_two: formik?.values?.contact_name_no_two,
          contact_name_no_three: formik?.values?.contact_name_no_three,
        },
        phone_no: {
          phone_no_one: formik?.values?.phone_no_one,
          phone_no_two: formik?.values?.phone_no_two,
          phone_no_three: formik?.values?.phone_no_three,
        },
        annual_income: {
          annual_income_one: formik?.values?.annual_income_one,
          annual_income_two: formik?.values?.annual_income_two,
          annual_income_three: formik?.values?.annual_income_three,
        },
        previous_employer: {
          previous_employer_one: formik?.values?.previous_employer_one,
          previous_employer_two: formik?.values?.previous_employer_two,
          previous_employer_three: formik?.values?.previous_employer_three,
        },
        previous_position: {
          previous_position_one: formik?.values?.previous_position_one,
          previous_position_two: formik?.values?.previous_position_two,
          previous_position_three: formik?.values?.previous_position_three,
        },
        previous_length_of_employment: {
          Previous_length_of_employment_one:
            formik?.values?.Previous_length_of_employment_one,
          Previous_length_of_employment_two:
            formik?.values?.Previous_length_of_employment_two,
          Previous_length_of_employment_three:
            formik?.values?.previous_length_of_residence_three,
        },
        previous_contact_name_and_no: {
          previous_contact_name_no_one:
            formik?.values?.previous_contact_name_no_one,
          previous_contact_name_no_two:
            formik?.values?.previous_contact_name_no_two,
          previous_contact_name_no_three:
            formik?.values?.previous_annual_income_three,
        },
        previous_annual_income: {
          previous_annual_income_one:
            formik?.values?.previous_annual_income_one,
          previous_annual_income_two:
            formik?.values?.previous_annual_income_two,
          previous_annual_income_three:
            formik?.values?.previous_annual_income_three,
        },
        bank: {
          bank_one: formik?.values?.bank_one,
          bank_two: formik?.values?.bank_two,
          bank_three: formik?.values?.bank_three,
        },
        branch: {
          branch_one: formik?.values?.branch_one,
          branch_two: formik?.values?.branch_two,
          branch_three: formik?.values?.branch_three,
        },
        account_no: {
          account_no_one: formik?.values?.account_no_one,
          account_no_two: formik?.values?.account_no_two,
          account_no_three: formik?.values?.account_no_three,
        },
        make_of_vehicle: {
          make_of_vehicle_one: formik?.values?.make_of_vehicle_one,
          make_of_vehicle_two: formik?.values?.make_of_vehicle_two,
          make_of_vehicle_three: formik?.values?.make_of_vehicle_three,
        },
        year_model_of_vehicle: {
          year_model_of_vehicle_one: formik?.values?.year_model_of_vehicle_one,
          year_model_of_vehicle_two: formik?.values?.year_model_of_vehicle_two,
          year_model_of_vehicle_three:
            formik?.values?.year_model_of_vehicle_threer,
        },
        colour_of_vehicle: {
          colour_of_vehicle_one: formik?.values?.colour_of_vehicle_one,
          colour_of_vehicle_two: formik?.values?.colour_of_vehicle_two,
          colour_of_vehicle_three: formik?.values?.colour_of_vehicle_three,
        },
        licence_plate_no: {
          licence_plate_no_one: formik?.values?.licence_plate_no_one,
          licence_plate_no_two: formik?.values?.licence_plate_no_two,
          licence_plate_no_three: formik?.values?.licence_plate_no_three,
        },
        drivers_license_no: {
          drivers_license_no_one: formik?.values?.drivers_license_no_one,
          drivers_license_no_two: formik?.values?.drivers_license_no_two,
          drivers_license_no_three: formik?.values?.drivers_license_no_three,
        },
        reference_name: {
          reference_name_one: formik?.values?.reference_name_one,
          reference_name_two: formik?.values?.reference_name_two,
          reference_name_three: formik?.values?.reference_name_three,
        },
        reference_address: {
          reference_address_one: formik?.values?.reference_address_one,
          reference_address_two: formik?.values?.reference_address_two,
          reference_address_three: formik?.values?.reference_address_three,
        },
        reference_phone_no: {
          reference_phone_no_one: formik?.values?.reference_phone_no_one,
          reference_phone_no_two: formik?.values?.reference_phone_no_two,
          reference_phone_no_three: formik?.values?.reference_phone_no_three,
        },
        reference_relationship: {
          reference_relationship_one:
            formik?.values?.reference_relationship_one,
          reference_relationship_two:
            formik?.values?.reference_relationship_two,
          reference_relationship_three:
            formik?.values?.reference_relationship_three,
        },
        next_of_kin_name: {
          next_name_one: formik?.values?.next_name_one,
          next_name_two: formik?.values?.next_name_two,
          next_name_three: formik?.values?.next_name_three,
        },
        next_of_kin_address: {
          next_address_one: formik?.values?.next_address_one,
          next_address_two: formik?.values?.next_address_two,
          next_address_three: formik?.values?.next_address_three,
        },
        next_of_kin_phone_no: {
          next_phone_no_one: formik?.values?.next_phone_no_one,
          next_phone_no_two: formik?.values?.next_phone_no_two,
          next_phone_no_three:
            formik?.values?.formik?.values?.next_phone_no_three,
        },
        next_of_kin_relationship: {
          next_relationship_one: formik?.values?.next_relationship_one,
          next_relationship_two: formik?.values?.next_relationship_two,
          next_relationship_three: formik?.values?.next_relationship_three,
        },
        credit_unit: formik?.values?.suite,
        credit_building: formik?.values?.building,
        credit_date: creditDate,
      };

      let res = await updateApplication(
        "/api/admin/application/update",
        "POST",
        JSON.stringify(data)
      );
      toast.success(res?.message); // Display the success toaster notification
      navigate("/application-listing");
    } catch (error) {
      toast.error(error?.statusText);
      notifyError(error?.responseJSON?.message);

      return false;
    }
  };

  setUpdateSubmitFunction(UpdateSubmit);

  // console.log("formik==>", formik);
  // console.log("Vals", newApplicants);
  // console.log("request Date", requestDate);
  // tabs Configuration
  const handleTabChange = (activeKey) => {
    setActiveTab(activeKey);
  };
  // console.log("setSignPad Building==>", signPadBuildingManager);
  // printings

  // const handleListingPage = useReactToPrint({
  //   content: () => listingRef.current,
  //   onAfterPrint: () => {
  //     setShowLogo(false);
  //   },
  // });
  // const handleListingPage = useReactToPrint({
  //   copyStyles: true,
  //   pageStyles:
  //     "@media print { body { -webkit-print-color-adjust: exact; } @page { size: A4; margin-top:800mm }}",
  //   content: () => {
  //     const data_toPrint = listingRef.current?.cloneNode(true);

  //     const listingRefpdf1 = listingRef1.current?.cloneNode(true);
  //     const listingRefpdf2 = listingRef2.current?.cloneNode(true);
  //     const listingRefpdf3 = listingRef3.current?.cloneNode(true);
  //     const listingRefpdf4 = listingRef4.current?.cloneNode(true);
  //     const listingRefpdf5 = listingRef5.current?.cloneNode(true);

  //     const PrintElem = document.createElement("div");

  //     // Append the content of each tab to the PrintElem container
  //     if (data_toPrint) {
  //       PrintElem.appendChild(data_toPrint);
  //     }
  //     if (listingRefpdf1) {
  //       PrintElem.appendChild(listingRefpdf1);
  //     }
  //     if (listingRefpdf2) {
  //       PrintElem.appendChild(listingRefpdf2);
  //     }
  //     if (listingRefpdf3) {
  //       PrintElem.appendChild(listingRefpdf3);
  //     }
  //     if (listingRefpdf4) {
  //       PrintElem.appendChild(listingRefpdf4);
  //     }
  //     if (listingRefpdf5) {
  //       PrintElem.appendChild(listingRefpdf5);
  //     }

  //     return PrintElem;
  //   },
  // });

  // const refsss = useReactToPrint({
  //   copyStyles: true,
  //   pageStyles:
  //     "@media print { body { -webkit-print-color-adjust: exact; } @page { size: A4; margin-top:800mm }}",
  //   content: () => {
  //     const data_toPrint = listingRef1.current?.cloneNode(true);
  //     console.log("pdf",{ elem: listingRef2.current });
  //     const listingRefpdf1 = listingRef2.current?.cloneNode(true);
  //     const listingRefpdf2 = listingRef2.current?.cloneNode(true);
  //     const listingRefpdf3 = listingRef3.current?.cloneNode(true);
  //     const listingRefpdf4 = listingRef4.current?.cloneNode(true);
  //     const listingRefpdf5 = listingRef5.current?.cloneNode(true);

  //     const PrintElem = document.createElement("div");
  //     PrintElem.appendChild(data_toPrint);
  //     PrintElem.appendChild(listingRefpdf1);
  //     PrintElem.appendChild(listingRefpdf2);
  //     PrintElem.appendChild(listingRefpdf3);
  //     PrintElem.appendChild(listingRefpdf4);
  //     PrintElem.appendChild(listingRefpdf5);

  //     return PrintElem;
  //   },
  // });

  const generatePrintContent = () => {
    const listingRefpdf1 = listingRef1.current?.cloneNode(true);
    const listingRefpdf2 = listingRef2.current?.cloneNode(true);
    const listingRefpdf3 = listingRef3.current?.cloneNode(true);
    const listingRefpdf4 = listingRef4.current?.cloneNode(true);

    const PrintElem = document.createElement("div");

    PrintElem.appendChild(listingRefpdf1);
    PrintElem.appendChild(listingRefpdf2);
    PrintElem.appendChild(listingRefpdf3);
    PrintElem.appendChild(listingRefpdf4);

    return PrintElem;
  };

  // const refsss = useReactToPrint({
  //   copyStyles: true,
  //   pageStyles:
  //     "@media print { body { -webkit-print-color-adjust: exact; } @page { size: A4; margin: 0; padding: 0; } div { page-break-after: auto; } }",
  //   content: generatePrintContent, // Call the function to generate print content
  //   onAfterPrint: () => {
  //     // Your onAfterPrint logic here'
  //     setShowLogo(false);
  //   },
  // });

  // const printApplication = () => {
  //   const printContent = generatePrintContent(); // Generate the content
  //   onPrint(printContent); // Call the callback function
  //   refsss(); // Trigger printing
  // };

  const refsss = useReactToPrint({
    copyStyles: true,
    pageStyles:
      "@media print { body { -webkit-print-color-adjust: exact; } @page { size: A4; margin: 0; padding: 0; } div { page-break-after: auto; } }",
    content: () => {
      // console.log("pdf", { elem: listingRef2.current });
      const listingRefpdf1 = listingRef1.current?.cloneNode(true);
      const listingRefpdf2 = listingRef2.current?.cloneNode(true);
      const listingRefpdf3 = listingRef3.current?.cloneNode(true);
      const listingRefpdf4 = listingRef4.current?.cloneNode(true);
      // const listingRefpdf5 = listingRef5.current?.cloneNode(true);
      // const listingRefpdf6 = listingRef6.current?.cloneNode(true);

      const PrintElem = document.createElement("div");

      // PrintElem.appendChild(data_toPrint);
      PrintElem.appendChild(listingRefpdf1);
      PrintElem.appendChild(listingRefpdf2);
      PrintElem.appendChild(listingRefpdf3);
      PrintElem.appendChild(listingRefpdf4);
      // PrintElem.appendChild(listingRefpdf5);
      setPrint(true);
      return PrintElem;
    },
    onAfterPrint: () => {
      setShowLogo(false);
      setPrint(false);
    },
  });

  // useEffect(()=>{
  //   function handleBeforePrint() {
  //     flushSync(() => {
  //       setShowLogo(true);
  //     })
  //   }

  //   function handleAfterPrint() {
  //     setShowLogo(false);
  //   }
  // },[])

  // const buildingListArray = JSON.parse(localStorage.getItem("buildingListArray"));

  const getBuildingNames = async () => {
    let res = await adminApiCall(
      "/api/admin/user/assign_buildings",
      "GET"
      // JSON.stringify(data)
    );
    if (!res) {
      notifyError("Something went wrong.. Please Try Again");
      return false;
    }
    if (res.hasOwnProperty("status")) {
      if (res.status === 200) {
        // console.log("res select building", res);
        setBuildingList(res?.data);
        let tempArray = [];
        let tempBuildingArray = [...buildingList];
        for (let i = 0; i < res.data.length; i++) {
          tempArray.push({
            name: res.data[i].property_id,
            _id: res.data[i]._id,
            building_id: res.data[i].building_id,
            address: res.data[i].street,
            noOfSuite: res.data[i].no_of_suite,
            availableSuite: res.data[i].available_suite,
            nameOfBuilding: res.data[i].building_name,
          });
          tempBuildingArray.push(res.data[i].building_name);
        }
        setFilteredOptions(tempArray);
        setOptions(tempArray);
        // setBuildingList(tempBuildingArray);
        // console.log("tempBuildingArray: ", tempBuildingArray);
        // localStorage.setItem("buildingListArray", tempBuildingArray);
      } else if (res.status === 400) {
        notifyError(res.message);
      }
    }
  };

  useEffect(() => {
    getBuildingNames();
  }, []);
  // console.log("api==>", buildingList);

  // Function to handle the download of the image
  // const downloadImage = (fileBuffer) => {
  //   const uint8Array = new Uint8Array(fileBuffer);
  //   const blob = new Blob([uint8Array], { type: "application/Image" });
  //   const url = URL.createObjectURL(blob);
  //   const link = document.createElement("a");
  //   link.href = url;
  //   link.setAttribute("download", "GovernmentIdentification.png");
  //   link.style.display = "none";
  //   document.body.appendChild(link);
  //   link.click();
  //   document.body.removeChild(link);
  // };

  const downloadFile = (fileData, fileExtension, fileNameWithoutSpaces) => {
    const uint8Array = new Uint8Array(fileData);
    const blob = new Blob([uint8Array], { type: fileExtension });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileNameWithoutSpaces);
    link.style.display = "none";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleDownloadImage = async (file) => {
    const requestData = {
      keys: [file],
    };
    try {
      let res = await createApplcation(
        "/api/admin/application/download_document",
        JSON.stringify(requestData),
        {
          responseType: "application/pdf", // Set the response type to arraybuffer
        }
      );
      console.log("Response:", res);
      if (res && res.length > 0) {
        res.forEach((fileObj, index) => {
          const { file_buffer: fileData, file_name: fileNameWithSpaces } =
            fileObj;
          const fileNameWithoutSpaces = fileNameWithSpaces?.replace(
            /\s+/g,
            "_"
          );
          const fileExtension = fileNameWithoutSpaces?.split(".").pop();
          if (!fileExtension) {
            throw new Error(
              `File extension not found for file at index ${index}.`
            );
          }
          downloadImage(fileData.data, fileExtension, fileNameWithoutSpaces);
        });
        toast.success("Files downloaded successfully");
      } else {
        throw new Error("No files found in the response.");
      }
    } catch (error) {
      console.log("Error:", error);
      if (error.status == 400) {
        toast.error(error.responseJSON?.message || "Bad Request");
      }

      // else {
      //   toast.error("Server Side Error");
      // }
    }
  };

  const downloadImage = (fileData, fileExtension, fileNameWithoutSpaces) => {
    const uint8Array = new Uint8Array(fileData);
    console.log("fileData", fileData, fileExtension);
    const blob = new Blob([uint8Array], { type: fileExtension });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileNameWithoutSpaces);
    link.style.display = "none";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const getMimeType = (fileExtension) => {
    // Add more extensions and MIME types as needed
    switch (fileExtension.toLowerCase()) {
      case "pdf":
        return "application/pdf";
      case "doc":
      case "docx":
        return "application/msword";
      case "xls":
      case "xlsx":
        return "application/vnd.ms-excel";
      case "jpg":
      case "jpeg":
        return "image/jpeg";
      case "gif":
        return "image/gif";
      case "png":
        return "image/png";
      // Add more cases for other file types
      default:
        return "application/octet-stream"; // default MIME type for binary data
    }
  };
  // const handleDownloadFileAll = async () => {
  //   const requestData = {
  //     keys: filesDataProof,
  //   };
  //   try {
  //     let res = await createApplcation(
  //       "/api/admin/application/download_pdf",
  //       JSON.stringify(requestData),
  //       {
  //         responseType: "arraybuffer", // Set the response type to arraybuffer
  //       }
  //     );
  //     console.log("Response:", res);
  //     if (res && res.length > 0) {
  //       res.forEach((fileObj, index) => {
  //         const { file_buffer: fileData, file_name: fileNameWithSpaces } =
  //           fileObj;
  //         const fileNameWithoutSpaces = fileNameWithSpaces?.replace(
  //           /\s+/g,
  //           "_"
  //         );
  //         const fileExtension = fileNameWithoutSpaces?.split(".").pop();
  //         if (!fileExtension) {
  //           throw new Error(
  //             `File extension not found for file at index ${index}.`
  //           );
  //         }
  //         downloadFile(fileData.data, fileExtension, fileNameWithoutSpaces);
  //       });
  //       toast.success("Files downloaded successfully");
  //     } else {
  //       throw new Error("No files found in the response.");
  //     }
  //   } catch (error) {
  //     console.log("Error:", error);
  //     if (error.status == 400) {
  //       toast.error(error.responseJSON?.message || "Bad Request");
  //     }

  //     //  else {
  //     //   toast.error("Server Side Error");
  //     // }
  //   }
  // };

  const handleDownloadFileAll = async () => {
    const requestData = {
      keys: filesDataProof,
    };
    setCheckDownload(true);
    try {
      let res = await createApplcation(
        "/api/admin/application/download_pdf",
        JSON.stringify(requestData)
      );
      console.log("Response:", res);
      if (res) {
        setCheckDownload(false);
        const downloadimges = (data, contentType, filename) => {
          const uint8Array = new Uint8Array(Object.values(data));
          const blob = new Blob([uint8Array], { type: contentType });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", filename);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
        };
        downloadimges(res, "application/pdf", "Download.pdf");
      }
    } catch (error) {
      setCheckDownload(false);

      console.log("Error:", error);
      if (error.status == 400) {
        toast.error(error.responseJSON?.message || "Bad Request");
      }
    }
  };

  const handleDownloadImageAll = async () => {
    const requestData = {
      keys: filesDataGov,
    };
    setCheckDownloadImage(true);

    try {
      let res = await createApplcation(
        "/api/admin/application/download_pdf",
        JSON.stringify(requestData)
      );
      if(res){
        setCheckDownloadImage(false);
        const downloadimges = (data, contentType, filename) => {
          const uint8Array = new Uint8Array(Object.values(data));
          const blob = new Blob([uint8Array], { type: contentType });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", filename);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
        };
        downloadimges(res, "application/pdf", "Download.pdf");
      }

    } catch (error) {
      setCheckDownloadImage(false);

      console.log("Error:", error);
      if (error.status == 400) {
        toast.error(error.responseJSON?.message || "Bad Request");
      }
    }
  };

  const handleDownloadFile = async (file) => {
    const requestData = {
      keys: [file],
    };
    try {
      let res = await createApplcation(
        "/api/admin/application/download_document",
        JSON.stringify(requestData),
        {
          responseType: "arraybuffer", // Set the response type to arraybuffer
        }
      );
      console.log("Response:", res);
      if (res && res.length > 0) {
        res.forEach((fileObj, index) => {
          const { file_buffer: fileData, file_name: fileNameWithSpaces } =
            fileObj;
          const fileNameWithoutSpaces = fileNameWithSpaces?.replace(
            /\s+/g,
            "_"
          );
          const fileExtension = fileNameWithoutSpaces?.split(".").pop();
          if (!fileExtension) {
            throw new Error(
              `File extension not found for file at index ${index}.`
            );
          }
          downloadFile(fileData.data, fileExtension, fileNameWithoutSpaces);
        });
        toast.success("Files downloaded successfully");
      } else {
        throw new Error("No files found in the response.");
      }
    } catch (error) {
      console.log("Error:", error);
      if (error.status == 400) {
        toast.error(error.responseJSON?.message || "Bad Request");
      }

      //  else {
      //   toast.error("Server Side Error");
      // }
    }
  };
  const handleRejectClick = async () => {
    const requestData = {
      application_id: applicationID,
    };
    try {
      let res = await createApplcation(
        "/api/admin/application/reject_application",
        JSON.stringify(requestData)
      );
      // console.log("Response ==>reject", res);
      toast.success(res?.message); // Display the success toaster notification
      navigate("/application-listing");
    } catch (error) {
      // console.log("err", error);
      if (error.status == 400) {
        toast.error("Something Went Wrong"); // Display the error toaster notification
      } else {
        toast.error("Server Side Error"); // Display the error toaster notification
      }
    }
  };

  const handleApproveClick = async () => {
    const requestData = {
      application_id: applicationID,
    };
    try {
      let res = await createApplcation(
        "/api/admin/application/approve_application",
        JSON.stringify(requestData)
      );
      // console.log("Response ==>approve", res);
      toast.success(res?.message); // Display the success toaster notification
      navigate("/application-listing");
    } catch (error) {
      // console.log("err", error);
      if (error.status == 400) {
        toast.error("Something Went Wrong"); // Display the error toaster notification
      } else {
        toast.error("Server Side Error"); // Display the error toaster notification
      }
    }
  };

  const handleGetNote = async () => {
    const requestData = {
      application_id: applicationID,
    };
    try {
      let res = await createApplcation(
        "/api/admin/application/get_notes",
        JSON.stringify(requestData)
      );
      setNotes(res?.notes); // Assuming the API returns an object with a 'notes' property containing the note data

      // console.log("Response ==>approve", res);
      // toast.success(res?.message); // Display the success toaster notification
      // navigate("/application-listing");
    } catch (error) {
      // console.log("err", error);
      if (error.status == 400) {
        toast.error("Something Went Wrong"); // Display the error toaster notification
      } else {
        toast.error("Server Side Error"); // Display the error toaster notification
      }
    }
  };
  const handleInputChange = (index, event) => {
    const values = [...inputFields];
    // console.log("values==>change", values);
    values[index].value = event.target.value;
    setInputFields(values);
  };
  const handleAddFieldModal = () => {
    const values = [...inputFields];
    if (values.length > 0) {
      values[values.length - 1].isNew = false; // Set isNew to false for the previous field when a new field is added
    }
    setInputFields([...values, { value: "", noteId: null, isNew: true }]);
  };

  const handleRemoveFieldModal = (index) => {
    const values = [...inputFields];
    values.splice(index, 1);
    setInputFields(values);
  };
  const handleAddNote = (field) => {
    if (field.isNew) {
      AddNoteApi(field); // Call the AddNoteApi function for new notes
    } else {
      updateNote(field); // Call the handleUpdateNote function for existing notes
    }
  };

  // console.log("nootes", notes);

  useEffect(() => {
    if (notes.length > 0) {
      setInputFields(
        notes.map((note) => ({ value: note?.note, noteId: note._id }))
      );
    }
  }, [notes]);
  // delete note
  const deleteNoteApi = async (field) => {
    // console.log("field delete", field);
    const requestData = {
      // application_id: modalId,
      note_id: field?.noteId, // Send only the value of the current field
    };
    try {
      let res = await createApplcation(
        "/api/admin/application/delete_note",
        JSON.stringify(requestData)
      );
      // console.log("Response ==>reject", res);
      toast.success(res?.message); // Display the success toaster notification
      // navigate("/application-listing");
      handleGetNote();
    } catch (error) {
      // console.log("err", error);
      if (error.status == 400) {
        toast.error("Something Went Wrong"); // Display the error toaster notification
      } else {
        toast.error("Server Side Error"); // Display the error toaster notification
      }
    }
  };
  // upate note
  const updateNote = async (field) => {
    // console.log("field delete", field);
    const requestData = {
      // application_id: modalId,
      note_id: field?.noteId, // Send only the value of the current field
      note: field?.value,
    };
    try {
      let res = await createApplcation(
        "/api/admin/application/update_note",
        JSON.stringify(requestData)
      );
      // console.log("Response ==>reject", res);
      toast.success(res?.message); // Display the success toaster notification
      // navigate("/application-listing");
      handleGetNote();
    } catch (error) {
      // console.log("err", error);
      if (error.status == 400) {
        toast.error("Something Went Wrong"); // Display the error toaster notification
      } else {
        toast.error("Server Side Error"); // Display the error toaster notification
      }
    }
  };
  const AddNoteApi = async (field) => {
    // console.log("field", field);
    const requestData = {
      application_id: applicationID,
      note: field.value, // Send only the value of the current field
    };
    try {
      let res = await createApplcation(
        "/api/admin/application/add_note",
        JSON.stringify(requestData)
      );
      // console.log("Response ==>reject", res);
      toast.success(res?.message); // Display the success toaster notification
      // navigate("/application-listing");
      handleGetNote();
    } catch (error) {
      // console.log("err", error);
      if (error.status == 400) {
        toast.error("Something Went Wrong"); // Display the error toaster notification
      } else {
        toast.error("Server Side Error"); // Display the error toaster notification
      }
    }
  };
  function isValidEmail(email) {
    const emailPattern = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
    return emailPattern.test(email);
  }
  // const handleBlur = (index) => {
  //   const newTenants = [...tenants];
  //   const email = newTenants[index].email;

  //   if (email && !isValidEmail(email)) {
  //     // Handle invalid email, show an error message or perform other actions
  //     console.log('Invalid email address');
  //   }
  // };
  // const handleBlur = (index) => {
  //   const email = fields[index].email;

  //   if (email && !isValidEmail(email)) {
  //     // Handle invalid email, show an error message or perform other actions
  //     console.log('Invalid email address');
  //   }
  // };

  // useEffect(()=>{
  // if(Object.values(formik.errors).length>0)
  // {
  //   toast.error("Please Fill Required Fields");

  // }
  // },[formik?.errors])

  const handleBuildingChange = (event) => {
    const newValue = event.target.value;
    console.log("event==>", newValue);
    setSelectedBuilding(newValue);
    // localStorage.setItem("building_namee", newValue); // Update local storage
    formik.handleChange(event); // Handle the Formik change event
  };

  // console.log("signPadBuildingManager", signPadBuildingManager);
  // console.log("signPadPropertyManager", signPadPropertyManager);

  const isSignatureEmpty = (canvasRef) => {
    if (!canvasRef || !canvasRef.current) {
      return true; // If the canvas reference is not available, consider it empty
    }
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");

    const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
    const pixelArray = new Uint32Array(imageData.data.buffer);

    // Check if there are any non-transparent pixels
    for (let pixel of pixelArray) {
      if ((pixel & 0xff000000) !== 0) {
        return false; // Signature is not empty
      }
    }

    return true; // Signature is empty
  };

  const perDiemRates = (event) => {
    const value = event.target.value?.replace(/[^0-9]/g, ""); // Remove non-numeric characters
    const formattedValue =
      value !== "" ? `$${parseFloat(value).toLocaleString()}` : ""; // Format with dollar sign and commas
    formik.handleChange({
      target: {
        name: "perDiem",
        value: formattedValue,
      },
    });
  };

  const proAmountes = (event) => {
    const value = event.target.value?.replace(/[^0-9]/g, ""); // Remove non-numeric characters
    const formattedValue =
      value !== "" ? `$${parseFloat(value).toLocaleString()}` : ""; // Format with dollar sign and commas
    formik.handleChange({
      target: {
        name: "proAmount",
        value: formattedValue,
      },
    });
  };

  const proAmountRentes = (event) => {
    const value = event.target.value?.replace(/[^0-9]/g, ""); // Remove non-numeric characters
    const formattedValue =
      value !== "" ? `$${parseFloat(value).toLocaleString()}` : ""; // Format with dollar sign and commas
    formik.handleChange({
      target: {
        name: "proAmountRent",
        value: formattedValue,
      },
    });
  };

  const lmres = (event) => {
    const value = event.target.value?.replace(/[^0-9]/g, ""); // Remove non-numeric characters
    const formattedValue =
      value !== "" ? `$${parseFloat(value).toLocaleString()}` : ""; // Format with dollar sign and commas
    formik.handleChange({
      target: {
        name: "lmr",
        value: formattedValue,
      },
    });
  };

  const Priores = (event) => {
    const value = event.target.value?.replace(/[^0-9]/g, ""); // Remove non-numeric characters
    const formattedValue =
      value !== "" ? `$${parseFloat(value).toLocaleString()}` : ""; // Format with dollar sign and commas
    formik.handleChange({
      target: {
        name: "Prior",
        value: formattedValue,
      },
    });
  };

  const lawRentes = (event) => {
    let value = event.target.value;
    let numericValue = value?.replace(/[^0-9.]/g, ""); // Remove non-numeric and non-decimal characters
    let parts = numericValue?.split("."); // Split into integer and decimal parts

    if (parts?.length > 1) {
      // If there is a decimal point
      let integerPart = parts[0];
      let decimalPart = parts[1];

      // Ensure only two decimal places are allowed
      if (decimalPart.length > 2) {
        decimalPart = decimalPart?.slice(0, 2);
      }

      // Reconstruct the value with formatting
      value = `$${integerPart?.replace(
        /\B(?=(\d{3})+(?!\d))/g,
        ","
      )}.${decimalPart}`;
    } else {
      // No decimal point, format as an integer
      value = `$${numericValue?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
    }

    formik.handleChange({
      target: {
        name: "lawRent",
        value: value,
      },
    });
  };
  // function getExtension(filename) {
  //   const parts = filename.split('.');
  //   return parts.length > 1 ? parts.pop() : '';
  // }
  function getExtension(filename) {
    // Split the filename at the last period
    const parts = filename?.split(".");

    // Check if there's at least one extension part (including underscore)
    if (parts?.length > 1) {
      // Get the last part (extension)
      const extension = parts?.pop();

      // Split the extension at the underscore
      const extensionParts = extension.split("_");

      // Return the part before the underscore (if it exists)
      return extensionParts.length > 0 ? extensionParts[0] : "";
    }
    return "";
  }
  // const parkingLawRentes = (event) => {
  //   const value = event.target.value?.replace(/[^0-9]/g, ""); // Remove non-numeric characters
  //   const formattedValue =
  //     value !== "" ? `$${parseFloat(value).toLocaleString()}` : ""; // Format with dollar sign and commas
  //   formik.handleChange({
  //     target: {
  //       name: "parkingLawRent",
  //       value: formattedValue,
  //     },
  //   });
  // };

  const parkingLawRentes = (event) => {
    let value = event.target.value;
    let numericValue = value?.replace(/[^0-9.]/g, ""); // Remove non-numeric and non-decimal characters
    let parts = numericValue?.split("."); // Split into integer and decimal parts

    if (parts?.length > 1) {
      // If there is a decimal point
      let integerPart = parts[0];
      let decimalPart = parts[1];

      // Ensure only two decimal places are allowed
      if (decimalPart.length > 2) {
        decimalPart = decimalPart?.slice(0, 2);
      }

      // Reconstruct the value with formatting
      value = `$${integerPart?.replace(
        /\B(?=(\d{3})+(?!\d))/g,
        ","
      )}.${decimalPart}`;
    } else {
      // No decimal point, format as an integer
      value = `$${numericValue?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
    }

    formik.handleChange({
      target: {
        name: "parkingLawRent",
        value: value,
      },
    });
  };

  // const parkingLawRentes = (event) => {
  //   let value = event.target.value; // Remove non-numeric and non-decimal characters
  //   console.log(value?.[value.length - 1]);
  //   if (value?.[value.length - 1] === ".") {
  //     formik.handleChange({
  //       target: {
  //         name: "parkingLawRent",
  //         value: value,
  //       },
  //     });
  //     return;
  //   }
  //   value = event.target.value?.replace(/[^0-9.]/g, "");
  //   const formattedValue =
  //     value !== "" ? `$${parseFloat(value).toLocaleString()}` : ""; // Format with dollar sign and commas
  //   formik.handleChange({
  //     target: {
  //       name: "parkingLawRent",
  //       value: formattedValue,
  //     },
  //   });
  // };
  console.log("Formik ==>", formik?.values?.parkingLawRent);
  const belowRentes = (event) => {
    // const value = event.target.value?.replace(/[^0-9]/g, ""); // Remove non-numeric characters
    // const formattedValue =
    //   value !== "" ? `$${parseFloat(value).toLocaleString()}` : ""; // Format with dollar sign and commas
    // formik.handleChange({
    //   target: {
    //     name: "belowRent",
    //     value: formattedValue,
    //   },
    // });
    let value = event.target.value;
    let numericValue = value?.replace(/[^0-9.]/g, ""); // Remove non-numeric and non-decimal characters
    let parts = numericValue?.split("."); // Split into integer and decimal parts

    if (parts?.length > 1) {
      // If there is a decimal point
      let integerPart = parts[0];
      let decimalPart = parts[1];

      // Ensure only two decimal places are allowed
      if (decimalPart.length > 2) {
        decimalPart = decimalPart?.slice(0, 2);
      }

      // Reconstruct the value with formatting
      value = `$${integerPart?.replace(
        /\B(?=(\d{3})+(?!\d))/g,
        ","
      )}.${decimalPart}`;
    } else {
      // No decimal point, format as an integer
      value = `$${numericValue?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
    }

    formik.handleChange({
      target: {
        name: "belowRent",
        value: value,
      },
    });
  };

  const discountPayablees = (event) => {
    const value = event.target.value?.replace(/[^0-9]/g, ""); // Remove non-numeric characters
    const formattedValue =
      value !== "" ? `$${parseFloat(value).toLocaleString()}` : ""; // Format with dollar sign and commas
    formik.handleChange({
      target: {
        name: "discountPayable",
        value: formattedValue,
      },
    });
  };
  const legalRentes = (event) => {
    const value = event.target.value?.replace(/[^0-9]/g, ""); // Remove non-numeric characters
    const formattedValue =
      value !== "" ? `$${parseFloat(value).toLocaleString()}` : ""; // Format with dollar sign and commas
    formik.handleChange({
      target: {
        name: "legalRent",
        value: formattedValue,
      },
    });
  };
  const calculateSumAndSetPrior = () => {
    const lmrValue =
      parseFloat(formik.values.lmr?.replace(/[^0-9.]/g, "")) || 0;
    const proAmountRentValue =
      parseFloat(formik.values.netPayable?.replace(/[^0-9.]/g, "")) || 0;
    const proAmountValue =
      parseFloat(formik.values.proAmount?.replace(/[^0-9.]/g, "")) || 0;

    // Calculate the sum of values
    const sum = lmrValue + proAmountRentValue + proAmountValue;

    // Format the sum with commas and a dollar sign
    const formattedSum = `$${sum
      .toFixed(2)
      ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;

    // Update the Prior field with the formatted sum
    formik.setFieldValue("Prior", formattedSum);
  };

  const handleBlur = () => {
    const { lawRent, parkingLawRent, belowRent, discountPayable } =
      formik.values;

    // Parse input values to numeric values
    const lawRentValue = parseFloat(lawRent?.replace(/[^0-9.]/g, "")) || 0;
    const parkingLawRentValue =
      parseFloat(parkingLawRent?.replace(/[^0-9.]/g, "")) || 0;
    const belowRentValue = parseFloat(belowRent?.replace(/[^0-9.]/g, "")) || 0;

    // Calculate the sum of the numeric values
    const total = lawRentValue + parkingLawRentValue + belowRentValue;

    // Calculate 2% of total as a numeric value
    const discount = total * 0.02;

    // Calculate netPayable by subtracting discountPayable from legalRent
    const netPayable = (total - discount).toFixed(2);

    // Format the values with commas and a dollar sign
    const formattedTotal = `$${total
      .toFixed(2)
      ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
    const formattedDiscount = `$${discount
      .toFixed(2)
      ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
    const formattedNetPayable = `$${netPayable?.replace(
      /\B(?=(\d{3})+(?!\d))/g,
      ","
    )}`;

    // Update the disabled fields with the formatted values
    formik.setFieldValue("legalRent", formattedTotal);
    formik.setFieldValue("discountPayable", formattedDiscount);
    formik.setFieldValue("netPayable", formattedNetPayable);

    // Update the sum
    setSum(total);
  };

  useEffect(() => {
    const legalRentValue =
      parseFloat(formik.values.legalRent?.replace(/[^0-9.]/g, "")) || 0;
    const perDiemRate = (legalRentValue * 12) / 365;

    // Format the Per Diem rate with commas and a dollar sign
    const formattedPerDiemRate = `$${perDiemRate
      .toFixed(2)
      ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;

    // Update the perDiem field with the formatted value
    formik.setFieldValue("perDiem", formattedPerDiemRate);
  }, [formik.values.legalRent, proStartDate, proEndDate]); // The empty dependency array ensures this effect runs only once on component mount

  useEffect(() => {
    // Calculate the initial sum of values
    const lmrValue =
      parseFloat(formik.values.lmr?.replace(/[^0-9.]/g, "")) || 0;
    const proAmountRentValue =
      parseFloat(formik.values.netPayable?.replace(/[^0-9.]/g, "")) || 0;
    const proAmountValue =
      parseFloat(formik.values.proAmount?.replace(/[^0-9.]/g, "")) || 0;
    const initialSum = lmrValue + proAmountRentValue + proAmountValue;

    // Format the initial sum with commas and a dollar sign
    const formattedInitialSum = `$${initialSum
      .toFixed(2)
      ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;

    // Check if legalRent is empty
    if (!formik.values.legalRent) {
      formik.setFieldValue("lmr", ""); // Clear "lmr" if legalRent is empty
      formik.setFieldValue("proAmountRent", "");
      formik.setFieldValue("perDiem", "");
    } else {
      formik.setFieldValue("lmr", formik.values.legalRent);
      formik.setFieldValue("proAmountRent", formik.values.legalRent);
    }

    // formik.setFieldValue("lmr", formik.values.legalRent);
    // formik.setFieldValue("proAmountRent", formik.values.legalRent);

    formik.setFieldValue("Prior", formattedInitialSum);
  }, [
    // formik.values.lmr,
    formik.values.legalRent,
    // formik.values.proAmountRent,
    // formik.values.proAmount,
  ]); // The empty dependency array ensures this effect runs only once on component mount

  useEffect(() => {
    // Calculate the initial sum of values
    const lmrValue =
      parseFloat(formik.values.lmr?.replace(/[^0-9.]/g, "")) || 0;
    const proAmountRentValue =
      parseFloat(formik.values.netPayable?.replace(/[^0-9.]/g, "")) || 0;
    const proAmountValue =
      parseFloat(formik.values.proAmount?.replace(/[^0-9.]/g, "")) || 0;
    const initialSum = lmrValue + proAmountRentValue + proAmountValue;

    // Format the initial sum with commas and a dollar sign
    const formattedInitialSum = `$${initialSum
      .toFixed(2)
      ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;

    formik.setFieldValue("Prior", formattedInitialSum);
  }, [formik.values.lmr, formik.values.proAmountRent, formik.values.proAmount]); // The empty dependency array ensures this effect runs only once on component mount

  useEffect(() => {
    const perDiemValue =
      parseFloat(formik?.values?.perDiem?.replace("$", "")) || 0;
    const noDaysValue = parseFloat(formik?.values?.noDays) || 0;

    if (isNaN(perDiemValue) || isNaN(noDaysValue)) {
      // Handle invalid input, e.g., set a default value or display an error message.
    } else {
      const product = perDiemValue * noDaysValue;

      const newProduct = `$${product
        .toFixed(2)
        ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;

      formik?.setFieldValue("proAmount", newProduct.toString()); // Convert the product back to a string
    }
  }, [formik?.values?.perDiem, formik?.values?.noDays]);

  console.log("applicationStatus", formik.values.building);
  console.log("dsad", filesDataGov);

  function calculateTruncatedLength(str, maxLength) {
    let words = str.split(" ");
    let truncatedLength = 0;

    for (let i = 0; i < words?.length; i++) {
      truncatedLength += words[i]?.length;
      if (truncatedLength + (i + 1) * 3 > maxLength) {
        // Account for spaces
        return truncatedLength;
      }
    }

    return maxLength; // No truncation needed
  }

  console.log("typeSelected", typeSelected);
  return (
    <>
      <MainLayout>
        <section className="pageWrapper cardControl">
          <div
            className="contentCard"
            style={{
              minHeight: "850px",
            }}
          >
            {approvedDate && approvedDate ? (
              <h5 style={{ textAlign: "center", color: "#d97228" }}>
                {/* Note: Currently Its Under Development Mode */}
                <div>
                  <p style={{ fontSize: "1rem", fontWeight: "700" }}>
                    Approved on: &#160;
                    {moment(approvedDate).format("LLL")}
                  </p>
                  <p style={{ fontSize: "1rem", fontWeight: "700" }}>
                    Approved by: &nbsp;
                    {approvedBy == "property_manager"
                      ? "Property Manager"
                      : "Super Admin"}
                  </p>
                </div>
              </h5>
            ) : (
              ""
            )}

            <div className="titleWrapper">
              <span>Application</span>
            </div>

            <form
              // onSubmit={(e) => {
              //   e.preventDefault();
              //   console.log({ ERR: formik.errors });
              //   const isBuildingManagerSignatureProvided = !signPadBuildingManager.isEmpty();
              //   const isPropertyManagerSignatureProvided = !signPadPropertyManager.isEmpty();

              //   if (Object.values(formik.errors).length) {
              //     toast.error("Please Fill Required Fields");
              //     formik.setTouched({ ...formik.touched, ...formik.errors }); // Mark all fields as touched

              //   } else if (
              //     !newApplicants &&
              //     !assignments &&
              //     !sublets &&
              //     !transfers &&
              //     !nameChanges
              //   ) {
              //     toast.error("Please Select At lease one Application Type");
              //     // return;
              //   }
              //   else if (!isBuildingManagerSignatureProvided) {
              //     toast.error("Please sign the Document before proceeding", {
              //       position: "top-right",
              //     });
              //   } else if (!isPropertyManagerSignatureProvided) {
              //     toast.error("Please sign the Document before proceeding", {
              //       position: "top-right",
              //     });
              //   } else {
              //     formik.handleSubmit(e);
              //   }
              // }}
              onSubmit={(e) => {
                e.preventDefault();
                console.log("formik.errors:", formik.errors);
                const buildingmg = signPadBuildingManager?.isEmpty();
                const propmg = signPadPropertyManager?.isEmpty();
                // const buildingManagerSignatureIsEmpty = isSignatureEmpty(signPadBuildingManager);
                console.log("buildingmg", buildingmg);
                if (Object.values(formik.errors).length) {
                  toast.error("Please Fill Required Fields");
                  formik.setTouched({ ...formik.touched, ...formik.errors });
                }
                //  else if (
                //   !newApplicants &&
                //   !assignments &&
                //   !sublets &&
                //   !transfers &&
                //   !nameChanges
                // ) {
                //   console.log("No application type selected");
                //   toast.error("Please Select At Least One Application Type");
                // }
                // else if (buildingmg) {
                //   console.log("Building manager signature is empty");
                //   toast.error(
                //     "Please sign the Building Manager's signature before proceeding",
                //     {
                //       position: "top-right",
                //     }
                //   );
                //   return;
                // }
                //  else if (propmg) {
                //   console.log("Property manager signature is empty");
                //   toast.error(
                //     "Please sign the Property Manager's signature before proceeding",
                //     {
                //       position: "top-right",
                //     }
                //   );
                //   return;
                // }
                else {
                  console.log("Submitting the form");
                  formik.handleSubmit(e); // Only submit the form if all checks are passed
                }
              }}
            >
              {/* top 3 fields of tenent contact information */}
              <div className="custom-tabs-handling">
                {/* <Tabs activeKey={activeTab} onChange={handleTabChange}>
                 
                 
                  
                </Tabs> */}

                {/* {["Application", "Applicant Particular", "Credit Information Agreement", "No Cosmetic Repair Agreement", "Upload Files"].map((t) => {
                  return (
                    <button
                      type="button"
                      key={t}
                      onClick={() => setActiveTab(t)}
                    >
                      {t}
                    </button>
                  );
                })} */}
                {/* {activeTab} */}
                <div
                  className="tab-container"
                  style={{ justifyContent: "center" }}
                >
                  {[
                    "1: Application",
                    "2: Applicant Particulars",
                    "3: Credit Information Agreement",
                    "4: No Cosmetic Repair Agreement",
                    "5: Upload Files",
                  ].map((t) => (
                    <button
                      type="button"
                      key={t}
                      onClick={() => setActiveTab(t)}
                      className={`tab-button ${
                        emailStatus != "SENT" &&
                        emailStatus !== "OPENED" &&
                        activeTab != t
                          ? "disable-color"
                          : ""
                      } ${activeTab === t ? "active" : ""} 
                    
                      `}
                      disabled={
                        emailStatus == "SENT" || emailStatus == "OPENED"
                          ? false
                          : true
                      }
                    >
                      {t}
                    </button>
                  ))}
                </div>

                <div
                  className="tab-content"
                  tab="1: Application"
                  key="1: Application"
                  style={{
                    display: activeTab === "1: Application" ? "block" : "none",
                  }}
                >
                  <section className="Application-tab" ref={listingRef1}>
                    {/* {showLogo ? (
                      <div
                        className="logoWrapper mb-5"
                        style={{ backgroundColor: "white" }}
                      >
                        <img
                          className="logo"
                          src={Logo}
                          alt=""
                          style={{ width: "15rem", height: "auto" }}
                        />
                      </div>
                    ) : (
                      ""
                    )} */}
                    <div
                      className="logoWrapperss mb-2 mt-0 d-none print-only-block"
                      style={{ backgroundColor: "white" }}
                    >
                      <img
                        className="logo"
                        src={Logo}
                        alt=""
                        style={{ width: "15rem", height: "auto" }}
                      />
                    </div>

                    <div className="row outside-top">
                      <div className="col-xl-2 col-lg-4 mt-3 print-only-four-section margin-top-zero ">
                        <label className="my-custom-height">
                          Tenant Contact Name
                        </label>
                        <input
                          className={
                            "form-control " +
                            (formik.errors.tenentContactName &&
                            formik.touched.tenentContactName
                              ? " is-invalid"
                              : "")
                          }
                          name="tenentContactName"
                          placeholder="Tenant Contact Name"
                          value={formik?.values?.tenentContactName}
                          onChange={formik?.handleChange}
                          type="text"
                          autoComplete="new-password" // Disable autofill and autocomplete suggestions
                          onInput={(event) => {
                            // Prevent entering numbers
                            event.target.value = event.target.value.replace(
                              /[0-9]/g,
                              ""
                            );
                          }}
                          disabled={
                            applicationStatus == "SUBMITTED" ? true : false
                          }
                        />
                      </div>

                      <div className="col-xl-2 col-lg-4 mt-3 print-only-four-section margin-top-zero ">
                        <label className="my-custom-height">
                          Tenant Email Address
                        </label>
                        <input
                          className={
                            "form-control " +
                            (formik?.errors?.tenentEmailAddress &&
                            formik?.touched?.tenentEmailAddress
                              ? " is-invalid"
                              : "")
                          }
                          name="tenentEmailAddress"
                          placeholder="Tenant Email Address"
                          value={formik?.values?.tenentEmailAddress}
                          onChange={formik?.handleChange}
                          type="text"
                          autoComplete="new-password" // Disable autofill and autocomplete suggestions
                          disabled={
                            applicationStatus == "SUBMITTED" ? true : false
                          }
                        />
                        {/* <div className="invalid-feedback">
                          {formik.errors.tenentEmailAddress}
                        </div> */}
                        {formik?.values?.tenentEmailAddress &&
                          !isValidEmail(formik?.values?.tenentEmailAddress) && (
                            <div
                              className="error-message"
                              style={{ color: "red" }}
                            >
                              Invalid email address
                            </div>
                          )}
                      </div>

                      <div className="col-xl-2 col-lg-4 mt-3 print-only-four-section margin-top-zero ">
                        <label className="my-custom-height">
                          Tenant Phone Number
                        </label>
                        {/* <input
                          className={
                            "form-control " +
                            (formik?.errors.tenentPhoneNumber &&
                            formik?.touched.tenentPhoneNumber
                              ? " is-invalid"
                              : "")
                          }
                          name="tenentPhoneNumber"
                          placeholder="Tenant Phone Number"
                          value={formik?.values?.tenentPhoneNumber}
                          onChange={formik?.handleChange}
                          type="text"
                          onKeyPress={handleKeyPress}
                          autoComplete="new-password" // Disable autofill and autocomplete suggestions
                        /> */}

                        <ReactInputMask
                          mask="(999) 999-9999"
                          value={formik?.values?.tenentPhoneNumber}
                          placeholder="Tenant Phone Number"
                          name="tenentPhoneNumber"
                          className={
                            "form-control " +
                            (formik?.errors.tenentPhoneNumber &&
                            formik?.touched.tenentPhoneNumber
                              ? " is-invalid"
                              : "")
                          }
                          onChange={formik?.handleChange}
                          disabled={
                            applicationStatus == "SUBMITTED" ? true : false
                          }
                        />
                      </div>

                      {/* <div className="row outside-top"> */}
                      <div className="col-xl-2 col-lg-6 mt-3 print-only-four-section margin-top-zero">
                        <label className="my-custom-height">Suite No</label>
                        <input
                          className={
                            "form-control " +
                            (formik?.errors.suite && formik?.touched.suite
                              ? " is-invalid"
                              : "")
                          }
                          name="suite"
                          placeholder="Suite No"
                          value={formik?.values?.suite}
                          onChange={formik?.handleChange}
                          type="text"
                          onKeyPress={handleKeyPress}
                          autoComplete="new-password" // Disable autofill and autocomplete suggestions
                          disabled={
                            applicationStatus == "SUBMITTED" ? true : false
                          }
                        />
                      </div>
                      <div className="col-xl-2 col-lg-4 mt-3 print-only-four-section margin-top-zero">
                        <div className="form-group">
                          <label>Unit Type</label>
                          <select
                            // className="form-control selectedTypeField"
                            className={
                              "form-control " +
                              (formik?.errors.selectedTypeField &&
                              formik?.touched.selectedTypeField
                                ? " is-invalid"
                                : "")
                            }
                            onChange={(e) => handleType(e)}
                            // value={formik?.values?.unit_type}
                            value={typeSelected}
                            disabled={
                              applicationStatus == "SUBMITTED" ? true : false
                            }
                            name="unit_type"
                          >
                            <option
                              className="d-none print-only-block"
                              value=""
                            >
                              {typeSelected}
                            </option>
                            <option disabled selected key={2121} value={""}>
                              Unit Type
                            </option>

                            {type &&
                              type.map((v, i) => {
                                return (
                                  <option key={i} value={v.value}>
                                    {v.label}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                      </div>
                      <div className="col-xl-2 col-lg-6 mt-3 print-only-four-section margin-top-zero">
                        <label className="my-custom-height">Building</label>
                        <select
                          className={
                            "form-control" +
                            (formik?.errors.building && formik?.touched.building
                              ? " is-invalid"
                              : "")
                          }
                          name="building"
                          // value={formik?.values?.building}
                          value={selectedBuilding}
                          // onChange={formik?.handleChange}
                          onChange={handleBuildingChange}
                          disabled={
                            applicationStatus == "SUBMITTED" ? true : false
                          }
                        >
                          <option className="d-none print-only-block" value="">
                            {selectedBuilding}
                          </option>
                          <option value="" disabled>
                            Select a building
                          </option>
                          {buildingList?.map((building, index) => (
                            <option key={index} value={building?.building_name}>
                              {building?.building_name}
                            </option>
                          ))}
                        </select>
                        {/* </div> */}
                      </div>

                      <div className="col-xl-2 col-lg-6 mt-3 print-only-four-section margin-top-zero d-none print-only-block">
                        <div className="form-group">
                          <label>Today Date</label>
                          <Space direction="vertical">
                            <DatePicker
                              className="form-control font-weight-print"
                              name="requested-date"
                              value={requestDate}
                              format={"MMMM DD YYYY"}
                              placeholder="Today Date"
                              onChange={handleRequestDate}
                              disabled={
                                applicationStatus == "SUBMITTED" ? true : false
                              }
                            />
                          </Space>
                        </div>
                      </div>
                    </div>
                    {/* calnder */}
                    <div className="row">
                      <div className="col-lg-6 mt-3 print-only-four-section margin-top-zero none-btn">
                        <div className="form-group">
                          <label>Today Date</label>
                          <Space direction="vertical">
                            <DatePicker
                              className="form-control font-weight-print"
                              name="requested-date"
                              value={requestDate}
                              format={"MMMM DD YYYY"}
                              placeholder="Today Date"
                              onChange={handleRequestDate}
                              disabled={
                                applicationStatus == "SUBMITTED" ? true : false
                              }
                            />
                          </Space>
                        </div>
                      </div>

                      {/* <div className="col-lg-6 mt-4  align-items-center custom-checkBoxes-color print-seventy">
                        <div
                        // className="d-flex"
                        // style={{
                        //   flexDirection: "column",
                        //   alignItems: "baseline",
                        // }}
                        >
                          <label>Application Type</label>
                          <div className="row  custom-checkBoxes-color">
                            <div
                              className={
                                "form-check shadow-sm mr-3 padding-control margin-r" +
                                (!newApplicant ? " is-invalid" : "")
                              }
                            >
                              <input
                                // className={`form-check-input ${!newApplicant && "is-invalid"}`}
                                className={
                                  "form-check-input" +
                                  (!newApplicant ? " is-invalid" : "")
                                }
                                type="radio"
                                checked={newApplicants}
                                value={newApplicants}
                                name="applicationType"
                                id="flexCheckDefault"
                                onChange={newApplicant}
                                disabled={
                                  applicationStatus == "SUBMITTED"
                                    ? true
                                    : false
                                }
                              />
                              <label
                                className="form-check-label"
                                for="flexCheckDefault"
                              >
                                New Application
                              </label>
                            </div>
                            <div className="form-check shadow-sm mr-3 padding-control margin-r">
                              <input
                                className="form-check-input"
                                type="radio"
                                checked={assignments}
                                value={assignments}
                                name="applicationType"
                                id="flexCheckDefault2"
                                onChange={assignment}
                                disabled={
                                  applicationStatus == "SUBMITTED"
                                    ? true
                                    : false
                                }
                              />
                              <label
                                className="form-check-label"
                                for="flexCheckDefault2"
                              >
                                Assignment
                              </label>
                            </div>
                            <div className="form-check shadow-sm mr-3 padding-control margin-r">
                              <input
                                className="form-check-input"
                                type="radio"
                                checked={sublets}
                                value={sublets}
                                name="applicationType"
                                id="flexCheckDefault3"
                                onChange={sublet}
                                disabled={
                                  applicationStatus == "SUBMITTED"
                                    ? true
                                    : false
                                }
                              />
                              <label
                                className="form-check-label"
                                for="flexCheckDefault3"
                              >
                                Sublet
                              </label>
                            </div>
                            <div className="form-check shadow-sm mr-3 padding-control margin-r">
                              <input
                                className="form-check-input"
                                type="radio"
                                checked={transfers}
                                value={transfers}
                                name="applicationType"
                                id="flexCheckDefault4"
                                onChange={transfer}
                                disabled={
                                  applicationStatus == "SUBMITTED"
                                    ? true
                                    : false
                                }
                              />
                              <label
                                className="form-check-label"
                                for="flexCheckDefault4"
                              >
                                Transfer
                              </label>
                            </div>
                            <div className="form-check shadow-sm mr-3 padding-control margin-r">
                              <input
                                className="form-check-input"
                                type="radio"
                                checked={nameChanges}
                                value={nameChanges}
                                name="applicationType"
                                id="flexCheckDefault5"
                                onChange={nameChange}
                                disabled={
                                  applicationStatus == "SUBMITTED"
                                    ? true
                                    : false
                                }
                              />
                              <label
                                className="form-check-label"
                                for="flexCheckDefault5"
                              >
                                Name Change
                              </label>
                            </div>
                          </div>
                        </div>
                      </div> */}
                    </div>
                    <div
                      className="tenant-application-header"
                      style={{
                        border: "2px solid #d97228",
                        paddingTop: "1rem",
                        paddingBottom: "1rem",
                      }}
                    >
                      {/* dyncamic of Tenent */}
                      <p
                        style={{
                          fontWeight: "700",
                          borderBottom: "2px solid #d97228",
                          paddingBottom: "1rem",
                          fontSize: "1.3rem",
                          textAlign: "center",
                          paddingLeft: "1rem",
                        }}
                      >
                        {/* It is clearly understood and agreed that no other persons
                      shall occupy the premises other than those identified
                      below */}
                        To Be Filled Out by Applicant
                      </p>
                      <div
                        className="cardTitleHome card-title h5 mt-3"
                        style={{ paddingLeft: "1rem" }}
                      >
                        {/* First, Middle & Last Names of "Bona Fide Tenant(s)" */}
                        First, Middle & Last Names of Responsible Lease
                        Holder(s)
                      </div>
                      {/* <div>
                {fields?.map((field, index) => (
                  <div className="row mt-3" key={index}>
                    <div className="col-lg-6 row">
                      <div className="col-lg-4 mt-3 mt-lg-0">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="First name of Tenant"
                          aria-label="First name"
                          name="firstName"
                          value={field?.firstName}
                          onChange={(event) => handleChangeTenent(index, event)}
                        />
                      </div>
                      <div className="col-lg-4 mt-3 mt-lg-0">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Middle name of Tenant"
                          aria-label="Middle name"
                          name="middleName"
                          value={field?.middleName}
                          onChange={(event) => handleChangeTenent(index, event)}
                        />
                      </div>
                      <div className="col-lg-4 mt-3 mt-lg-0">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Last name of Tenant"
                          aria-label="Last name"
                          name="lastName"
                          value={field?.lastName}
                          onChange={(event) => handleChangeTenent(index, event)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-2 mt-3 mt-lg-0">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Social Insurance Number of Tenant"
                        name="sin"
                        value={field?.sin}
                        onChange={(event) => handleChangeTenent(index, event)}
                        onKeyPress={handleKeyPress}
                      />
                    </div>
                    <div className="col-lg-3 mt-3 mt-lg-0">
                      <input
                        type="email"
                        className="form-control"
                        id="inputEmail4"
                        placeholder="Email Address of Tenant"
                        name="email"
                        value={field?.email}
                        onChange={(event) => handleChangeTenent(index, event)}
                      />
                    </div>
                    {index > 0 && (
                      <div className="col-lg-1 mt-2 mt-lg-0">
                        <button
                          type="button"
                          className="btn btn-outline-danger"
                          onClick={() => handleRemoveField(index)}
                        >
                          Remove
                        </button>
                      </div>
                    )}
                  </div>
                ))}

                <button
                  type="button"
                  className="btn btn-outline-danger add-btn mt-2"
                  onClick={handleAddField}
                  style={{ borderColor: "gray", color: "gray" }}
                >
                  + Add
                </button>
              </div> */}

                      <div style={{ paddingLeft: "1rem" }}>
                        {fields?.map((field, index) => (
                          <div className="row mt-3" key={index}>
                            {/* <div className="col-lg-6 row print-only-full"> */}
                            <div className="col-xl-2 col-lg-4 mt-3 mt-lg-0 print-only-signT">
                              <label className="my-custom-height">
                                First name of Tenant
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="First name of Tenant"
                                aria-label="First name"
                                name="firstName"
                                value={field?.firstName}
                                onChange={(event) =>
                                  handleChangeTenent(index, event)
                                }
                                disabled
                              />
                            </div>
                            <div className="col-xl-2 col-lg-4 mt-3 mt-lg-0 print-only-signT">
                              <label className="my-custom-height">
                                Middle name of Tenant
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Middle name of Tenant"
                                aria-label="Middle name"
                                name="middleName"
                                value={field?.middleName}
                                onChange={(event) =>
                                  handleChangeTenent(index, event)
                                }
                                disabled
                              />
                            </div>
                            <div className="col-xl-2 col-lg-4 mt-3 mt-lg-0 print-only-signT">
                              <label className="my-custom-height">
                                Last name of Tenant
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Last name of Tenant"
                                aria-label="Last name"
                                name="lastName"
                                value={field?.lastName}
                                onChange={(event) =>
                                  handleChangeTenent(index, event)
                                }
                                disabled
                              />
                            </div>
                            {/* </div> */}
                            <div className="col-xl-2 col-lg-4 mt-3 mt-lg-0 print-only-signTEmail">
                              <label className="my-custom-height">
                                Social Insurance Number{" "}
                                <span className="none-btn">of Tenant</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Social Insurance Number of Tenant"
                                name="sin"
                                value={field?.sin}
                                onChange={(event) =>
                                  handleChangeTenent(index, event)
                                }
                                onKeyPress={handleKeyPress}
                                disabled
                              />
                            </div>
                            <div className="col-xl-2 col-lg-4 mt-3 mt-lg-0 print-only-signTEmail">
                              <label className="my-custom-height">
                                Email Address of Tenant
                              </label>
                              <input
                                type="email"
                                className="form-control"
                                id="inputEmail4"
                                placeholder="Email Address of Tenant"
                                name="email"
                                value={field?.email}
                                onChange={(event) =>
                                  handleChangeTenent(index, event)
                                }
                                disabled
                              />
                            </div>
                            {index > 0 && (
                              <div
                                className="col-lg-2 mt-2 mt-lg-0 d-flex"
                                style={{ alignItems: "end" }}
                              >
                                <button
                                  type="button"
                                  className="btn btn-outline-danger none-btn"
                                  onClick={() => handleRemoveField(index)}
                                  disabled
                                >
                                  Remove
                                </button>
                              </div>
                            )}
                          </div>
                        ))}

                        <button
                          type="button"
                          className="btn btn-outline-danger add-btn mt-2 none-btn"
                          onClick={handleAddField}
                          style={{ borderColor: "gray", color: "gray" }}
                          disabled
                        >
                          + Add
                        </button>
                      </div>

                      {/* Dyncamic of occupant */}
                      <div
                        className="cardTitleHome card-title h5 mt-3"
                        style={{ paddingLeft: "1rem" }}
                      >
                        {/* First, Middle & Last Names of other Occupants */}
                        First, Middle & Last Names of Occupant(s)
                      </div>
                      <div style={{ paddingLeft: "1rem" }}>
                        {occupantFields?.map((field, index) => (
                          <div className="row mt-3" key={index}>
                            {/* <div className="col-lg-6 row"> */}
                            <div className="col-xl-2 col-lg-4 mt-3 mt-lg-0 print-only-signT ">
                              <label className="my-custom-height">
                                First name of Occupant
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="First name of Occupant"
                                aria-label="First name"
                                name="firstNameOccupant"
                                value={field?.firstNameOccupant}
                                onChange={(event) =>
                                  handleChangeOccupant(index, event)
                                }
                                disabled
                              />
                            </div>
                            <div className="col-xl-2 col-lg-4 mt-3 mt-lg-0 print-only-signT ">
                              <label className="my-custom-height">
                                Middle name of Occupant
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Middle name of Occupant"
                                aria-label="Middle name"
                                name="middleNameOccupant"
                                value={field?.middleNameOccupant}
                                onChange={(event) =>
                                  handleChangeOccupant(index, event)
                                }
                                disabled
                              />
                            </div>
                            <div className="col-xl-2 col-lg-4 mt-3 mt-lg-0 print-only-signT ">
                              <label className="my-custom-height">
                                Last name of Occupant
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Last name of Occupant"
                                aria-label="Last name"
                                name="lastNameOccupant"
                                value={field?.lastNameOccupant}
                                onChange={(event) =>
                                  handleChangeOccupant(index, event)
                                }
                                disabled
                              />
                            </div>
                            {/* </div> */}
                            <div className="col-xl-2 col-lg-4 mt-3 mt-lg-0 print-only-signTEmail">
                              <label className="my-custom-height">
                                Age of Occupant
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Age of Occupant"
                                aria-label="Age"
                                name="sinOccupant"
                                value={field?.sinOccupant}
                                onChange={(event) =>
                                  handleChangeOccupant(index, event)
                                }
                                onKeyPress={handleKeyPress}
                                disabled
                              />
                            </div>
                            <div className="col-xl-2 col-lg-4 mt-3 mt-lg-0 print-only-signTEmail">
                              <label className="my-custom-height">
                                Email Address of Occupant
                              </label>
                              <input
                                type="email"
                                className="form-control"
                                id="inputEmail4"
                                placeholder="Email Address of Occupant"
                                name="emailOccupant"
                                value={field?.emailOccupant}
                                onChange={(event) =>
                                  handleChangeOccupant(index, event)
                                }
                                disabled
                              />
                            </div>
                            {index > 0 && (
                              <div
                                className="col-lg-1 mt-2 mt-lg-0 d-flex"
                                style={{ alignItems: "end" }}
                              >
                                <button
                                  type="button"
                                  className="btn btn-outline-danger none-btn"
                                  onClick={() =>
                                    handleRemoveFieldOcuupant(index)
                                  }
                                  disabled
                                >
                                  Remove
                                </button>
                              </div>
                            )}
                          </div>
                        ))}

                        <button
                          type="button"
                          className="btn btn-outline-danger add-btn mt-2 none-btn"
                          onClick={handleAddFieldOccupant}
                          style={{ borderColor: "gray", color: "gray" }}
                          disabled
                        >
                          + Add
                        </button>
                      </div>
                    </div>

                    {/* calnder */}
                    <p
                      className="margin-bottom-zero margin-top-zero "
                      style={{ marginTop: "25px", fontWeight: "600" }}
                    >
                      Note: Included utilities are heat and water. Hydro is
                      sub-metered and at the sole expense of the Tenant
                    </p>

                    <div
                      className="row margin-top-zero"
                      style={{ marginTop: "1.5rem" }}
                    >
                      <div className="col-lg-6 print-only-half">
                        <div className="form-group">
                          <label style={{ fontWeight: "600" }}>
                            Tenancy Start Date From (must be 1st of the month)
                          </label>

                          <Space direction="vertical">
                            <DatePicker
                              className="form-control font-weight-print"
                              value={tenentStartDate}
                              format={"MMMM DD YYYY"}
                              name="Tenancy-start"
                              onChange={handleTenencyStartDate}
                              disabled={
                                applicationStatus == "SUBMITTED" ? true : false
                              }
                              style={{ height: "3rem" }}
                            />
                          </Space>
                        </div>
                      </div>
                      <div className="col-lg-6 print-only-half">
                        <div className="form-group">
                          <label style={{ fontWeight: "600" }}>
                            Tenancy End Date (must be last day of the month)
                          </label>

                          <Space direction="vertical">
                            <DatePicker
                              className="form-control font-weight-print"
                              name="Tenancy-end"
                              value={tenentEndDate}
                              format={"MMMM DD YYYY"}
                              onChange={handleTenencyEndDate}
                              disabled={
                                applicationStatus == "SUBMITTED" ? true : false
                              }
                              style={{ height: "3rem" }}
                            />
                          </Space>
                        </div>
                      </div>
                    </div>
                    {/* Billing details */}
                    {/* <div className="cardTitleHome card-title h5 mt-2">
                        Billing details
                      </div> */}
                    {/* extra */}
                    <div className="input-group mb-3 d-lg-flex d-none print-only-block margin-bottom-zero">
                      <span
                        className="input-group-text col-lg-6 print-only-half "
                        id="basic-addon3"
                      >
                        Rental Amount
                      </span>
                      <span
                        className="input-group-text col-lg-3 print-only-four-section "
                        id="basic-addon3"
                      >
                        Lawfull Rent
                      </span>
                      {/* <span className="input-group-text col-lg-3" id="basic-addon3">
                  Rent Payable
                </span> */}
                      <span
                        className="input-group-text col-lg-3 print-only-four-section "
                        id="basic-addon3"
                      >
                        Last Month Rent Deposit “D”
                      </span>
                    </div>
                    {/* extra */}

                    {/* groups */}
                    <div className="groups-field margin-bottom-zero margin-top-zero">
                      <div className="input-group mb-3 margin-bottom-zero">
                        <span
                          className="input-group-text col-lg-6 print-only-half"
                          id="basic-addon3"
                        >
                          Rent
                        </span>
                        <input
                          className={
                            "form-control  col-lg-3 print-only" +
                            (formik?.errors.lawRent && formik?.touched.lawRent
                              ? " is-invalid"
                              : "")
                          }
                          name="lawRent"
                          // placeholder={`"A"`}
                          value={formik?.values?.lawRent}
                          placeholder="$"
                          // onChange={formik?.handleChange}
                          onChange={lawRentes}
                          type="text"
                          // onKeyPress={handleKeyPress}
                          autoComplete="new-password" // Disable autofill and autocomplete suggestions
                          disabled={
                            applicationStatus == "SUBMITTED" ? true : false
                          }
                          onBlur={handleBlur} // Add onBlur event handler
                        />
                        {/* <input
                    className={
                      "form-control col-lg-3" +
                      (formik?.errors.payAbleRent && formik?.touched.payAbleRent
                        ? " is-invalid"
                        : "")
                    }
                    name="payAbleRent"
                    placeholder={`"E"`}
                    value={formik?.values?.payAbleRent}
                    onChange={formik?.handleChange}
                    type="text"
                    onKeyPress={handleKeyPress}
                  /> */}
                      </div>
                      <div className="input-group mb-3 margin-bottom-zero">
                        <span
                          className="input-group-text col-lg-6 print-only-half"
                          id="basic-addon3"
                          style={{ height: "39px" }}
                        >
                          Parking Above Ground
                        </span>
                        <input
                          className={
                            "form-control col-lg-3 print-only" +
                            (formik?.errors.parkingLawRent &&
                            formik?.touched.parkingLawRent
                              ? " is-invalid"
                              : "")
                          }
                          name="parkingLawRent"
                          placeholder="$"
                          // placeholder={`"B"`}
                          value={formik?.values?.parkingLawRent}
                          // onChange={formik?.handleChange}
                          onChange={parkingLawRentes}
                          type="text"
                          // onKeyPress={handleKeyPress}
                          autoComplete="new-password" // Disable autofill and autocomplete suggestions
                          disabled={
                            applicationStatus == "SUBMITTED" ? true : false
                          }
                          onBlur={handleBlur} // Add onBlur event handler
                        />
                        {/* <input
                    className={
                      "form-control col-lg-3 " +
                      (formik?.errors.parkingPayAbleRent &&
                      formik?.touched.parkingPayAbleRent
                        ? " is-invalid"
                        : "")
                    }
                    name="parkingPayAbleRent"
                    placeholder={`"F"`}
                    value={formik?.values?.parkingPayAbleRent}
                    onChange={formik?.handleChange}
                    type="text"
                    onKeyPress={handleKeyPress}
                  /> */}
                        <p className="col-lg-3 mb-0">
                          {" "}
                          To be applied to the last month of the tenancy. Must
                          be equal to the amount stated in Box “D”.{" "}
                        </p>
                      </div>
                      <div className="input-group mb-3 margin-bottom-zero">
                        <span
                          className="input-group-text  col-lg-6 print-only-half"
                          id="basic-addon3"
                        >
                          Parking Below Ground
                        </span>
                        <input
                          className={
                            "form-control col-lg-3 print-only" +
                            (formik?.errors.belowRent &&
                            formik?.touched.belowRent
                              ? " is-invalid"
                              : "")
                          }
                          name="belowRent"
                          placeholder="$"
                          // placeholder={`"C"`}
                          value={formik?.values?.belowRent}
                          // onChange={formik?.handleChange}
                          onChange={belowRentes}
                          type="text"
                          // onKeyPress={handleKeyPress}
                          autoComplete="new-password" // Disable autofill and autocomplete suggestions
                          disabled={
                            applicationStatus == "SUBMITTED" ? true : false
                          }
                          onBlur={handleBlur} // Add onBlur event handler
                        />
                        {/* <input
                    className={
                      "form-control col-lg-3" +
                      (formik?.errors.belowPayAble &&
                      formik?.touched.belowPayAble
                        ? " is-invalid"
                        : "")
                    }
                    name="belowPayAble"
                    placeholder={`"G"`}
                    value={formik?.values?.belowPayAble}
                    onChange={formik?.handleChange}
                    type="text"
                    onKeyPress={handleKeyPress}
                  /> */}
                      </div>{" "}
                      <div className="input-group mb-3 margin-bottom-zero">
                        <span
                          className="input-group-text  col-lg-6 print-only-half"
                          id="basic-addon3"
                        >
                          Total Legal Rent
                        </span>
                        <input
                          className={
                            "form-control col-lg-3 print-only-four-section" +
                            (formik?.errors.legalRent &&
                            formik?.touched.legalRent
                              ? " is-invalid"
                              : "")
                          }
                          name="legalRent"
                          // placeholder={`"D"`}
                          placeholder="$"
                          value={formik?.values?.legalRent}
                          onChange={formik?.handleChange}
                          // onChange={legalRentes}
                          type="text"
                          onKeyPress={handleKeyPress}
                          autoComplete="new-password" // Disable autofill and autocomplete suggestions
                          // disabled={
                          //   applicationStatus == "SUBMITTED" ? true : false
                          // }
                          disabled
                        />
                        {/* <input
                    className={
                      "form-control col-lg-3" +
                      (formik?.errors.legalPayAble &&
                      formik?.touched.legalPayAble
                        ? " is-invalid"
                        : "")
                    }
                    name="legalPayAble"
                    placeholder={`"H"`}
                    value={formik?.values?.legalPayAble}
                    onChange={formik?.handleChange}
                    type="text"
                    onKeyPress={handleKeyPress}
                  /> */}
                        <input
                          className={
                            "form-control print-only-four-section" +
                            (formik?.errors.legalLast &&
                            formik?.touched.legalLast
                              ? " is-invalid"
                              : "")
                          }
                          name="legalLast"
                          placeholder="$"
                          // value={formik?.values?.legalLast}
                          value={formik?.values?.legalRent}
                          onChange={formik?.handleChange}
                          type="text"
                          onKeyPress={handleKeyPress}
                          disabled
                        />
                      </div>
                      <div className="input-group mb-3 margin-bottom-zero">
                        <span
                          className="input-group-text  col-lg-6 print-only-half"
                          id="basic-addon3"
                        >
                          Prompt Payment Discount (2% of Monthly Rent)
                        </span>

                        <input
                          className={
                            "form-control col-lg-3 print-only" +
                            (formik?.errors.discountPayable &&
                            formik?.touched.discountPayable
                              ? " is-invalid"
                              : "")
                          }
                          name="discountPayable"
                          // placeholder={`"E"`}
                          placeholder="$"
                          value={formik?.values?.discountPayable}
                          onChange={formik?.handleChange}
                          type="text"
                          onKeyPress={handleKeyPress}
                          autoComplete="new-password" // Disable autofill and autocomplete suggestions
                          // disabled={
                          //   applicationStatus == "SUBMITTED" ? true : false
                          // }
                          disabled
                        />
                      </div>{" "}
                      <div className="input-group mb-3 margin-bottom-zero">
                        <span
                          className="input-group-text  col-lg-6 print-only-half"
                          id="basic-addon3"
                        >
                          Net Rent payable on or Before the 1st day of each
                          Month
                        </span>
                        <input
                          className={
                            "form-control col-lg-3 print-only-four-section" +
                            (formik?.errors.netPayable &&
                            formik?.touched.netPayable
                              ? " is-invalid"
                              : "")
                          }
                          name="netPayable"
                          // placeholder={`"F"`}
                          placeholder="$"
                          value={formik?.values?.netPayable}
                          onChange={formik?.handleChange}
                          type="text"
                          onKeyPress={handleKeyPress}
                          autoComplete="new-password" // Disable autofill and autocomplete suggestions
                          // disabled={
                          //   applicationStatus == "SUBMITTED" ? true : false
                          // }
                          disabled
                        />
                      </div>
                    </div>
                    {/* <div className="cardTitleHome card-title h5 mt-2">
                        Pro Rates
                      </div> */}
                    {/* <div className="row"> */}
                    <div
                      className="row input-group mb-3 margin-bottom-zero col-12"
                      style={{ paddingRight: "0px" }}
                    >
                      <span
                        className="input-group-text col-lg-2 col-xl-2  font-control print-thirty"
                        style={{ fontSize: "10px !important" }}
                      >
                        Pro Rate Start Date
                      </span>
                      <DatePicker
                        className="form-control col-lg-2 col-xl-2 print-twn font-weight-print"
                        value={proStartDate}
                        name="pro-start"
                        format={"MMMM DD YYYY"}
                        onChange={ProRatesStart}
                        disabled={
                          applicationStatus == "SUBMITTED" ? true : false
                        }
                      />

                      <span
                        className="input-group-text col-lg-2 font-control  print-thirty "
                        id="basic-addon3"
                      >
                        Pro Rate End Date
                      </span>
                      <DatePicker
                        className="form-control col-lg-3 print-twn font-weight-print"
                        value={proEndDate}
                        name="pro-end"
                        onChange={ProRatesEnd}
                        disabledDate={disabledEndDate} // Use the custom disabledDate function
                        disabled={
                          applicationStatus == "SUBMITTED" ? true : false
                        }
                        format={"MMMM DD YYYY"}
                      />
                      {/* </div> */}
                      {/* <div className="input-group mb-3 col-12"> */}
                      <span
                        className="input-group-text col-lg-1 font-control print-fiveteen"
                        font-control
                      >
                        No. of Days
                      </span>
                      <input
                        className="form-control col-lg-2 print-fiveteen"
                        name="noDays"
                        placeholder="Days"
                        value={formik?.values?.noDays}
                        // onChange={formik?.handleChange}
                        onChange={(e) => {
                          // Ensure the entered value is within the desired range
                          const newValue = e.target.value;
                          if (newValue <= 31) {
                            formik?.handleChange(e);
                          }
                        }}
                        type="text"
                        max={31}
                        onKeyPress={handleKeyPress}
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                        // disabled={
                        //   applicationStatus == "SUBMITTED" ? true : false
                        // }
                        disabled
                      />
                      <span className="input-group-text  col-lg-2 font-control print-fiveteen">
                        Per Diem Rate
                      </span>
                      <input
                        className={"form-control col-lg-1 print-fiveteen"}
                        name="perDiem"
                        placeholder="$"
                        value={formik?.values?.perDiem}
                        // onChange={formik?.handleChange}
                        onChange={perDiemRates}
                        type="text"
                        onKeyPress={handleKeyPress}
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                        // disabled={
                        //   applicationStatus == "SUBMITTED" ? true : false
                        // }
                        disabled
                      />
                    </div>
                    {/* </div> */}
                    {/* No of Days */}
                    {/* <div className="row">
                <div className="input-group mb-3 col-12">
                  <span className="input-group-text col-lg-3">No. of Days</span>
                  <input
                    className={
                      "form-control " +
                      (formik?.errors.noDays && formik?.touched.noDays
                        ? " is-invalid"
                        : "")
                    }
                    name="noDays"
                    placeholder="Days"
                    value={formik?.values?.noDays}
                    onChange={formik?.handleChange}
                    type="text"
                    onKeyPress={handleKeyPress}
                  />
                  <span className="input-group-text  col-lg-3">
                    Per Diem Rate
                  </span>
                  <input
                    className={
                      "form-control " +
                      (formik?.errors.perDiem && formik?.touched.perDiem
                        ? " is-invalid"
                        : "")
                    }
                    name="perDiem"
                    placeholder="$"
                    value={formik?.values?.perDiem}
                    onChange={formik?.handleChange}
                    type="text"
                    onKeyPress={handleKeyPress}
                  />
                </div>
              </div> */}
                    {/* pro rate Amount */}
                    <div
                      className="row input-group mb-3 col-12 margin-bottom-zero"
                      style={{ paddingRight: "0px" }}
                    >
                      <span
                        className="input-group-text col-lg-2 font-control print-fiveteen"
                        id="basic-addon3"
                      >
                        Pro-Rate Amount
                      </span>
                      <input
                        className="form-control col-lg-2 print-fiveteen"
                        name="proAmount"
                        placeholder="$"
                        value={formik?.values?.proAmount}
                        // onChange={formik?.handleChange}
                        onChange={proAmountes}
                        onBlur={calculateSumAndSetPrior} // Add onBlur event handler
                        type="text"
                        onKeyPress={handleKeyPress}
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                        // disabled={
                        //   applicationStatus == "SUBMITTED" ? true : false
                        // }
                        disabled
                      />
                      <span
                        className="input-group-text col-lg-1 font-control print-fiveteen"
                        id="basic-addon3"
                      >
                        Rent
                      </span>
                      <input
                        className="form-control col-lg-2 print-fiveteen"
                        name="proAmountRent"
                        placeholder="$"
                        // value={formik?.values?.proAmountRent}
                        value={formik?.values?.netPayable}
                        // value={formik?.values?.legalRent}
                        // onChange={formik?.handleChange}
                        onChange={proAmountRentes}
                        onBlur={calculateSumAndSetPrior} // Add onBlur event handler
                        type="text"
                        onKeyPress={handleKeyPress}
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                        // disabled={
                        //   applicationStatus == "SUBMITTED" ? true : false
                        // }'
                        disabled
                      />
                      <span
                        className="input-group-text col-lg-1 font-control print-fiveteen"
                        id="basic-addon3"
                      >
                        LMR
                      </span>
                      <input
                        className="form-control col-lg-1 col-xl-2 print-fiveteen"
                        name="lmr"
                        placeholder="$"
                        value={formik?.values?.lmr}
                        // value={formik?.values?.legalRent}
                        // onChange={formik?.handleChange}
                        onChange={lmres}
                        type="text"
                        onKeyPress={handleKeyPress}
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                        // disabled={
                        //   applicationStatus == "SUBMITTED" ? true : false
                        // }
                        disabled
                      />
                      <span
                        className="input-group-text col-lg-3 col-xl-2 font-control print-only-four-section custom-padding-left  print-thirty margin-bottom-print"
                        id="basic-addon3"
                      >
                        Total Amount Prior to Move In
                      </span>
                      <input
                        className="form-control col-lg-1 print-fiveteen margin-bottom-print"
                        name="Prior"
                        placeholder="$"
                        value={formik?.values?.Prior}
                        // onChange={formik?.handleChange}
                        onChange={Priores}
                        type="text"
                        onKeyPress={handleKeyPress}
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                        // disabled={
                        //   applicationStatus == "SUBMITTED" ? true : false
                        // }
                        disabled
                      />
                    </div>

                    {/* signature */}
                    <div className="row ">
                      <div
                        id="canvas_Wraper"
                        className="col-lg-4 print-only"
                        onLoad={() => getDimension()}
                        style={{ position: "relative" }}
                      >
                        <div
                          className="mb-3 tenent-resolution"
                          // style={{ position: "relative" }}
                        >
                          <SignatureCanvas
                            ref={(ref) => signatureHandlerTenent(ref)}
                            penColor="grey"
                            canvasProps={{
                              className: "canvasSignature-tenants",
                              // width: `${divWidth ? divWidth : 220}`,
                              width: 400,

                              height: 150,
                            }}
                          />
                          <p
                            className="text-center mt-3"
                            style={{
                              borderTop: "1px solid black",
                              fontWeight: "500",
                            }}
                          >
                            Tenant Signature
                          </p>
                          <img
                            className="none-btn"
                            src={tenantView}
                            style={{
                              left: "50%",
                              minHeight: "6rem",
                              maxHeight: "6rem",
                              maxWidth: "12rem",

                              right: "50%",
                              transform: "translate(-50%, -50%)",
                              // bottom: "0.5rem",
                              bottom: "0rem",

                              position: "absolute",
                            }}
                          />
                          <img
                            className="d-none print-only-block"
                            src={tenantView}
                            style={{
                              left: "50%",
                              right: "50%",
                              minHeight: "6rem",
                              maxHeight: "6rem",
                              maxWidth: "12rem",

                              transform: "translate(-50%, -50%)",
                              // bottom: "0.5rem",
                              bottom: "0rem",

                              position: "absolute",
                            }}
                          />
                          {/* <img
                            className="d-none print-only-block"
                            src={tenantView}
                            style={{
                              position: "absolute",
                              bottom: "2rem",
                              width: "100%",
                              height: "7rem",
                            }}
                          /> */}
                        </div>
                      </div>
                      <div
                        id="canvas_Wraper"
                        // className="col-lg-4 print-only"
                        className={`col-lg-4 print-only ${
                          fields && fields.length >= 2 ? "visible" : "invisible"
                        }`}
                        onLoad={() => getDimension()}
                        style={{ position: "relative" }}
                      >
                        <div
                          className="mb-3 tenent-resolution"
                          // style={{ position: "relative" }}
                        >
                          <SignatureCanvas
                            ref={(ref) => signatureHandlerTenent2(ref)}
                            penColor="grey"
                            canvasProps={{
                              className: "canvasSignature-tenants",
                              // width: `${divWidth ? divWidth : 220}`,
                              width: 400,

                              height: 150,
                            }}
                          />
                          <p
                            className="text-center mt-3"
                            style={{
                              borderTop: "1px solid black",
                              fontWeight: "500",
                            }}
                          >
                            Tenant Signature
                          </p>
                          <img
                            className="none-btn"
                            src={tenantView2}
                            style={{
                              left: "50%",
                              right: "50%",
                              minHeight: "6rem",
                              maxHeight: "6rem",
                              maxWidth: "12rem",

                              transform: "translate(-50%, -50%)",
                              // bottom: "0.5rem",
                              bottom: "0rem",

                              position: "absolute",
                            }}
                          />
                          {/* <img
                            className="none-btn"
                            src={tenantView2}
                            style={{
                              position: "absolute",
                              bottom: "2rem",
                              width: "100%",
                              height: "7rem",
                            }}
                          /> */}
                          <img
                            className="d-none print-only-block"
                            src={tenantView2}
                            style={{
                              left: "50%",
                              right: "50%",
                              minHeight: "6rem",
                              maxHeight: "6rem",
                              maxWidth: "12rem",

                              transform: "translate(-50%, -50%)",
                              // bottom: "0.5rem",
                              bottom: "0rem",

                              position: "absolute",
                            }}
                          />
                          {/* <img
                            className="d-none print-only-block"
                            src={tenantView2}
                            style={{
                              position: "absolute",
                              bottom: "2rem",
                              width: "100%",
                              height: "7rem",
                            }}
                          /> */}
                        </div>
                      </div>

                      <div
                        id="canvas_Wraper"
                        // className="row canvasSignature"
                        // className="col-lg-4 print-only"
                        className={`col-lg-4 print-only ${
                          fields && fields.length === 3
                            ? "visible"
                            : "invisible"
                        }`}
                        onLoad={() => getDimension()}
                        style={{ position: "relative" }}
                      >
                        <div
                          className="building-manager-resolution mb-3"
                          style={
                            {
                              // position: "relative",
                              // textAlign: "end"
                            }
                          }
                        >
                          {" "}
                          <SignatureCanvas
                            ref={(ref) => signatureHandlerTenent3(ref)}
                            penColor="grey"
                            canvasProps={{
                              className: "canvasSignature-tenants",
                              // className: ` ${signatureSaved == true ? 'canvasSignature-tenants' : 'canvasSignature'}`,

                              width: 400,
                              height: 150,
                            }}
                          />
                          <p
                            className=" text-center mt-3"
                            style={{
                              borderTop: "1px solid black",
                              fontWeight: "500",
                            }}
                          >
                            Tenant Signature
                          </p>
                          <img
                            className="none-btn"
                            src={tenantView3}
                            style={{
                              left: "50%",
                              right: "50%",
                              minHeight: "6rem",
                              maxHeight: "6rem",
                              maxWidth: "12rem",

                              transform: "translate(-50%, -50%)",
                              // bottom: "0.5rem",

                              bottom: "0rem",
                              position: "absolute",
                            }}
                          />
                          <img
                            className="d-none print-only-block"
                            src={tenantView3}
                            style={{
                              left: "50%",
                              right: "50%",
                              minHeight: "6rem",
                              maxHeight: "6rem",
                              maxWidth: "12rem",
                              transform: "translate(-50%, -50%)",
                              bottom: "0.5rem",
                              position: "absolute",
                            }}
                          />
                          {/* <img
                            className="d-none print-only-block"
                            src={tenantView3}
                            style={{
                              position: "absolute",
                              bottom: "2rem",
                              width: "100%",
                              height: "7rem",
                            }}
                          /> */}
                        </div>
                      </div>

                      {/* manager signature */}
                      <div
                        id="canvas_Wraper1"
                        className="col-lg-4  invisible none-btn"
                        onLoad={() => getDimension1()}
                        style={{ position: "absolute" }}
                      >
                        <div
                          className="building-manager-resolution mb-3"
                          style={{ position: "relative", textAlign: "end" }}
                        >
                          <SignatureCanvas
                            ref={(ref) => signatureHandlerBuldingManager(ref)}
                            penColor="grey"
                            canvasProps={{
                              // className: "canvasSignature",
                              // width: `${divWidth1 ? divWidth1 : ""}`,
                              width: 400,
                              height: 150,
                            }}
                            // onEnd={handleSignatureChange}
                          />

                          <p
                            className=" text-center mt-3 ml-auto signs"
                            style={{
                              borderTop: "1px solid black",
                              fontWeight: "500",
                              textAlign: "center",
                            }}
                          >
                            Building Manager Signature
                          </p>
                          <img
                            className="d-none print-only-block"
                            src={buildingManagerView}
                            style={{
                              position: "absolute",
                              bottom: "2rem",
                              width: "100%",
                              height: "7rem",
                            }}
                          />

                          {/* <div className="clear-button" onClick={clearSignatureBM} style={{cursor:"pointer"}} >Clear Signature</div> */}

                          {/* {
              signatureExists  &&
              <div
              className="cross-button"
              onClick={clearSignatureBM}
              style={{
                position: "absolute",
                top: "33px",
                right: 0,
                background: "none",
                border: "none",
              }}
            >
              &#10005;
            </div>
            } */}
                        </div>
                      </div>

                      {/* prperty Manager Acceptance */}

                      <div
                        id="canvas_Wraper2"
                        className="col-lg-4  mb-3 invisible none-btn"
                        onLoad={() => getDimension2()}
                        style={{ position: "absolute" }}
                      >
                        <div
                          className="property-manager-resolution"
                          // style={{ alignItems: "end" }}
                        >
                          <div>
                            <SignatureCanvas
                              ref={(ref) =>
                                signatureHandlerPropertyManager(ref)
                              }
                              penColor="grey"
                              canvasProps={{
                                // className: "canvasSignature",
                                // width: `${divWidth2 ? divWidth2 : ""}`,
                                width: 400,

                                height: 150,
                              }}
                            />

                            <p
                              className="text-center mt-3"
                              style={{
                                borderTop: "1px solid black",
                                fontWeight: "500",
                              }}
                            >
                              Property Manager Signature
                            </p>
                            <img
                              className="d-none "
                              src={propertyManagerView}
                              style={{
                                position: "absolute",
                                bottom: "2.8rem",
                                width: "100%",
                                height: "7rem",
                              }}
                            />

                            {/* <div className="clear-button" onClick={clearSignaturePM} style={{cursor:"pointer"}} >Clear Signature</div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div
                      className="col-lg-6 building-manager-resolution mb-3 d-flex"
                      style={{ alignItems: "end" }}
                    >
                      <DatePicker
                    className="form-control canvasSignature "
                    name="agreementDate"
                    value={agreementDate}
                    placeholder="Agreement Date"
                    onChange={handleAgreementtDate}
                  />
                    </div> */}

                    <p className="text-center ">
                      <p style={{ fontWeight: "800", fontSize: "14px" }}>
                        Terms & Conditions
                      </p>
                      <p style={{ textAlign: "justify" }}>
                        The undersigned acknowledges and agrees that the
                        Landlord may accept or refuse this rental application in
                        the Landlord's sole discretion, after all of the usual
                        forms have been completed in full and approved. Then and
                        only then is a binding tenancy agreement created between
                        the Landlord and the tenant at which time the
                        undersigned shall forthwith enter into a tenancy
                        agreement in the Landlord's usual form of tenancy
                        agreement prior to the undersigned taking possession of
                        the premises in accordance with the terms and conditions
                        outlined above and, in the Landlord’s, usual form of
                        tenancy agreement. The tenant acknowledges that the
                        Landlord provided an opportunity to the tenant to
                        receive or review a blank copy of the Landlord's usual
                        form of tenancy agreement prior to the tenant completing
                        and signing this rental application. In the event that
                        the tenant refuses to sign the tenancy agreement after
                        this rental application has been accepted by the
                        Landlord, then the tenant hereby accepts all the terms
                        and conditions set out in the tenancy agreement and it
                        is hereby deemed that the tenant has signed the
                        Landlord's usual form of tenancy agreement and is bound
                        by such tenancy agreement as if the tenant had signed it
                        in any event. The tenant hereby further acknowledges to
                        having read and understood and agreed to all the terms
                        and conditions set out in this rental application as
                        well as the terms and conditions set out in the
                        Landlord's usual form of tenancy agreement. The
                        undersigned consents and authorizes the Landlord or its
                        authorized agents to obtain such information about the
                        undersigned as the Landlord may deem necessary at any
                        time in conjunction with the premises for which rental
                        application is hereby made, or any renewal or extension
                        thereof. The undersigned also consents to the disclosure
                        of any information concerning the undersigned to any
                        credit reporting agency or to any person with whom the
                        undersigned has or proposes to have financial relations.
                        I/We hereby declare that the information provided in
                        this application and in the tenancy, agreement is true
                        and correct and any false misrepresentation may lead to
                        charges for fraud or any other remedies available.{" "}
                      </p>
                      <p style={{ fontWeight: "800", fontSize: "14px" }}>
                        Terms and Conditions of the 2% Prompt Payment Discount
                      </p>
                      <p style={{ textAlign: "initial", marginTop: "2rem" }}>
                        The Tenant hereby acknowledges and understands that the
                        2% Prompt Payment discount will only be credited to the
                        Tenant based on fulfilling all of the below
                        requirements:
                      </p>

                      <ul style={{ marginBottom: "3rem", marginTop: "1rem" }}>
                        <li style={{ textAlign: "initial" }}>
                          The Tenant must have an account balance of $0.00 on or
                          before the first day of each month and;
                        </li>
                        <li style={{ textAlign: "initial" }}>
                          The Tenant must maintain suitable apartment insurance
                          at all times during the Tenancy.
                        </li>
                        <li style={{ textAlign: "initial" }}>
                          The Landlord has the right to remove the Tenants
                          eligibility of the 2% Prompt Payment Discount if:
                        </li>
                        <li style={{ textAlign: "initial" }}>
                          Any cheque is returned for whatever reason and/or;
                        </li>
                        <li style={{ textAlign: "initial" }}>
                          Proof of adequate Tenant Insurance is not maintained
                          during the life of the Tenancy or provided to the
                          Landlord upon request at the Landlords discretion.
                        </li>
                      </ul>
                    </p>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        position: "relative",
                        top: "-1rem",
                      }}
                    >
                      <p
                        style={{
                          fontWeight: "800",
                          fontSize: "14px",
                          textAlign: "center",
                        }}
                      >
                        Tenant Insurance
                      </p>
                      The undersigned understands and agrees that Tenant
                      apartment insurance is a mandatory requirement by the
                      Landlord. Proof of Tenant insurance must be provided prior
                      to the move in date. Possession of the unit can be
                      withheld until such time as Tenant Insurance is in place
                      and acceptable at the Landlord’s sole discretion.
                      {/* <span style={{ display: "flex", width: "6rem" ,justifyContent:"center",position:"absolute",top:"4rem"}}> */}
                      <span
                        className="print-margin-top-initial"
                        style={{
                          display: "flex",
                          width: "13rem",
                          gap: "1rem",
                          justifyContent: "center",
                        }}
                      >
                        <div>
                          <input
                            className="form-control"
                            name="initial_one"
                            value={formik?.values?.initial_one}
                            onChange={formik?.handleChange}
                            type="text"
                            maxLength={3} // Limit to one character
                            onBlur={formik.handleBlur}
                            autoComplete="new-password"
                            onInput={(event) => {
                              event.target.value =
                                event.target.value.toUpperCase();

                              // Allow only letters (uppercase or lowercase)
                              event.target.value = event.target.value.replace(
                                /[^a-zA-Z]/g,
                                ""
                              );
                            }}
                            disabled
                            style={{
                              // borderLeft: "none",
                              // borderRight: "none",
                              // borderTop: "none",
                              // marginRight: "1rem",
                              textAlign: "center",

                              width: "6rem",
                            }}
                          />
                          <span style={{ fontSize: "12px" }}>
                            Tenant(s) Initial
                          </span>
                        </div>
                        <div>
                          <input
                            // className="form-control print-only-four-section"
                            className={`form-control  ${
                              fields && fields.length >= 2
                                ? "visible"
                                : "invisible"
                            }`}
                            name="initial_two"
                            value={formik?.values?.initial_two}
                            onChange={formik?.handleChange}
                            type="text"
                            maxLength={3} // Limit to one character
                            onBlur={formik.handleBlur}
                            autoComplete="new-password"
                            onInput={(event) => {
                              event.target.value =
                                event.target.value.toUpperCase();

                              // Allow only letters (uppercase or lowercase)
                              event.target.value = event.target.value.replace(
                                /[^a-zA-Z]/g,
                                ""
                              );
                            }}
                            disabled
                            style={{
                              // borderLeft: "none",
                              // borderRight: "none",
                              // borderTop: "none",
                              // marginRight: "1rem",
                              textAlign: "center",

                              width: "6rem",
                            }}
                          />
                          <span
                            className={` ${
                              fields && fields.length >= 2
                                ? "visible"
                                : "invisible"
                            }`}
                            style={{ fontSize: "12px" }}
                          >
                            Tenant(s) Initial
                          </span>
                        </div>
                        {fields && fields.length === 3 && (
                          <div>
                            <input
                              // className="form-control "
                              className={`form-control  ${
                                fields && fields.length === 3
                                  ? "visible"
                                  : "invisible"
                              }`}
                              name="initial_three"
                              value={formik?.values?.initial_three}
                              onChange={formik?.handleChange}
                              type="text"
                              maxLength={3} // Limit to one character
                              onBlur={formik.handleBlur}
                              autoComplete="new-password"
                              onInput={(event) => {
                                event.target.value =
                                  event.target.value.toUpperCase();

                                // Allow only letters (uppercase or lowercase)
                                event.target.value = event.target.value.replace(
                                  /[^a-zA-Z]/g,
                                  ""
                                );
                              }}
                              disabled
                              style={{
                                // borderLeft: "none",
                                // borderRight: "none",
                                // borderTop: "none",
                                // marginRight: "1rem",
                                textAlign: "center",
                                width: "6rem",
                              }}
                            />
                            <span style={{ fontSize: "12px" }}>
                              Tenant(s) Initial
                            </span>
                          </div>
                        )}

                        {/* <input
                          className="form-control print-only-four-section"
                          name="initial_two"
                          value={formik?.values?.initial_two}
                          onChange={formik?.handleChange}
                          type="text"
                          maxLength={1} // Limit to one character
                          onBlur={formik.handleBlur}
                          autoComplete="new-password"
                          onInput={(event) => {
                            // Allow only letters (uppercase or lowercase)
                            event.target.value = event.target.value.replace(
                              /[^a-zA-Z]/g,
                              ""
                            );
                          }}
                          style={{ borderLeft: "none", borderRight: "none", borderTop: "none" }}

                        /> */}
                      </span>
                    </div>
                  </section>
                </div>

                <div
                  className="tab-content"
                  tab="2: Applicant Particulars"
                  key="2: Applicant Particulars"
                  style={{
                    display:
                      activeTab === "2: Applicant Particulars"
                        ? "block"
                        : "none",
                  }}
                >
                  <section
                    className="Applicant-particular seconed-page"
                    ref={listingRef2}
                  >
                    <h5 style={{ textAlign: "center", color: "#d97228" }}>
                      Note: These Fields only Tenant can Fill
                    </h5>
                    <div className="cardTitleHome card-title h5 mt-2">
                      Additional details
                    </div>
                    <div className="input-group mb-3 d-lg-flex d-none print-only-block margin-bottom-zero">
                      <span
                        className="input-group-text col-lg-3 print-only-four-section"
                        id="basic-addon3"
                      >
                        Detail
                      </span>
                      <span
                        className="input-group-text col-lg-3 print-only-four-section"
                        id="basic-addon3"
                      >
                        Applicant “One”
                      </span>
                      <span
                        className="input-group-text col-lg-3 print-only-four-section"
                        id="basic-addon3"
                      >
                        Applicant “Two”
                      </span>
                      <span
                        className="input-group-text col-lg-3 print-only-four-section"
                        id="basic-addon3"
                      >
                        Applicant “Three”{" "}
                      </span>
                    </div>

                    {/* name */}
                    <div className="input-group mb-3 margin-bottom-zero">
                      <span
                        className="input-group-text  col-lg-3 print-only-four-section"
                        id="basic-addon3"
                      >
                        Name
                      </span>
                      <input
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.name_applicant_one &&
                          formik?.touched.name_applicant_one
                            ? " is-invalid"
                            : "")
                        }
                        name={"name_applicant_one"}
                        value={formik?.values?.name_applicant_one}
                        onChange={formik?.handleChange}
                        type="text"
                        disabled
                      />
                      <input
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.name_applicant_two &&
                          formik?.touched.name_applicant_two
                            ? " is-invalid"
                            : "")
                        }
                        name={"name_applicant_two"}
                        value={formik?.values?.name_applicant_two}
                        onChange={formik?.handleChange}
                        type="text"
                        disabled
                      />
                      <input
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.name_applicant_three &&
                          formik?.touched.name_applicant_three
                            ? " is-invalid"
                            : "")
                        }
                        name={"name_applicant_three"}
                        value={formik?.values?.name_applicant_three}
                        onChange={formik?.handleChange}
                        type="text"
                        disabled
                      />
                    </div>

                    {/* dob */}

                    <div className="input-group mb-3 margin-bottom-zero">
                      <span
                        className="input-group-text  col-lg-3 print-only-four-section"
                        id="basic-addon3"
                      >
                        Date of Birth
                      </span>
                      {/* <input
                  className={
                    "form-control col-lg-3" +
                    (formik?.errors.date_of_birth_one &&
                    formik?.touched.date_of_birth_one
                      ? " is-invalid"
                      : "")
                  }
                  name={"date_of_birth_one"}
                  value={formik?.values?.date_of_birth_one}
                  onChange={formik?.handleChange}
                  type="text"
                  disabled
                />
                <input
                  className={
                    "form-control col-lg-3" +
                    (formik?.errors.date_of_birth_two &&
                    formik?.touched.date_of_birth_two
                      ? " is-invalid"
                      : "")
                  }
                  name={"date_of_birth_two"}
                  value={formik?.values?.date_of_birth_two}
                  onChange={formik?.handleChange}
                  type="text"
                  disabled
                />
                <input
                  className={
                    "form-control col-lg-3" +
                    (formik?.errors.date_of_birth_three &&
                    formik?.touched.date_of_birth_three
                      ? " is-invalid"
                      : "")
                  }
                  name={"date_of_birth_three"}
                  value={formik?.values?.date_of_birth_three}
                  onChange={formik?.handleChange}
                  type="text"
                  disabled
                /> */}
                      <DatePicker
                        className="form-control print-only-four-section font-weight-print"
                        value={dobApplicantOnes}
                        name="dobApplicantOne"
                        onChange={dobApplicantOne}
                        disabled
                      />
                      <DatePicker
                        className="form-control print-only-four-section font-weight-print"
                        value={dobApplicantTwos}
                        name="dobApplicantTwo"
                        onChange={dobApplicantTwo}
                        disabled
                      />{" "}
                      <DatePicker
                        className="form-control print-only-four-section font-weight-print"
                        value={dobApplicantThrees}
                        name="dobApplicantThree"
                        onChange={dobApplicantThree}
                        disabled
                      />
                    </div>

                    {/* Current Address */}

                    <div className="input-group mb-3 margin-bottom-zero">
                      <span
                        className="input-group-text print-only-four-section  col-lg-3"
                        id="basic-addon3"
                      >
                        Current Address
                      </span>

                      <textarea
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.current_address_one &&
                          formik?.touched.current_address_one
                            ? " is-invalid"
                            : "")
                        }
                        name={"current_address_one"}
                        value={formik?.values?.current_address_one}
                        onChange={formik?.handleChange}
                        type="text"
                        disabled
                        style={{
                          minHeight: "100px",
                          resize: "none",
                          width: "100%",
                          overflow: "hidden",
                        }}
                        onInput={(e) => {
                          e.target.style.height = "auto";
                          e.target.style.height = e.target.scrollHeight + "px";
                        }}
                        ref={(textarea) => {
                          if (textarea) {
                            textarea.style.height = "auto";
                            textarea.style.height =
                              textarea.scrollHeight + "px";
                          }
                        }}
                        // style={{ width: "auto" ,boxSizing: "content-box" }}
                      />
                      <textarea
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.current_address_two &&
                          formik?.touched.current_address_two
                            ? " is-invalid"
                            : "")
                        }
                        name={"current_address_two"}
                        value={formik?.values?.current_address_two}
                        onChange={formik?.handleChange}
                        type="text"
                        disabled
                        style={{
                          minHeight: "100px",
                          resize: "none",
                          width: "100%",
                          overflow: "hidden",
                        }}
                        onInput={(e) => {
                          e.target.style.height = "auto";
                          e.target.style.height = e.target.scrollHeight + "px";
                        }}
                        ref={(textarea) => {
                          if (textarea) {
                            textarea.style.height = "auto";
                            textarea.style.height =
                              textarea.scrollHeight + "px";
                          }
                        }}
                        // style={{ width: "auto" ,boxSizing: "content-box" }}
                      />
                      <textarea
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.current_address_three &&
                          formik?.touched.current_address_three
                            ? " is-invalid"
                            : "")
                        }
                        name={"current_address_three"}
                        value={formik?.values?.current_address_three}
                        onChange={formik?.handleChange}
                        type="text"
                        disabled
                        style={{
                          minHeight: "100px",
                          resize: "none",
                          width: "100%",
                          overflow: "hidden",
                        }}
                        onInput={(e) => {
                          e.target.style.height = "auto";
                          e.target.style.height = e.target.scrollHeight + "px";
                        }}
                        ref={(textarea) => {
                          if (textarea) {
                            textarea.style.height = "auto";
                            textarea.style.height =
                              textarea.scrollHeight + "px";
                          }
                        }}
                        // style={{ width: "auto" ,boxSizing: "content-box" }}
                      />
                    </div>

                    {/* Appartment No */}

                    <div className="input-group mb-3 margin-bottom-zero">
                      <span
                        className="input-group-text print-only-four-section  col-lg-3"
                        id="basic-addon3"
                      >
                        Apartment No.
                      </span>
                      <input
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.appartment_no_one &&
                          formik?.touched.appartment_no_one
                            ? " is-invalid"
                            : "")
                        }
                        name={"appartment_no_one"}
                        value={formik?.values?.appartment_no_one}
                        onChange={formik?.handleChange}
                        type="text"
                        disabled
                        onKeyPress={handleKeyPress}
                      />
                      <input
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.appartment_no_two &&
                          formik?.touched.appartment_no_two
                            ? " is-invalid"
                            : "")
                        }
                        name={"appartment_no_two"}
                        value={formik?.values?.appartment_no_two}
                        onChange={formik?.handleChange}
                        type="text"
                        disabled
                        onKeyPress={handleKeyPress}
                      />
                      <input
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.appartment_no_three &&
                          formik?.touched.appartment_no_three
                            ? " is-invalid"
                            : "")
                        }
                        name={"appartment_no_three"}
                        value={formik?.values?.appartment_no_three}
                        onChange={formik?.handleChange}
                        type="text"
                        disabled
                        onKeyPress={handleKeyPress}
                      />
                    </div>

                    {/* Present Monthly Rent */}

                    <div className="input-group mb-3 margin-bottom-zero">
                      <span
                        className="input-group-text print-only-four-section  col-lg-3"
                        id="basic-addon3"
                      >
                        Present Monthly Rent
                      </span>
                      <input
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.present_monthly_rent_one &&
                          formik?.touched.present_monthly_rent_one
                            ? " is-invalid"
                            : "")
                        }
                        name={"present_monthly_rent_one"}
                        value={formik?.values?.present_monthly_rent_one}
                        onChange={formik?.handleChange}
                        type="text"
                        disabled
                        onKeyPress={handleKeyPress}
                      />
                      <input
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.present_monthly_rent_two &&
                          formik?.touched.present_monthly_rent_two
                            ? " is-invalid"
                            : "")
                        }
                        name={"present_monthly_rent_two"}
                        value={formik?.values?.present_monthly_rent_two}
                        onChange={formik?.handleChange}
                        type="text"
                        disabled
                        onKeyPress={handleKeyPress}
                      />
                      <input
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.present_monthly_rent_three &&
                          formik?.touched.present_monthly_rent_three
                            ? " is-invalid"
                            : "")
                        }
                        name={"present_monthly_rent_three"}
                        value={formik?.values?.present_monthly_rent_three}
                        onChange={formik?.handleChange}
                        type="text"
                        disabled
                        onKeyPress={handleKeyPress}
                      />
                    </div>

                    {/* Postal Code  */}

                    <div className="input-group mb-3 margin-bottom-zero">
                      <span
                        className="input-group-text print-only-four-section  col-lg-3"
                        id="basic-addon3"
                      >
                        Postal Code
                      </span>
                      <input
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.postal_code_one &&
                          formik?.touched.postal_code_one
                            ? " is-invalid"
                            : "")
                        }
                        name={"postal_code_one"}
                        value={formik?.values?.postal_code_one}
                        onChange={formik?.handleChange}
                        type="text"
                        disabled
                        onKeyPress={handleKeyPress}
                      />
                      <input
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.postal_code_two &&
                          formik?.touched.postal_code_two
                            ? " is-invalid"
                            : "")
                        }
                        name={"postal_code_two"}
                        value={formik?.values?.postal_code_two}
                        onChange={formik?.handleChange}
                        type="text"
                        disabled
                        onKeyPress={handleKeyPress}
                      />
                      <input
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.postal_code_three &&
                          formik?.touched.postal_code_three
                            ? " is-invalid"
                            : "")
                        }
                        name={"postal_code_three"}
                        value={formik?.values?.postal_code_three}
                        onChange={formik?.handleChange}
                        type="text"
                        disabled
                        onKeyPress={handleKeyPress}
                      />
                    </div>

                    {/* Length of Residence  */}

                    <div className="input-group mb-3 margin-bottom-zero">
                      <span
                        className="input-group-text print-only-four-section  col-lg-3"
                        id="basic-addon3"
                      >
                        Length of Residence
                      </span>
                      <input
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.length_of_residence_one &&
                          formik?.touched.length_of_residence_one
                            ? " is-invalid"
                            : "")
                        }
                        name={"length_of_residence_one"}
                        value={formik?.values?.length_of_residence_one}
                        onChange={formik?.handleChange}
                        type="text"
                        disabled
                      />
                      <input
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.length_of_residence_two &&
                          formik?.touched.length_of_residence_two
                            ? " is-invalid"
                            : "")
                        }
                        name={"length_of_residence_two"}
                        value={formik?.values?.length_of_residence_two}
                        onChange={formik?.handleChange}
                        type="text"
                        disabled
                      />
                      <input
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.length_of_residence_three &&
                          formik?.touched.length_of_residence_three
                            ? " is-invalid"
                            : "")
                        }
                        name={"length_of_residence_three"}
                        value={formik?.values?.length_of_residence_three}
                        onChange={formik?.handleChange}
                        type="text"
                        disabled
                      />
                    </div>

                    {/* Home Phone No.  */}

                    <div className="input-group mb-3 margin-bottom-zero">
                      <span
                        className="input-group-text print-only-four-section  col-lg-3"
                        id="basic-addon3"
                      >
                        Home Phone No.
                      </span>
                      {/* <input
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.home_phone_no_one &&
                          formik?.touched.home_phone_no_one
                            ? " is-invalid"
                            : "")
                        }
                        name={"home_phone_no_one"}
                        value={formik?.values?.home_phone_no_one}
                        onChange={formik?.handleChange}
                        type="text"
                        disabled
                        onKeyPress={handleKeyPress}
                      /> */}
                      <ReactInputMask
                        mask="(999) 999-9999"
                        value={formik?.values?.home_phone_no_one}
                        name={"home_phone_no_one"}
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.home_phone_no_one &&
                          formik?.touched.home_phone_no_one
                            ? " is-invalid"
                            : "")
                        }
                        onChange={formik?.handleChange}
                        disabled
                      />
                      {/* <input
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.home_phone_no_two &&
                          formik?.touched.home_phone_no_two
                            ? " is-invalid"
                            : "")
                        }
                        name={"home_phone_no_two"}
                        value={formik?.values?.home_phone_no_two}
                        onChange={formik?.handleChange}
                        type="text"
                        disabled
                        onKeyPress={handleKeyPress}
                      /> */}
                      <ReactInputMask
                        mask="(999) 999-9999"
                        value={formik?.values?.home_phone_no_two}
                        name={"home_phone_no_two"}
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.home_phone_no_two &&
                          formik?.touched.home_phone_no_two
                            ? " is-invalid"
                            : "")
                        }
                        onChange={formik?.handleChange}
                        disabled
                      />
                      {/* <input
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.home_phone_no_three &&
                          formik?.touched.home_phone_no_three
                            ? " is-invalid"
                            : "")
                        }
                        name={"home_phone_no_three"}
                        value={formik?.values?.home_phone_no_three}
                        onChange={formik?.handleChange}
                        type="text"
                        disabled
                        onKeyPress={handleKeyPress}
                      /> */}
                      <ReactInputMask
                        mask="(999) 999-9999"
                        value={formik?.values?.home_phone_no_three}
                        name={"home_phone_no_three"}
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.home_phone_no_three &&
                          formik?.touched.home_phone_no_three
                            ? " is-invalid"
                            : "")
                        }
                        onChange={formik?.handleChange}
                        disabled
                      />
                    </div>

                    {/* Cell Phone No.  */}

                    <div className="input-group mb-3 margin-bottom-zero">
                      <span
                        className="input-group-text print-only-four-section  col-lg-3"
                        id="basic-addon3"
                      >
                        Cell Phone No.
                      </span>
                      {/* <input
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.cell_phone_no_one &&
                          formik?.touched.cell_phone_no_one
                            ? " is-invalid"
                            : "")
                        }
                        name={"cell_phone_no_one"}
                        value={formik?.values?.cell_phone_no_one}
                        onChange={formik?.handleChange}
                        type="text"
                        disabled
                        onKeyPress={handleKeyPress}
                      /> */}

                      <ReactInputMask
                        mask="(999) 999-9999"
                        value={formik?.values?.cell_phone_no_one}
                        name={"cell_phone_no_one"}
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.cell_phone_no_one &&
                          formik?.touched.cell_phone_no_one
                            ? " is-invalid"
                            : "")
                        }
                        onChange={formik?.handleChange}
                        disabled
                      />
                      {/* <input
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.cell_phone_no_two &&
                          formik?.touched.cell_phone_no_two
                            ? " is-invalid"
                            : "")
                        }
                        name={"cell_phone_no_two"}
                        value={formik?.values?.cell_phone_no_two}
                        onChange={formik?.handleChange}
                        type="text"
                        disabled
                        onKeyPress={handleKeyPress}
                      /> */}

                      <ReactInputMask
                        mask="(999) 999-9999"
                        value={formik?.values?.cell_phone_no_two}
                        name={"cell_phone_no_two"}
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.cell_phone_no_two &&
                          formik?.touched.cell_phone_no_two
                            ? " is-invalid"
                            : "")
                        }
                        onChange={formik?.handleChange}
                        disabled
                      />
                      {/* 
                      <input
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.cell_phone_no_three &&
                          formik?.touched.cell_phone_no_three
                            ? " is-invalid"
                            : "")
                        }
                        name={"cell_phone_no_three"}
                        value={formik?.values?.cell_phone_no_three}
                        onChange={formik?.handleChange}
                        type="text"
                        disabled
                        onKeyPress={handleKeyPress}
                      /> */}
                      <ReactInputMask
                        mask="(999) 999-9999"
                        value={formik?.values?.cell_phone_no_three}
                        name={"cell_phone_no_three"}
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.cell_phone_no_three &&
                          formik?.touched.cell_phone_no_three
                            ? " is-invalid"
                            : "")
                        }
                        onChange={formik?.handleChange}
                        disabled
                      />
                    </div>

                    {/* If Renting, Landlords Name  */}

                    <div className="input-group mb-3 margin-bottom-zero">
                      <span
                        className="input-group-text print-only-four-section  col-lg-3"
                        id="basic-addon3"
                      >
                        If Renting, Landlords Name
                      </span>
                      <input
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.landlords_name_one &&
                          formik?.touched.landlords_name_one
                            ? " is-invalid"
                            : "")
                        }
                        name={"landlords_name_one"}
                        value={formik?.values?.landlords_name_one}
                        onChange={formik?.handleChange}
                        type="text"
                        disabled
                      />
                      <input
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.landlords_name_two &&
                          formik?.touched.landlords_name_two
                            ? " is-invalid"
                            : "")
                        }
                        name={"landlords_name_two"}
                        value={formik?.values?.landlords_name_two}
                        onChange={formik?.handleChange}
                        type="text"
                        disabled
                      />
                      <input
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.landlords_name_three &&
                          formik?.touched.landlords_name_three
                            ? " is-invalid"
                            : "")
                        }
                        name={"landlords_name_three"}
                        value={formik?.values?.landlords_name_three}
                        onChange={formik?.handleChange}
                        type="text"
                        disabled
                      />
                    </div>

                    {/* If Renting, Landlords phone  */}

                    <div className="input-group mb-3 margin-bottom-zero">
                      <span
                        className="input-group-text print-only-four-section  col-lg-3"
                        id="basic-addon3"
                      >
                        If Renting, Landlords Name
                      </span>
                      <input
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.landlords_phone_one &&
                          formik?.touched.landlords_phone_one
                            ? " is-invalid"
                            : "")
                        }
                        name={"landlords_phone_one"}
                        value={formik?.values?.landlords_phone_one}
                        onChange={formik?.handleChange}
                        type="text"
                        disabled
                      />
                      <input
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.landlords_phone_two &&
                          formik?.touched.landlords_phone_two
                            ? " is-invalid"
                            : "")
                        }
                        name={"landlords_phone_two"}
                        value={formik?.values?.landlords_phone_two}
                        onChange={formik?.handleChange}
                        type="text"
                        disabled
                      />
                      <input
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.landlords_phone_three &&
                          formik?.touched.landlords_phone_three
                            ? " is-invalid"
                            : "")
                        }
                        name={"landlords_phone_three"}
                        value={formik?.values?.landlords_phone_three}
                        onChange={formik?.handleChange}
                        type="text"
                        disabled
                      />
                    </div>

                    {/* Previous Address  */}
                    <div className="cardTitleHome card-title h5 mt-2">
                      Previous Address
                    </div>

                    {/* sasa */}

                    <div className="input-group mb-3">
                      <span
                        className="input-group-text print-only-four-section  col-lg-3"
                        id="basic-addon3"
                      >
                        Previous Address
                      </span>
                      <textarea
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.previous_address_one &&
                          formik?.touched.previous_address_one
                            ? " is-invalid"
                            : "")
                        }
                        name={"previous_address_one"}
                        value={formik?.values?.previous_address_one}
                        onChange={formik?.handleChange}
                        type="text"
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                        onBlur={formik.handleBlur}
                        style={{
                          minHeight: "100px",
                          resize: "none",
                          width: "100%",
                          overflow: "hidden",
                        }}
                        onInput={(e) => {
                          e.target.style.height = "auto";
                          e.target.style.height = e.target.scrollHeight + "px";
                        }}
                        ref={(textarea) => {
                          if (textarea) {
                            textarea.style.height = "auto";
                            textarea.style.height =
                              textarea.scrollHeight + "px";
                          }
                        }}
                        disabled
                      />
                      <textarea
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.previous_address_two &&
                          formik?.touched.previous_address_two
                            ? " is-invalid"
                            : "")
                        }
                        name={"previous_address_two"}
                        value={formik?.values?.previous_address_two}
                        onChange={formik?.handleChange}
                        type="text"
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                        style={{
                          minHeight: "100px",
                          resize: "none",
                          width: "100%",
                          overflow: "hidden",
                        }}
                        onInput={(e) => {
                          e.target.style.height = "auto";
                          e.target.style.height = e.target.scrollHeight + "px";
                        }}
                        ref={(textarea) => {
                          if (textarea) {
                            textarea.style.height = "auto";
                            textarea.style.height =
                              textarea.scrollHeight + "px";
                          }
                        }}
                        disabled
                      />
                      <textarea
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.previous_address_three &&
                          formik?.touched.previous_address_three
                            ? " is-invalid"
                            : "")
                        }
                        name={"previous_address_three"}
                        value={formik?.values?.previous_address_three}
                        onChange={formik?.handleChange}
                        type="text"
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                        style={{
                          minHeight: "100px",
                          resize: "none",
                          width: "100%",
                          overflow: "hidden",
                        }}
                        onInput={(e) => {
                          e.target.style.height = "auto";
                          e.target.style.height = e.target.scrollHeight + "px";
                        }}
                        ref={(textarea) => {
                          if (textarea) {
                            textarea.style.height = "auto";
                            textarea.style.height =
                              textarea.scrollHeight + "px";
                          }
                        }}
                        disabled
                      />
                    </div>

                    {/* asa */}
                    <div className="input-group mb-3 margin-bottom-zero">
                      <span
                        className="input-group-text print-only-four-section  col-lg-3"
                        id="basic-addon3"
                      >
                        Previous Apartment No.
                      </span>
                      <textarea
                        className={
                          "form-control col-lg-3 print-only-four-section pdf-generation" +
                          (formik?.errors.previous_appartment_no_one &&
                          formik?.touched.previous_appartment_no_one
                            ? " is-invalid"
                            : "")
                        }
                        name={"previous_appartment_no_one"}
                        value={formik?.values?.previous_appartment_no_one}
                        onChange={formik?.handleChange}
                        type="text"
                        disabled
                        onKeyPress={handleKeyPress}
                        // rows={1} // This should be set to 1 initially
                        style={{
                          minHeight: "100px",
                          resize: "none",
                          width: "100%",
                          overflow: "hidden",
                        }}
                        onInput={(e) => {
                          e.target.style.height = "auto";
                          e.target.style.height = e.target.scrollHeight + "px";
                        }}
                        ref={(textarea) => {
                          if (textarea) {
                            textarea.style.height = "auto";
                            textarea.style.height =
                              textarea.scrollHeight + "px";
                          }
                        }}
                      />
                      <textarea
                        className={
                          "form-control col-lg-3 print-only-four-section pdf-generation" +
                          (formik?.errors.previous_appartment_no_two &&
                          formik?.touched.previous_appartment_no_two
                            ? " is-invalid"
                            : "")
                        }
                        name={"previous_appartment_no_two"}
                        value={formik?.values?.previous_appartment_no_two}
                        onChange={formik?.handleChange}
                        type="text"
                        disabled
                        onKeyPress={handleKeyPress}
                        // rows={1} // This should be set to 1 initially
                        style={{
                          minHeight: "100px",
                          resize: "none",
                          width: "100%",
                          overflow: "hidden",
                        }}
                        onInput={(e) => {
                          e.target.style.height = "auto";
                          e.target.style.height = e.target.scrollHeight + "px";
                        }}
                        ref={(textarea) => {
                          if (textarea) {
                            textarea.style.height = "auto";
                            textarea.style.height =
                              textarea.scrollHeight + "px";
                          }
                        }}
                      />
                      <textarea
                        className={
                          "form-control col-lg-3 print-only-four-section pdf-generation" +
                          (formik?.errors.previous_appartment_no_three &&
                          formik?.touched.previous_appartment_no_three
                            ? " is-invalid"
                            : "")
                        }
                        name={"previous_appartment_no_three"}
                        value={formik?.values?.previous_appartment_no_three}
                        onChange={formik?.handleChange}
                        type="text"
                        disabled
                        onKeyPress={handleKeyPress}
                        // rows={1} // This should be set to 1 initially

                        style={{
                          minHeight: "100px",
                          resize: "none",
                          width: "100%",
                          overflow: "hidden",
                        }}
                        onInput={(e) => {
                          e.target.style.height = "auto";
                          e.target.style.height = e.target.scrollHeight + "px";
                        }}
                        ref={(textarea) => {
                          if (textarea) {
                            textarea.style.height = "auto";
                            textarea.style.height =
                              textarea.scrollHeight + "px";
                          }
                        }}
                      />
                    </div>

                    {/* monthly rent previous */}
                    <div className="input-group mb-3 margin-bottom-zero">
                      <span
                        className="input-group-text print-only-four-section  col-lg-3"
                        id="basic-addon3"
                      >
                        Previous Monthly Rent
                      </span>
                      <input
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.previous_monthly_rent_one &&
                          formik?.touched.previous_monthly_rent_one
                            ? " is-invalid"
                            : "")
                        }
                        name={"previous_monthly_rent_one"}
                        value={formik?.values?.previous_monthly_rent_one}
                        onChange={formik?.handleChange}
                        type="text"
                        disabled
                        onKeyPress={handleKeyPress}
                      />
                      <input
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.previous_monthly_rent_two &&
                          formik?.touched.previous_monthly_rent_two
                            ? " is-invalid"
                            : "")
                        }
                        name={"previous_monthly_rent_two"}
                        value={formik?.values?.previous_monthly_rent_two}
                        onChange={formik?.handleChange}
                        type="text"
                        disabled
                        onKeyPress={handleKeyPress}
                      />
                      <input
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.previous_monthly_rent_three &&
                          formik?.touched.previous_monthly_rent_three
                            ? " is-invalid"
                            : "")
                        }
                        name={"previous_monthly_rent_three"}
                        value={formik?.values?.previous_monthly_rent_three}
                        onChange={formik?.handleChange}
                        type="text"
                        disabled
                        onKeyPress={handleKeyPress}
                      />
                    </div>

                    {/* monthly rent previous */}
                    <div className="input-group mb-3 margin-bottom-zero">
                      <span
                        className="input-group-text print-only-four-section  col-lg-3"
                        id="basic-addon3"
                      >
                        Previous Postal Code
                      </span>
                      <input
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.previous_postal_code_one &&
                          formik?.touched.previous_postal_code_one
                            ? " is-invalid"
                            : "")
                        }
                        name={"previous_postal_code_one"}
                        value={formik?.values?.previous_postal_code_one}
                        onChange={formik?.handleChange}
                        type="text"
                        disabled
                        onKeyPress={handleKeyPress}
                      />
                      <input
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.previous_postal_code_two &&
                          formik?.touched.previous_postal_code_two
                            ? " is-invalid"
                            : "")
                        }
                        name={"previous_postal_code_two"}
                        value={formik?.values?.previous_postal_code_two}
                        onChange={formik?.handleChange}
                        type="text"
                        disabled
                        onKeyPress={handleKeyPress}
                      />
                      <input
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.previous_postal_code_three &&
                          formik?.touched.previous_postal_code_three
                            ? " is-invalid"
                            : "")
                        }
                        name={"previous_postal_code_three"}
                        value={formik?.values?.previous_postal_code_three}
                        onChange={formik?.handleChange}
                        type="text"
                        disabled
                        onKeyPress={handleKeyPress}
                      />
                    </div>

                    {/* Previous Length of Residence */}
                    <div className="input-group mb-3 margin-bottom-zero">
                      <span
                        className="input-group-text print-only-four-section  col-lg-3"
                        id="basic-addon3"
                      >
                        Previous Length of Residence
                      </span>
                      <input
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.previous_length_of_residence_one &&
                          formik?.touched.previous_length_of_residence_one
                            ? " is-invalid"
                            : "")
                        }
                        name={"previous_length_of_residence_one"}
                        value={formik?.values?.previous_length_of_residence_one}
                        onChange={formik?.handleChange}
                        type="text"
                        disabled
                        onKeyPress={handleKeyPress}
                      />
                      <input
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.previous_length_of_residence_two &&
                          formik?.touched.previous_length_of_residence_two
                            ? " is-invalid"
                            : "")
                        }
                        name={"previous_length_of_residence_two"}
                        value={formik?.values?.previous_length_of_residence_two}
                        onChange={formik?.handleChange}
                        type="text"
                        disabled
                        onKeyPress={handleKeyPress}
                      />
                      <input
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.previous_length_of_residence_three &&
                          formik?.touched.previous_length_of_residence_three
                            ? " is-invalid"
                            : "")
                        }
                        name={"previous_length_of_residence_three"}
                        value={
                          formik?.values?.previous_length_of_residence_three
                        }
                        onChange={formik?.handleChange}
                        type="text"
                        disabled
                        onKeyPress={handleKeyPress}
                      />
                    </div>

                    {/* Previous Landlords Name */}
                    <div className="input-group mb-3 margin-bottom-zero">
                      <span
                        className="input-group-text print-only-four-section  col-lg-3"
                        id="basic-addon3"
                      >
                        Previous Landlords Name
                      </span>
                      <input
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.previous_landlords_name_one &&
                          formik?.touched.previous_landlords_name_one
                            ? " is-invalid"
                            : "")
                        }
                        name={"previous_landlords_name_one"}
                        value={formik?.values?.previous_landlords_name_one}
                        onChange={formik?.handleChange}
                        type="text"
                        disabled
                      />
                      <input
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.previous_landlords_name_two &&
                          formik?.touched.previous_landlords_name_two
                            ? " is-invalid"
                            : "")
                        }
                        name={"previous_landlords_name_two"}
                        value={formik?.values?.previous_landlords_name_two}
                        onChange={formik?.handleChange}
                        type="text"
                        disabled
                      />
                      <input
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.previous_landlords_name_three &&
                          formik?.touched.previous_landlords_name_three
                            ? " is-invalid"
                            : "")
                        }
                        name={"previous_landlords_name_three"}
                        value={formik?.values?.previous_landlords_name_three}
                        onChange={formik?.handleChange}
                        type="text"
                        disabled
                      />
                    </div>

                    {/* Previous Landlords Phone No. */}
                    <div className="input-group mb-3">
                      <span
                        className="input-group-text print-only-four-section  col-lg-3"
                        id="basic-addon3"
                      >
                        Previous Landlords Phone No.
                      </span>
                      {/* <input
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.previous_landlords_phone_one &&
                          formik?.touched.previous_landlords_phone_one
                            ? " is-invalid"
                            : "")
                        }
                        name={"previous_landlords_phone_one"}
                        value={formik?.values?.previous_landlords_phone_one}
                        onChange={formik?.handleChange}
                        type="text"
                        disabled
                        onKeyPress={handleKeyPress}
                      /> */}
                      <ReactInputMask
                        mask="(999) 999-9999"
                        value={formik?.values?.previous_landlords_phone_one}
                        name={"previous_landlords_phone_one"}
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.previous_landlords_phone_one &&
                          formik?.touched.previous_landlords_phone_one
                            ? " is-invalid"
                            : "")
                        }
                        onChange={formik?.handleChange}
                        disabled
                      />
                      {/* <input
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.previous_landlords_phone_two &&
                          formik?.touched.previous_landlords_phone_two
                            ? " is-invalid"
                            : "")
                        }
                        name={"previous_landlords_phone_two"}
                        value={formik?.values?.previous_landlords_phone_two}
                        onChange={formik?.handleChange}
                        type="text"
                        disabled
                        onKeyPress={handleKeyPress}
                      /> */}
                      <ReactInputMask
                        mask="(999) 999-9999"
                        value={formik?.values?.previous_landlords_phone_two}
                        name={"previous_landlords_phone_two"}
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.previous_landlords_phone_two &&
                          formik?.touched.previous_landlords_phone_two
                            ? " is-invalid"
                            : "")
                        }
                        onChange={formik?.handleChange}
                        disabled
                      />
                      {/* <input
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.previous_landlords_phone_three &&
                          formik?.touched.previous_landlords_phone_three
                            ? " is-invalid"
                            : "")
                        }
                        name={"previous_landlords_phone_three"}
                        value={formik?.values?.previous_landlords_phone_three}
                        onChange={formik?.handleChange}
                        type="text"
                        disabled
                        onKeyPress={handleKeyPress}
                      /> */}
                      <ReactInputMask
                        mask="(999) 999-9999"
                        value={formik?.values?.previous_landlords_phone_three}
                        name={"previous_landlords_phone_three"}
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.previous_landlords_phone_three &&
                          formik?.touched.previous_landlords_phone_three
                            ? " is-invalid"
                            : "")
                        }
                        onChange={formik?.handleChange}
                        disabled
                      />
                    </div>

                    {/* Employment Information */}

                    <div className="cardTitleHome card-title h5 mt-2  margin-top-zero">
                      Employment Information
                    </div>
                    <div className="input-group mb-3 margin-bottom-zero">
                      <span
                        className="input-group-text print-only-four-section  col-lg-3"
                        id="basic-addon3"
                      >
                        Current Employer
                      </span>
                      <textarea
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.current_employer_one &&
                          formik?.touched.current_employer_one
                            ? " is-invalid"
                            : "")
                        }
                        name={"current_employer_one"}
                        value={formik?.values?.current_employer_one}
                        onChange={formik?.handleChange}
                        type="text"
                        disabled
                        style={{
                          minHeight: "100px",
                          resize: "none",
                          width: "100%",
                          overflow: "hidden",
                        }}
                        onInput={(e) => {
                          e.target.style.height = "auto";
                          e.target.style.height = e.target.scrollHeight + "px";
                        }}
                        ref={(textarea) => {
                          if (textarea) {
                            textarea.style.height = "auto";
                            textarea.style.height =
                              textarea.scrollHeight + "px";
                          }
                        }}
                      />
                      <textarea
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.current_employer_two &&
                          formik?.touched.current_employer_two
                            ? " is-invalid"
                            : "")
                        }
                        name={"current_employer_two"}
                        value={formik?.values?.current_employer_two}
                        onChange={formik?.handleChange}
                        type="text"
                        disabled
                        style={{
                          minHeight: "100px",
                          resize: "none",
                          width: "100%",
                          overflow: "hidden",
                        }}
                        onInput={(e) => {
                          e.target.style.height = "auto";
                          e.target.style.height = e.target.scrollHeight + "px";
                        }}
                        ref={(textarea) => {
                          if (textarea) {
                            textarea.style.height = "auto";
                            textarea.style.height =
                              textarea.scrollHeight + "px";
                          }
                        }}
                      />
                      <textarea
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.current_employer_three &&
                          formik?.touched.current_employer_three
                            ? " is-invalid"
                            : "")
                        }
                        name={"current_employer_three"}
                        value={formik?.values?.current_employer_three}
                        onChange={formik?.handleChange}
                        type="text"
                        disabled
                        style={{
                          minHeight: "100px",
                          resize: "none",
                          width: "100%",
                          overflow: "hidden",
                        }}
                        onInput={(e) => {
                          e.target.style.height = "auto";
                          e.target.style.height = e.target.scrollHeight + "px";
                        }}
                        ref={(textarea) => {
                          if (textarea) {
                            textarea.style.height = "auto";
                            textarea.style.height =
                              textarea.scrollHeight + "px";
                          }
                        }}
                      />
                    </div>

                    <div className="input-group mb-3 margin-bottom-zero">
                      <span
                        className="input-group-text print-only-four-section  col-lg-3"
                        id="basic-addon3"
                      >
                        Work Phone No.
                      </span>
                      {/* <input
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.work_phone_no_one &&
                          formik?.touched.work_phone_no_one
                            ? " is-invalid"
                            : "")
                        }
                        name={"work_phone_no_one"}
                        value={formik?.values?.work_phone_no_one}
                        onChange={formik?.handleChange}
                        type="text"
                        disabled
                        onKeyPress={handleKeyPress}
                      /> */}
                      <ReactInputMask
                        mask="(999) 999-9999"
                        value={formik?.values?.work_phone_no_one}
                        name={"work_phone_no_one"}
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.work_phone_no_one &&
                          formik?.touched.work_phone_no_one
                            ? " is-invalid"
                            : "")
                        }
                        onChange={formik?.handleChange}
                        disabled
                      />

                      {/* <input
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.work_phone_no_two &&
                          formik?.touched.work_phone_no_two
                            ? " is-invalid"
                            : "")
                        }
                        name={"work_phone_no_two"}
                        value={formik?.values?.work_phone_no_two}
                        onChange={formik?.handleChange}
                        type="text"
                        disabled
                        onKeyPress={handleKeyPress}
                      /> */}
                      <ReactInputMask
                        mask="(999) 999-9999"
                        value={formik?.values?.work_phone_no_two}
                        name={"work_phone_no_two"}
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.work_phone_no_two &&
                          formik?.touched.work_phone_no_two
                            ? " is-invalid"
                            : "")
                        }
                        onChange={formik?.handleChange}
                        disabled
                      />
                      {/* <input
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.work_phone_no_three &&
                          formik?.touched.work_phone_no_three
                            ? " is-invalid"
                            : "")
                        }
                        name={"work_phone_no_three"}
                        value={formik?.values?.work_phone_no_three}
                        onChange={formik?.handleChange}
                        type="text"
                        disabled
                        onKeyPress={handleKeyPress}
                      /> */}
                      <ReactInputMask
                        mask="(999) 999-9999"
                        value={formik?.values?.work_phone_no_three}
                        name={"work_phone_no_three"}
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.work_phone_no_three &&
                          formik?.touched.work_phone_no_three
                            ? " is-invalid"
                            : "")
                        }
                        onChange={formik?.handleChange}
                        disabled
                      />
                    </div>

                    <div className="input-group mb-3 margin-bottom-zero">
                      <span
                        className="input-group-text print-only-four-section  col-lg-3"
                        id="basic-addon3"
                      >
                        Position
                      </span>
                      <textarea
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.position_one &&
                          formik?.touched.position_one
                            ? " is-invalid"
                            : "")
                        }
                        name={"position_one"}
                        value={formik?.values?.position_one}
                        onChange={formik?.handleChange}
                        type="text"
                        disabled
                        style={{
                          minHeight: "100px",
                          resize: "none",
                          width: "100%",
                          overflow: "hidden",
                        }}
                        onInput={(e) => {
                          e.target.style.height = "auto";
                          e.target.style.height = e.target.scrollHeight + "px";
                        }}
                        ref={(textarea) => {
                          if (textarea) {
                            textarea.style.height = "auto";
                            textarea.style.height =
                              textarea.scrollHeight + "px";
                          }
                        }}
                      />
                      <textarea
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.position_two &&
                          formik?.touched.position_two
                            ? " is-invalid"
                            : "")
                        }
                        name={"position_two"}
                        value={formik?.values?.position_two}
                        onChange={formik?.handleChange}
                        type="text"
                        disabled
                        style={{
                          minHeight: "100px",
                          resize: "none",
                          width: "100%",
                          overflow: "hidden",
                        }}
                        onInput={(e) => {
                          e.target.style.height = "auto";
                          e.target.style.height = e.target.scrollHeight + "px";
                        }}
                        ref={(textarea) => {
                          if (textarea) {
                            textarea.style.height = "auto";
                            textarea.style.height =
                              textarea.scrollHeight + "px";
                          }
                        }}
                      />
                      <textarea
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.position_three &&
                          formik?.touched.position_three
                            ? " is-invalid"
                            : "")
                        }
                        name={"position_three"}
                        value={formik?.values?.position_three}
                        onChange={formik?.handleChange}
                        type="text"
                        disabled
                        style={{
                          minHeight: "100px",
                          resize: "none",
                          width: "100%",
                          overflow: "hidden",
                        }}
                        onInput={(e) => {
                          e.target.style.height = "auto";
                          e.target.style.height = e.target.scrollHeight + "px";
                        }}
                        ref={(textarea) => {
                          if (textarea) {
                            textarea.style.height = "auto";
                            textarea.style.height =
                              textarea.scrollHeight + "px";
                          }
                        }}
                      />
                    </div>

                    <div className="input-group mb-3 margin-bottom-zero">
                      <span
                        className="input-group-text print-only-four-section  col-lg-3"
                        id="basic-addon3"
                      >
                        Length of Employment
                      </span>
                      <input
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.length_of_employment_one &&
                          formik?.touched.length_of_employment_one
                            ? " is-invalid"
                            : "")
                        }
                        name={"length_of_employment_one"}
                        value={formik?.values?.length_of_employment_one}
                        onChange={formik?.handleChange}
                        type="text"
                        disabled
                        onKeyPress={handleKeyPress}
                      />
                      <input
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.length_of_employment_two &&
                          formik?.touched.length_of_employment_two
                            ? " is-invalid"
                            : "")
                        }
                        name={"length_of_employment_two"}
                        value={formik?.values?.length_of_employment_two}
                        onChange={formik?.handleChange}
                        type="text"
                        disabled
                        onKeyPress={handleKeyPress}
                      />
                      <input
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.length_of_employment_three &&
                          formik?.touched.length_of_employment_three
                            ? " is-invalid"
                            : "")
                        }
                        name={"length_of_employment_three"}
                        value={formik?.values?.length_of_employment_three}
                        onChange={formik?.handleChange}
                        type="text"
                        disabled
                        onKeyPress={handleKeyPress}
                      />
                    </div>

                    <div className="input-group mb-3 margin-bottom-zero">
                      <span
                        className="input-group-text print-only-four-section  col-lg-3"
                        id="basic-addon3"
                      >
                        Contact Name
                      </span>
                      <input
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.contact_name_no_one &&
                          formik?.touched.contact_name_no_one
                            ? " is-invalid"
                            : "")
                        }
                        name={"contact_name_no_one"}
                        value={formik?.values?.contact_name_no_one}
                        onChange={formik?.handleChange}
                        type="text"
                        disabled
                      />
                      <input
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.contact_name_no_two &&
                          formik?.touched.contact_name_no_two
                            ? " is-invalid"
                            : "")
                        }
                        name={"contact_name_no_two"}
                        value={formik?.values?.contact_name_no_two}
                        onChange={formik?.handleChange}
                        type="text"
                        disabled
                      />
                      <input
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.contact_name_no_three &&
                          formik?.touched.contact_name_no_three
                            ? " is-invalid"
                            : "")
                        }
                        name={"contact_name_no_three"}
                        value={formik?.values?.contact_name_no_three}
                        onChange={formik?.handleChange}
                        type="text"
                        disabled
                      />
                    </div>

                    <div className="input-group mb-3 margin-bottom-zero">
                      <span
                        className="input-group-text print-only-four-section  col-lg-3"
                        id="basic-addon3"
                      >
                        Phone No.
                      </span>
                      {/* <input
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.phone_no_one &&
                          formik?.touched.phone_no_one
                            ? " is-invalid"
                            : "")
                        }
                        name={"phone_no_one"}
                        value={formik?.values?.phone_no_one}
                        onChange={formik?.handleChange}
                        type="text"
                        disabled
                        onKeyPress={handleKeyPress}
                      /> */}
                      <ReactInputMask
                        mask="(999) 999-9999"
                        value={formik?.values?.phone_no_one}
                        name={"phone_no_one"}
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.phone_no_one &&
                          formik?.touched.phone_no_one
                            ? " is-invalid"
                            : "")
                        }
                        onChange={formik?.handleChange}
                        disabled
                      />
                      {/* <input
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.phone_no_two &&
                          formik?.touched.phone_no_two
                            ? " is-invalid"
                            : "")
                        }
                        name={"phone_no_two"}
                        value={formik?.values?.phone_no_two}
                        onChange={formik?.handleChange}
                        type="text"
                        disabled
                        onKeyPress={handleKeyPress}
                      /> */}
                      <ReactInputMask
                        mask="(999) 999-9999"
                        value={formik?.values?.phone_no_two}
                        name={"phone_no_two"}
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.phone_no_two &&
                          formik?.touched.phone_no_two
                            ? " is-invalid"
                            : "")
                        }
                        onChange={formik?.handleChange}
                        disabled
                      />
                      {/* <input
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.phone_no_three &&
                          formik?.touched.phone_no_three
                            ? " is-invalid"
                            : "")
                        }
                        name={"phone_no_three"}
                        value={formik?.values?.phone_no_three}
                        onChange={formik?.handleChange}
                        type="text"
                        disabled
                        onKeyPress={handleKeyPress}
                      /> */}
                      <ReactInputMask
                        mask="(999) 999-9999"
                        value={formik?.values?.phone_no_three}
                        name={"phone_no_three"}
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.phone_no_three &&
                          formik?.touched.phone_no_three
                            ? " is-invalid"
                            : "")
                        }
                        onChange={formik?.handleChange}
                        disabled
                      />
                    </div>

                    <div className="input-group mb-3 print-margin-top-exssaa">
                      <span
                        className="input-group-text print-only-four-section  col-lg-3"
                        id="basic-addon3"
                      >
                        Annual Income
                      </span>
                      <input
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.annual_income_one &&
                          formik?.touched.annual_income_one
                            ? " is-invalid"
                            : "")
                        }
                        name={"annual_income_one"}
                        value={formik?.values?.annual_income_one}
                        onChange={formik?.handleChange}
                        type="text"
                        disabled
                        onKeyPress={handleKeyPress}
                      />
                      <input
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.annual_income_two &&
                          formik?.touched.annual_income_two
                            ? " is-invalid"
                            : "")
                        }
                        name={"annual_income_two"}
                        value={formik?.values?.annual_income_two}
                        onChange={formik?.handleChange}
                        type="text"
                        disabled
                        onKeyPress={handleKeyPress}
                      />
                      <input
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.annual_income_three &&
                          formik?.touched.annual_income_three
                            ? " is-invalid"
                            : "")
                        }
                        name={"annual_income_three"}
                        value={formik?.values?.annual_income_three}
                        onChange={formik?.handleChange}
                        type="text"
                        disabled
                        onKeyPress={handleKeyPress}
                      />
                    </div>

                    <div className="cardTitleHome card-title h5 mt-2 ">
                      Previous Employment
                    </div>

                    <div className="input-group mb-3 margin-bottom-zero">
                      <span
                        className="input-group-text print-only-four-section  col-lg-3"
                        id="basic-addon3"
                      >
                        Previous Employer
                      </span>
                      <input
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.previous_employer_one &&
                          formik?.touched.previous_employer_one
                            ? " is-invalid"
                            : "")
                        }
                        name={"previous_employer_one"}
                        value={formik?.values?.previous_employer_one}
                        onChange={formik?.handleChange}
                        type="text"
                        disabled
                      />
                      <input
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.previous_employer_two &&
                          formik?.touched.previous_employer_two
                            ? " is-invalid"
                            : "")
                        }
                        name={"previous_employer_two"}
                        value={formik?.values?.previous_employer_two}
                        onChange={formik?.handleChange}
                        type="text"
                        disabled
                      />
                      <input
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.previous_employer_three &&
                          formik?.touched.previous_employer_three
                            ? " is-invalid"
                            : "")
                        }
                        name={"previous_employer_three"}
                        value={formik?.values?.previous_employer_three}
                        onChange={formik?.handleChange}
                        type="text"
                        disabled
                      />
                    </div>

                    <div className="input-group mb-3 margin-bottom-zero">
                      <span
                        className="input-group-text print-only-four-section  col-lg-3"
                        id="basic-addon3"
                      >
                        Previous Position
                      </span>
                      <input
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.previous_position_one &&
                          formik?.touched.previous_position_one
                            ? " is-invalid"
                            : "")
                        }
                        name={"previous_position_one"}
                        value={formik?.values?.previous_position_one}
                        onChange={formik?.handleChange}
                        type="text"
                        disabled
                      />
                      <input
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.previous_position_two &&
                          formik?.touched.previous_position_two
                            ? " is-invalid"
                            : "")
                        }
                        name={"previous_position_two"}
                        value={formik?.values?.previous_position_two}
                        onChange={formik?.handleChange}
                        type="text"
                        disabled
                      />
                      <input
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.previous_position_three &&
                          formik?.touched.previous_position_three
                            ? " is-invalid"
                            : "")
                        }
                        name={"previous_position_three"}
                        value={formik?.values?.previous_position_three}
                        onChange={formik?.handleChange}
                        type="text"
                        disabled
                      />
                    </div>

                    <div className="input-group mb-3 margin-bottom-zero">
                      <span
                        className="input-group-text print-only-four-section  col-lg-3"
                        id="basic-addon3"
                      >
                        Length of Employment
                      </span>
                      <input
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.Previous_length_of_employment_one &&
                          formik?.touched.Previous_length_of_employment_one
                            ? " is-invalid"
                            : "")
                        }
                        name={"Previous_length_of_employment_one"}
                        value={
                          formik?.values?.Previous_length_of_employment_one
                        }
                        onChange={formik?.handleChange}
                        type="text"
                        disabled
                        onKeyPress={handleKeyPress}
                      />
                      <input
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.Previous_length_of_employment_two &&
                          formik?.touched.Previous_length_of_employment_two
                            ? " is-invalid"
                            : "")
                        }
                        name={"Previous_length_of_employment_two"}
                        value={
                          formik?.values?.Previous_length_of_employment_two
                        }
                        onChange={formik?.handleChange}
                        type="text"
                        disabled
                        onKeyPress={handleKeyPress}
                      />
                      <input
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.Previous_length_of_employment_three &&
                          formik?.touched.Previous_length_of_employment_three
                            ? " is-invalid"
                            : "")
                        }
                        name={"Previous_length_of_employment_three"}
                        value={
                          formik?.values?.Previous_length_of_employment_three
                        }
                        onChange={formik?.handleChange}
                        type="text"
                        disabled
                        onKeyPress={handleKeyPress}
                      />
                    </div>

                    <div className="input-group mb-3 margin-bottom-zero">
                      <span
                        className="input-group-text print-only-four-section  col-lg-3"
                        id="basic-addon3"
                      >
                        Previous Contact Name & No.
                      </span>
                      <input
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.previous_contact_name_no_one &&
                          formik?.touched.previous_contact_name_no_one
                            ? " is-invalid"
                            : "")
                        }
                        name={"previous_contact_name_no_one"}
                        value={formik?.values?.previous_contact_name_no_one}
                        onChange={formik?.handleChange}
                        type="text"
                        disabled
                      />
                      <input
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.previous_contact_name_no_two &&
                          formik?.touched.previous_contact_name_no_two
                            ? " is-invalid"
                            : "")
                        }
                        name={"previous_contact_name_no_two"}
                        value={formik?.values?.previous_contact_name_no_two}
                        onChange={formik?.handleChange}
                        type="text"
                        disabled
                      />
                      <input
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.previous_contact_name_no_three &&
                          formik?.touched.previous_contact_name_no_three
                            ? " is-invalid"
                            : "")
                        }
                        name={"previous_contact_name_no_three"}
                        value={formik?.values?.previous_contact_name_no_three}
                        onChange={formik?.handleChange}
                        type="text"
                        disabled
                      />
                    </div>

                    <div className="input-group mb-3 margin-bottom-zero">
                      <span
                        className="input-group-text print-only-four-section  col-lg-3"
                        id="basic-addon3"
                      >
                        Previous Annual Income
                      </span>
                      <input
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.previous_annual_income_one &&
                          formik?.touched.previous_annual_income_one
                            ? " is-invalid"
                            : "")
                        }
                        name={"previous_annual_income_one"}
                        value={formik?.values?.previous_annual_income_one}
                        onChange={formik?.handleChange}
                        type="text"
                        disabled
                        onKeyPress={handleKeyPress}
                      />
                      <input
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.previous_annual_income_two &&
                          formik?.touched.previous_annual_income_two
                            ? " is-invalid"
                            : "")
                        }
                        name={"previous_annual_income_two"}
                        value={formik?.values?.previous_annual_income_two}
                        onChange={formik?.handleChange}
                        type="text"
                        disabled
                        onKeyPress={handleKeyPress}
                      />
                      <input
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.previous_annual_income_three &&
                          formik?.touched.previous_annual_income_three
                            ? " is-invalid"
                            : "")
                        }
                        name={"previous_annual_income_three"}
                        value={formik?.values?.previous_annual_income_three}
                        onChange={formik?.handleChange}
                        type="text"
                        disabled
                        onKeyPress={handleKeyPress}
                      />
                    </div>

                    <div className="cardTitleHome card-title h5 mt-2">
                      Banking Information
                    </div>

                    <div className="input-group mb-3 margin-bottom-zero">
                      <span
                        className="input-group-text print-only-four-section  col-lg-3"
                        id="basic-addon3"
                      >
                        Bank
                      </span>
                      <input
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.bank_one && formik?.touched.bank_one
                            ? " is-invalid"
                            : "")
                        }
                        name={"bank_one"}
                        value={formik?.values?.bank_one}
                        onChange={formik?.handleChange}
                        type="text"
                        disabled
                      />
                      <input
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.bank_two && formik?.touched.bank_two
                            ? " is-invalid"
                            : "")
                        }
                        name={"bank_two"}
                        value={formik?.values?.bank_two}
                        onChange={formik?.handleChange}
                        type="text"
                        disabled
                      />
                      <input
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.bank_three &&
                          formik?.touched.bank_three
                            ? " is-invalid"
                            : "")
                        }
                        name={"bank_three"}
                        value={formik?.values?.bank_three}
                        onChange={formik?.handleChange}
                        type="text"
                        disabled
                      />
                    </div>

                    <div className="input-group mb-3 margin-bottom-zero">
                      <span
                        className="input-group-text print-only-four-section  col-lg-3"
                        id="basic-addon3"
                      >
                        Branch
                      </span>
                      <input
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.branch_one &&
                          formik?.touched.branch_one
                            ? " is-invalid"
                            : "")
                        }
                        name={"branch_one"}
                        value={formik?.values?.branch_one}
                        onChange={formik?.handleChange}
                        type="text"
                        disabled
                      />
                      <input
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.branch_two &&
                          formik?.touched.branch_two
                            ? " is-invalid"
                            : "")
                        }
                        name={"branch_two"}
                        value={formik?.values?.branch_two}
                        onChange={formik?.handleChange}
                        type="text"
                        disabled
                      />
                      <input
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.branch_three &&
                          formik?.touched.branch_three
                            ? " is-invalid"
                            : "")
                        }
                        name={"branch_three"}
                        value={formik?.values?.branch_three}
                        onChange={formik?.handleChange}
                        type="text"
                        disabled
                      />
                    </div>

                    <div className="input-group mb-3 margin-bottom-zero">
                      <span
                        className="input-group-text print-only-four-section  col-lg-3"
                        id="basic-addon3"
                      >
                        Account No.
                      </span>
                      <input
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.account_no_one &&
                          formik?.touched.account_no_one
                            ? " is-invalid"
                            : "")
                        }
                        name={"account_no_one"}
                        value={formik?.values?.account_no_one}
                        onChange={formik?.handleChange}
                        type="text"
                        disabled
                        onKeyPress={handleKeyPress}
                      />
                      <input
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.account_no_two &&
                          formik?.touched.account_no_two
                            ? " is-invalid"
                            : "")
                        }
                        name={"account_no_two"}
                        value={formik?.values?.account_no_two}
                        onChange={formik?.handleChange}
                        type="text"
                        disabled
                        onKeyPress={handleKeyPress}
                      />
                      <input
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.account_no_three &&
                          formik?.touched.account_no_three
                            ? " is-invalid"
                            : "")
                        }
                        name={"account_no_three"}
                        value={formik?.values?.account_no_three}
                        onChange={formik?.handleChange}
                        type="text"
                        disabled
                        onKeyPress={handleKeyPress}
                      />
                    </div>

                    <div className="cardTitleHome card-title h5 mt-2">
                      Vehicle Information
                    </div>

                    <div className="input-group mb-3 margin-bottom-zero">
                      <span
                        className="input-group-text print-only-four-section  col-lg-3"
                        id="basic-addon3"
                      >
                        Make of Vehicle
                      </span>
                      <input
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.make_of_vehicle_one &&
                          formik?.touched.make_of_vehicle_one
                            ? " is-invalid"
                            : "")
                        }
                        name={"make_of_vehicle_one"}
                        value={formik?.values?.make_of_vehicle_one}
                        onChange={formik?.handleChange}
                        type="text"
                        disabled
                      />
                      <input
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.make_of_vehicle_two &&
                          formik?.touched.make_of_vehicle_two
                            ? " is-invalid"
                            : "")
                        }
                        name={"make_of_vehicle_two"}
                        value={formik?.values?.make_of_vehicle_two}
                        onChange={formik?.handleChange}
                        type="text"
                        disabled
                      />
                      <input
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.make_of_vehicle_three &&
                          formik?.touched.make_of_vehicle_three
                            ? " is-invalid"
                            : "")
                        }
                        name={"make_of_vehicle_three"}
                        value={formik?.values?.make_of_vehicle_three}
                        onChange={formik?.handleChange}
                        type="text"
                        disabled
                      />
                    </div>

                    <div className="input-group mb-3 margin-bottom-zero">
                      <span
                        className="input-group-text print-only-four-section  col-lg-3"
                        id="basic-addon3"
                      >
                        Year & Model of Vehicle
                      </span>
                      <input
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.year_model_of_vehicle_one &&
                          formik?.touched.year_model_of_vehicle_one
                            ? " is-invalid"
                            : "")
                        }
                        name={"year_model_of_vehicle_one"}
                        value={formik?.values?.year_model_of_vehicle_one}
                        onChange={formik?.handleChange}
                        type="text"
                        disabled
                      />
                      <input
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.year_model_of_vehicle_two &&
                          formik?.touched.year_model_of_vehicle_two
                            ? " is-invalid"
                            : "")
                        }
                        name={"year_model_of_vehicle_two"}
                        value={formik?.values?.year_model_of_vehicle_two}
                        onChange={formik?.handleChange}
                        type="text"
                        disabled
                      />
                      <input
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.year_model_of_vehicle_three &&
                          formik?.touched.year_model_of_vehicle_three
                            ? " is-invalid"
                            : "")
                        }
                        name={"year_model_of_vehicle_three"}
                        value={formik?.values?.year_model_of_vehicle_three}
                        onChange={formik?.handleChange}
                        type="text"
                        disabled
                      />
                    </div>

                    <div className="input-group mb-3 margin-bottom-zero">
                      <span
                        className="input-group-text print-only-four-section  col-lg-3"
                        id="basic-addon3"
                      >
                        Colour of Vehicle
                      </span>
                      <input
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.colour_of_vehicle_one &&
                          formik?.touched.colour_of_vehicle_one
                            ? " is-invalid"
                            : "")
                        }
                        name={"colour_of_vehicle_one"}
                        value={formik?.values?.colour_of_vehicle_one}
                        onChange={formik?.handleChange}
                        type="text"
                        disabled
                      />
                      <input
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.colour_of_vehicle_two &&
                          formik?.touched.colour_of_vehicle_two
                            ? " is-invalid"
                            : "")
                        }
                        name={"colour_of_vehicle_two"}
                        value={formik?.values?.colour_of_vehicle_two}
                        onChange={formik?.handleChange}
                        type="text"
                        disabled
                      />
                      <input
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.colour_of_vehicle_three &&
                          formik?.touched.colour_of_vehicle_three
                            ? " is-invalid"
                            : "")
                        }
                        name={"colour_of_vehicle_three"}
                        value={formik?.values?.colour_of_vehicle_three}
                        onChange={formik?.handleChange}
                        type="text"
                        disabled
                      />
                    </div>

                    <div className="input-group mb-3 margin-bottom-zero">
                      <span
                        className="input-group-text print-only-four-section  col-lg-3"
                        id="basic-addon3"
                      >
                        Licence Plate No.
                      </span>
                      <input
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.licence_plate_no_one &&
                          formik?.touched.licence_plate_no_one
                            ? " is-invalid"
                            : "")
                        }
                        name={"licence_plate_no_one"}
                        value={formik?.values?.licence_plate_no_one}
                        onChange={formik?.handleChange}
                        type="text"
                        disabled
                        onKeyPress={handleKeyPress}
                      />
                      <input
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.licence_plate_no_two &&
                          formik?.touched.licence_plate_no_two
                            ? " is-invalid"
                            : "")
                        }
                        name={"licence_plate_no_two"}
                        value={formik?.values?.licence_plate_no_two}
                        onChange={formik?.handleChange}
                        type="text"
                        disabled
                        onKeyPress={handleKeyPress}
                      />
                      <input
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.licence_plate_no_three &&
                          formik?.touched.licence_plate_no_three
                            ? " is-invalid"
                            : "")
                        }
                        name={"licence_plate_no_three"}
                        value={formik?.values?.licence_plate_no_three}
                        onChange={formik?.handleChange}
                        type="text"
                        disabled
                        onKeyPress={handleKeyPress}
                      />
                    </div>

                    <div className="input-group mb-3 margin-bottom-zero">
                      <span
                        className="input-group-text print-only-four-section  col-lg-3"
                        id="basic-addon3"
                      >
                        Drivers License No.
                      </span>
                      <input
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.drivers_license_no_one &&
                          formik?.touched.drivers_license_no_one
                            ? " is-invalid"
                            : "")
                        }
                        name={"drivers_license_no_one"}
                        value={formik?.values?.drivers_license_no_one}
                        onChange={formik?.handleChange}
                        type="text"
                        disabled
                        onKeyPress={handleKeyPress}
                      />
                      <input
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.drivers_license_no_two &&
                          formik?.touched.drivers_license_no_two
                            ? " is-invalid"
                            : "")
                        }
                        name={"drivers_license_no_two"}
                        value={formik?.values?.drivers_license_no_two}
                        onChange={formik?.handleChange}
                        type="text"
                        disabled
                        onKeyPress={handleKeyPress}
                      />
                      <input
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.drivers_license_no_three &&
                          formik?.touched.drivers_license_no_three
                            ? " is-invalid"
                            : "")
                        }
                        name={"drivers_license_no_three"}
                        value={formik?.values?.drivers_license_no_three}
                        onChange={formik?.handleChange}
                        type="text"
                        disabled
                        onKeyPress={handleKeyPress}
                      />
                    </div>

                    <div className="cardTitleHome card-title h5 mt-2">
                      Reference Information
                    </div>
                    <div className="input-group mb-3 margin-bottom-zero">
                      <span
                        className="input-group-text print-only-four-section  col-lg-3"
                        id="basic-addon3"
                      >
                        Name
                      </span>
                      <input
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.reference_name_one &&
                          formik?.touched.reference_name_one
                            ? " is-invalid"
                            : "")
                        }
                        name={"reference_name_one"}
                        value={formik?.values?.reference_name_one}
                        onChange={formik?.handleChange}
                        type="text"
                        disabled
                      />
                      <input
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.reference_name_two &&
                          formik?.touched.reference_name_two
                            ? " is-invalid"
                            : "")
                        }
                        name={"reference_name_two"}
                        value={formik?.values?.reference_name_two}
                        onChange={formik?.handleChange}
                        type="text"
                        disabled
                      />
                      <input
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.reference_name_three &&
                          formik?.touched.reference_name_three
                            ? " is-invalid"
                            : "")
                        }
                        name={"reference_name_three"}
                        value={formik?.values?.reference_name_three}
                        onChange={formik?.handleChange}
                        type="text"
                        disabled
                      />
                    </div>

                    <div className="input-group mb-3 margin-bottom-zero">
                      <span
                        className="input-group-text print-only-four-section  col-lg-3"
                        id="basic-addon3"
                      >
                        Address
                      </span>
                      <textarea
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.reference_address_one &&
                          formik?.touched.reference_address_one
                            ? " is-invalid"
                            : "")
                        }
                        name={"reference_address_one"}
                        value={formik?.values?.reference_address_one}
                        onChange={formik?.handleChange}
                        type="text"
                        disabled
                        style={{
                          minHeight: "100px",
                          resize: "none",
                          width: "100%",
                          overflow: "hidden",
                        }}
                        onInput={(e) => {
                          e.target.style.height = "auto";
                          e.target.style.height = e.target.scrollHeight + "px";
                        }}
                        ref={(textarea) => {
                          if (textarea) {
                            textarea.style.height = "auto";
                            textarea.style.height =
                              textarea.scrollHeight + "px";
                          }
                        }}
                      />
                      <textarea
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.reference_address_two &&
                          formik?.touched.reference_address_two
                            ? " is-invalid"
                            : "")
                        }
                        name={"reference_address_two"}
                        value={formik?.values?.reference_address_two}
                        onChange={formik?.handleChange}
                        type="text"
                        disabled
                        style={{
                          minHeight: "100px",
                          resize: "none",
                          width: "100%",
                          overflow: "hidden",
                        }}
                        onInput={(e) => {
                          e.target.style.height = "auto";
                          e.target.style.height = e.target.scrollHeight + "px";
                        }}
                        ref={(textarea) => {
                          if (textarea) {
                            textarea.style.height = "auto";
                            textarea.style.height =
                              textarea.scrollHeight + "px";
                          }
                        }}
                      />
                      <textarea
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.reference_address_three &&
                          formik?.touched.reference_address_three
                            ? " is-invalid"
                            : "")
                        }
                        name={"reference_address_three"}
                        value={formik?.values?.reference_address_three}
                        onChange={formik?.handleChange}
                        type="text"
                        disabled
                        style={{
                          minHeight: "100px",
                          resize: "none",
                          width: "100%",
                          overflow: "hidden",
                        }}
                        onInput={(e) => {
                          e.target.style.height = "auto";
                          e.target.style.height = e.target.scrollHeight + "px";
                        }}
                        ref={(textarea) => {
                          if (textarea) {
                            textarea.style.height = "auto";
                            textarea.style.height =
                              textarea.scrollHeight + "px";
                          }
                        }}
                      />
                    </div>

                    <div className="input-group mb-3 margin-bottom-zero">
                      <span
                        className="input-group-text print-only-four-section  col-lg-3"
                        id="basic-addon3"
                      >
                        Phone No.
                      </span>
                      {/* <input
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.reference_phone_no_one &&
                          formik?.touched.reference_phone_no_one
                            ? " is-invalid"
                            : "")
                        }
                        name={"reference_phone_no_one"}
                        value={formik?.values?.reference_phone_no_one}
                        onChange={formik?.handleChange}
                        type="text"
                        disabled
                        onKeyPress={handleKeyPress}
                      /> */}
                      <ReactInputMask
                        mask="(999) 999-9999"
                        value={formik?.values?.reference_phone_no_one}
                        name={"reference_phone_no_one"}
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.reference_phone_no_one &&
                          formik?.touched.reference_phone_no_one
                            ? " is-invalid"
                            : "")
                        }
                        onChange={formik?.handleChange}
                        disabled
                      />
                      {/* <input
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.reference_phone_no_two &&
                          formik?.touched.reference_phone_no_two
                            ? " is-invalid"
                            : "")
                        }
                        name={"reference_phone_no_two"}
                        value={formik?.values?.reference_phone_no_two}
                        onChange={formik?.handleChange}
                        type="text"
                        disabled
                        onKeyPress={handleKeyPress}
                      /> */}
                      <ReactInputMask
                        mask="(999) 999-9999"
                        value={formik?.values?.reference_phone_no_two}
                        name={"reference_phone_no_two"}
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.reference_phone_no_two &&
                          formik?.touched.reference_phone_no_two
                            ? " is-invalid"
                            : "")
                        }
                        onChange={formik?.handleChange}
                        disabled
                      />
                      {/* <input
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.reference_phone_no_three &&
                          formik?.touched.reference_phone_no_three
                            ? " is-invalid"
                            : "")
                        }
                        name={"reference_phone_no_three"}
                        value={formik?.values?.reference_phone_no_three}
                        onChange={formik?.handleChange}
                        type="text"
                        disabled
                        onKeyPress={handleKeyPress}
                      /> */}
                      <ReactInputMask
                        mask="(999) 999-9999"
                        value={formik?.values?.reference_phone_no_three}
                        name={"reference_phone_no_three"}
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.reference_phone_no_three &&
                          formik?.touched.reference_phone_no_three
                            ? " is-invalid"
                            : "")
                        }
                        onChange={formik?.handleChange}
                        disabled
                      />
                    </div>

                    <div className="input-group mb-3 margin-bottom-zero ">
                      <span
                        className="input-group-text print-only-four-section  col-lg-3"
                        id="basic-addon3"
                      >
                        Relationship
                      </span>
                      <input
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.reference_relationship_one &&
                          formik?.touched.reference_relationship_one
                            ? " is-invalid"
                            : "")
                        }
                        name={"reference_relationship_one"}
                        value={formik?.values?.reference_relationship_one}
                        onChange={formik?.handleChange}
                        type="text"
                        disabled
                      />
                      <input
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.reference_relationship_two &&
                          formik?.touched.reference_relationship_two
                            ? " is-invalid"
                            : "")
                        }
                        name={"reference_relationship_two"}
                        value={formik?.values?.reference_relationship_two}
                        onChange={formik?.handleChange}
                        type="text"
                        disabled
                      />
                      <input
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.reference_relationship_three &&
                          formik?.touched.reference_relationship_three
                            ? " is-invalid"
                            : "")
                        }
                        name={"reference_relationship_three"}
                        value={formik?.values?.reference_relationship_three}
                        onChange={formik?.handleChange}
                        type="text"
                        disabled
                      />
                    </div>

                    <div className="cardTitleHome card-title h5 mt-2 margin-bottom-zero">
                      Next of Kin Contact Info
                    </div>

                    <div className="input-group mb-3 margin-bottom-zero">
                      <span
                        className="input-group-text print-only-four-section  col-lg-3"
                        id="basic-addon3"
                      >
                        Name
                      </span>
                      <input
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.next_name_one &&
                          formik?.touched.next_name_one
                            ? " is-invalid"
                            : "")
                        }
                        name={"next_name_one"}
                        value={formik?.values?.next_name_one}
                        onChange={formik?.handleChange}
                        type="text"
                        disabled
                      />
                      <input
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.next_name_two &&
                          formik?.touched.next_name_two
                            ? " is-invalid"
                            : "")
                        }
                        name={"next_name_two"}
                        value={formik?.values?.next_name_two}
                        onChange={formik?.handleChange}
                        type="text"
                        disabled
                      />
                      <input
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.next_name_three &&
                          formik?.touched.next_name_three
                            ? " is-invalid"
                            : "")
                        }
                        name={"next_name_three"}
                        value={formik?.values?.next_name_three}
                        onChange={formik?.handleChange}
                        type="text"
                        disabled
                      />
                    </div>

                    <div className="input-group mb-3 margin-bottom-zero">
                      <span
                        className="input-group-text print-only-four-section  col-lg-3"
                        id="basic-addon3"
                      >
                        Address
                      </span>
                      <textarea
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.next_address_one &&
                          formik?.touched.next_address_one
                            ? " is-invalid"
                            : "")
                        }
                        name={"next_address_one"}
                        value={formik?.values?.next_address_one}
                        onChange={formik?.handleChange}
                        type="text"
                        disabled
                        style={{
                          minHeight: "100px",
                          resize: "none",
                          width: "100%",
                          overflow: "hidden",
                        }}
                        onInput={(e) => {
                          e.target.style.height = "auto";
                          e.target.style.height = e.target.scrollHeight + "px";
                        }}
                        ref={(textarea) => {
                          if (textarea) {
                            textarea.style.height = "auto";
                            textarea.style.height =
                              textarea.scrollHeight + "px";
                          }
                        }}
                      />
                      <textarea
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.next_address_two &&
                          formik?.touched.next_address_two
                            ? " is-invalid"
                            : "")
                        }
                        name={"next_address_two"}
                        value={formik?.values?.next_address_two}
                        onChange={formik?.handleChange}
                        type="text"
                        disabled
                        style={{
                          minHeight: "100px",
                          resize: "none",
                          width: "100%",
                          overflow: "hidden",
                        }}
                        onInput={(e) => {
                          e.target.style.height = "auto";
                          e.target.style.height = e.target.scrollHeight + "px";
                        }}
                        ref={(textarea) => {
                          if (textarea) {
                            textarea.style.height = "auto";
                            textarea.style.height =
                              textarea.scrollHeight + "px";
                          }
                        }}
                      />
                      <textarea
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.next_address_three &&
                          formik?.touched.next_address_three
                            ? " is-invalid"
                            : "")
                        }
                        name={"next_address_three"}
                        value={formik?.values?.next_address_three}
                        onChange={formik?.handleChange}
                        type="text"
                        disabled
                        style={{
                          minHeight: "100px",
                          resize: "none",
                          width: "100%",
                          overflow: "hidden",
                        }}
                        onInput={(e) => {
                          e.target.style.height = "auto";
                          e.target.style.height = e.target.scrollHeight + "px";
                        }}
                        ref={(textarea) => {
                          if (textarea) {
                            textarea.style.height = "auto";
                            textarea.style.height =
                              textarea.scrollHeight + "px";
                          }
                        }}
                      />
                    </div>

                    <div className="input-group mb-3 margin-bottom-zero">
                      <span
                        className="input-group-text print-only-four-section  col-lg-3"
                        id="basic-addon3"
                      >
                        Phone No.
                      </span>
                      <input
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.next_phone_no_one &&
                          formik?.touched.next_phone_no_one
                            ? " is-invalid"
                            : "")
                        }
                        name={"next_phone_no_one"}
                        value={formik?.values?.next_phone_no_one}
                        onChange={formik?.handleChange}
                        type="text"
                        disabled
                        onKeyPress={handleKeyPress}
                      />
                      <input
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.next_phone_no_two &&
                          formik?.touched.next_phone_no_two
                            ? " is-invalid"
                            : "")
                        }
                        name={"next_phone_no_two"}
                        value={formik?.values?.next_phone_no_two}
                        onChange={formik?.handleChange}
                        type="text"
                        disabled
                        onKeyPress={handleKeyPress}
                      />
                      <input
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.next_phone_no_three &&
                          formik?.touched.next_phone_no_three
                            ? " is-invalid"
                            : "")
                        }
                        name={"next_phone_no_three"}
                        value={formik?.values?.next_phone_no_three}
                        onChange={formik?.handleChange}
                        type="text"
                        disabled
                        onKeyPress={handleKeyPress}
                      />
                    </div>

                    <div className="input-group mb-3 margin-bottom-zero">
                      <span
                        className="input-group-text print-only-four-section  col-lg-3"
                        id="basic-addon3"
                      >
                        Relationship
                      </span>
                      <input
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.next_relationship_one &&
                          formik?.touched.next_relationship_one
                            ? " is-invalid"
                            : "")
                        }
                        name={"next_relationship_one"}
                        value={formik?.values?.next_relationship_one}
                        onChange={formik?.handleChange}
                        type="text"
                        disabled
                      />
                      <input
                        className={
                          "form-control col-lg-3 print-only-four-section" +
                          (formik?.errors.next_relationship_two &&
                          formik?.touched.next_relationship_two
                            ? " is-invalid"
                            : "")
                        }
                        name={"next_relationship_two"}
                        value={formik?.values?.next_relationship_two}
                        onChange={formik?.handleChange}
                        type="text"
                        disabled
                      />
                      <input
                        className={
                          "form-control col-lg-3 print-only-four-section " +
                          (formik?.errors.next_relationship_three &&
                          formik?.touched.next_relationship_three
                            ? " is-invalid"
                            : "")
                        }
                        name={"next_relationship_three"}
                        value={formik?.values?.next_relationship_three}
                        onChange={formik?.handleChange}
                        type="text"
                        disabled
                      />
                    </div>
                  </section>
                </div>
                <div
                  style={{
                    display:
                      activeTab === "3: Credit Information Agreement"
                        ? "flex"
                        : "none",
                    justifyContent: "center",
                  }}
                >
                  <div
                    className="max-ei-make max-ei-none"
                    style={{ textAlign: "justify" }}
                    // tab="3: Credit Information Agreement"
                    // key={"3: Credit Information Agreement"}
                    ref={listingRef3}
                  >
                    <section className="seconed-page">
                      <div>
                        <div className="d-flex justify-content-center print-margin-top-exs">
                          <p className="credit-top">
                            Credit Information Agreement to Share & Investigate
                            for the Purpose of this Tenancy Application
                          </p>
                        </div>

                        <p>
                          The word “Information” means credit information,
                          personal information, information about the services
                          you use that are provided by the Landlord listed in
                          Part (C) and/or (D) below and information relating to
                          your tenancy at the Premises applied for in this
                          application including information regarding the
                          duration of your tenancy, monthly rent, emergency
                          contacts and any matters relating to your
                          lease/tenancy agreement, including misrepresentations
                          relating to, defaults under and/or breaches of your
                          lease/tenancy agreement.
                        </p>
                        <p>
                          <span style={{ fontWeight: "600" }}>
                            “Credit Information”
                          </span>{" "}
                          means information about you, including your name, age,
                          date of birth, occupation, place of residence,
                          previous places of residence, occupancy length,
                          marital status, co-occupant’s/spouse’s/same-sex
                          partner’s name and age, number of dependents,
                          particulars of education or professional
                          qualifications, places of employment, previous places
                          of employment, employment duration, estimated income,
                          paying habits, outstanding debt obligations, cost of
                          living obligations, involvement in bankruptcy
                          proceedings or landlord and tenant disputes, assets,
                          and banking information (including account and credit
                          card information).
                        </p>

                        <p>
                          <span style={{ fontWeight: "600" }}>
                            “Personal Information”{" "}
                          </span>{" "}
                          means information about you other than credit
                          information that is relevant to your suitability as a
                          tenant, including your social insurance number
                          (optional), driver’s license number, vehicle license
                          plate number, vehicle make and year, and information
                          from references which you provide about your
                          character, reputation, physical or personal
                          characteristics or mode of living or about any other
                          matter concerning you that is relevant to your
                          suitability as a tenant.
                        </p>
                        <p>Collection, Use and Disclosure of Information:</p>
                        <p>
                          In consideration for the Landlord accepting you as a
                          tenant and entering into a lease/tenancy agreement
                          with you, you expressly consent to and authorize the
                          following:
                        </p>
                        <ol>
                          <li className="mb-3">
                            The Landlord may obtain Information about you
                            through a tenant check and/or credit or consumer
                            report conducted by Rent Check Credit Bureau or
                            GateMaster Inc./Tenchek or Equifax or Trans Union or
                            any other subsequent credit collection authority
                            hereinafter called the information facilitator and
                            as permitted or required by law. You expressly
                            authorize the information facilitator to provide
                            Information regarding you to the Landlord
                          </li>
                          <li className="mb-3">
                            The Landlord may use Information about you to
                            determine your suitability as a tenant and as
                            permitted or required by law.
                          </li>
                          <li className="mb-2">
                            The Landlord may disclose Information about you as
                            permitted or required by law and to any information
                            facilitator in order to be included within a
                            database of tenant information, and/or within a file
                            on you, for purposes of:
                          </li>
                          <ul className="mb-3">
                            <li>
                              tenant reporting and credit reporting in
                              accordance with the Consumer Reporting Act
                              (Ontario);
                            </li>
                            <li>
                              establishing a credit history and a rental history
                            </li>
                            <li>
                              comparing with aggregate statistical data for
                              purposes of tenancy and credit scoring; and
                            </li>
                            <li>supporting the credit approval process.</li>
                            <li>
                              providing contact information to Bell, Rogers and
                              Metergy Toronto Hydro
                            </li>
                          </ul>
                          <li className="mb-3">
                            You expressly authorize the information facilitator
                            to retain Information regarding you indefinitely for
                            the purposes outlined in section 3 above, subject to
                            any applicable legal restrictions.
                          </li>
                          <li className="mb-3">
                            You expressly authorize the information facilitator
                            to disclose Information regarding you to its members
                            and subscribers as required or permitted by law and
                            for the purposes outlined in section 3 above
                          </li>
                          <li className="mb-3">
                            You agree that you will not withdraw your
                            authorization and consent to the collection, use and
                            disclosure of Information about you by the
                            information facilitator as outlined in sections 1 to
                            5 above.
                          </li>
                          <li className="mb-3">
                            You agree that all statements on this Residential
                            Rental Application are true and you expressly
                            authorize all references given to release
                            information about you to the Landlord for
                            verification subject to sections 1 to 5.
                          </li>
                        </ol>
                        <p className="credit-bottom">
                          I/We the undersigned applicant(s) hereby confirm the
                          “Information” section set out above in this
                          Application and by my/our signature, I/we have read
                          and understood all the conditions and agree to its
                          terms and contents.
                        </p>
                      </div>
                      <div className="input-group mb-3 print-margin-top-cs">
                        <span
                          className="input-group-text  col-lg-1 print-tens"
                          id="basic-addon3"
                        >
                          Building
                        </span>
                        <input
                          className={"form-control col-lg-3 print-fiveteen"}
                          name={"credit_building"}
                          value={formik?.values?.building}
                          onChange={formik?.handleChange}
                          type="text"
                          disabled
                        />
                        <span
                          className="input-group-text  col-lg-1 print-tens"
                          id="basic-addon3"
                        >
                          Unit
                        </span>
                        <input
                          className={"form-control col-lg-3 print-fiveteen"}
                          name={"credit_unit"}
                          value={formik?.values?.suite}
                          onChange={formik?.handleChange}
                          type="text"
                          disabled
                        />
                        <span
                          className="input-group-text  col-lg-1 print-only-four-section"
                          id="basic-addon3"
                        >
                          Date
                        </span>
                        <DatePicker
                          className="form-control col-lg-2 print-only-four-section font-weight-print"
                          name="credit_date"
                          value={creditDate}
                          placeholder="credit_date"
                          onChange={handleCreditDate}
                          disabled
                        />
                      </div>

                      {/* signs print-only-half*/}
                      <div className="row margin-bottom-zero margin-top-zero ">
                        <div
                          className="col-lg-4 "
                          style={{ position: "absolute", visibility: "hidden" }}
                        >
                          <div
                            id="canvas_Wraper_credit_bm"
                            // className="row"
                            onLoad={() => getDimensionCreditBm()}
                          >
                            <div className="mb-3 tenent-resolution">
                              <SignatureCanvas
                                ref={(ref) => signatureHandlerCreditBm(ref)}
                                penColor="grey"
                                canvasProps={{
                                  className: "canvasSignature",

                                  width: 400,

                                  height: 150,
                                }}
                              />
                              <p
                                className=" text-center mt-3"
                                style={{
                                  borderTop: "1px solid black",
                                  fontWeight: "500",
                                }}
                              >
                                Building Manager
                              </p>
                            </div>
                            {/* <div
                              style={{
                                position: "absolute",
                                bottom: "4rem",
                                width: "100%",
                                height: "7rem",
                                display: "flex",
                                alignItems: "center",
                                justifyContent:
                                  "center" 
                              }}
                            >
                              <img className="" src={statics} />
                            </div> */}
                          </div>
                        </div>
                        <div className="col-lg-4 print-only ">
                          <div
                            id="canvas_Wraper_creditTenent1"
                            // className="row"
                            onLoad={() => getDimensionCreditTenent1()}
                          >
                            <div
                              className=" mb-3 tenent-resolution"
                              // style={{ position: "relative" }}
                            >
                              <SignatureCanvas
                                ref={(ref) =>
                                  signatureHandlerCreditTenent1(ref)
                                }
                                penColor="grey"
                                canvasProps={{
                                  className: "canvasSignature-tenants",
                                  // width: `${
                                  //   divWidhCreditTenent1
                                  //     ? divWidhCreditTenent1
                                  //     : ""
                                  // }`,
                                  width: 400,

                                  height: 150,
                                }}
                              />
                              <p
                                className=" text-center mt-3"
                                style={{
                                  borderTop: "1px solid black",
                                  fontWeight: "500",
                                }}
                              >
                                Tenant Signature{" "}
                              </p>

                              <img
                                className="none-btn"
                                src={creditTenantView}
                                style={{
                                  left: "50%",
                                  right: "50%",
                                  minHeight: "6rem",
                                  maxHeight: "6rem",
                                  transform: "translate(-50%, -50%)",
                                  bottom: "0.5rem",
                                  // bottom: "0rem",
                                  position: "absolute",
                                }}
                              />
                              <img
                                className="d-none print-only-block"
                                src={creditTenantView}
                                style={{
                                  left: "50%",
                                  right: "50%",
                                  transform: "translate(-50%, -50%)",
                                  bottom: "0.5rem",
                                  minHeight: "6rem",
                                  maxHeight: "6rem",
                                  // bottom: "0rem",
                                  position: "absolute",
                                }}
                              />
                              {/* <img
                                className="d-none print-only-block"
                                src={creditTenantView}
                                style={{
                                  position: "absolute",
                                  bottom: "2rem",
                                  width: "100%",
                                  height: "7rem",
                                }}
                              /> */}
                            </div>
                            {/* <div className="canvasSignature" style={{position:"absolute"}}></div> */}
                          </div>
                        </div>
                        <div
                          // className="col-lg-4"
                          // style={{ visibility: "hidden" }}
                          className={`col-lg-4 print-only  ${
                            fields && fields.length >= 2
                              ? "visible"
                              : "invisible"
                          }`}
                        >
                          <div
                            id="canvas_Wraper_creditTenent2"
                            // className="row"
                            onLoad={() => getDimensionCreditTenent2()}
                          >
                            <div className="mb-3 tenent-resolution">
                              <SignatureCanvas
                                ref={(ref) =>
                                  signatureHandlerCreditTenent2(ref)
                                }
                                penColor="grey"
                                canvasProps={{
                                  className: "canvasSignature-tenants",
                                  // width: `${
                                  //   divWidhCreditTenent2
                                  //     ? divWidhCreditTenent2
                                  //     : ""
                                  // }`,
                                  width: 400,

                                  height: 150,
                                }}
                              />
                              <p
                                className=" text-center mt-3"
                                style={{
                                  borderTop: "1px solid black",
                                  fontWeight: "500",
                                }}
                              >
                                Tenant Signature
                              </p>

                              <img
                                className="none-btn"
                                src={creditTenantView2}
                                style={{
                                  left: "50%",
                                  right: "50%",
                                  transform: "translate(-50%, -50%)",
                                  bottom: "0.5rem",
                                  minHeight: "6rem",
                                  maxHeight: "6rem",
                                  // bottom: "0rem",
                                  position: "absolute",
                                }}
                              />
                              <img
                                className="d-none print-only-block"
                                src={creditTenantView2}
                                style={{
                                  left: "50%",
                                  right: "50%",
                                  transform: "translate(-50%, -50%)",
                                  bottom: "0.5rem",
                                  minHeight: "6rem",
                                  maxHeight: "6rem",
                                  // bottom: "0rem",
                                  position: "absolute",
                                }}
                              />

                              {/* 
                              <img
                                className="d-none print-only-block"
                                src={creditTenantView2}
                                style={{
                                  position: "absolute",
                                  bottom: "2rem",
                                  width: "100%",
                                  height: "7rem",
                                }}
                              /> */}
                            </div>
                          </div>
                        </div>

                        <div
                          // className="col-lg-4"
                          // style={{ visibility: "hidden" }}
                          //  print-only
                          className={`col-lg-4 print-only ${
                            fields && fields.length === 3
                              ? "visible"
                              : "invisible"
                          }`}
                          style={{
                            position:
                              fields.length === 3 ? "static" : "absolute",
                          }}
                        >
                          <div
                            id="canvas_Wraper_creditTenent3"
                            className="row"
                            onLoad={() => getDimensionCreditTenent3()}
                          >
                            <div className="col-lg-6 mb-3 tenent-resolution">
                              <SignatureCanvas
                                ref={(ref) =>
                                  signatureHandlerCreditTenent3(ref)
                                }
                                penColor="grey"
                                canvasProps={{
                                  className: "canvasSignature-tenants",
                                  // width: `${
                                  //   divWidhCreditTenent2
                                  //     ? divWidhCreditTenent2
                                  //     : ""
                                  // }`,
                                  width: 400,

                                  height: 150,
                                }}
                              />
                              <p
                                className="canvasSignature text-center mt-3"
                                style={{
                                  borderTop: "1px solid black",
                                  fontWeight: "500",
                                }}
                              >
                                Tenant Signature
                              </p>

                              <img
                                className="none-btn"
                                src={creditTenantView3}
                                style={{
                                  left: "50%",
                                  right: "50%",
                                  transform: "translate(-50%, -50%)",
                                  bottom: "0.5rem",
                                  minHeight: "6rem",
                                  maxHeight: "6rem",
                                  // bottom: "0rem",
                                  position: "absolute",
                                }}
                              />
                              <img
                                className="d-none print-only-block"
                                src={creditTenantView3}
                                style={{
                                  left: "50%",
                                  right: "50%",
                                  transform: "translate(-50%, -50%)",
                                  bottom: "0.5rem",
                                  minHeight: "6rem",
                                  maxHeight: "6rem",
                                  // bottom: "0rem",
                                  position: "absolute",
                                }}
                              />
                              {/* <img
                                className="d-none print-only-block"
                                src={creditTenantView3}
                                style={{
                                  position: "absolute",
                                  bottom: "2rem",
                                  width: "100%",
                                  height: "7rem",
                                }}
                              /> */}
                            </div>
                          </div>
                        </div>
                      </div>
                      <p
                        style={{
                          background: "black",
                          color: "white",
                          padding: "10px",
                        }}
                        className=" margin-top-zero "
                      >
                        Important Information: Do not sign unless you have read
                        and understood the conditions herein contained
                      </p>
                    </section>
                  </div>
                </div>
                <div
                  tab="4: No Cosmetic Repair Agreement"
                  key={"4: No Cosmetic Repair Agreement"}
                  style={{
                    display:
                      activeTab === "4: No Cosmetic Repair Agreement"
                        ? "flex"
                        : "none",
                    justifyContent: "center",
                  }}
                  className="margin-top-zero"
                >
                  <div
                    ref={listingRef4}
                    className=" max-width-justify max-ei-none max"
                  >
                    <div className="d-flex justify-content-center print-margin-top-exsa">
                      <p className="credit-top mt-3 ">
                        No Cosmetic Repair Agreement
                      </p>
                    </div>

                    <p className="mb-4 print-margin-top-exs">
                      I/we, the undersigned prospective tenants acknowledge that
                      no promise of cosmetic repairs by the Landlord or its
                      superintendent were made to me verbally or given to me as
                      a condition of rental to carry out decorating repairs in
                      the apartment including but not limited to painting, floor
                      sanding, retiling of bathroom, counter top replacement,
                      cleaning of appliances, cleaning of apartment, removal of
                      wallpaper and removal of existing carpets. Any outstanding
                      repairs required under the Tenant protection Act or
                      successor legislation will be carried out after the tenant
                      has notified the Landlord in writing of the items
                      requiring repair on the forms supplied by the Landlord and
                      reasonable time will be allowed and agreed upon by both
                      parties to carry out these repairs after the tenant has
                      moved in, as trades become available.
                    </p>
                    <p className="mb-4">
                      No other contractual agreements have been made verbally or
                      otherwise as to form part of this tenancy agreement other
                      than those expressed in this form and in the application
                      for rental and the lease agreement. I unconditionally
                      accept possession of my suite on the first day of the
                      tenancy agreement or if the Landlord is unable to give
                      possession, then on the day the keys are made available to
                      me. This acceptance is made regardless of the condition of
                      the suite. I will not hold the Landlord responsible for
                      delays howsoever caused. I agree that no alterations or
                      decorating will be made during my tenancy without prior
                      written approval from the Landlord. Should I during the
                      term of my tenancy re-decorate or make alterations, I will
                      not hold the Landlord responsible to make good any
                      decorations to plaster repairs, howsoever caused, to match
                      tenant's existing alterations.
                    </p>
                    {/* <p>
                      Smoke and or CO Detector and Fire Equipment
                      Acknowledgement
                    </p> */}
                    <div className="d-flex justify-content-center">
                      <p className="credit-top">
                        Smoke and or CO Detector and Fire Equipment
                        Acknowledgement
                      </p>
                    </div>
                    <p className="mb-4">
                      We, the undersigned tenant(s) hereby verify after having
                      been shown that a smoke detector and CO detector if
                      applicable has been installed or is supplied in the suite
                      and is in proper working order as of today. I further
                      agree to notify the Landlord in the event that the smoke
                      detector stops functioning in any way that I am aware of.
                      I further agree that I shall not tamper or thwart its
                      functionality nor will I in any way hamper its proper use
                      by either removing it, cutting the wires supplying the
                      power, removing the battery. Further, the tenant hereby
                      verifies that a front entrance suite door closer has been
                      installed to the front suite door stated on the face of
                      this application. The Tenant hereby agrees not to remove
                      or disable the door closer. In the event that the door
                      closer has been removed or disabled by the tenant for
                      whatever reason, the tenant hereby assumes full
                      responsibility for such removal and will be held
                      responsible for the cost of replacement of the same and
                      any other damages the Landlord has incurred due to its
                      removal for fines and any other damages howsoever caused.
                    </p>
                    <p className="mb-4">
                      The Tenant hereby agrees not to remove or disable the horn
                      attached to the Fire Alarm System in the suite. In the
                      event that a speaker or horn attached to the Fire Alarm
                      System has been removed or disabled by the tenant for
                      whatever reason, the tenant hereby assumes full
                      responsibility for such removal or disabling in
                      contravention by such authorities who have jurisdiction
                      and will be held responsible for the cost of replacement
                      of the same and any other damages the Landlord has
                      incurred due to its removal for fines and any other
                      damages howsoever caused.
                    </p>

                    <p className="margin-bottom-print ">
                      The Tenant hereby agrees not to remove, install or alter
                      the locking devices on his door without written consent
                      first. In the event that the tenant changes, removes,
                      alters the locking device on his suite front entrance
                      door, the tenant will be held responsible for the
                      replacement of the building suite lock and if a lock has
                      been added, then the tenant agrees to pay for the cost of
                      the replacement of the suite door including all material
                      and labor.
                    </p>
                    <div className="row">
                      <div
                        id="canvas_Wraper_smoke print-align"
                        className="d-flex col-lg-4  print-only-sign"
                        onLoad={() => getDimensionSmoke()}
                        style={{
                          flexDirection: "column",
                          justifyContent: "end",
                        }}
                      >
                        <div
                          className=" mb-3 tenent-resolution d-flex fit-c"
                          style={{
                            flexDirection: "column",
                            // alignItems: "center",
                            position: "relative",
                            width: "100%",
                          }}
                        >
                          <SignatureCanvas
                            ref={(ref) => signatureHandlerTenentSmoke(ref)}
                            penColor="grey"
                            canvasProps={{
                              className: "canvasSignature-tenants",
                              // width: `${divWidthSmoke ? divWidthSmoke : ""}`,
                              width: 400,

                              height: 150,
                            }}
                          />
                          <p
                            className="text-center mt-3"
                            style={{
                              borderTop: "1px solid black",
                              fontWeight: "500",
                            }}
                          >
                            Tenant Signature
                          </p>

                          <img
                            className="none-btn"
                            src={smokeTenantView}
                            style={{
                              left: "50%",
                              right: "50%",
                              transform: "translate(-50%, -50%)",
                              // bottom: "0.6rem",
                              minHeight: "6rem",
                              maxHeight: "6rem",
                              maxWidth: "100%",
                              bottom: "0rem",
                              position: "absolute",
                            }}
                          />
                          <img
                            className="d-none print-only-block"
                            src={smokeTenantView}
                            style={{
                              left: "50%",
                              right: "50%",
                              transform: "translate(-50%, -50%)",
                              // bottom: "0.6rem",
                              minHeight: "6rem",
                              maxHeight: "6rem",
                              maxWidth: "100%",

                              bottom: "0rem",
                              position: "absolute",
                            }}
                          />
                          {/* <img
                            className="d-none print-only-block"
                            src={smokeTenantView}
                            style={{
                              position: "absolute",
                              bottom: "2.8rem",
                              width: "100%",
                              height: "7rem",
                            }}
                          /> */}
                        </div>
                      </div>
                      <div
                        id="canvas_Wraper_smoke print-align"
                        // className="d-flex margin-bottom-againss col-lg-4"
                        className={`d-flex  col-lg-4  print-only-sign ${
                          fields && fields.length >= 2 ? "visible" : "invisible"
                        }`}
                        onLoad={() => getDimensionSmoke()}
                        // style={{ flexDirection: "column", alignItems: "end" }}
                        style={{ alignItems: "end" }}
                        // , justifyContent: "end"
                      >
                        <div
                          className=" mb-3 tenent-resolution d-flex"
                          style={{
                            flexDirection: "column",
                            // alignItems: "center",
                            position: "relative",
                            width: "100%",
                          }}
                        >
                          <SignatureCanvas
                            ref={(ref) => signatureHandlerTenentSmoke2(ref)}
                            penColor="grey"
                            canvasProps={{
                              className: "canvasSignature-tenants",
                              // width: `${divWidthSmoke ? divWidthSmoke : ""}`,
                              width: 400,

                              height: 150,
                            }}
                          />
                          <p
                            className="text-center mt-3"
                            style={{
                              borderTop: "1px solid black",
                              fontWeight: "500",
                            }}
                          >
                            Tenant Signature
                          </p>
                          <img
                            className="none-btn"
                            src={smokeTenantView2}
                            style={{
                              left: "50%",
                              right: "50%",
                              transform: "translate(-50%, -50%)",
                              // bottom: "0.5rem",
                              maxWidth: "100%",

                              minHeight: "6rem",
                              maxHeight: "6rem",
                              bottom: "0rem",
                              position: "absolute",
                            }}
                          />
                          <img
                            className="d-none print-only-block"
                            src={smokeTenantView2}
                            style={{
                              left: "50%",
                              right: "50%",
                              transform: "translate(-50%, -50%)",
                              // bottom: "0.5rem",
                              maxWidth: "100%",

                              minHeight: "6rem",
                              maxHeight: "6rem",
                              bottom: "0rem",
                              position: "absolute",
                            }}
                          />
                          {/* <img
                            className="d-none print-only-block"
                            src={smokeTenantView2}
                            style={{
                              position: "absolute",
                              bottom: "2.8rem",
                              width: "100%",
                              height: "7rem",
                            }}
                          /> */}
                        </div>
                      </div>

                      <div
                        id="canvas_Wraper_smoke print-align"
                        // className="d-flex  col-lg-4"
                        className={`d-flex  col-lg-4  print-only-sign  ${
                          fields && fields.length === 3
                            ? "visible"
                            : "invisible"
                        }`}
                        onLoad={() => getDimensionSmoke()}
                        style={{ alignItems: "end" }}
                      >
                        {/* justifyContent: "end" */}
                        <div
                          className=" mb-3 tenent-resolution d-flex"
                          style={{
                            flexDirection: "column",
                            // alignItems: "center",
                            position: "relative",
                            width: "100%",
                          }}
                        >
                          <SignatureCanvas
                            ref={(ref) => signatureHandlerTenentSmoke3(ref)}
                            penColor="grey"
                            canvasProps={{
                              className: "canvasSignature-tenants",
                              // width: `${divWidthSmoke ? divWidthSmoke : ""}`,
                              width: 400,

                              height: 150,
                            }}
                          />
                          <p
                            className=" text-center mt-3"
                            style={{
                              borderTop: "1px solid black",
                              fontWeight: "500",
                            }}
                          >
                            Tenant Signature
                          </p>

                          <img
                            className="none-btn"
                            src={smokeTenantView3}
                            style={{
                              left: "50%",
                              right: "50%",
                              transform: "translate(-50%, -50%)",
                              // bottom: "0rem",
                              maxWidth: "100%",

                              minHeight: "6rem",
                              maxHeight: "6rem",
                              bottom: "0rem",
                              position: "absolute",
                            }}
                          />
                          <img
                            className="d-none print-only-block"
                            src={smokeTenantView3}
                            style={{
                              left: "50%",
                              right: "50%",
                              transform: "translate(-50%, -50%)",
                              // bottom: "0rem",
                              maxWidth: "100%",

                              minHeight: "6rem",
                              maxHeight: "6rem",
                              bottom: "0rem",
                              position: "absolute",
                            }}
                          />
                          {/* <img
                            className="d-none print-only-block"
                            src={smokeTenantView3}
                            style={{
                              position: "absolute",
                              bottom: "2.8rem",
                              width: "100%",
                              height: "7rem",
                            }}
                          /> */}
                        </div>
                      </div>
                    </div>

                    <div className="d-flex justify-content-center seconed-page ">
                      <p className="credit-top">
                        Window Opening Limiter Agreement
                      </p>
                    </div>
                    <p className="mb-3">
                      I, the tenant, hereby request that the window limiter
                      device be installed in my suite. Should I remove it, I do
                      hereby accept responsibility for any problems or mishaps
                      or accidents that may arise due to my decision and save
                      the Landlord from harm, as a result of injury to myself,
                      members of my family, visitors wanted or unwanted as a
                      result of my not having the window opening limiters
                      installed. The screening is not designed nor meant to keep
                      children from falling out of the windows. I undertake not
                      to leave children unattended or leave anything that
                      children can climb onto and thereby increase the risk of
                      accidentally falling out of the window or off the balcony.
                    </p>
                    <div className="row">
                      <div
                        id="canvas_Wraper_limmiter print-align"
                        className="d-flex col-lg-4 print-only-sign"
                        onLoad={() => getDimensionLimmiter()}
                        style={{
                          flexDirection: "column",
                          // alignItems: "end",
                          // position: "relative",
                        }}
                      >
                        <div
                          className=" mb-3 tenent-resolution d-flex"
                          style={{
                            flexDirection: "column",
                            alignItems: "center",
                            position: "relative",
                          }}
                        >
                          <SignatureCanvas
                            ref={(ref) => signatureHandlerTenentLimmiter(ref)}
                            penColor="grey"
                            canvasProps={{
                              className: "canvasSignature-tenants",
                              // width: `${
                              //   divWidthLimmiter ? divWidthLimmiter : ""
                              // }`,
                              width: 400,

                              height: 150,
                            }}
                          />
                          <p
                            className="text-center mt-3"
                            style={{
                              borderTop: "1px solid black",
                              fontWeight: "500",
                              width: "100%",
                            }}
                          >
                            Tenant Signature
                          </p>
                          <img
                            className="none-btn"
                            src={limmiterTenantView}
                            style={{
                              left: "50%",
                              right: "50%",
                              transform: "translate(-50%, -50%)",
                              // bottom: "1.5rem",
                              // bottom: "0rem",
                              minHeight: "6rem",
                              maxHeight: "6rem",
                              bottom: "0rem",
                              position: "absolute",
                            }}
                          />
                          <img
                            className="d-none print-only-block"
                            src={limmiterTenantView}
                            style={{
                              left: "50%",
                              right: "50%",
                              transform: "translate(-50%, -50%)",
                              // bottom: "1.5rem",
                              minHeight: "6rem",
                              maxHeight: "6rem",
                              bottom: "0rem",
                              position: "absolute",
                            }}
                          />
                          {/* 
                          <img
                            className="d-none print-only-block canvasSignature-tenants"
                            src={limmiterTenantView}
                            style={{
                              position: "absolute",
                              bottom: "9rem",
                              height: "7rem",
                            }}
                          /> */}
                        </div>
                        <div className="mt-3">
                          <div className="form-group">
                            <Space direction="vertical">
                              <DatePicker
                                className="form-control font-weight-print"
                                name="limmiter-date"
                                value={limmiterDate}
                                placeholder="Date"
                                onChange={handleLimmiterDate}
                                disabled
                              />
                            </Space>
                          </div>
                        </div>
                      </div>

                      <div
                        id="canvas_Wraper_limmiter print-align"
                        // className="d-flex col-lg-4"
                        className={`d-flex col-lg-4 print-only-sign  ${
                          fields && fields.length >= 2 ? "visible" : "invisible"
                        }`}
                        onLoad={() => getDimensionLimmiter()}
                        style={{
                          flexDirection: "column",
                          alignItems: "end",
                          // position: "relative",
                        }}
                      >
                        <div
                          className=" mb-3 tenent-resolution d-flex"
                          style={{
                            flexDirection: "column",
                            alignItems: "center",
                            width: "100%",
                            position: "relative",
                          }}
                        >
                          <SignatureCanvas
                            ref={(ref) => signatureHandlerTenentLimmiter2(ref)}
                            penColor="grey"
                            canvasProps={{
                              className: "canvasSignature-tenants",
                              // width: `${
                              //   divWidthLimmiter ? divWidthLimmiter : ""
                              // }`,
                              width: 400,

                              height: 150,
                            }}
                          />
                          <p
                            className="text-center mt-3"
                            style={{
                              borderTop: "1px solid black",
                              fontWeight: "500",
                              width: "100%",
                            }}
                          >
                            Tenant Signature
                          </p>

                          <img
                            className="none-btn"
                            src={limmiterTenantView2}
                            style={{
                              left: "50%",
                              right: "50%",
                              transform: "translate(-50%, -50%)",
                              // bottom: "0rem",
                              minHeight: "6rem",
                              maxHeight: "6rem",
                              bottom: "0rem",
                              position: "absolute",
                            }}
                          />
                          <img
                            className="d-none print-only-block"
                            src={limmiterTenantView2}
                            style={{
                              left: "50%",
                              right: "50%",
                              transform: "translate(-50%, -50%)",
                              // bottom: "0rem",
                              minHeight: "6rem",
                              maxHeight: "6rem",
                              bottom: "0rem",
                              position: "absolute",
                            }}
                          />

                          {/* <img
                            className="d-none print-only-block canvasSignature-tenants"
                            src={limmiterTenantView2}
                            style={{
                              position: "absolute",
                              bottom: "9rem",
                              height: "7rem",
                            }}
                          /> */}
                        </div>
                        <div className="mt-3" style={{ width: "100%" }}>
                          <div className="form-group">
                            <Space direction="vertical">
                              <DatePicker
                                className="form-control font-weight-print"
                                name="limmiter-date"
                                value={limmiterDate2}
                                placeholder="Date"
                                onChange={handleLimmiterDate2}
                                disabled
                              />
                            </Space>
                          </div>
                        </div>
                      </div>

                      <div
                        id="canvas_Wraper_limmiter print-align"
                        // className="d-flex col-lg-4"
                        className={`d-flex col-lg-4  print-only-sign ${
                          fields && fields.length === 3
                            ? "visible"
                            : "invisible"
                        }`}
                        onLoad={() => getDimensionLimmiter()}
                        style={{
                          flexDirection: "column",
                          // alignItems: "end",
                          // position: "relative",
                        }}
                      >
                        <div
                          className="mb-3 tenent-resolution d-flex"
                          style={{
                            flexDirection: "column",
                            alignItems: "center",
                            position: "relative",
                          }}
                        >
                          <SignatureCanvas
                            ref={(ref) => signatureHandlerTenentLimmiter3(ref)}
                            penColor="grey"
                            canvasProps={{
                              className: "canvasSignature-tenants",
                              // width: `${
                              //   divWidthLimmiter ? divWidthLimmiter : ""
                              // }`,
                              width: 400,

                              height: 150,
                            }}
                          />
                          <p
                            className=" text-center mt-3"
                            style={{
                              borderTop: "1px solid black",
                              fontWeight: "500",
                              width: "100%",
                            }}
                          >
                            Tenant Signature
                          </p>

                          <img
                            className="none-btn"
                            src={limmiterTenantView3}
                            style={{
                              left: "50%",
                              right: "50%",
                              transform: "translate(-50%, -50%)",
                              // bottom: "0rem",
                              minHeight: "6rem",
                              maxHeight: "6rem",
                              bottom: "0rem",
                              position: "absolute",
                            }}
                          />
                          <img
                            className="d-none print-only-block"
                            src={limmiterTenantView3}
                            style={{
                              left: "50%",
                              right: "50%",
                              transform: "translate(-50%, -50%)",
                              // bottom: "0rem",
                              minHeight: "6rem",
                              maxHeight: "6rem",
                              bottom: "0rem",
                              position: "absolute",
                            }}
                          />
                          {/* <img
                            className="d-none print-only-block canvasSignature-tenants"
                            // className="none-btn"
                            src={limmiterTenantView3}
                            style={{
                              position: "absolute",
                              bottom: "9rem",
                              height: "7rem",
                            }}
                          /> */}
                        </div>
                        <div className="mt-3" style={{ width: "100%" }}>
                          <div className="form-group">
                            <Space direction="vertical">
                              <DatePicker
                                className="form-control font-weight-print"
                                name="limmiter-date"
                                value={limmiterDate}
                                placeholder="Date"
                                onChange={handleLimmiterDate}
                                disabled
                              />
                            </Space>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  tab="5: Upload Files"
                  key={"5: Upload Files"}
                  style={{
                    display: activeTab === "5: Upload Files" ? "block" : "none",
                  }}
                >
                  <section ref={listingRef5}>
                    <p
                      style={{
                        textAlign: "center",
                        marginBottom: "2rem ",
                        fontWeight: "700",
                      }}
                    >
                      Your data is secured using latest Amazon security
                    </p>

                    <div className="row" style={{ alignItems: "flex-start" }}>
                      {/* images */}
                      <div
                        className="col-lg-6 d-flex flex-column image-control"
                        style={{ alignItems: "center" }}
                      >
                        <div style={{ minHeight: "10rem" }}>
                          <p style={{ fontWeight: "700", marginTop: "10px" }}>
                            1: Upload 1 Piece of your Government Identification
                          </p>
                          <p
                            style={{ fontWeight: "700", marginBottom: "14px" }}
                          >
                            {" "}
                            Acceptable Government identification:
                          </p>
                          <ul
                            style={{ fontWeight: "500", paddingLeft: "1rem" }}
                          >
                            <li>Drivers License</li>
                            <li>Passport</li>
                            <li>
                              Citizenship or Immigration Status Certificate with
                              photo
                            </li>
                            {filesDataGov !== null &&
                              filesDataGov !== undefined &&
                              filesDataGov.length > 0 && (
                                <p
                                  style={{
                                    fontWeight: "600",
                                    marginBottom: "2.6rem",
                                  }}
                                >
                                  Click on the Download icon to Download{" "}
                                </p>
                              )}
                          </ul>
                          <div>
                            {filesDataGov !== null &&
                            filesDataGov !== undefined &&
                            filesDataGov.length > 0 ? (
                              <>
                                {filesDataGov?.map((file, index) => (
                                  <div
                                    key={index}
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      gap: "10px",
                                      marginBottom: "7px",
                                    }}
                                  >
                                    <p
                                      title={file}
                                      style={{
                                        marginBottom: "0px",
                                        paddingLeft: "1rem",
                                        width: "11rem",
                                        overflow: "hidden",
                                      }}
                                    >
                                      {/* {file}{" "} */}
                                      {/* {getExtension(file)} */}
                                      {file?.length > 10
                                        ? `${file.substring(0, 10)}.` +
                                          getExtension(file)
                                        : file}
                                    </p>
                                    <img
                                      src={download}
                                      alt="Preview"
                                      style={{
                                        maxWidth: "23px",
                                        borderRadius: "13px",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => handleDownloadImage(file)}
                                    />
                                  </div>
                                ))}
                                <hr />

                                {filesDataGov !== null &&
                                  filesDataGov !== undefined &&
                                  filesDataGov?.length > 0 && (
                                    <div
                                      className="row"
                                      style={{ marginBottom: "3rem" }}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          paddingLeft: "2rem",
                                        }}
                                      >
   <p>
                                            All{" "}
                                            {checkDownloadImage
                                              ? "Downloading..."
                                              : "Download"}
                                          </p>                                        <div
                                          style={{
                                            marginBottom: "0px",
                                            paddingLeft: "1rem",
                                            width: "11rem",
                                            overflow: "hidden",
                                          }}
                                        >
                                          <img
                                            src={download}
                                            alt="Preview"
                                            style={{
                                              maxWidth: "23px",
                                              borderRadius: "13px",
                                              cursor: "pointer",
                                            }}
                                            onClick={() =>
                                              handleDownloadImageAll()
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  )}
                              </>
                            ) : (
                              <p style={{ paddingLeft: "1rem" }}>
                                Not Submitted
                              </p>
                            )}
                          </div>
                        </div>
                        {/* <div>
                          {filesDataGov !== null &&
                          filesDataGov !== undefined ? (
                            <>
                              <p>
                                Click on the Download icon to download the the
                                Tenant Government Identification{" "}
                              </p>
                              <img
                                src={download}
                                alt="Preview"
                                style={{
                                  maxWidth: "40px",
                                  borderRadius: "13px",
                                  cursor: "pointer",
                                }}
                                onClick={handleDownloadImage}
                              />
                            </>
                          ) : (
                            <p>Not Submitted</p>
                          )}
                        </div> */}
                      </div>
                      {/* Images */}

                      {/* Filesssss==>>>>>> */}
                      <div className="col-lg-6 d-flex flex-column justify-content-center image-control">
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            paddingLeft: "10rem",
                            // paddingRight:"7rem"
                            // alignItems: "flex-start",
                          }}
                        >
                          <div style={{}}>
                            <p
                              style={{
                                fontWeight: "700",
                                // textAlign: "center",
                                paddingRight: "9rem",
                                marginTop: "10px",
                                marginBottom: "14px",
                              }}
                            >
                              2: Upload Proof of Employment (Job Letter)
                            </p>

                            <p
                              style={{
                                fontWeight: "700",
                                paddingRight: "12rem",
                                // marginBottom: "0px",
                                // textAlign: "center",
                              }}
                            >
                              Acceptable Proof of Employment:
                            </p>

                            <div
                              style={{
                                display: "flex",
                                // justifyContent: "center",
                                // marginBottom: "4rem ",
                                fontWeight: "500",
                              }}
                            >
                              <ul style={{ paddingLeft: "1rem" }}>
                                <li>
                                  Two most recent paystubs and recent job income
                                  letter
                                </li>
                                <li>A recent T4, not older than last year’s</li>{" "}
                                <li>
                                  The most recent tax return if self-employed
                                </li>{" "}
                                <li>
                                  A letter from your bank with banking
                                  information and summary
                                </li>
                                {filesDataProof !== null &&
                                  filesDataProof !== undefined &&
                                  filesDataProof.length > 0 && (
                                    <p
                                      style={{
                                        fontWeight: "600",
                                        marginBottom: "0px",
                                      }}
                                    >
                                      Click on the Download icon to Download{" "}
                                    </p>
                                  )}
                              </ul>
                            </div>
                            <div>
                              {filesDataProof !== null &&
                              filesDataProof !== undefined &&
                              filesDataProof?.length > 0 ? (
                                <>
                                  {filesDataProof?.map((file, index) => (
                                    <div
                                      key={index}
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: "10px",
                                        width: "30rem",
                                      }}
                                    >
                                      <p
                                        title={file}
                                        style={{
                                          marginBottom: "0px",
                                          paddingLeft: "1rem",
                                          width: "11rem",
                                          overflow: "hidden",
                                          marginBottom: "7px",
                                        }}
                                      >
                                        {/* {file}{" "} */}
                                        {/* {file?.length > 50
                                          ? `${file.substring(0, 20)}...`
                                          : file} */}
                                        {file?.length > 10
                                          ? `${file.substring(0, 10)}.` +
                                            getExtension(file)
                                          : file}
                                      </p>
                                      <img
                                        src={download}
                                        alt="Preview"
                                        style={{
                                          maxWidth: "23px",
                                          borderRadius: "13px",
                                          cursor: "pointer",
                                        }}
                                        onClick={() => handleDownloadFile(file)}
                                      />
                                    </div>
                                  ))}

                                  <hr />
                                  {/* {filesDataProof?.length > 0 && (
                                    <div
                                      className="row"
                                      style={{ marginBottom: "3rem" }}
                                    >
                                      <div className="col-lg-6 mt-3 image-control">
                                        <h5>All Download</h5>
                                        <div
                                          // key={index}
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            gap: "10px",
                                            width: "30rem",
                                          }}
                                        >
                                          <p
                                            style={{
                                              marginBottom: "0px",
                                              paddingLeft: "1rem",
                                              width: "11rem",
                                              overflow: "hidden",
                                              marginBottom: "7px",
                                            }}
                                          ></p>
                                          <img
                                            src={download}
                                            alt="Preview"
                                            style={{
                                              maxWidth: "23px",
                                              borderRadius: "13px",
                                              cursor: "pointer",
                                            }}
                                            onClick={() =>
                                              handleDownloadFileAll()
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  )} */}

                                  {filesDataProof !== null &&
                                    filesDataProof !== undefined &&
                                    filesDataProof?.length > 0 && (
                                      <div
                                        className="row"
                                        style={{ marginBottom: "3rem" }}
                                      >
                                        <div
                                          style={{
                                            display: "flex",
                                            paddingLeft: "2rem",
                                          }}
                                        >
                                          <p>
                                            All{" "}
                                            {checkDownload
                                              ? "Downloading..."
                                              : "Download"}
                                          </p>
                                          <div
                                            style={{
                                              marginBottom: "0px",
                                              paddingLeft: "1rem",
                                              width: "11rem",
                                              overflow: "hidden",
                                            }}
                                          >
                                            <img
                                              src={download}
                                              alt="Preview"
                                              style={{
                                                maxWidth: "23px",
                                                borderRadius: "13px",
                                                cursor: "pointer",
                                              }}
                                              onClick={() =>
                                                handleDownloadFileAll()
                                              }
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                </>
                              ) : (
                                <p style={{ paddingLeft: "1rem" }}>
                                  Not Submitted
                                </p>
                              )}
                            </div>
                          </div>

                          <div
                            className="mt-3 file-control file-flexes"
                            style={{ alignItems: "center" }}
                          ></div>
                        </div>
                      </div>

                      {/* Filesssss==>>>>>> */}
                    </div>
                  </section>
                </div>
              </div>

              <Modal
                title="Notes"
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
              >
                {inputFields?.map((field, index) => (
                  <div key={index} style={{ marginBottom: "1rem" }}>
                    <Input
                      placeholder={`Field ${index + 1}`}
                      value={field?.value}
                      onChange={(e) => handleInputChange(index, e)}
                      style={{ marginBottom: "1px" }}
                    />
                    <Button
                      onClick={() => {
                        handleRemoveFieldModal(index);
                        deleteNoteApi(field);
                      }}
                      style={{ color: "gray" }}
                    >
                      Remove
                    </Button>

                    <Button
                      onClick={() => handleAddNote(field)}
                      style={{ background: "#d97228", color: "white" }}
                    >
                      {field.isNew
                        ? `Submit Note ${index + 1}`
                        : `Update Note ${index + 1}`}
                    </Button>
                  </div>
                ))}
                <Button type="dashed" onClick={handleAddFieldModal}>
                  + Add Note
                </Button>
              </Modal>

              {/* buttons */}

              {applicationStatus == "SUBMITTED" ? (
                <div
                  className="d-flex justify-content-center mt-3"
                  style={{ gap: "2rem", flexWrap: "wrap" }}
                >
                  {/* <div
                    className="button-effects "
                    style={{
                      padding: "1rem 2rem 1rem 2rem",
                      border: "1px solid #d97228",
                      cursor: "pointer",
                      borderRadius: "10PX",
                    }}
                    onClick={Update}
                  >
                    Update/Draft
                  </div> */}

                  {/* new one buttons */}
                  {localStorage?.getItem("role") != "building_manager" && (
                    <div
                      className="button-effects "
                      style={{
                        padding: "1rem 2rem 1rem 2rem",
                        border: "1px solid #d97228",
                        cursor: "pointer",
                        borderRadius: "10PX",
                      }}
                      onClick={handleApproveClick}
                    >
                      Approve
                    </div>
                  )}

                  <div
                    className="button-effects "
                    style={{
                      padding: "1rem 2rem 1rem 2rem",
                      border: "1px solid #d97228",
                      cursor: "pointer",
                      borderRadius: "10PX",
                    }}
                    onClick={handleRejectClick}
                  >
                    Reject
                  </div>

                  {applicationStatus != "SUBMITTED" && (
                    <div
                      className="button-effects "
                      style={{
                        padding: "1rem 2rem 1rem 2rem",
                        border: "1px solid #d97228",
                        cursor: "pointer",
                        borderRadius: "10PX",
                      }}
                      onClick={Update}
                    >
                      Save/Drafts
                    </div>
                  )}

                  <div
                    className="button-effects "
                    style={{
                      padding: "1rem 2rem 1rem 2rem",
                      border: "1px solid #d97228",
                      cursor: "pointer",
                      borderRadius: "10PX",
                    }}
                    // onClick={UpdateSubmit}
                    onClick={() => {
                      showModal();
                      handleGetNote();
                      // setNoteId()
                    }}
                  >
                    Add Note
                  </div>

                  {/* <div
                    className="button-effects "
                    style={{
                      padding: "1rem 2rem 1rem 2rem",
                      border: "1px solid #d97228",
                      cursor: "pointer",
                      borderRadius: "10PX",
                    }}
                    onClick={UpdateSubmit}
                  >
                    Resend Application
                  </div> */}
                  {/* new one buttons */}

                  <div style={{ width: "9rem" }}>
                    <button
                      className="myBtn bdr"
                      style={{ width: "100%" }}
                      type="button"
                      onClick={() => {
                        // handlePrint();

                        setShowLogo(true);
                        setTimeout(() => {
                          refsss();
                        }, 500);
                      }}
                    >
                      <div style={{ marginRight: "2px" }}>
                        <Print />
                      </div>
                      Print
                    </button>
                  </div>
                </div>
              ) : (
                <div
                  className="d-flex justify-content-center mt-3"
                  style={{ gap: "2rem", flexWrap: "wrap" }}
                >
                  {applicationID && (
                    <div
                      className="button-effects "
                      style={{
                        padding: "1rem 2rem 1rem 2rem",
                        border: "1px solid #d97228",
                        cursor: "pointer",
                        borderRadius: "10PX",
                      }}
                      onClick={DraftHandling}
                    >
                      Save/Drafts
                    </div>
                  )}

                  {!applicationID && (
                    <div
                      className="button-effects "
                      style={{
                        padding: "1rem 2rem 1rem 2rem",
                        border: "1px solid #d97228",
                        cursor: "pointer",
                        borderRadius: "10PX",
                      }}
                      onClick={DraftHandlingCreate}
                    >
                      Save/Drafts
                    </div>
                  )}

                  {applicationStatus != "OPENED" &&
                    applicationStatus != "PENDING" &&
                    applicationStatus != "APPROVED" &&
                    applicationStatus != "REJECTED" && (
                      <button
                        className="button-effects "
                        type="submit"
                        style={{
                          padding: "1rem 2rem 1rem 2rem",
                          border: "1px solid #d97228",
                          cursor: "pointer",
                          borderRadius: "10PX",
                          background: "none",
                        }}
                        // onClick={formik?.handleSubmit}
                      >
                        Send Application to Applicant
                      </button>
                    )}
                  {applicationID && (
                    <div
                      className="button-effects "
                      style={{
                        padding: "1rem 2rem 1rem 2rem",
                        border: "1px solid #d97228",
                        cursor: "pointer",
                        borderRadius: "10PX",
                      }}
                      onClick={UpdateSubmit}
                    >
                      Send Application to Applicant
                    </div>
                  )}

                  {/* <div
                    className="button-effects "
                    style={{
                      padding: "1rem 2rem 1rem 2rem",
                      border: "1px solid #d97228",
                      cursor: "pointer",
                      borderRadius: "10PX",
                    }}
                    onClick={formik?.handleSubmit}
                  >
                  </div> */}

                  <div style={{ width: "9rem" }}>
                    <button
                      className="myBtn bdr"
                      style={{ width: "100%" }}
                      type="button"
                      onClick={() => {
                        setShowLogo(true);
                        setTimeout(() => {
                          refsss();
                        }, 500);
                      }}
                    >
                      <div style={{ marginRight: "2px" }}>
                        <Print />
                      </div>
                      Print
                    </button>
                  </div>
                </div>
              )}
            </form>
          </div>

          {/* modal */}
        </section>
      </MainLayout>{" "}
    </>
  );
};
export default forwardRef(Application);
