import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useNavigate,
} from "react-router-dom";
import Logosm from "../../assets/images/logosm.png";
import { PATH } from "../../constants/paths";
import { adminApiCall, userAccessibility } from "../../helpers/ajax_request";
import Swal from "sweetalert2";
import {
  Logout,
  Message,
  PurchaseOrder,
  TrafficSheet,
  WorkConfirmation,
  WorkOrder,
} from "../../Icons/Icons";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CreateOrder from "../CreateOrder/CreateOrder";

import { Modal, Button } from "react-bootstrap";

export default function Index() {
  const navigate = useNavigate();

  const [modalShow, setModalShow] = useState(false);

  toast.configure();

  const notifySuccess = (msg) =>
    toast.success(msg, {
      autoClose: 3000,
    });

  const notifyWarning = (msg) =>
    toast.warning(msg, {
      autoClose: 3000,
    });
  const notifyError = (msg) =>
    toast.error(msg, {
      autoClose: 3000,
    });

  function MyVerticallyCenteredModal(props) {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="justify-content-center">
          <Modal.Title id="contained-modal-title-vcenter">
            <h2 className="modalTitle text-left"> Choose a Category</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="text text-center">
            To create the purchase order, Please select the category first
          </p>
          <div className="formDesign text-center">
            <Link
              className="myBtn shadow "
              style={{ minWidth: "176px" }}
              to={PATH.MAINTENANCE_SERVICES}
            >
              Maintenance Service
            </Link>
            <br />
            <br />
            <Link
              className="myBtn shadow "
              style={{ minWidth: "176px" }}
              to={PATH.PURCHASE_PARTS}
            >
              Purchase Parts
            </Link>
          </div>
        </Modal.Body>
      </Modal>
    );
  }

  const logout = async () => {
    let data = {};
    let res = await adminApiCall("/api/admin/logout", "GET");
    if (!res) {
      notifyError("Something went wrong.. Please Try Again!");
      return false;
    }
    if (res.hasOwnProperty("status")) {
      if (res.status === 200) {
        notifySuccess("Logged Out Successfully!");
        // localStorage.clear();
        localStorage.removeItem("adminToken");
        navigate("/");
        // this.props.history.push('/')
      } else if (res.status != 200) {
        // localStorage.clear();
        // window.location.href = "/";
      }
    }
  };
  return (
    <>
      <section className="sideBar">
        <div className="logoWrapper">
          <Link className="logo" to={PATH.HOME}>
            <img className="logo" src={Logosm} alt="" />
          </Link>
        </div>
        <div className="menuListing mainMenu">
          <ul>
            <li>
              <a className="navItem" data-toggle="purchase">
                <i>
                  <PurchaseOrder />
                </i>
                <span>Purchase Orders</span>
              </a>
              {/* <div className="dropdown">
                <button className="dropbtn">Dropdown</button>
                <div className="dropdown-content">
                  <a href="#">Link 1</a>
                  <a href="#">Link 2</a>
                  <a href="#">Link 3</a>
                </div>
              </div> */}
            </li>
            {/* <li>
              <a className="navItem">
                <i>
                  <WorkConfirmation />
                </i>
                <span>Work Confirmation</span>
              </a>
            </li> */}
            <li>
              <a className="navItem" data-toggle="work">
                <i>
                  <WorkOrder />
                </i>
                <span>Work Orders</span>
              </a>
            </li>
            <li>
              <a className="navItem" data-toggle="traffic">
                <i>
                  <TrafficSheet />
                </i>
                <span>Traffic Sheet</span>
              </a>
            </li>
            {/* new application */}

            <li>
              <>
                <a className="navItem" data-toggle="application">
                  <i>
                    <WorkOrder />
                  </i>
                  <span>Application</span>
                </a>
              </>
            </li>
            {/* <li>
              <>
                <a className="navItem" data-toggle="message">
                  <i>
                    <Message />
                  </i>
                  <span>Send Message</span>
                </a>
              </>
            </li> */}
          </ul>
        </div>
        <div className="menuListing logout">
          <ul>
            <li>
              <Link className="navItem" onClick={() => logout()} to="/">
                <i>
                  <Logout />
                </i>
                <span>Logout</span>
              </Link>
            </li>
          </ul>
        </div>
        {/* purchase Order */}
        <div className="innerSidebar" data-attr="purchase">
          <div className="sideBarHeader">
            <h2>Purchase Orders</h2>
          </div>
          <div className="sideBarBody">
            <ul>
              {localStorage.getItem("role") === "viewer" ? (
                userAccessibility("purchase_order_management", "create") ===
                false ? (
                  ""
                ) : (
                  <li>
                    <Link
                      to={"#"}
                      onClick={() => {
                        if (localStorage.getItem("building_id") === null) {
                          notifyError("Please Select a Building");
                        } else {
                          setModalShow(true);
                        }
                      }}
                    >
                      Create Order{" "}
                    </Link>
                    <MyVerticallyCenteredModal
                      show={modalShow}
                      onHide={() => setModalShow(false)}
                    />
                  </li>
                )
              ) : localStorage.getItem("role") === "building_manager" ? (
                userAccessibility("purchase_order_management", "create") ===
                false ? (
                  ""
                ) : (
                  <li>
                    <Link
                      to={"#"}
                      onClick={() => {
                        if (localStorage.getItem("building_id") === null) {
                          notifyError("Please Select a Building");
                        } else {
                          setModalShow(true);
                        }
                      }}
                    >
                      Create Order
                    </Link>
                    <MyVerticallyCenteredModal
                      show={modalShow}
                      onHide={() => setModalShow(false)}
                    />
                  </li>
                )
              ) : localStorage.getItem("role") === "property_manager" ? (
                userAccessibility("purchase_order_management", "create") ===
                false ? (
                  ""
                ) : (
                  <li>
                    <Link
                      to={"#"}
                      onClick={() => {
                        if (localStorage.getItem("building_id") === null) {
                          notifyError("Please Select a Building");
                        } else {
                          setModalShow(true);
                        }
                      }}
                    >
                      Create Order{" "}
                    </Link>
                    <MyVerticallyCenteredModal
                      show={modalShow}
                      onHide={() => setModalShow(false)}
                    />
                  </li>
                )
              ) : (
                <li>
                  <Link
                    to={"#"}
                    onClick={() => {
                      if (localStorage.getItem("building_id") === null) {
                        notifyError("Please Select a Building");
                      } else {
                        setModalShow(true);
                      }
                    }}
                  >
                    Create Order{" "}
                  </Link>
                  <MyVerticallyCenteredModal
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                  />
                </li>
              )}
              <li>
                <Link
                  to={`${
                    localStorage.getItem("building_id") === null
                      ? "#"
                      : PATH.VIEW_EXISTING
                  }`}
                  onClick={() => {
                    if (localStorage.getItem("building_id") === null) {
                      notifyError("Please Select a Building");
                    } else {
                      navigate(PATH.VIEW_EXISTING);
                    }
                  }}
                >
                  View Existing
                </Link>
              </li>
            </ul>
          </div>
        </div>
        {/* Work Order */}
        <div className="innerSidebar" data-attr="work">
          <div className="sideBarHeader">
            <h2>Document Center</h2>
          </div>
          <div className="sideBarBody">
            <ul>
              <li>
                <Link
                  to={`${
                    localStorage.getItem("building_id") === null
                      ? "#"
                      : PATH.WORK_ORDER
                  }`}
                  onClick={() => {
                    if (localStorage.getItem("building_id") === null) {
                      notifyError("Please Select a Building");
                    } else {
                      navigate(PATH.WORK_ORDER);
                    }
                  }}
                >
                  Work Order{" "}
                </Link>
              </li>
              {localStorage.getItem("role") === "viewer" ? (
                userAccessibility("work_order_management", "create") ===
                false ? (
                  ""
                ) : (
                  <li>
                    <Link
                      to={`${
                        localStorage.getItem("building_id") === null
                          ? "#"
                          : PATH.WORK_ORDER_CREATION
                      }`}
                      onClick={() => {
                        if (localStorage.getItem("building_id") === null) {
                          notifyError("Please Select a Building");
                        } else {
                          navigate(PATH.WORK_ORDER_CREATION);
                        }
                      }}
                    >
                      Work Order Creation
                    </Link>
                  </li>
                )
              ) : localStorage.getItem("role") === "building_manager" ? (
                userAccessibility("work_order_management", "create") ===
                false ? (
                  ""
                ) : (
                  <li>
                    <Link
                      to={`${
                        localStorage.getItem("building_id") === null
                          ? "#"
                          : PATH.WORK_ORDER_CREATION
                      }`}
                      onClick={() => {
                        if (localStorage.getItem("building_id") === null) {
                          notifyError("Please Select a Building");
                        } else {
                          navigate(PATH.WORK_ORDER_CREATION);
                        }
                      }}
                    >
                      Work Order Creation
                    </Link>
                  </li>
                )
              ) : localStorage.getItem("role") === "property_manager" ? (
                userAccessibility("work_order_management", "create") ===
                false ? (
                  ""
                ) : (
                  <li>
                    <Link
                      to={`${
                        localStorage.getItem("building_id") === null
                          ? "#"
                          : PATH.WORK_ORDER_CREATION
                      }`}
                      onClick={() => {
                        if (localStorage.getItem("building_id") === null) {
                          notifyError("Please Select a Building");
                        } else {
                          navigate(PATH.WORK_ORDER_CREATION);
                        }
                      }}
                    >
                      Work Order Creation
                    </Link>
                  </li>
                )
              ) : (
                <li>
                  <Link
                    to={`${
                      localStorage.getItem("building_id") === null
                        ? "#"
                        : PATH.WORK_ORDER_CREATION
                    }`}
                    onClick={() => {
                      if (localStorage.getItem("building_id") === null) {
                        notifyError("Please Select a Building");
                      } else {
                        navigate(PATH.WORK_ORDER_CREATION);
                      }
                    }}
                  >
                    Work Order Creation
                  </Link>
                </li>
              )}
            </ul>
          </div>
        </div>
        {/* Trafic Sheet */}
        <div className="innerSidebar" data-attr="traffic">
          <div className="sideBarHeader">
            <h2>Traffic Sheet</h2>
          </div>
          <div className="sideBarBody">
            <ul>
              <li>
                <Link
                  // to="/viewTrafficSheet"
                  to={`${
                    localStorage.getItem("building_id") === null
                      ? "#"
                      : PATH.VIEW_TRAFFIC_SHEET
                  }`}
                  onClick={() => {
                    if (localStorage.getItem("building_id") === null) {
                      notifyError("Please Select a Building");
                    } else {
                      navigate(PATH.VIEW_TRAFFIC_SHEET);
                    }
                  }}
                >
                  View Traffic Sheet
                </Link>
              </li>
              {localStorage.getItem("role") === "viewer" ? (
                userAccessibility("traffic_sheet_management", "create") ===
                false ? (
                  ""
                ) : (
                  <li>
                    <Link
                      // to="/createTrafficSheet"
                      to={`${
                        localStorage.getItem("building_id") === null
                          ? "#"
                          : PATH.CREATE_TRAFFIC_SHEET
                      }`}
                      onClick={() => {
                        if (localStorage.getItem("building_id") === null) {
                          notifyError("Please Select a Building");
                        } else {
                          navigate(PATH.CREATE_TRAFFIC_SHEET);
                        }
                      }}
                    >
                      New Traffic Sheet Item
                    </Link>
                  </li>
                )
              ) : localStorage.getItem("role") === "building_manager" ? (
                userAccessibility("traffic_sheet_management", "create") ===
                false ? (
                  ""
                ) : (
                  <li>
                    <Link
                      // to="/createTrafficSheet"
                      to={`${
                        localStorage.getItem("building_id") === null
                          ? "#"
                          : PATH.CREATE_TRAFFIC_SHEET
                      }`}
                      onClick={() => {
                        if (localStorage.getItem("building_id") === null) {
                          notifyError("Please Select a Building");
                        } else {
                          navigate(PATH.CREATE_TRAFFIC_SHEET);
                        }
                      }}
                    >
                      New Traffic Sheet Item
                    </Link>
                  </li>
                )
              ) : localStorage.getItem("role") === "property_manager" ? (
                userAccessibility("traffic_sheet_management", "create") ===
                false ? (
                  ""
                ) : (
                  <li>
                    <Link
                      // to="/createTrafficSheet"
                      to={`${
                        localStorage.getItem("building_id") === null
                          ? "#"
                          : PATH.CREATE_TRAFFIC_SHEET
                      }`}
                      onClick={() => {
                        if (localStorage.getItem("building_id") === null) {
                          notifyError("Please Select a Building");
                        } else {
                          navigate(PATH.CREATE_TRAFFIC_SHEET);
                        }
                      }}
                    >
                      New Traffic Sheet Item
                    </Link>
                  </li>
                )
              ) : (
                <li>
                  <Link
                    // to="/createTrafficSheet"
                    to={`${
                      localStorage.getItem("building_id") === null
                        ? "#"
                        : PATH.CREATE_TRAFFIC_SHEET
                    }`}
                    onClick={() => {
                      if (localStorage.getItem("building_id") === null) {
                        notifyError("Please Select a Building");
                      } else {
                        navigate(PATH.CREATE_TRAFFIC_SHEET);
                      }
                    }}
                  >
                    New Traffic Sheet Item
                  </Link>
                </li>
              )}

              {/* {localStorage.getItem("role") === "viewer" ? (
                userAccessibility("building_management", "create") === false ? (
                  ""
                ) : (
                  <li>
                    <Link to="/buildingList">View Buildings</Link>
                  </li>
                )
              ) : localStorage.getItem("role") === "building_manager" ? (
                userAccessibility("building_management", "create") === false ? (
                  ""
                ) : (
                  <li>
                    <Link to="/buildingList">View Buildings</Link>
                  </li>
                )
              ) : localStorage.getItem("role") === "property_manager" ? (
                userAccessibility("building_management", "create") === false ? (
                  ""
                ) : (
                  <li>
                    <Link to="/buildingList">View Buildings</Link>
                  </li>
                )
              ) : (
                <li>
                  <Link to="/buildingList">View Buildings</Link>
                </li>
              )} */}

              {localStorage.getItem("role") === "viewer" ? (
                userAccessibility("unit_management", "create") === false ? (
                  ""
                ) : (
                  <li>
                    <Link
                      to={`${
                        localStorage.getItem("building_id") === null
                          ? "#"
                          : PATH.ADD_UNIT
                      }`}
                      onClick={() => {
                        if (localStorage.getItem("building_id") === null) {
                          notifyError("Please Select a Building");
                        } else {
                          navigate(PATH.ADD_UNIT);
                        }
                      }}
                    >
                      Unit Inventory
                    </Link>
                  </li>
                )
              ) : localStorage.getItem("role") === "building_manager" ? (
                userAccessibility("unit_management", "create") === false ? (
                  ""
                ) : (
                  <li>
                    <Link
                      to={`${
                        localStorage.getItem("building_id") === null
                          ? "#"
                          : PATH.ADD_UNIT
                      }`}
                      onClick={() => {
                        if (localStorage.getItem("building_id") === null) {
                          notifyError("Please Select a Building");
                        } else {
                          navigate(PATH.ADD_UNIT);
                        }
                      }}
                    >
                      Unit Inventory
                    </Link>
                  </li>
                )
              ) : localStorage.getItem("role") === "property_manager" ? (
                userAccessibility("unit_management", "create") === false ? (
                  ""
                ) : (
                  <li>
                    <Link
                      to={`${
                        localStorage.getItem("building_id") === null
                          ? "#"
                          : PATH.ADD_UNIT
                      }`}
                      onClick={() => {
                        if (localStorage.getItem("building_id") === null) {
                          notifyError("Please Select a Building");
                        } else {
                          navigate(PATH.ADD_UNIT);
                        }
                      }}
                    >
                      Unit Inventory
                    </Link>
                  </li>
                )
              ) : (
                <li>
                  <Link
                    to={`${
                      localStorage.getItem("building_id") === null
                        ? "#"
                        : PATH.ADD_UNIT
                    }`}
                    onClick={() => {
                      if (localStorage.getItem("building_id") === null) {
                        notifyError("Please Select a Building");
                      } else {
                        navigate(PATH.ADD_UNIT);
                      }
                    }}
                  >
                    Unit Inventory
                  </Link>
                </li>
              )}
            </ul>
          </div>
        </div>

        {/* Application */}

        <div className="innerSidebar" data-attr="application">
          <div className="sideBarHeader">
            <h2>Application</h2>
          </div>
          <div className="sideBarBody">
            <ul>
              <li>
                <Link
                  to={`${
                    localStorage.getItem("building_id") === null
                      ? "#"
                      : PATH.APPLICATION
                  }`}
                  onClick={() => {
                    if (localStorage.getItem("building_id") === null) {
                      notifyError("Please Select a Building");
                    } else {
                      navigate(PATH.APPLICATION);
                    }
                  }}
                >
                  Create Application
                </Link>
              </li>

              <li>
                <Link
                  to={`${
                    localStorage.getItem("building_id") === null
                      ? "#"
                      : PATH.APPLICATION_LISTING
                  }`}
                  onClick={() => {
                    if (localStorage.getItem("building_id") === null) {
                      notifyError("Please Select a Building");
                    } else {
                      navigate(PATH.APPLICATION_LISTING);
                    }
                  }}
                >
                  Application Listing
                </Link>
              </li>
              {/* {localStorage.getItem("role") === "viewer" ? (
                userAccessibility("work_order_management", "create") ===
                false ? (
                  ""
                ) : (
                  <li>
                    <Link
                      to={`${
                        localStorage.getItem("building_id") === null
                          ? "#"
                          : PATH.WORK_ORDER_CREATION
                      }`}
                      onClick={() => {
                        if (localStorage.getItem("building_id") === null) {
                          notifyError("Please Select a Building");
                        } else {
                          navigate(PATH.WORK_ORDER_CREATION);
                        }
                      }}
                    >
                      Work Order Creation
                    </Link>
                  </li>
                )
              ) : localStorage.getItem("role") === "building_manager" ? (
                userAccessibility("work_order_management", "create") ===
                false ? (
                  ""
                ) : (
                  <li>
                    <Link
                      to={`${
                        localStorage.getItem("building_id") === null
                          ? "#"
                          : PATH.WORK_ORDER_CREATION
                      }`}
                      onClick={() => {
                        if (localStorage.getItem("building_id") === null) {
                          notifyError("Please Select a Building");
                        } else {
                          navigate(PATH.WORK_ORDER_CREATION);
                        }
                      }}
                    >
                      Work Order Creation
                    </Link>
                  </li>
                )
              ) : localStorage.getItem("role") === "property_manager" ? (
                userAccessibility("work_order_management", "create") ===
                false ? (
                  ""
                ) : (
                  <li>
                    <Link
                      to={`${
                        localStorage.getItem("building_id") === null
                          ? "#"
                          : PATH.WORK_ORDER_CREATION
                      }`}
                      onClick={() => {
                        if (localStorage.getItem("building_id") === null) {
                          notifyError("Please Select a Building");
                        } else {
                          navigate(PATH.WORK_ORDER_CREATION);
                        }
                      }}
                    >
                      Work Order Creation
                    </Link>
                  </li>
                )
              ) : (
                <li>
                  <Link
                    to={`${
                      localStorage.getItem("building_id") === null
                        ? "#"
                        : PATH.WORK_ORDER_CREATION
                    }`}
                    onClick={() => {
                      if (localStorage.getItem("building_id") === null) {
                        notifyError("Please Select a Building");
                      } else {
                        navigate(PATH.WORK_ORDER_CREATION);
                      }
                    }}
                  >
                    Work Order Creation
                  </Link>
                </li>
              )} */}
            </ul>
          </div>
        </div>

        <div className="innerSidebar" data-attr="message">
          <div className="sideBarHeader">
            <h2>Application</h2>
          </div>
          <div className="sideBarBody">
            <ul>
              {/* <li>
                <Link
                  to={`${
                    localStorage.getItem("building_id") === null
                      ? "#"
                      : PATH.SEND_FORMS
                  }`}
                  onClick={() => {
                    if (localStorage.getItem("building_id") === null) {
                      notifyError("Please Select a Building");
                    } else {
                      navigate(PATH.SEND_FORMS);
                    }
                  }}
                >
                  Add Message
                </Link>
              </li> */}

              {/* <li>
                <Link
                  to={`${
                    localStorage.getItem("building_id") === null
                      ? "#"
                      : PATH.LIST_FORMS
                  }`}
                  onClick={() => {
                    if (localStorage.getItem("building_id") === null) {
                      notifyError("Please Select a Building");
                    } else {
                      navigate(PATH.LIST_FORMS);
                    }
                  }}
                >
                  Message Listing
                </Link>
              </li> */}
              {/* {localStorage.getItem("role") === "viewer" ? (
                userAccessibility("work_order_management", "create") ===
                false ? (
                  ""
                ) : (
                  <li>
                    <Link
                      to={`${
                        localStorage.getItem("building_id") === null
                          ? "#"
                          : PATH.WORK_ORDER_CREATION
                      }`}
                      onClick={() => {
                        if (localStorage.getItem("building_id") === null) {
                          notifyError("Please Select a Building");
                        } else {
                          navigate(PATH.WORK_ORDER_CREATION);
                        }
                      }}
                    >
                      Work Order Creation
                    </Link>
                  </li>
                )
              ) : localStorage.getItem("role") === "building_manager" ? (
                userAccessibility("work_order_management", "create") ===
                false ? (
                  ""
                ) : (
                  <li>
                    <Link
                      to={`${
                        localStorage.getItem("building_id") === null
                          ? "#"
                          : PATH.WORK_ORDER_CREATION
                      }`}
                      onClick={() => {
                        if (localStorage.getItem("building_id") === null) {
                          notifyError("Please Select a Building");
                        } else {
                          navigate(PATH.WORK_ORDER_CREATION);
                        }
                      }}
                    >
                      Work Order Creation
                    </Link>
                  </li>
                )
              ) : localStorage.getItem("role") === "property_manager" ? (
                userAccessibility("work_order_management", "create") ===
                false ? (
                  ""
                ) : (
                  <li>
                    <Link
                      to={`${
                        localStorage.getItem("building_id") === null
                          ? "#"
                          : PATH.WORK_ORDER_CREATION
                      }`}
                      onClick={() => {
                        if (localStorage.getItem("building_id") === null) {
                          notifyError("Please Select a Building");
                        } else {
                          navigate(PATH.WORK_ORDER_CREATION);
                        }
                      }}
                    >
                      Work Order Creation
                    </Link>
                  </li>
                )
              ) : (
                <li>
                  <Link
                    to={`${
                      localStorage.getItem("building_id") === null
                        ? "#"
                        : PATH.WORK_ORDER_CREATION
                    }`}
                    onClick={() => {
                      if (localStorage.getItem("building_id") === null) {
                        notifyError("Please Select a Building");
                      } else {
                        navigate(PATH.WORK_ORDER_CREATION);
                      }
                    }}
                  >
                    Work Order Creation
                  </Link>
                </li>
              )} */}
            </ul>
          </div>
        </div>
      </section>
    </>
  );
}
