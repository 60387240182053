export const PATH = {
  LOGIN: "/",
  FORGOT_PASSWORD: "/forgotPassword",
  HOME: "/home",
  BUILDING_LIST: "/buildingList",
  ADD_BUILDING: "/addBuilding",
  EDIT_BUILDING: "/editBuilding",
  BUILDING_DETAIL: "/buildingDetail",
  CONTACT: "/contact",

  INVENTORY_LIST: "/inventoryList",
  ADD_INVENTORY: "/addInventory",
  EDIT_INVENTORY: "/editInventory",
  MANAGE_CATEGORY: "/manageCategory",
  ADD_CATEGORY: "/addCategory",
  EDIT_CATEGORY: "/editCategory",

  VIEW_TRADES: "/viewTrades",
  ADD_TRADE: "/addTrade",
  EDIT_TRADE: "/editTrade",
  VIEW_COMMENTS: "/viewComments",

  PO_REPORT: "/poReport",
  VIEW_PO_REPORT: "/viewPOReport",
  VIEW_MAINTENANCE_PO_REPORT: "/viewMaintenancePOReport",
  VACANCY_REPORT: "/vacancyReport",

  USER_LIST: "/userList",
  ADD_USER: "/addUser",
  EDIT_USER: "/editUser",

  ACCOUNT_SETTINGS: "/accountSettings",
  APPLICATION_SETTINGS: "/applicationSettings",
  APPLICATION: "/application",
  SEND_FORMS:"/send-forms",
  LIST_FORMS:"/list-forms",
  APPLICATION_LISTING: "/application-listing",

  UPDATE_EMAIL_TEMPLATE: "/updateEmailTemplate",
  EDIT_EMAIL_TEMPLATE: "/EditEmailTemplate",
  API_SETTINGS: "/apiSettings",

  CREATE_ORDER: "/createOrder",
  VIEW_EXISTING: "/viewExisting",
  MAINTENANCE_SERVICES: "/maintenanceServices",
  PURCHASE_PARTS: "/purchaseParts",
  PURCHASE_PART_DETAIL: "/purchasePartDetail",
  EDIT_PURCHASE_PART_DETAIL: "/editPurchasePartDetail",
  MAINTENANCE_SERVICES_DETAIL: "/maintenanceServicesDetail",
  EDIT_MAINTENANCE_SERVICES_DETAIL: "/editMaintenanceServicesDetail",
  WORK_CONFIRMATION_PURCHASE_ORDER: "/workConfirmationPurchaseOrder",
  WORK_CONFIRMATION_MAINTENANCE_ORDER: "/workConfirmationMaintenance",

  WORK_ORDER: "/workOrder",
  VIEW_WORK_ORDER: "/viewWorkOrder",
  WORK_ORDER_CREATION: "/workOrderCreation",
  WORK_ORDER_COMPLETION: "/workOrderCompletion",

  VIEW_TRAFFIC_SHEET: "/viewTrafficSheet",
  VIEW_ASSIGNED_UNIT: "/viewAssignedUnit",
  CREATE_TRAFFIC_SHEET: "/createTrafficSheet",
  SCHEDULE_VACANCY: "/scheduleVacancy",
  MOVE_IN_NEW_TENANT: "/movieInNewTenant",
  EDIT_TRAFFIC_SHEET: "/editTrafficSheet",
  ADD_UNIT: "/addUnit",
  EDIT_UNIT: "/editUnit",
  USER_APPLICATION: "/user_application",
  FORMS:"/forms"

};

export const PurchaseParts = "purchase_parts";
export const MaintenanceParts = "maintenance_service";

