import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import MainLayout from "../../layouts";
import { useFormik } from "formik";
import DatePicker from "react-multi-date-picker";
import { adminApiCall } from "../../helpers/ajax_request";
function SendForms() {
  const [data,setData] = useState([]);
  const fetchList = async () => {
    try {
      let res = await adminApiCall("/api/admin/get-forms","get");
      if(res?.status === 200) {
        setData(res?.data);
      }
      console.log(res,'fadResponsibleForNow')
    }catch (e) {
      console.log(e);
    }
  }
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone_number: "",
      formId: "",
      date: "",
      message: "",
    },
  });
  useEffect(()=> {
    fetchList();
  },[])
  console.log(formik.values,'fasdlfjahldfjalhdsfjahlsdjf')
  return (
    <MainLayout>
      <section className="pageWrapper cardControl">
        <div className="contentCard" style={{ minHeight: "850px" }}>
          <div className="titleWrapper">
            <span>Message</span>
          </div>
          <div className="contentCard">
            <div className="formDesign">
              <div className="row">
                <div className="col-12 col-md-6 col-lg-3">
                  <div className="form-group">
                    <label>Name</label>
                    <input
                      disabled={formik.isSubmitting}
                      className={
                        "form-control " +
                        (formik.errors.name && formik.touched.name
                          ? " is-invalid"
                          : "")
                      }
                      type="text"
                      name="name"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                    />
                    <div className="invalid-feedback">{formik.errors.name}</div>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-3">
                  <div className="form-group">
                    <label>Email</label>
                    <input
                      disabled={formik.isSubmitting}
                      className={
                        "form-control " +
                        (formik.errors.email && formik.touched.email
                          ? " is-invalid"
                          : "")
                      }
                      type="text"
                      name="email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                    />
                    <div className="invalid-feedback">
                      {formik.errors.email}
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-3">
                  <div className="form-group">
                    <label>Phone Number</label>
                    <input
                      disabled={formik.isSubmitting}
                      className={
                        "form-control " +
                        (formik.errors.phone_number &&
                        formik.touched.phone_number
                          ? " is-invalid"
                          : "")
                      }
                      type="text"
                      name="phone_number"
                      value={formik.values.phone_number}
                      onChange={formik.handleChange}
                    />
                    <div className="invalid-feedback">
                      {formik.errors.phone_number}
                    </div>
                  </div>
                </div>

                <div className="col-12 col-md-3">
                  <div className="form-group">
                    <label>Forms</label>
                    <select
                      className="form-control"
                      name="formId"
                      value={formik.values.formId}
                      // onChange={(e) => handleChangeCategory(e)}
                    >
                      {/* {category &&
                        category.map((v, i) => {
                          return (
                            <option key={i} value={v._id}>
                              {v.category_name.charAt(0).toUpperCase() +
                                v.category_name.slice(1)}
                            </option>
                          );
                        })} */}
                      <option>fasdfsd</option>
                      <option>fasdfsd</option>
                      <option>fasdfsd</option>
                    </select>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-3">
                  <div
                    className="form-group"
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <label>Date</label>
                    <DatePicker
                      name="date"
                      value={formik.values.date}
                      style={{
                        width: "100%",
                        height: "37px",
                        backgroundColor: "#EEF2F6",
                      }}
                    />
                  </div>
                </div>
                <div className="col-12 ">
                  <div
                    className="form-group"
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <label>Message</label>
                    <textarea
                      className="form-control"
                      rows={5}
                      // className={
                      //     "form-control " +
                      //     (formik.errors.description && formik.touched.description
                      //       ? " is-invalid"
                      //       : "")
                      //   }
                    ></textarea>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "10px",
                    width: "100%",
                  }}
                >
                  <Button className="myBtn shadow reactBoostrapModalBtn">
                    Save to draft
                  </Button>
                  <button className="myBtn bdr">Cancel</button>
                  <button className="myBtn bdr">Send</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </MainLayout>
  );
}

export default SendForms;
