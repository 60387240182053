import React, { Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import Login from "../pages/authentication/login";
import ForgotPassword from "../pages/authentication/ForgotPassword/Index";
import Home from "../pages/home";

import Loading from "../components/Loading";
import AuthenticatedGuard from "../guards/index";
import "../assets/css/styles.css";

//====== BUILDINGS ROUTES ================
// import BuildingsList from "../pages/building/buildings-list";

//////////////////////////////////////////
/////////////////////// Login ///////////////////////////
// import Login from "./Pages/Authentication/Login/Index";

/////////////////////// Building ///////////////////////////
import BuildingList from "../pages/Building/BuildingList/Index";
import AddBuilding from "../pages/Building/AddBuilding/Index";
import EditBuilding from "../pages/Building/EditBuilding/Index";
import BuildingDetails from "../pages/Building/BuildingDetails/Index";
import Contact from "../pages/Building/Contact/Index";

/////////////////////// Inventory ///////////////////////////
import InventoryList from "../pages/Inventory/InventoryList/Index";
import AddInventory from "../pages/Inventory/AddInventory/Index";
import ManageCategory from "../pages/Inventory/ManageCategories/Index";
import AddCategory from "../pages/Inventory/AddCategory/Index";
import EditCategory from "../pages/Inventory/EditCategory/Index";
import EditInventory from "../pages/Inventory/EditInventory/Index";

/////////////////////// Trades ///////////////////////////
import ViewTrades from "../pages/Trades/ViewTrades/Index";
import AddTrades from "../pages/Trades/AddTrades/Index";
import EditTrades from "../pages/Trades/EditTrades/Index";
import ViewComments from "../pages/Trades/ViewComments/Index";

/////////////////////// Reports ///////////////////////////
import POReport from "../pages/Reports/POReport/Index";
import ViewPOReport from "../pages/Reports/ViewPOReport/Index";
import ViewMaintenancePOReport from "../pages/Reports/ViewMaintenancePOReport/Index";
import VacancyReport from "../pages/Reports/VacancyReport/Index";

/////////////////////// Users ///////////////////////////
import UserList from "../pages/Users/UserList/Index";
import AddUser from "../pages/Users/AddUser/Index";
import EditUser from "../pages/Users/EditUser/Index";

/////////////////////// Settings ///////////////////////////
import AccountSettings from "../pages/Settings/AccountSetting/Index";
import ApplicationSetting from "../pages/Settings/ApplicationSetting/Index";
import UpdateEmailTemplate from "../pages/Settings/UpdateEmailTemplate/Index";
import EditEmailTemplate from "../pages/Settings/EditEmailTemplate/Index";
import APISettings from "../pages/Settings/APISettings/Index";
// import Logout from "../pages/Settings/Logout/Index";

/////////////////////// Purchase Orders ///////////////////////////
import CreateOrder from "../pages/PurchaseOrders/CreateOrder/Index";
import ViewExisting from "../pages/PurchaseOrders/ViewExisting/Index";
import MaintenanceServices from "../pages/PurchaseOrders/MaintenanceServices/Index";
import PurchaseParts from "../pages/PurchaseOrders/PurchaseParts/Index";
import PurchasePartDetail from "../pages/PurchaseOrders/PurchasePartDetail/Index";
import EditPurchasePartDetail from "../pages/PurchaseOrders/EditPurchasePartDetail/Index";
import MaintenanceServicesDetail from "../pages/PurchaseOrders/MaintenanceServiceDetail/Index";
import EditMaintenanceServicesDetail from "../pages/PurchaseOrders/EditMaintenanceServiceDetail/Index";
import WorkConfirmationPurchaseOrder from "../pages/PurchaseOrders/WorkConfirmation/Index";
import WorkConfirmationMaintenance from "../pages/PurchaseOrders/WorkConfirmationMaintenance/Index";

/////////////////////// Work Orders ///////////////////////////
import WorkOrder from "../pages/WordOrders/WorkOrder/Index";
import ViewWorkOrder from "../pages/WordOrders/ViewWorkOrder/Index";
import WorkOrderCreation from "../pages/WordOrders/WorkOrderCreation/Index";
import WorkOrderCompletion from "../pages/WordOrders/WorkOrderCompletion/Index";

/////////////////////// Traffic Sheet ///////////////////////////
import ViewTrafficSheet from "../pages/TrafficSheet/ViewTraffic/Index";
import ViewAssignedUnit from "../pages/TrafficSheet/ViewAssignedUnit/Index";
import CreateTrafficSheet from "../pages/TrafficSheet/CreateTraffic/Index";
import ScheduleVacancy from "../pages/TrafficSheet/ScheduleVacancy/Index";
import MovieInNewTenant from "../pages/TrafficSheet/MoveInTenant/Index";
import EditTrafficSheet from "../pages/TrafficSheet/EditTraffic/Index";
import AddUnit from "../pages/TrafficSheet/AddUnit/Index";
import EditUnit from "../pages/TrafficSheet/EditUnit/Index";

import PageNotFound from "../components/404PageNotFound/Index";
import Application from "../pages/Application";
import { ApplicationListing } from "../pages/Application-listing";
import UserApplcation from "../pages/Application-user/Index";
import Verifyotp from "../pages/Application-user/Verifyotp";
import Thanks from "../pages/Application-user/Thanks";
import Printable from "../pages/Application/Printable";
import Forms from "../pages/Forms";
import SendForms from "../pages/SendForms";
import ListForms from "../pages/SendForms/ListForms";
export default function Routing() {
  return (
    <BrowserRouter>
      <Suspense fallback={<Loading />}>
        <Routes>
          <Route element={<AuthenticatedGuard />}>
            {/* <Route exact path="/building-list" element={<BuildingsList />} /> */}

            {/* /////////////////////////////////////// */}
            <Route exact path="/home" element={<Home />} />
            <Route exact path="/forms" element={<Forms />} />
            <Route exact path="/send-forms" element={<SendForms />} />
            <Route exact path="/list-forms" element={<ListForms />} />
            <Route exact path="/application" element={<Application />} />
            <Route
              exact
              path="/application-listing"
              element={<ApplicationListing />}
            />
            <Route
              exact
              path="/user_application/verify_otp"
              element={<Verifyotp />}
            />
            <Route
              exact
              path="/user_application"
              element={<UserApplcation />}
            />
            <Route exact path="/thankyou" element={<Thanks />} />
            <Route exact path="/Printable" element={<Printable />} />

            <Route path="/edit_application/:id" element={<Application />} />

            <Route path="/buildingList" element={<BuildingList />} />
            <Route path="/addBuilding" element={<AddBuilding />} />
            <Route path="/editBuilding/:id" element={<EditBuilding />} />
            <Route path="/buildingDetail/:id" element={<BuildingDetails />} />
            <Route path="/contact/:id" element={<Contact />} />
            <Route path="/inventoryList" element={<InventoryList />} />
            <Route path="/addInventory" element={<AddInventory />} />
            <Route path="/editInventory/:id" element={<EditInventory />} />
            <Route path="/manageCategory" element={<ManageCategory />} />
            <Route path="/addCategory" element={<AddCategory />} />
            <Route path="/editCategory/:id" element={<EditCategory />} />
            <Route path="/viewTrades" element={<ViewTrades />} />
            <Route path="/viewComments/:id" element={<ViewComments />} />
            <Route path="/addTrade" element={<AddTrades />} />
            <Route path="/editTrade/:id" element={<EditTrades />} />
            <Route path="/poReport" element={<POReport />} />
            <Route path="/viewPOReport/:id" element={<ViewPOReport />} />
            <Route
              path="/viewMaintenancePOReport/:id"
              element={<ViewMaintenancePOReport />}
            />
            <Route path="/vacancyReport" element={<VacancyReport />} />
            <Route path="/userList" element={<UserList />} />
            <Route path="/addUser" element={<AddUser />} />
            <Route path="/editUser/:id" element={<EditUser />} />
            <Route path="/accountSettings" element={<AccountSettings />} />
            <Route
              path="/applicationSettings"
              element={<ApplicationSetting />}
            />
            <Route
              path="/updateEmailTemplate/:id"
              element={<UpdateEmailTemplate />}
            />
            <Route
              path="/EditEmailTemplate/:id"
              element={<EditEmailTemplate />}
            />
            <Route path="/apiSettings" element={<APISettings />} />

            {/* <Route path="/logout" element={<Logout />} /> */}
            <Route path="/createOrder" element={<CreateOrder />} />
            <Route path="/viewExisting" element={<ViewExisting />} />
            <Route
              path="/maintenanceServices"
              element={<MaintenanceServices />}
            />
            <Route path="/purchaseParts" element={<PurchaseParts />} />
            <Route
              path="/purchasePartDetail/:id"
              element={<PurchasePartDetail />}
            />
            <Route
              path="/editPurchasePartDetail/:id"
              element={<EditPurchasePartDetail />}
            />
            <Route
              path="/maintenanceServicesDetail/:id"
              element={<MaintenanceServicesDetail />}
            />
            <Route
              path="/editMaintenanceServicesDetail/:id"
              element={<EditMaintenanceServicesDetail />}
            />
            <Route
              path="/workConfirmationPurchaseOrder/:id"
              element={<WorkConfirmationPurchaseOrder />}
            />
            <Route
              path="/workConfirmationMaintenance/:id"
              element={<WorkConfirmationMaintenance />}
            />
            <Route path="/workOrder" element={<WorkOrder />} />
            <Route path="/viewWorkOrder/:id" element={<ViewWorkOrder />} />
            <Route path="/workOrderCreation" element={<WorkOrderCreation />} />
            <Route
              path="/workOrderCompletion/:id"
              element={<WorkOrderCompletion />}
            />
            <Route path="/viewTrafficSheet" element={<ViewTrafficSheet />} />
            <Route
              path="/viewAssignedUnit/:id&:_id"
              element={<ViewAssignedUnit />}
            />
            <Route
              path="/createTrafficSheet"
              element={<CreateTrafficSheet />}
            />
            <Route path="/scheduleVacancy/:id" element={<ScheduleVacancy />} />
            <Route
              path="/movieInNewTenant/:id"
              element={<MovieInNewTenant />}
            />
            <Route
              path="/editTrafficSheet/:id"
              element={<EditTrafficSheet />}
            />
            <Route path="/addUnit" element={<AddUnit />} />
            <Route path="/editUnit/:id" element={<EditUnit />} />

            {/* /////////////////////////////////////// */}
          </Route>
          <Route path="/" element={<Login />} />
          <Route path="/forgotPassword" element={<ForgotPassword />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}
