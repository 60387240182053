import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import * as yup from "yup";
import Form from "react-bootstrap/Form";
import { Close } from "../../../Icons/Icons";
import {
  adminApiCall,
  adminApiCallForFormData,
  deleteFormApiCall,
  updateApplication,
  updateFormApiCall,
} from "../../../helpers/ajax_request";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loading from "../../../components/Loading";
function AddForms({
  isModalOpen,
  setIsModalOpen,
  fetchList,
  selectedId,
  setSelectedId,
}) {
  const [selectedFile, setSelectedFile] = useState(null);

  toast.configure();
  const notifySuccess = (msg) =>
    toast.success(msg, {
      autoClose: 3000,
    });
  const userId =
    localStorage.getItem("userID") !== null ||
    localStorage.getItem("userID") !== undefined
      ? localStorage.getItem("userID")
      : "";
  const initialValues = {
    form_name: "",
    subject: "",
    upload_pdf: "",
    recipt_message: "",
    created_by: userId,
    created_by_info: userId,
  };
  let validationSchema = yup.object({
    form_name: yup
      .string("Please add form name.")
      .required("Please add form name."),
    subject: yup.string("Please add subject.").required("Please add subject."),
    recipt_message: yup
      .string("Please add message.")
      .required("Please add message."),
    created_by: yup
      .string("Please add created by.")
      .required("Please add created by."),
    created_by_info: yup
      .string("Please add created by info.")
      .required("Please add created by info."),
  });
  const onSubmit = async (values, { resetForm }) => {
    const formDataToSend = new FormData();
    formDataToSend.append("form_name", values.form_name);
    formDataToSend.append("subject", values.subject);
    formDataToSend.append("upload_pdf", values.upload_pdf);
    formDataToSend.append("recipt_message", values.recipt_message);
    formDataToSend.append("created_by", values.created_by);
    formDataToSend.append("created_by_info", values.created_by_info);
    let res;
    try {
      const updateFormData = new FormData();
      updateFormData.append("form_name",values.form_name);
      updateFormData.append("subject",values.subject);
      updateFormData.append("recipt_message",values?.recipt_message);
      updateFormData.append("formId",selectedId?._id);
      updateFormData.append("upload_pdf",values?.upload_pdf ? values?.upload_pdf : null );
      if (selectedId?._id) {
        
        res = await updateFormApiCall(`/api/admin/update-form`, "PUT", updateFormData,true);
        if (res?.status === 200) {
          setIsModalOpen(false);
          resetForm();
          fetchList();
          notifySuccess(res?.data?.message);
          setSelectedId("");
        }
      } else {
        res = await adminApiCallForFormData(
          "/api/admin/add-form",
          formDataToSend
        );
        if (res?.status === 200) {
          console.log("faldsjfhalsdkhfasd31as3d2f1s3d", res);
          setIsModalOpen(false);
          resetForm();
          fetchList();
          notifySuccess(res?.data?.message);
          setSelectedId("");
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    formik.setFieldValue("upload_pdf", file);
    setSelectedFile(file);
  };

  const handleUpdate = (data) => {
    if (data?._id) {
      formik.setFieldValue("upload_pdf", null);
      formik.setFieldValue("form_name", data?.form_name);
      formik.setFieldValue("subject", data?.subject);
      formik.setFieldValue("recipt_message", data?.recipt_message);
    }
  };
  useEffect(() => {
    handleUpdate(selectedId);
  }, [selectedId]);
  const handleClose = () => {
    setIsModalOpen(false);
    setSelectedId("");
    formik.resetForm();
  };
  
  return (
    <Modal
      show={isModalOpen}
      onHide={() => setIsModalOpen(false)}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <form onSubmit={formik.handleSubmit}>
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            Add Trade
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {formik.isSubmitting ? (
            <div className="flex items-center w-full justify-center">
              {" "}
              <Loading />
            </div>
          ) : (
            <div className="contentCard">
              <div className="formDesign">
                <div className="row">
                  
                  <div className="col-12 col-md-6 col-lg-6">
                    <div className="form-group">
                      <label>Form Name</label>
                      <input
                        disabled={formik.isSubmitting}
                        className={
                          "form-control " +
                          (formik.errors.form_name && formik.touched.form_name
                            ? " is-invalid"
                            : "")
                        }
                        type="text"
                        name="form_name"
                        value={formik.values.form_name}
                        onChange={formik.handleChange}
                      />
                      <div className="invalid-feedback">
                        {formik.errors.form_name}
                      </div>
                    </div>
                  </div>

                  <div className="col-12 col-md-6 col-lg-6">
                    <div className="form-group">
                      <label>Subject</label>
                      <input
                        disabled={formik.isSubmitting}
                        className={
                          "form-control " +
                          (formik.errors.subject && formik.touched.subject
                            ? " is-invalid"
                            : "")
                        }
                        type="text"
                        name="subject"
                        value={formik.values.subject}
                        onChange={formik.handleChange}
                      />
                      <div className="invalid-feedback">
                        {formik.errors.subject}
                      </div>
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="form-group">
                      <label>Recipent Message</label>
                      <textarea
                        disabled={formik.isSubmitting}
                        className={
                          "form-control " +
                          (formik.errors.recipt_message &&
                          formik.touched.recipt_message
                            ? " is-invalid"
                            : "")
                        }
                        type="text"
                        name="recipt_message"
                        rows={5}
                        value={formik.values.recipt_message}
                        onChange={formik.handleChange}
                      />
                      <div className="invalid-feedback">
                        {formik.errors.recipt_message}
                      </div>
                    </div>
                  </div>

                  <div className="col-12 " style={{ position: "relative" }}>
                    <Form.Group controlId="formFile" className="mb-3">
                      <Form.Label>Upload Pdf/Docs/Image</Form.Label>
                      <Form.Control
                        type="file"
                        onChange={handleFileChange}
                        name="upload_pdf"
                        disabled={formik.isSubmitting}
                      />
                    </Form.Group>
                    {formik.values.upload_pdf && (
                      <div
                        style={{
                          position: "absolute",
                          top: "33px",
                          right: "15px",
                          zIndex: "9999",
                          cursor: "pointer",
                        }}
                      >
                        <Close />{" "}
                      </div>
                    )}
                  </div>
                  
                  
                  <div
                    className="col-12"
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      gap: "10px",
                    }}
                  >
                    <Button
                      type="submit"
                      className="myBtn shadow reactBoostrapModalBtn"
                    >
                      Submit
                    </Button>
                    <Button className="" onClick={() => handleClose()}>
                      Cancel
                    </Button>
                  </div>



                </div>
              </div>
            </div>
          )}
        </Modal.Body>
      </form>
    </Modal>
  );
}

export default AddForms;
