import React from "react";
import { Link } from "react-router-dom";
import { File, Edit, Delete } from "../../../Icons/Icons";
import { updateApplication } from "../../../helpers/ajax_request";
function FormTables({ data, handleUpdate, setSelectedId, handleDelete }) {
  return (
    <div className="mainTable table-responsive dataTable scrollable">
      <table id="table-to-xls" className="table table-striped">
        <thead>
          <tr>
            <th className="sort">Form Name</th>
            <th className="sort">Subject</th>
            <th className="sort">Upload Pdf</th>
            <th className="sort">Recipt Messages</th>
            <th className="text-right">Action</th>
          </tr>
        </thead>
        <tbody>
          {data?.map((element, idx) => {
            return (
              <tr key={element?._id}>
                <td>{element?.form_name}</td>
                <td>
                  <b>{element?.subject}</b>
                </td>
                <td>{"Upload Pdf"}</td>
                <td>{element?.recipt_message}</td>
                <td className="text-right">
                  <div className="dropleft">
                    <button
                      type="button"
                      className="actionEclips dropdown-toggle"
                      data-toggle="dropdown"
                    >
                      <span></span>
                      <span></span>
                      <span></span>
                    </button>
                    <div className="dropdown-menu dropDesign">
                      <Link
                        to="#"
                        onClick={(e) => {
                          e.preventDefault();
                          handleUpdate(element);
                        }}
                      >
                        Edit
                        <span>
                          <Edit />
                        </span>
                      </Link>
                      <Link
                        to="#"
                        onClick={(e) => {
                          e.preventDefault();
                          handleDelete(element?._id);
                        }}
                      >
                        Delete
                        <span>
                          <Delete />
                        </span>
                      </Link>
                    </div>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default FormTables;
