import { useEffect, useRef, useState } from "react";
import MainLayout from "../../layouts";
import {
  listingApplication,
  updateApplication,
} from "../../helpers/ajax_request";
// import Loader from "../../../assets/images/LoaderNew.gif";
import Loader from "../.../../../assets/images/LoaderNew.gif";
import noRecord from "../.../../../assets/images/noRecord.png";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisVertical } from "@fortawesome/free-solid-svg-icons"; // Import the specific icon you want to use
// import Loader from "../../assets/images/LoaderNew.gif";
import Logosm from "../../assets/images/logo.png";
import NoRecordFound from "../../assets/images/noRecordFound.png";
import ReactPaginate from "react-paginate";
import Swal from "sweetalert2";
import moment from "moment";
// import { Button, Modal } from "react-bootstrap";
import { Modal, Input, Button } from "antd";
import "antd/dist/antd.css"; // Import the Antd CSS styles (or include it in your main styles file)
import { Link } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { baseURL } from "../../config";
import { PATH } from "../../constants/paths";

import {
  Email,
  File,
  Edit,
  Approve,
  Print,
  Delete,
} from "../../Icons/Icons";
import {
  adminApiCall,
  capatalizeLetters,
  userAccessibility,
  getDate,
  getDateOnly,
  deleteApplication,
  createApplcation,
} from "../../helpers/ajax_request";
import { formatDollarToUS } from "../../helpers/converter";

import { useNavigate } from "react-router-dom";
import Application from "../Application";
import { flushSync } from "react-dom";

// stateContainer.js
let updateSubmitFunction = null;

export const setUpdateSubmitFunction = (func) => {
  updateSubmitFunction = func;
};

export const getUpdateSubmitFunction = () => {
  // console.log("Getting");
  return updateSubmitFunction;
};

export const ApplicationListing = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [lisitngData, setListingData] = useState([]);
  const [modalVisible, setModalVisible] = useState(false); // State to control the visibility of the modal

  const [noRecordsMessage, setNoRecordsMessage] = useState("");

  const [loading, setLoading] = useState(false);
  const [noRecord, setNoRecord] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10); // Assuming default items per page is 10
  const [totalRecord, setTotalRecord] = useState(0); // Initialize totalRecord state
  const [listID, setListID] = useState();
  const [refresh, setRefresh] = useState(0);
  const [visible, setVisible] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [dynamicFields, setDynamicFields] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalId, setModalId] = useState("");
  const [notes, setNotes] = useState([]);
  const [deleteItem, setDeleteItem] = useState([]);
  const [printData, setPrintData] = useState(null);

  // const [inputFields, setInputFields] = useState([{ value: "" }]);
  const [inputFields, setInputFields] = useState([
    { value: "", noteId: "", isNew: true },
  ]);
  const applicationRef = useRef();

  const showModal = () => {
    setIsModalVisible(true);
    // setModalId(id)
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const Navigate = useNavigate();

  toast.configure();

  const handleInputChange = (index, event) => {
    const values = [...inputFields];
    // console.log("values==>change", values);
    values[index].value = event.target.value;
    setInputFields(values);
  };

  // const handleAddField = () => {
  //   setInputFields([...inputFields, { value: "" }]);
  // };
  const handleAddField = () => {
    const values = [...inputFields];
    if (values.length > 0) {
      values[values.length - 1].isNew = false; // Set isNew to false for the previous field when a new field is added
    }
    setInputFields([...values, { value: "", noteId: null, isNew: true }]);
  };

  const handleRemoveField = (index) => {
    const values = [...inputFields];
    values.splice(index, 1);
    setInputFields(values);
  };

  const notifySuccess = (msg) =>
    toast.success(msg, {
      autoClose: 3000,
    });

  const notifyError = (msg) =>
    toast.error(msg, {
      autoClose: 3000,
    });
  // console.log("modal id", modalId);
  // useEffect(async () => {
  //   let data =  {
  //     items_per_page:perPage,
  //     current_page:currentPage
  //   }

  //   let res = await listingApplication("/api/admin/application/list","POST",

  // JSON.stringify(data)
  //   );
  //   setListingData(res?.data);
  //     setRefresh((o) => o + 1);

  //   console.log("res", res);
  // }, [currentPage, perPage]);

  const fetchData = async () => {
    setLoading(true); // Start loading when data fetching begins

    let data = {
      items_per_page: perPage,
      current_page: currentPage,
      building_name: localStorage.getItem("building_namee"),
    };

    try {
      let res = await listingApplication(
        "/api/admin/application/list",
        "POST",
        JSON.stringify(data)
      );

      if (res?.success === true) {
        setListingData(res?.data);
        setTotalRecord(res?.total_records); // Assuming you receive the total number of records from the API

        setNoRecordsMessage(""); // Clear the no records message
      } else if (res?.message === "No record found") {
        setListingData([]); // Clear the listing data
        setNoRecordsMessage("No records found");
      } else {
        setNoRecordsMessage("Error fetching data");
      }
    } catch (error) {
      if (error?.status === 300) console.log("error", error);
      setNoRecordsMessage(error?.responseJSON.message);
    } finally {
      setLoading(false); // Stop loading when data fetching is complete
    }
  };

  // const fetchData = async () => {

  //   let data = {
  //     items_per_page: perPage,
  //     current_page: currentPage,
  //     building_name: localStorage.getItem("building_namee"),
  //   };

  //   let res = await listingApplication(
  //     "/api/admin/application/list",
  //     "POST",
  //     JSON.stringify(data)
  //   );
  //   if (res?.success == true) {
  //     setLoading(false); // Set loading to false when data is received
  //     setListingData(res?.data);
  //     setTotalRecord(res?.total_records); // Assuming you receive the total number of records from the API
  //   }
  //   if (res?.message == "No record found") {
  //     setNoRecordsMessage(res?.message);
  //   } else {
  //     setLoading(false); // Set loading to false in case of an error
  //     setNoRecordsMessage("Error fetching data");
  //   }
  // };

  useEffect(() => {
    fetchData();
  }, [currentPage, perPage]);

  const handleChange = (e) => {
    const itemPerPage = parseInt(e.target.value);
    setPerPage(itemPerPage);
    setCurrentPage(1); // Reset current page when changing items per page
  };

  const handlePageClick = (e) => {
    const selectedPage = e.selected + 1;
    setCurrentPage(selectedPage);
  };
  const pageCount = Math.ceil(totalRecord / perPage);

  const deleteInventory = async (deleteArray) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure!",
        text: "You want to delete?",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "No, cancel please!",
        confirmButtonText: "Yes! Delete This",
        confirmButtonColor: "#d97228",
        closeOnConfirm: false,
      });

      if (result.isConfirmed) {
        let data;
        if (typeof deleteArray === "number") {
          data = {
            application_id: deleteArray,
          };
        } else {
          data = {
            application_id: deleteArray,
          };
        }

        let res = await deleteApplication(
          "/api/admin/application/delete ",
          "POST",
          JSON.stringify(data)
        );
        setDeleteItem([]);
        // window.location.reload();
        fetchData();
        toast.success(res?.message);

        if (!res) {
          notifyError("Something went wrong.. Please Try Again");
          return false;
        }
      }
    } catch (error) {
      // console.log("Error:", error);
      // Handle the error accordingly
      toast.error("Something Wen Wrong");
    }
  };

  const deleteInventoryAll = (deleteArray) => {
    Swal.fire({
      title: "Are you sure!",
      text: "You want to delete?",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "No, cancel please!",
      confirmButtonText: "Yes! Delete This",
      confirmButtonColor: "#d97228",
      closeOnConfirm: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        let data;
        if (typeof deleteArray === "number") {
          // let dataArray = [];
          // dataArray.push(deleteArray);
          data = {
            application_ids: deleteArray,
          };
        } else {
          data = {
            application_ids: deleteArray,
          };
        }
        let res = await adminApiCall(
          "/api/admin/application/delete_multiple",
          "POST",
          JSON.stringify(data)
        );
        if (!res) {
          notifyError("Something went wrong.. Please Try Again");
          return false;
        }
        if (res.success === true) {
          setDeleteItem([]);
          toast.success(res?.message);
          fetchData();
        } else if (res.status === 400) {
          toast.error(res?.message);
        }
      }
    });
  };

  // const deleteInventoryAll = (deleteArray) => {
  //   Swal.fire({
  //     title: "Are you sure!",
  //     text: "You want to delete?",
  //     icon: "warning",
  //     showCancelButton: true,
  //     cancelButtonText: "No, cancel please!",
  //     confirmButtonText: "Yes! Delete This",
  //     confirmButtonColor: "#d97228",
  //     closeOnConfirm: false,
  //   }).then(async (result) => {
  //     if (result.isConfirmed) {
  //       let data;
  //       if (typeof deleteArray === "number") {
  //         // let dataArray = [];
  //         // dataArray.push(deleteArray);
  //         data = {
  //           inventory_id: deleteArray,
  //         };
  //       } else {
  //         data = {
  //           inventory_id: deleteArray,
  //         };
  //       }
  //       let res = await adminApiCall(
  //         "/api/admin/inventory/delete",
  //         "POST",
  //         JSON.stringify(data)
  //       );
  //       if (!res) {
  //         notifyError("Something went wrong.. Please Try Again");
  //         return false;
  //       }
  //       if (res.hasOwnProperty("status")) {
  //         if (res.status === 200) {
  //           getInventoryList();
  //           setDeleteItem([]);
  //           notifySuccess("Inventory Deleted Successfully!");
  //         } else if (res.status === 400) {
  //           notifyError(res.message);
  //         }
  //       }
  //     }
  //   });
  // };

  const handleApproveClick = async (id) => {
    // console.log("id", id);
    const requestData = {
      application_id: id,
    };
    try {
      let res = await createApplcation(
        "/api/admin/application/approve_application",
        JSON.stringify(requestData)
      );
      // console.log("Response ==>approve", res);
      toast.success(res?.message); // Display the success toaster notification
      // navigate("/application-listing");
    } catch (error) {
      // console.log("err", error);
      if (error.status == 400) {
        toast.error("Something Went Wrong"); // Display the error toaster notification
      } else {
        toast.error("Server Side Error"); // Display the error toaster notification
      }
    }
  };

  const handleGetNote = async (modalId) => {
    // console.log("idaaa", modalId);
    const requestData = {
      application_id: modalId,
    };
    try {
      let res = await createApplcation(
        "/api/admin/application/get_notes",
        JSON.stringify(requestData)
      );
      setNotes(res?.notes); // Assuming the API returns an object with a 'notes' property containing the note data

      // console.log("Response ==>approve", res);
      // toast.success(res?.message); // Display the success toaster notification
      // navigate("/application-listing");
    } catch (error) {
      // console.log("err", error);
      if (error.status == 400) {
        toast.error("Something Went Wrong"); // Display the error toaster notification
      } else {
        toast.error("Server Side Error"); // Display the error toaster notification
      }
    }
  };

  const handleRejectClick = async (id) => {
    // console.log("id", id);
    const requestData = {
      application_id: id,
    };
    try {
      let res = await createApplcation(
        "/api/admin/application/reject_application",
        JSON.stringify(requestData)
      );
      // console.log("Response ==>reject", res);
      toast.success(res?.message); // Display the success toaster notification
      // navigate("/application-listing");
    } catch (error) {
      // console.log("err", error);
      if (error.status == 400) {
        toast.error("Something Went Wrong"); // Display the error toaster notification
      } else {
        toast.error("Server Side Error"); // Display the error toaster notification
      }
    }
  };

  const crditCheck = async (id) => {
    // console.log("id", id);
    const requestData = {
      application_id: id,
    };
    try {
      let res = await createApplcation(
        "/api/admin/application/initiate_credit_check",
        JSON.stringify(requestData)
      );
      // console.log("Response ==>reject", res);
      toast.success(res?.message); // Display the success toaster notification
      // navigate("/application-listing");
    } catch (error) {
      // console.log("err", error);
      if (error.status == 400) {
        toast.error("Something Went Wrong"); // Display the error toaster notification
      } else {
        toast.error("Server Side Error"); // Display the error toaster notification
      }
    }
  };

  const AddNoteApi = async (field) => {
    // console.log("field", field);
    const requestData = {
      application_id: modalId,
      note: field.value, // Send only the value of the current field
    };
    try {
      let res = await createApplcation(
        "/api/admin/application/add_note",
        JSON.stringify(requestData)
      );
      // console.log("Response ==>reject", res);
      toast.success(res?.message); // Display the success toaster notification
      // navigate("/application-listing");
    } catch (error) {
      // console.log("err", error);
      if (error.status == 400) {
        toast.error("Something Went Wrong"); // Display the error toaster notification
      } else {
        toast.error("Server Side Error"); // Display the error toaster notification
      }
    }
  };

  const handleChangeCheckBox = (e) => {
    let checked = e.target.checked;
    let value = e.target.value;
    // console.log(e);
    // console.log(checked + value);
    // if (deleteItem) {
    let tempArray = [...deleteItem];
    if (checked) {
      tempArray.push(value);
      // console.log("deleteitem: ", deleteItem);
    } else {
      tempArray.splice(tempArray.indexOf(value), 1);
      // console.log("deleteitem: ", deleteItem);
    }
    setDeleteItem(tempArray);
    // }
  };
  // Function to handle submitting a new note
  const handleAddNote = (field) => {
    if (field.isNew) {
      AddNoteApi(field); // Call the AddNoteApi function for new notes
    } else {
      updateNote(field); // Call the handleUpdateNote function for existing notes
    }
  };

  // console.log("nootes", notes);

  useEffect(() => {
    if (notes.length > 0) {
      setInputFields(
        notes.map((note) => ({ value: note?.note, noteId: note._id }))
      );
    }
  }, [notes]);
  // delete note
  const deleteNoteApi = async (field) => {
    // console.log("field delete", field);
    const requestData = {
      // application_id: modalId,
      note_id: field?.noteId, // Send only the value of the current field
    };
    try {
      let res = await createApplcation(
        "/api/admin/application/delete_note",
        JSON.stringify(requestData)
      );
      // console.log("Response ==>reject", res);
      toast.success(res?.message); // Display the success toaster notification
      // navigate("/application-listing");
    } catch (error) {
      // console.log("err", error);
      if (error.status == 400) {
        toast.error("Something Went Wrong"); // Display the error toaster notification
      } else {
        toast.error("Server Side Error"); // Display the error toaster notification
      }
    }
  };
  // upate note
  const updateNote = async (field) => {
    // console.log("field delete", field);
    const requestData = {
      // application_id: modalId,
      note_id: field?.noteId, // Send only the value of the current field
      note: field?.value,
    };
    try {
      let res = await createApplcation(
        "/api/admin/application/update_note",
        JSON.stringify(requestData)
      );
      // console.log("Response ==>reject", res);
      toast.success(res?.message); // Display the success toaster notification
      // navigate("/application-listing");
    } catch (error) {
      // console.log("err", error);
      if (error.status == 400) {
        toast.error("Something Went Wrong"); // Display the error toaster notification
      } else {
        toast.error("Server Side Error"); // Display the error toaster notification
      }
    }
  };
  // console.log("inputFields modal==>", inputFields);
  const handleChangeCheckBoxGI = async (id) => {
    // console.log("handleChangeCheckBoxGI", id);
    const requestData = {
      application_id: id,
    };
    try {
      let res = await createApplcation(
        "/api/admin/application/approve_government_identification",
        JSON.stringify(requestData)
      );
      // console.log("Response ==>reject", res);
      toast.success(res?.message); // Display the success toaster notificationbu
      // navigate("/application-listing");
      // Update the checked status of the checkbox based on the response from the API
      fetchData();
    } catch (error) {
      // console.log("err", error);
      if (error.status == 400) {
        toast.error("Something Went Wrong"); // Display the error toaster notification
      } else {
        toast.error("Server Side Error"); // Display the error toaster notification
      }
    }
  };

  const handleChangeCheckBoxPE = async (id) => {
    // console.log("handleChangeCheckBoxPE", id);
    const requestData = {
      application_id: id,
    };
    try {
      let res = await createApplcation(
        "/api/admin/application/approve_proof_of_employment",
        JSON.stringify(requestData)
      );
      // console.log("Response ==>reject", res);
      toast.success(res?.message); // Display the success toaster notification
      // navigate("/application-listing");
      fetchData();
    } catch (error) {
      // console.log("err", error);
      if (error.status == 400) {
        toast.error("Something Went Wrong"); // Display the error toaster notification
      } else {
        toast.error("Server Side Error"); // Display the error toaster notification
      }
    }
  };

  const UpdateSubmit = async (id) => {
    const data = {
      action: "send",
      // application_id: applicationID,
      application_id: id,
    };
    try {
      let res = await updateApplication(
        "/api/admin/application/update",
        "POST",
        JSON.stringify(data)
      );
      toast.success(res?.message); // Display the success toaster notification
      // navigate("/application-listing");
    } catch (error) {
      // notifyError("Something went wrong.. Please Try Again");
      console.log("error", error);
      toast.error(error?.statusText);
      notifyError(error?.responseJSON?.message);

      return false;
    }
  };

  // new call
  // update Api call on submit
  const UpdateSubmitAgain = async (id) => {
    console.log("with id Submit");
    try {
      let data = {
        application_id: id,
        action: "send",
      };
      // let data = {
      //   suite_no: formik?.values?.suite,
      //   application_type: formik?.values?.type,
      //   building: formik?.values?.building,

      //   inside: formik?.values?.inside,
      //   outside: formik?.values?.outside,
      //   lastMonthRent: formik?.values?.lastMonthRent,
      //   belowLast: formik?.values?.belowLast,
      //   discountRent: formik?.values?.discountRent,
      //   discountLast: formik?.values?.discountLast,
      //   discountPayable: formik?.values?.discountPayable,
      //   netLast: formik?.values?.netLast,
      //   netPayable: formik?.values?.netPayable,
      //   lastMonthDeposit: formik?.values?.lastMonthDeposit,
      //   noDays: formik?.values?.noDays,
      //   perDiem: formik?.values?.perDiem,
      //   proAmountRent: formik?.values?.proAmountRent,
      //   proAmount: formik?.values?.proAmount,
      //   Prior: formik?.values?.Prior,

      //   tenant_name: formik?.values?.tenentContactName,
      //   tenant_email: formik?.values?.tenentEmailAddress,
      //   tenant_phone_no: formik?.values?.tenentPhoneNumber,
      //   parking_below_ground: {
      //     law_rent: formik?.values?.belowRent,
      //     rent_payable: formik?.values?.belowPayAble,
      //   },
      //   bona_fide_tenants: fields,
      //   occupants: occupantFields,

      //   tenancy_start_date: tenentStartDate,
      //   tenancy_end_date: tenentEndDate,
      //   rent: {
      //     law_rent: formik?.values?.lawRent,
      //     rent_payable: formik?.values?.payAbleRent,
      //   },
      //   parking_above_ground: {
      //     law_rent: formik?.values?.parkingLawRent,
      //     rent_payable: formik?.values?.parkingPayAbleRent,
      //   },
      //   total_legal_rent: {
      //     law_rent: formik?.values?.legalRent,
      //     rent_payable_legal: formik?.values?.legalPayAble,
      //     last_month_rent: formik?.values?.legalLast,
      //   },
      //   prompt_payment_discount: formik?.values?.discountPayable,
      //   net_rent_payable: formik?.values?.netPayable,

      //   pro_rate: {
      //     pro_start_date: proStartDate,
      //     pro_End_date: proEndDate,
      //     no_of_days: formik?.values?.noDays,
      //     per_diem_rate: formik?.values?.perDiem,
      //     pro_rent: formik?.values?.proAmountRent,
      //     pro_rate_amount: formik?.values?.proAmount,
      //     lmr: formik?.values?.lmr,
      //     total_amount_prior_move_in: formik?.values?.Prior,
      //   },

      //   application_type: {
      //     new_application: newApplicants,
      //     assignment: assignments,
      //     sublet: sublets,
      //     transfer: transfers,
      //     name_change: nameChanges,
      //   },
      //   action: "send",
      //   application_id: applicationID,

      //   government_identification: selectedImage,
      //   proof_of_employment: selectedFile,
      //   aggrement_date: agreementDate,
      //   tenant_signature:
      //     signPadTenent?.getTrimmedCanvas()?.toDataURL("image/png") ?? null,
      //   building_manager_signature:
      //     signPadBuildingManager?.getTrimmedCanvas()?.toDataURL("image/png") ??
      //     null,
      //   property_manager_signature:
      //     signPadPropertyManager?.getTrimmedCanvas()?.toDataURL("image/png") ??
      //     null,
      //   // acceptance_date :acceptanceDate,
      //   today_date: requestDate,

      //   applicant_name: {
      //     name_applicant_one: formik?.values?.name_applicant_one,
      //     name_applicant_two: formik?.values?.name_applicant_two,
      //     name_applicant_three: formik?.values?.name_applicant_three,
      //   },
      //   date_of_birth: {
      //     date_of_birth_one: formik?.values?.date_of_birth_one,
      //     date_of_birth_two: formik?.values?.date_of_birth_two,
      //     date_of_birth_three: formik?.values?.date_of_birth_three,
      //   },
      //   current_address: {
      //     current_address_one: formik?.values?.current_address_one,
      //     current_address_two: formik?.values?.current_address_two,
      //     current_address_three: formik?.values?.current_address_three,
      //   },
      //   apartment_no: {
      //     appartment_no_one: formik?.values?.appartment_no_one,
      //     appartment_no_two: formik?.values?.appartment_no_two,
      //     appartment_no_three: formik?.values?.appartment_no_three,
      //   },
      //   present_monthly_rent: {
      //     present_monthly_rent_one: formik?.values?.present_monthly_rent_one,
      //     present_monthly_rent_two: formik?.values?.present_monthly_rent_two,
      //     present_monthly_rent_three:
      //       formik?.values?.present_monthly_rent_three,
      //   },
      //   postal_code: {
      //     postal_code_one: formik?.values?.postal_code_one,
      //     postal_code_two: formik?.values?.postal_code_two,
      //     postal_code_three: formik?.values?.postal_code_three,
      //   },
      //   length_of_residence: {
      //     length_of_residence_one: formik?.values?.length_of_residence_one,
      //     length_of_residence_two: formik?.values?.length_of_residence_two,
      //     length_of_residence_three: formik?.values?.length_of_residence_three,
      //   },
      //   home_phone_no: {
      //     home_phone_no_one: formik?.values?.home_phone_no_one,
      //     home_phone_no_two: formik?.values?.home_phone_no_two,
      //     home_phone_no_three: formik?.values?.home_phone_no_three,
      //   },
      //   cell_phone_no: {
      //     cell_phone_no_one: formik?.values?.cell_phone_no_one,
      //     cell_phone_no_two: formik?.values?.cell_phone_no_two,
      //     cell_phone_no_three: formik?.values?.cell_phone_no_three,
      //   },

      //   landlords_name: {
      //     landlords_name_one: formik?.values?.landlords_name_one,
      //     landlords_name_two: formik?.values?.landlords_name_two,
      //     landlords_name_three: formik?.values?.landlords_name_three,
      //   },
      //   landlords_phone_no: {
      //     landlords_phone_one: formik?.values?.landlords_phone_one,
      //     landlords_phone_two: formik?.values?.landlords_phone_two,
      //     landlords_phone_three: formik?.values?.landlords_name_three,
      //   },
      //   previous_apartment_no: {
      //     previous_appartment_no_one:
      //       formik?.values?.previous_appartment_no_one,
      //     previous_appartment_no_two:
      //       formik?.values?.previous_appartment_no_two,
      //     previous_appartment_no_three:
      //       formik?.values?.previous_appartment_no_three,
      //   },
      //   previous_monthly_rent: {
      //     previous_monthly_rent_one: formik?.values?.previous_monthly_rent_one,
      //     previous_monthly_rent_two: formik?.values?.previous_monthly_rent_two,
      //     previous_monthly_rent_three:
      //       formik?.values?.previous_monthly_rent_three,
      //   },
      //   previous_postal_code: {
      //     previous_postal_code_one: formik?.values?.previous_postal_code_one,
      //     previous_postal_code_two: formik?.values?.previous_postal_code_two,
      //     previous_postal_code_three: formik?.values?.previous_position_three,
      //   },
      //   previous_length_of_residence: {
      //     previous_length_of_residence_one:
      //       formik?.values?.previous_length_of_residence_one,
      //     previous_length_of_residence_two:
      //       formik?.values?.previous_length_of_residence_two,
      //     previous_length_of_residence_three:
      //       formik?.values?.previous_length_of_residence_three,
      //   },
      //   previous_landlords_name: {
      //     previous_landlords_name_one:
      //       formik?.values?.previous_landlords_name_one,
      //     previous_landlords_name_two:
      //       formik?.values?.previous_landlords_name_two,
      //     previous_landlords_name_three:
      //       formik?.values?.previous_landlords_name_three,
      //   },
      //   previous_landlords_phone_no: {
      //     previous_landlords_phone_one:
      //       formik?.values?.previous_landlords_phone_one,
      //     previous_landlords_phone_two:
      //       formik?.values?.previous_landlords_name_two,
      //     previous_landlords_phone_three:
      //       formik?.values?.previous_landlords_name_three,
      //   },
      //   current_employer: {
      //     current_employer_one: formik?.values?.current_employer_one,
      //     current_employer_two: formik?.values?.current_employer_two,
      //     current_employer_three: formik?.values?.current_employer_three,
      //   },
      //   work_phone_no: {
      //     work_phone_no_one: formik?.values?.work_phone_no_one,
      //     work_phone_no_two: formik?.values?.work_phone_no_two,
      //     work_phone_no_three: formik?.values?.work_phone_no_three,
      //   },
      //   position: {
      //     position_one: formik?.values?.position_one,
      //     position_two: formik?.values?.position_two,
      //     position_three: formik?.values?.position_three,
      //   },
      //   length_of_employment: {
      //     length_of_employment_one: formik?.values?.length_of_employment_one,
      //     length_of_employment_two: formik?.values?.length_of_employment_two,
      //     length_of_employment_three:
      //       formik?.values?.length_of_employment_three,
      //   },
      //   contact_name_and_no: {
      //     contact_name_no_one: formik?.values?.contact_name_no_one,
      //     contact_name_no_two: formik?.values?.contact_name_no_two,
      //     contact_name_no_three: formik?.values?.contact_name_no_three,
      //   },
      //   phone_no: {
      //     phone_no_one: formik?.values?.phone_no_one,
      //     phone_no_two: formik?.values?.phone_no_two,
      //     phone_no_three: formik?.values?.phone_no_three,
      //   },
      //   annual_income: {
      //     annual_income_one: formik?.values?.annual_income_one,
      //     annual_income_two: formik?.values?.annual_income_two,
      //     annual_income_three: formik?.values?.annual_income_three,
      //   },
      //   previous_employer: {
      //     previous_employer_one: formik?.values?.previous_employer_one,
      //     previous_employer_two: formik?.values?.previous_employer_two,
      //     previous_employer_three: formik?.values?.previous_employer_three,
      //   },
      //   previous_position: {
      //     previous_position_one: formik?.values?.previous_position_one,
      //     previous_position_two: formik?.values?.previous_position_two,
      //     previous_position_three: formik?.values?.previous_position_three,
      //   },
      //   previous_length_of_employment: {
      //     Previous_length_of_employment_one:
      //       formik?.values?.Previous_length_of_employment_one,
      //     Previous_length_of_employment_two:
      //       formik?.values?.Previous_length_of_employment_two,
      //     Previous_length_of_employment_three:
      //       formik?.values?.previous_length_of_residence_three,
      //   },
      //   previous_contact_name_and_no: {
      //     previous_contact_name_no_one:
      //       formik?.values?.previous_contact_name_no_one,
      //     previous_contact_name_no_two:
      //       formik?.values?.previous_contact_name_no_two,
      //     previous_contact_name_no_three:
      //       formik?.values?.previous_annual_income_three,
      //   },
      //   previous_annual_income: {
      //     previous_annual_income_one:
      //       formik?.values?.previous_annual_income_one,
      //     previous_annual_income_two:
      //       formik?.values?.previous_annual_income_two,
      //     previous_annual_income_three:
      //       formik?.values?.previous_annual_income_three,
      //   },
      //   bank: {
      //     bank_one: formik?.values?.bank_one,
      //     bank_two: formik?.values?.bank_two,
      //     bank_three: formik?.values?.bank_three,
      //   },
      //   branch: {
      //     branch_one: formik?.values?.branch_one,
      //     branch_two: formik?.values?.branch_two,
      //     branch_three: formik?.values?.branch_three,
      //   },
      //   account_no: {
      //     account_no_one: formik?.values?.account_no_one,
      //     account_no_two: formik?.values?.account_no_two,
      //     account_no_three: formik?.values?.account_no_three,
      //   },
      //   make_of_vehicle: {
      //     make_of_vehicle_one: formik?.values?.make_of_vehicle_one,
      //     make_of_vehicle_two: formik?.values?.make_of_vehicle_two,
      //     make_of_vehicle_three: formik?.values?.make_of_vehicle_three,
      //   },
      //   year_model_of_vehicle: {
      //     year_model_of_vehicle_one: formik?.values?.year_model_of_vehicle_one,
      //     year_model_of_vehicle_two: formik?.values?.year_model_of_vehicle_two,
      //     year_model_of_vehicle_three:
      //       formik?.values?.year_model_of_vehicle_threer,
      //   },
      //   colour_of_vehicle: {
      //     colour_of_vehicle_one: formik?.values?.colour_of_vehicle_one,
      //     colour_of_vehicle_two: formik?.values?.colour_of_vehicle_two,
      //     colour_of_vehicle_three: formik?.values?.colour_of_vehicle_three,
      //   },
      //   licence_plate_no: {
      //     licence_plate_no_one: formik?.values?.licence_plate_no_one,
      //     licence_plate_no_two: formik?.values?.licence_plate_no_two,
      //     licence_plate_no_three: formik?.values?.licence_plate_no_three,
      //   },
      //   drivers_license_no: {
      //     drivers_license_no_one: formik?.values?.drivers_license_no_one,
      //     drivers_license_no_two: formik?.values?.drivers_license_no_two,
      //     drivers_license_no_three: formik?.values?.drivers_license_no_three,
      //   },
      //   reference_name: {
      //     reference_name_one: formik?.values?.reference_name_one,
      //     reference_name_two: formik?.values?.reference_name_two,
      //     reference_name_three: formik?.values?.reference_name_three,
      //   },
      //   reference_address: {
      //     reference_address_one: formik?.values?.reference_address_one,
      //     reference_address_two: formik?.values?.reference_address_two,
      //     reference_address_three: formik?.values?.reference_address_three,
      //   },
      //   reference_phone_no: {
      //     reference_phone_no_one: formik?.values?.reference_phone_no_one,
      //     reference_phone_no_two: formik?.values?.reference_phone_no_two,
      //     reference_phone_no_three: formik?.values?.reference_phone_no_three,
      //   },
      //   reference_relationship: {
      //     reference_relationship_one:
      //       formik?.values?.reference_relationship_one,
      //     reference_relationship_two:
      //       formik?.values?.reference_relationship_two,
      //     reference_relationship_three:
      //       formik?.values?.reference_relationship_three,
      //   },
      //   next_of_kin_name: {
      //     next_name_one: formik?.values?.next_name_one,
      //     next_name_two: formik?.values?.next_name_two,
      //     next_name_three: formik?.values?.next_name_three,
      //   },
      //   next_of_kin_address: {
      //     next_address_one: formik?.values?.next_address_one,
      //     next_address_two: formik?.values?.next_address_two,
      //     next_address_three: formik?.values?.next_address_three,
      //   },
      //   next_of_kin_phone_no: {
      //     next_phone_no_one: formik?.values?.next_phone_no_one,
      //     next_phone_no_two: formik?.values?.next_phone_no_two,
      //     next_phone_no_three:
      //       formik?.values?.formik?.values?.next_phone_no_three,
      //   },
      //   next_of_kin_relationship: {
      //     next_relationship_one: formik?.values?.next_relationship_one,
      //     next_relationship_two: formik?.values?.next_relationship_two,
      //     next_relationship_three: formik?.values?.next_relationship_three,
      //   },
      //   credit_unit: formik?.values?.suite,
      //   credit_building: formik?.values?.building,
      //   credit_date: creditDate,
      // };
      // console.log("data: ", data);
      // return;
      let res = await updateApplication(
        "/api/admin/application/update",
        "POST",
        JSON.stringify(data)
      );
      toast.success("Application Sent Again Successfully"); // Display the success toaster notification

      // toast.success(res?.message); // Display the success toaster notification
      // navigate("/application-listing");
    } catch (error) {
      // notifyError("Something went wrong.. Please Try Again");
      console.log("error", error);
      toast.error(error?.statusText);
      notifyError(error?.responseJSON?.message);

      return false;
    }
  };

  const handlePrint = (data) => {
    flushSync(() => {
      setPrintData(data);
    });
    if (applicationRef.current) {
      applicationRef.current.refsss();
    }
  };

  console.log("applicationRef", applicationRef);
  return (
    <>
      <MainLayout>
        <section
          className="pageWrapper"
          // style={{ marginLeft: "8rem", marginRight: "1rem", marginTop: "6rem" }}
        >
          <div className="contentCard">
            <div className="titleWrapper">
              <span>Application Listing</span>
              <div>
                <button
                  className="myBtn shadow ml-auto"
                  onClick={() => {
                    Navigate("/application");
                  }}
                >
                  <i className="fa fa-plus-circle"></i> Create New Application
                </button>
              </div>
            </div>

            <div className="mainTable ax table-responsive dataTable scrollable abb">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th></th>

                    <th className="sort">Tenant Name </th>
                    <th className="sort"> Unit </th>

                    <th className="sort">Tenant Email</th>
                    <th className="sort">Email Status</th>
                    <th className="sort">Tenancy Start Date</th>
                    <th className="sort">Tenancy End Date</th>
                    <th className="sort">Approved Date</th>

                    {/* <th className="sort">GI</th>
                  <th className="sort"> PE </th> */}

                    <th className="sort">Status</th>
                    <th className="text-right">Action</th>
                  </tr>
                </thead>
                {loading ? (
                  // <div className="text-center">
                  //   <img src={Loader} alt="" />
                  // </div>
                  <tr>
                    <td colSpan="9" className="text-center">
                      <img src={Loader} alt="" />
                    </td>
                  </tr>
                ) : (
                  <>
                    {noRecordsMessage ? (
                      <tr>
                        <td colSpan="9" className="text-center">
                          <img src={NoRecordFound} alt="" />
                        </td>
                      </tr>
                    ) : (
                      <tbody>
                        {lisitngData &&
                          lisitngData.map((v, i) => {
                            return (
                              <tr key={i}>
                                <td>
                                  <input
                                    type="checkbox"
                                    className="customCheck mr-3"
                                    id={"inventoryCheck" + i}
                                    value={v._id}
                                    checked={
                                      deleteItem && deleteItem.includes(v._id)
                                        ? true
                                        : false
                                    }
                                    onChange={(e) => handleChangeCheckBox(e)}
                                  />
                                </td>

                                <td className="orderItems">
                                  {/* <b>{v.tenant_name} </b> */}

                                  {v?.tenant_name}
                                </td>
                                <td className="orderItems">
                                  {/* <b>{v.tenant_name} </b> */}

                                  {v?.suite_no}
                                </td>
                                <td>
                                  {/* {capatalizeLetters(v.order_type.split("_")[0]) +
                        " " +
                        capatalizeLetters(v.order_type.split("_")[1])} */}
                                  {v?.tenant_email}
                                </td>
                                <td>{v?.email_status}</td>
                                <td>
                                  {/* {moment(v.tenancy_start_date).format(
                "DD MMMM YYYY"
              )} */}
                                  {moment
                                    .utc(new Date(v.tenancy_start_date))
                                    .format("DD MMMM YYYY")}
                                </td>
                                <td>
                                  {moment
                                    .utc(new Date(v.tenancy_end_date))
                                    .format("DD MMMM YYYY")}

                                  {/* </span> */}
                                </td>

                                <td>
                                  {v?.approved_at
                                    ? moment
                                        .utc(new Date(v.approved_at))
                                        .format("DD MMMM YYYY")
                                    : "No Approve Date"}
                                </td>

                                {/* <td>
                          {" "}
                          <input
                            type="checkbox"
                            className="cursore"
                            value={v._id}
                            checked={
                              v.government_identification_approved
                                ? true
                                : false
                            }
                            // disabled
                            onChange={(e) => handleChangeCheckBoxGI(v?._id)}
                          />
                        </td> */}
                                {/* <td>
                          <input
                            type="checkbox"
                            className="cursore"
                            value={v._id}
                            checked={v.proof_of_employment_approved}
                            // disabled
                            onChange={(e) => handleChangeCheckBoxPE(v?._id)}
                          />
                        </td> */}
                                <td>
                                  {" "}
                                  <span className={`text ${v.status}`}>
                                    <span></span>{" "}
                                    {/* {v.order_status === "COMPLETED"
                          ? "INVOICE RECEIVED"
                          : v.order_status === "PARTIALLY_COMPLETE"
                          ? "RECEIVED"
                          : "INCOMPLETE"} */}
                                    {v.status == "SUBMITTED"
                                      ? "READY FOR REVIEW"
                                      : v.status}
                                  </span>
                                </td>

                                <td className="text-right">
                                  <div className="dropleft">
                                    <button
                                      type="button"
                                      className="actionEclips dropdown-toggle"
                                      data-toggle="dropdown"
                                    >
                                      <span></span>
                                      <span></span>
                                      <span></span>
                                    </button>
                                    <div className="dropdown-menu dropDesign ">
                                      {v?.status == "SUBMITTED" &&
                                        localStorage?.getItem("role") !=
                                          "building_manager" && (
                                          <a
                                            href="#"
                                            data-toggle="modal"
                                            onClick={() => {
                                              handleApproveClick(v._id);
                                            }}
                                          >
                                            Approve
                                            <span>
                                              <Approve />
                                            </span>
                                          </a>
                                        )}

                                      {/* ////////////////////////////////////handleRejectClick///////////////////////////////////// */}

                                      {v?.status == "SUBMITTED" && (
                                        <a
                                          href="#"
                                          data-toggle="modal"
                                          onClick={() => {
                                            handleRejectClick(v._id);
                                          }}
                                        >
                                          Reject
                                          <span>
                                            <Delete />
                                          </span>
                                        </a>
                                      )}

                                      <Link to={`/edit_application/${v._id}`}>
                                        View / Edit
                                        <span>
                                          <Edit />
                                        </span>
                                      </Link>

                                      {/* ////////////////////////////////////view///////////////////////////////////// */}

                                      {/* <a
                                        href="#"
                                        data-toggle="modal"
                                        onClick={() => deleteInventory(v._id)}
                                      >
                                        Delete
                                        <span>
                                          <Delete />
                                        </span>
                                      </a> */}

                                      {v?.status == "SUBMITTED" && (
                                        <a
                                          href="#"
                                          data-toggle="modal"
                                          onClick={() => {
                                            crditCheck(v._id);
                                          }}
                                        >
                                          Initiate Credit Check
                                          <span>
                                            <Edit />
                                          </span>
                                        </a>
                                      )}

                                      {/* ////////////////////////////////////print///////////////////////////////////// */}
                                      <a
                                        href="#"
                                        data-toggle="modal"
                                        // onClick={() => deleteInventory(v._id)}
                                        onClick={() => handlePrint(v)}
                                      >
                                        Print
                                        <span>
                                          <Print />
                                        </span>
                                      </a>
                                      <a
                                        href="#"
                                        data-toggle="modal"
                                        // onClick={() => deleteInventory(v._id)}
                                        onClick={() => {
                                          showModal();
                                          setModalId(v?._id);
                                          handleGetNote(v?._id);
                                          // setNoteId()
                                        }}
                                      >
                                        Add Note
                                        <span>
                                          <Edit />
                                        </span>
                                      </a>
                                      {/* || v?.email_status == "OPENED"  */}
                                      {v?.email_status == "SENT" ||
                                      v?.status == "APPROVED" ||
                                      v?.status == "SUBMITTED" ||
                                      v?.status == "OPENED" ||
                                      v?.status == "REJECTED" ? (
                                        <a
                                          href="#"
                                          onClick={() => {
                                            UpdateSubmitAgain(v?._id);
                                          }}
                                        >
                                          Send Again
                                          <span>
                                            <Edit />
                                          </span>
                                        </a>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    )}
                  </>
                )}
              </table>
            </div>

            <Modal
              title="Notes"
              visible={isModalVisible}
              onOk={handleOk}
              onCancel={handleCancel}
            >
              {inputFields.map((field, index) => (
                <div key={index} style={{ marginBottom: "1rem" }}>
                  <Input
                    placeholder={`Field ${index + 1}`}
                    value={field?.value}
                    onChange={(e) => handleInputChange(index, e)}
                    style={{ marginBottom: "1px" }}
                  />
                  <Button
                    onClick={() => {
                      handleRemoveField(index);
                      deleteNoteApi(field);
                    }}
                    style={{ color: "gray" }}
                  >
                    Remove
                  </Button>

                  <Button
                    onClick={() => handleAddNote(field)}
                    style={{ background: "#d97228", color: "white" }}
                  >
                    {field.isNew
                      ? `Submit Note ${index + 1}`
                      : `Update Note ${index + 1}`}
                  </Button>
                </div>
              ))}
              <Button type="dashed" onClick={handleAddField}>
                + Add Note
              </Button>
            </Modal>

            {lisitngData && lisitngData.length > 0 ? (
              <div className="row align-items-center">
                <div className="col-sm-12 col-md-5">
                  Showing {currentPage * perPage - perPage + 1} to{" "}
                  {totalRecord > currentPage * perPage
                    ? currentPage * perPage
                    : totalRecord}{" "}
                  of {totalRecord} entries
                </div>
                <div className="col-sm-12 col-md-7">
                  <div
                    className="dataTables_paginate paging_simple_numbers text-right"
                    id="datatable_paginate"
                  >
                    <ReactPaginate
                      previousLabel={"Prev"}
                      nextLabel={"Next"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={pageCount}
                      onPageChange={handlePageClick}
                      containerClassName={"pagination"}
                      subContainerClassName={"pages pagination"}
                      pageClassName={"paginate_button page-item"}
                      activeClassName={"active"}
                    />
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            <div className="inventoryCardFooter">
              <button
                className="myBtn danger squre"
                onClick={() => deleteInventoryAll(deleteItem)}
              >
                <Delete />
              </button>
            </div>
          </div>
        </section>
      </MainLayout>

      <div className="d-none print-only-block">
        {printData && (
          <Application
            key={printData ? printData?._id : -1}
            printData={printData}
            ref={applicationRef}
          />
        )}
        {/* <Application ref={applicationRef} /> */}
      </div>
    </>
  );
};

//  ApplicationListing;
